import {CreationStep} from "../CreationStep";
import {Button, Col, Form, Row, Select, Typography} from "antd";
import React, {useEffect} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../../utils/graphql/queries";
import {ListThingsByThingGroupQuery} from "../../../utils/graphql/queries";

export const SensorInstructions = ({instructions, onStepComplete, onStepBack}: CreationStep) => {
  const [form] = Form.useForm();

  const [sensors, setSensors] = React.useState<string[]>([]);
  const [selectedSensors, setSelectedSensors] = React.useState<string[]>([]);

  const loadSensors = async () => {
    const tmpSensors: string[] = [];
    let nextToken = null;

    do {
      const response: any = await API.graphql<GraphQLQuery<ListThingsByThingGroupQuery>>({
        query: queries.listThingsByThingGroup,
        variables: {
          page: {
            nextToken: nextToken,
          },
          thingGroupName: "smartfloor",
          recursive: true,
        },
      });

      response.data?.listThingsByThingGroup.items.forEach((item: any) => {
        if (item.thingType === 'heelable') {
          tmpSensors.push(item.name);
        }
      });

      if (response.data?.listThingsByThingGroup.nextToken) {
        nextToken = response.data?.listThingsByThingGroup.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken);

    // sort sensors
    tmpSensors.sort();

    setSensors(tmpSensors);
  }

  useEffect(() => {
    loadSensors().then();
  }, [instructions]);

  const onFinish = (values: any) => {
    if (instructions.groups) {
      instructions.groups[0].sensors = values.sensors;
    }

    onStepComplete(instructions);
  }

  const filterSensors = sensors.filter((sensor) => {
    return !selectedSensors.includes(sensor);
  });

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Assign sensors</Typography.Title>
          <Typography.Paragraph>
            Select the sensors that are assigned to this group. The sensors can only be assigned to one group at a time.
            If you select a sensor that is already assigned to another group, it will be removed from that group.
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: "20px"}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            initialValues={{
              sensors: instructions.groups ? instructions.groups[0].sensors : [],
            }}
          >
            <Form.Item
              label={"Sensors"}
              name={"sensors"}
            >
              <Select
                mode="multiple"
                placeholder="select sensors"
                value={selectedSensors}
                onChange={setSelectedSensors}
                style={{ width: '100%' }}
                options={filterSensors.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button onClick={onStepBack}>Back</Button>
    </>
  )
}