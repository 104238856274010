import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {
    GetUserWearableLinkQuery,
    GetUserWearableLinkQueryVariables,
    ListFrpsForWearableQuery, ListFrpsForWearableQueryVariables
} from "../../utils/graphql/queries";
import * as queries from "../../utils/graphql/queries";
import * as mutations from "../../utils/graphql/mutations";
import {Alert, App, Button, Card, Col, Form, Input, InputNumber, Modal, Row, Table} from "antd";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
    UpdateUserWearableLinkUserMutation,
    UpdateUserWearableLinkWearableMutation
} from "../../utils/graphql/mutations";
import {ThingSelector} from "../DeviceDiagnostics/ThingSelector";

dayjs.extend(relativeTime);

interface UserWearableLink {
    id: number,
    userId: number,
    wearableId: string
    startTime: number
    endTime: number
}


interface ModifyUserUWLProps {
    uwlId: number | null
    reload: () => void
}


const ModifyUserUWL = ({uwlId, reload}: ModifyUserUWLProps) => {
    const [open, setOpen] = useState(false);
    const {notification} = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        const success = await updateUserWearableLinkUser(values.userId);

        if (success) {
            notification.success({
                message: 'Success',
                description: 'User ID updated'
            });
            onClose();
            reload();
        }
        setLoading(false);
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const updateUserWearableLinkUser = async (userId: number) => {
        const response = await API.graphql<GraphQLQuery<UpdateUserWearableLinkUserMutation>>({
            query: mutations.updateUserWearableLinkUser,
            variables: {
                id: uwlId,
                userId: userId
            }
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        });

        return !!(response && response.data);
    }

    return (
        <>
            <Modal
                title="Update User ID"
                open={open}
                onOk={() => form.submit()}
                onCancel={() => onClose()}
                confirmLoading={loading}
            >
                <Alert
                    message="Warning"
                    description="Make sure that the updated user ID is within the same tenant as the wearable (during the same time period)."
                    type="warning"
                    showIcon
                />
                <div style={{height: '10px'}}/>
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="userId"
                        label="User ID"
                        rules={[
                            {required: true, message: 'Please enter a user ID'},
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
            <Button
                type="link"
                onClick={() => setOpen(true)}
            >
                edit
            </Button>
        </>
    );
}


interface ModifyWearableUWLProps {
    uwlId: number | null
    reload: () => void
}


const ModifyWearableUWL = ({uwlId, reload}: ModifyWearableUWLProps) => {
    const [open, setOpen] = useState(false);
    const {notification} = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        const success = await updateUserWearableLinkWearable(values.wearableId);

        if (success) {
            notification.success({
                message: 'Success',
                description: 'Wearable ID updated'
            });
            onClose();
            reload();
        }
        setLoading(false);
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const updateUserWearableLinkWearable = async (wearableId: string) => {
        const response = await API.graphql<GraphQLQuery<UpdateUserWearableLinkWearableMutation>>({
            query: mutations.updateUserWearableLinkWearable,
            variables: {
                id: uwlId,
                wearableId: wearableId
            }
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        });

        return !!(response && response.data);
    }

    return (
        <>
            <Modal
                title="Update Wearable ID"
                open={open}
                onOk={() => form.submit()}
                onCancel={() => onClose()}
                confirmLoading={loading}
            >
                <Alert
                    message="Warning"
                    description="Make sure that the updated wearable ID is within the same tenant as the user (during the same time period)."
                    type="warning"
                    showIcon
                />
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="wearableId"
                        label="Wearable ID"
                    >
                        <ThingSelector
                            onSelect={(value) => form.setFieldsValue({wearableId: value})}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Button
                type="link"
                onClick={() => setOpen(true)}
            >
                edit
            </Button>
        </>
    );
}


interface UWLFRPProps {
    uwl: UserWearableLink | null
}

interface FallRiskProfileData {
    wearableId: string;
    createdAt: number;
    floor: {
        id: number;
        name: string;
    };
    walkingSpeed: number;
    strideLength: number;
    strideFrequency: number,
    total: number
}


const UWLFRP = ({uwl}: UWLFRPProps) => {
    // List all the FRPs for this UWL
    const {notification} = App.useApp();
    const [fallRiskProfiles, setFallRiskProfiles] = useState<FallRiskProfileData[]>([]);
    const [loading, setLoading] = useState(true);


    const loadFallRiskProfiles = async (wearableId: string, startTime: number, endTime: number) => {
        setLoading(true);
        let nextToken = null;
        let response = null;
        let fallRiskProfiles: FallRiskProfileData[] = [];

        do {
            response = await API.graphql<GraphQLQuery<ListFrpsForWearableQuery>>({
                query: queries.listFrpsForWearable,
                variables: {
                    wearableId: wearableId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListFrpsForWearableQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'Fall risk profiles failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listFrpsForWearable.items) {
                response.data.listFrpsForWearable.items.forEach((item) => {
                    fallRiskProfiles.push(item);
                });
            }

            nextToken = response?.data?.listFrpsForWearable.nextToken ?? null;
        } while (nextToken !== null);

        setFallRiskProfiles(fallRiskProfiles);
        setLoading(false);
    }

    useEffect(() => {
        if (!uwl) {
            return;
        }
        // If end time is in the future, set it to now
        let endTime = uwl.endTime;
        if (endTime > dayjs().unix()) {
            endTime = dayjs().unix();
        }
        loadFallRiskProfiles(uwl.wearableId, uwl.startTime, endTime).then();
    }, [uwl]);


    return (
        <Col span={12}>
            <Card title="Fall Risk Profiles">
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            render: (timestamp: number) => {
                                return new Date(timestamp * 1000).toLocaleString();
                            }
                        },
                        {
                            title: 'Floor',
                            dataIndex: 'floor',
                            key: 'floor',
                            render: (floor: { id: number, name: string }) => {
                                return `${floor.name} (${floor.id})`;
                            }
                        },
                        {
                            title: 'Walking Speed',
                            dataIndex: 'walkingSpeed',
                            key: 'walkingSpeed',
                        },
                        {
                            title: 'Stride Length',
                            dataIndex: 'strideLength',
                            key: 'strideLength',
                        },
                        {
                            title: 'Stride Frequency',
                            dataIndex: 'strideFrequency',
                            key: 'strideFrequency',
                        },
                        {
                            title: 'Total',
                            dataIndex: 'total',
                            key: 'total',
                        },
                    ]}
                    dataSource={fallRiskProfiles}
                    size={'small'}
                />
            </Card>
        </Col>
    );
}


const UserWearableLinkExplorer = () => {
    const location = useLocation();
    const {notification} = App.useApp();

    const getUserWearableLinkIdFromPath = () => {
        const urlParams = new URLSearchParams(location.search);
        const uwlId = urlParams.get('id') ?? null;
        if (uwlId !== null) {
            return parseInt(uwlId);
        }
        return null;
    }

    const [uwlId, setUwlId] = useState<number | null>(getUserWearableLinkIdFromPath());
    const [userWearableLink, setUserWearableLink] = useState<UserWearableLink | null>(null);

    useEffect(() => {
        setUwlId(getUserWearableLinkIdFromPath());
    }, [location]);

    const fetchUserWearableLink = async () => {
        const response: any = await API.graphql<GraphQLQuery<GetUserWearableLinkQuery>>({
            query: queries.getUserWearableLink,
            variables: {
                id: uwlId
            } as GetUserWearableLinkQueryVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        });

        if (response && response.data) {
            setUserWearableLink(response.data.getUserWearableLink);
        }
    }

    useEffect(() => {
        if (!uwlId) {
            return;
        }
        fetchUserWearableLink().then();
    }, [uwlId]);

    const formatTime = (time: number | undefined | null) => {
        if (time === null) {
            return 'never';
        }
        if (!time) {
            return 'loading...';
        }

        const formattedTime = dayjs(time * 1000).format('YYYY-MM-DD HH:mm:ss');
        const relativeTime = dayjs(time * 1000).fromNow();

        return `${formattedTime} (${relativeTime})`;
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card
                        title="User Wearable Link Information"
                        style={{width: '100%'}}
                    >
                        <Button
                            type="primary"
                            onClick={() => fetchUserWearableLink()}
                            loading={!userWearableLink}
                        >
                            Refresh
                        </Button>
                        <Table
                            columns={[
                                {
                                    title: 'Attribute',
                                    dataIndex: 'attribute',
                                    key: 'attribute',
                                },
                                {
                                    title: 'Value',
                                    dataIndex: 'value',
                                    key: 'value',
                                },
                                {
                                    title: 'Operation',
                                    dataIndex: 'operation',
                                    key: 'operation',
                                }
                            ]}
                            dataSource={[
                                {
                                    key: 'id',
                                    attribute: 'ID',
                                    value: userWearableLink?.id,
                                },
                                {
                                    key: 'userId',
                                    attribute: 'User ID',
                                    value: userWearableLink?.userId,
                                    operation: <ModifyUserUWL uwlId={uwlId} reload={fetchUserWearableLink}/>,
                                },
                                {
                                    key: 'wearableId',
                                    attribute: 'Wearable ID',
                                    value: <a
                                        href={'./device-diagnostics?device_id=' + userWearableLink?.wearableId}>{userWearableLink?.wearableId}</a>,
                                    operation: <ModifyWearableUWL uwlId={uwlId} reload={fetchUserWearableLink}/>,
                                },
                                {
                                    key: 'startTime',
                                    attribute: 'Start Time',
                                    value: formatTime(userWearableLink?.startTime),
                                },
                                {
                                    key: 'endTime',
                                    attribute: 'End Time',
                                    value: formatTime(userWearableLink?.endTime),
                                },
                            ]}
                            pagination={false}
                        />
                    </Card>
                </Col>
                <UWLFRP uwl={userWearableLink}/>
            </Row>
        </>
    )
}

export default UserWearableLinkExplorer;
