import React, { useState } from 'react';
import { Period, ReportType } from './CustomerInsightsView';
import { Col, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import { CustomerInsightsReportData } from './ReportLayoutWrapper';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { AGGREGATE_STATS_FIELD_NAME, AgeStats, FloorWithColor, GenderBreakdown, HIGH_RISK_FIELD, LOW_RISK_FIELD, LocationWithColor, MEDIUM_RISK_FIELD, TOTAL_STATS_FIELD_NAME, YEAR_TO_DATE_STATS_FIELD_NAME } from './reportUtils';

interface ReportLayoutProps {
    municipalityName: string,
    periods: Period[]
    aggregatePeriod: Period,
    yearToDatePeriod?: Period,
    totalPeriod?: Period,
    reportData: CustomerInsightsReportData,
    reportTypeIsFRP: boolean
    includeLocationBreakdown: boolean
}

/**
 * The layout for the municipality PDF report.
 */
const ReportLayout = React.forwardRef((props: ReportLayoutProps, ref: any) => {
    const {
        municipalityName,
        periods,
        aggregatePeriod,
        yearToDatePeriod,
        totalPeriod,
        reportData,
        reportTypeIsFRP,
        includeLocationBreakdown
    } = props;

    const [figuresList, setFiguresList] = useState<string[]>([]);
    const [tablesList, setTablesList] = useState<string[]>([]);
    const mustShowAggregateStats = periods.length > 1;
    const mustShowTotalStats = totalPeriod !== undefined;
    const mustShowYearToDateStats = yearToDatePeriod !== undefined;

    function addNewFigureWithName(name: string) {
        const existingFigureName = figuresList.find(figureName => figureName === name);
        if (existingFigureName === undefined) {
            setFiguresList(prev => (Array.from(new Set([...prev, name]))));
        }
    }

    function getFigureNumberForFigureWithName(name: string) {
        const existingFigureName = figuresList.find((figureName) => figureName === name);
        if (existingFigureName !== undefined) {
            return figuresList.indexOf(existingFigureName) + 1;
        }
        return 0;
    }

    function addNewTableWithName(name: string) {
        const existingTableName = tablesList.find(tableName => tableName === name);
        if (existingTableName === undefined) {
            setTablesList(prev => (Array.from(new Set([...prev, name]))));
        }
    }

    function getTableNumberForTableWithName(name: string) {
        const existingTableName = tablesList.find((tableName) => tableName === name);
        if (existingTableName !== undefined) {
            return tablesList.indexOf(existingTableName) + 1;
        }
        return 0;
    }

    function getAggregateDescription() {
        let aggregateDescription = '';
        periods.forEach((p: Period, idx: number) => {
            aggregateDescription += p.name;
            if (idx < periods.length - 1) {
                aggregateDescription += ' + ';
            }
        });
        return aggregateDescription;
    }

    function generateLegend() {
        function getFormattedRow(period: Period, description?: string) {
            return <Row key={period.name}><b>{period.name + ' '}</b>: {
                description ||
                `${period.dates[0].format('DD-MM-YYYY')} - ${period.dates[1].format('DD-MM-YYYY')}`
            }</Row>
        }

        const rows: any[] = periods.map(p => getFormattedRow(p));
        if (mustShowAggregateStats) {
            rows.push(getFormattedRow(
                aggregatePeriod,
                getAggregateDescription()
            ));
        }
        if (mustShowYearToDateStats) {
            rows.push(getFormattedRow(
                yearToDatePeriod,
                `Jaar tot nu toe (${yearToDatePeriod.dates[0].format('DD-MM-YYYY')} - ${yearToDatePeriod.dates[1].format('DD-MM-YYYY')})`
            ));
        }
        if (mustShowTotalStats) {
            rows.push(getFormattedRow(totalPeriod, 'Metingen van alle tijden'));
        }

        return rows;
    }

    return <div ref={ref}>
        <div>
            <h3>Data rapportage Smart Floor</h3>
            <div style={{ border: '1px solid', padding: 10 }} className="page-break">
                <Row>
                    <Col span={12}>Gemeente:</Col>
                    <Col span={12}>{municipalityName}</Col>
                </Row>
                <Row>
                    <Col span={12}>Periode:</Col>
                    <Col span={12}>{generateLegend()}</Col>
                </Row>
                <Row>
                    <Col span={12}>Opgesteld op:</Col>
                    <Col span={12}>{moment().format('DD-MM-YYYY')}</Col>
                </Row>
            </div>
            <h3>1. Inleiding</h3>
            <p>Dit rapport is opgesteld door Smart Floor door middel van data gegenereerd door gebruik van Smart
                Floor vloer(en) in combinatie met gegevens aangeleverd op de Smart Floor Vitality pagina. In dit
                rapport presenteren we de resultaten van de metingen in uw gemeente. Deze rapportage beslaat
                metingen die hebben plaatsgevonden in de periode: {getAggregateDescription()}. Uitgangspunt is dat per Smart Floor ID
                één persoon is gemeten.</p>

            <h3>2. Resultaten</h3>
            <h4>2.1 Overzicht gemeente</h4>
            <DemographicsTable
                clientCount={reportData.clientCountPerPeriod[TOTAL_STATS_FIELD_NAME]}
                genderBreakdown={reportData.clientGendersPerPeriod[TOTAL_STATS_FIELD_NAME]}
                ageStats={reportData.clientAgesPerPeriod[TOTAL_STATS_FIELD_NAME]}
                clientsWithWalkingAid={reportData.allClientsWalkingAid}
                addNewTableWithName={addNewTableWithName}
                getTableNumberForTableWithName={getTableNumberForTableWithName}
            />
            <PeriodDetailsTable
                clientCountPerPeriod={reportData.clientCountPerPeriod}
                clientGendersPerPeriod={reportData.clientGendersPerPeriod}
                clientAgesPerPeriod={reportData.clientAgesPerPeriod}
                floorsPerPeriod={reportData.floorsPerPeriod}
                periods={periods}
                addNewTableWithName={addNewTableWithName}
                getTableNumberForTableWithName={getTableNumberForTableWithName}
                reportTypeIsFRP={reportTypeIsFRP}
            />
            {yearToDatePeriod !== undefined &&
                <YearToDateStatsTable
                    avgMeasurementsPerClientForYears={reportData.avgMeasurementsPerClientForYears!}
                    maxMeasurementsPerClientForYears={reportData.maxMeasurementsPerClientForYears!}
                    addNewTableWithName={addNewTableWithName}
                    getTableNumberForTableWithName={getTableNumberForTableWithName}
                />
            }
            {mustShowTotalStats && reportTypeIsFRP && <div style={{ pageBreakInside: 'avoid' }}>
                <p>Onderstaande figuur toont de aantallen personen per vloer.</p>
                <ClientsMeasuredPerSubgroupPerPeriod
                    data={reportData.clientCountPerFloorPerPeriod}
                    subgroups={reportData.floors}
                    showPeriodsWithNames={[TOTAL_STATS_FIELD_NAME]}
                    figureTitle='Totaal aantal personen gemeten per vloer'
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    subgroupId='id'
                />
            </div>}
            {/* There is no need to display the aggregate when only one period is selected. */}
            {mustShowAggregateStats && reportTypeIsFRP && <div style={{ pageBreakInside: 'avoid' }}>
                <p>Onderstaande figuur toont de aantallen personen per vloer in de periode: {aggregatePeriod.name}.</p>
                <ClientsMeasuredPerSubgroupPerPeriod
                    data={reportData.clientCountPerFloorPerPeriod}
                    subgroups={reportData.floors}
                    showPeriodsWithNames={[aggregatePeriod.name]}
                    figureTitle='Aantal personen gemeten per vloer in de tijd'
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    subgroupId='id'
                />
            </div>}
            {reportTypeIsFRP && <div style={{ pageBreakInside: 'avoid' }}>
                <p>Onderstaande figuur toont de aantallen personen per vloer per periode.</p>
                <ClientsMeasuredPerSubgroupPerPeriod
                    data={reportData.clientCountPerFloorPerPeriod}
                    subgroups={reportData.floors}
                    showPeriodsWithNames={[...periods.map(p => p.name)]}
                    figureTitle='Aantal personen gemeten per vloer in de tijd'
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    subgroupId='id'
                />
            </div>}
            {reportTypeIsFRP && <div style={{ pageBreakInside: 'avoid' }}>
                <p>Onderstaande tabel toont de aantallen personen in uw gemeente die vaker gemeten worden. Dit
                    betreft alle periodes.</p>
                <ClientsMeasuredMoreOftenTable
                    data={reportData.clientCountPerFRPCountPerPeriod}
                    periods={periods}
                    addNewTableWithName={addNewTableWithName}
                    getTableNumberForTableWithName={getTableNumberForTableWithName}
                />
            </div>}
            <h4>2.2 Verdeling risicocategorieën</h4>
            <p>De onderstaande figuren tonen het aantal personen per risicocategorie (laag-verhoogd-hoog). {mustShowTotalStats &&
                'Percentages ten opzichte van het totaal staan weergegeven.'}</p>
            <ClientsPerFRPCategoryPerPeriodOrLocation
                data={reportData.clientCountPerFRPCategoryPerPeriod}
                addNewFigureWithName={addNewFigureWithName}
                getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                isPeriod={true}
            />
            {reportTypeIsFRP && <div>
                <p>De onderstaande figuren tonen het aantal personen per risicocategorie (laag-verhoogd-hoog) per vloer. {mustShowTotalStats &&
                    'Percentages ten opzichte van het totaal van die vloer staan weergegeven.'}</p>
                <ClientsPerFRPCategoryPerFloorOrLocationPerPeriod
                    data={reportData.clientCountPerFRPCategoryPerPeriodPerFloor}
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    isFloor={true}
                />
            </div>}
            <div style={{ pageBreakInside: 'avoid' }}>
                <p>Onderstaande figuur toont de aantallen personen per locatie in de periode: {mustShowAggregateStats ? aggregatePeriod.name : periods[0].name}.</p>
                <ClientsMeasuredPerSubgroupPerPeriod
                    data={reportData.clientCountPerLocationPerPeriod}
                    subgroups={reportData.locations}
                    showPeriodsWithNames={[aggregatePeriod.name]}
                    figureTitle='Aantal personen gemeten per locatie in de tijd'
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    subgroupId='name'
                />
            </div>

            {reportTypeIsFRP && includeLocationBreakdown && <div>
                <p>De onderstaande figuren tonen het aantal personen per risicocategorie (laag-verhoogd-hoog) per locatie in de periode: {mustShowAggregateStats ? aggregatePeriod.name : periods[0].name}.</p>
                <ClientsPerFRPCategoryPerPeriodOrLocation
                    data={reportData.clientCountPerFRPCategoryPerLocation}
                    addNewFigureWithName={addNewFigureWithName}
                    getFigureNumberForFigureWithName={getFigureNumberForFigureWithName}
                    isPeriod={false}
                />
            </div>
            }
        </div>
    </div>
})

const CustomAxis = ({ text, orientation, x, y }: { text: string, orientation: 'horizontal' | 'vertical', x?: any, y?: any }) => {
    return (
        orientation === 'horizontal' ?
            <svg>
                <text x={x || "90%"} y={y || "85%"} fontSize={16} fill='gray'>{text}</text>
            </svg>
            :
            <svg>
                <text x={x || "5%"} y={y || "10%"} fontSize={16} fill='gray' transform="translate(0,230) rotate(-90)">{text}</text>
            </svg>
    )
}

function getColorForFRPCategory(risk: any) {
    const colors: any = { [HIGH_RISK_FIELD]: '#ff0a00', [MEDIUM_RISK_FIELD]: '#fa9604', [LOW_RISK_FIELD]: '#46c767' };
    return colors[risk];
}

const DemographicsTable = (
    {
        clientCount,
        genderBreakdown,
        ageStats,
        clientsWithWalkingAid,
        addNewTableWithName,
        getTableNumberForTableWithName
    }: {
        clientCount: number,
        genderBreakdown: GenderBreakdown,
        ageStats: AgeStats,
        clientsWithWalkingAid: { count: number, percentage: number },
        addNewTableWithName: (name: string) => void,
        getTableNumberForTableWithName: (name: string) => number
    }
) => {
    const firstColumnValues: { [statistic: string]: string } = {
        'clientCount': 'Totaal aantal personen gemeten in gemeente',
        'genderBreakdown': 'Mannen/Vrouwen/Niet-gespecificeerd/Onbekend',
        'ageStats': 'Gemiddelde leeftijd',
        'clientsWithWalkingAid': 'Totaal aantal personen met loophulpmiddel',
    };

    let rows: any[] = [];
    const totalField = 'value';
    const tableName = 'demographics';
    addNewTableWithName(tableName);

    // A bit butchered but it's late
    Object.entries(firstColumnValues).forEach(([fieldName, translation]) => {
        const row: any = {
            statName: translation
        };
        switch (fieldName) {
            case 'clientCount':
                row[totalField] = clientCount;
                break;
            case 'genderBreakdown':
                row[totalField] = `${genderBreakdown.m.count} (${genderBreakdown.m.percentage.toFixed(1)}%) / ${genderBreakdown.f.count} (${genderBreakdown.f.percentage.toFixed(1)}%) / ${genderBreakdown.x.count} (${genderBreakdown.x.percentage.toFixed(1)}%) / ${genderBreakdown.missingData.count} (${genderBreakdown.missingData.percentage.toFixed(1)}%)`;
                break;
            case 'ageStats':
                row[totalField] = `${ageStats.avgAge.toFixed(1)} (SD ${ageStats.sd.toFixed(1)}) jaar`;
                break;
            case 'clientsWithWalkingAid':
                row[totalField] = `${clientsWithWalkingAid.count} (${clientsWithWalkingAid.percentage.toFixed(1)}%)`;
                break;
            default:
                break;
        }
        rows.push(row);
    });

    const columns: { title?: string, dataIndex: string, key: string }[] = [
        {
            dataIndex: 'statName',
            key: 'statName',
        },
        {
            dataIndex: totalField,
            key: totalField,
        }
    ];

    return <>
        <p>Tabel {getTableNumberForTableWithName(tableName)}: Demografische gegevens</p>
        <Table
            dataSource={rows}
            columns={columns}
            pagination={false}
            size='small'
            bordered
            showHeader={false}
        />
        <br />
    </>;
}

const PeriodDetailsTable = (
    {
        clientCountPerPeriod,
        clientGendersPerPeriod,
        clientAgesPerPeriod,
        floorsPerPeriod,
        periods,
        addNewTableWithName,
        getTableNumberForTableWithName,
        reportTypeIsFRP
    }: {
        clientCountPerPeriod: { [periodName: string]: number },
        clientGendersPerPeriod: { [periodName: string]: GenderBreakdown },
        clientAgesPerPeriod: { [periodName: string]: AgeStats },
        floorsPerPeriod: { [periodName: string]: FloorWithColor[] },
        periods: Period[],
        addNewTableWithName: (name: string) => void,
        getTableNumberForTableWithName: (name: string) => number,
        reportTypeIsFRP: boolean
    }
) => {
    const tableName = 'periodDetails';
    addNewTableWithName(tableName);

    const firstColumnValues: { [statistic: string]: string } = {
        'floorsPerPeriod': 'Aantal Smart Floor vloeren',
        'clientCountPerPeriod': 'Aantal personen gemeten*',
        'clientGendersPerPeriod': 'Mannen/Vrouwen/Niet-gespecifieerd/Onbekend',
        'clientAgesPerPeriod': 'Gemiddelde leeftijd',
    };

    if (!reportTypeIsFRP) {
        delete firstColumnValues['floorsPerPeriod'];
    }

    let rows: any[] = [];

    // A bit butchered but it's late
    Object.entries(firstColumnValues).forEach(([fieldName, translation]) => {
        const row: any = {
            statName: translation
        };
        periods.forEach(p => {
            switch (fieldName) {
                case 'floorsPerPeriod':
                    row[p.name] = floorsPerPeriod[p.name].length;
                    break;
                case 'clientCountPerPeriod':
                    row[p.name] = clientCountPerPeriod[p.name];
                    break;
                case 'clientGendersPerPeriod':
                    row[p.name] = `${clientGendersPerPeriod[p.name].m.count} (${clientGendersPerPeriod[p.name].m.percentage.toFixed(1)}%) / ${clientGendersPerPeriod[p.name].f.count} (${clientGendersPerPeriod[p.name].f.percentage.toFixed(1)}%) / ${clientGendersPerPeriod[p.name].x.count} (${clientGendersPerPeriod[p.name].x.percentage.toFixed(1)}%) / ${clientGendersPerPeriod[p.name].missingData.count} (${clientGendersPerPeriod[p.name].missingData.percentage.toFixed(1)}%)`;
                    break;
                case 'clientAgesPerPeriod':
                    row[p.name] = `${clientAgesPerPeriod[p.name].avgAge.toFixed(1)} (SD ${clientAgesPerPeriod[p.name].sd.toFixed(1)}) jaar`;
                    break;
                default:
                    break;
            }
        });
        rows.push(row);
    });

    const columns: { title?: string, dataIndex: string, key: string }[] = [
        {
            dataIndex: 'statName',
            key: 'statName',
        }
    ];

    periods.forEach(p => {
        columns.push({
            title: p.name,
            dataIndex: p.name,
            key: p.name,
        });
    });

    return <>
        <p>Tabel {getTableNumberForTableWithName(tableName)}: Periode gegevens</p>
        <Table
            dataSource={rows}
            columns={columns}
            pagination={false}
            size='small'
            bordered
        />
        <p><i>* aantal Smart Floor ID's</i></p>
        <br />
    </>;
}

const YearToDateStatsTable = ({
    avgMeasurementsPerClientForYears,
    maxMeasurementsPerClientForYears,
    addNewTableWithName,
    getTableNumberForTableWithName
}: {
    avgMeasurementsPerClientForYears: number,
    maxMeasurementsPerClientForYears: number,
    addNewTableWithName: (name: string) => void,
    getTableNumberForTableWithName: (name: string) => number
}) => {
    const tableName = 'yearToDateStats';
    addNewTableWithName(tableName);
    const columns: { title?: string, dataIndex: string, key: string, width?: number }[] = [
        {
            dataIndex: 'name',
            key: 'name',
            width: 500
        },
        {
            dataIndex: 'value',
            key: 'value',
        }
    ];

    let rows: any[] = [{
        name: `Gemiddeld aantal keer dat één persoon is gemeten per ${YEAR_TO_DATE_STATS_FIELD_NAME}`,
        value: avgMeasurementsPerClientForYears.toFixed(1)
    },
    {
        name: `Maximaal aantal keer dat één persoon is gemeten per ${YEAR_TO_DATE_STATS_FIELD_NAME}`,
        value: maxMeasurementsPerClientForYears
    }];

    return <div style={{ pageBreakInside: 'avoid' }}>
        <p>Tabel {getTableNumberForTableWithName(tableName)}: Jaarlijkse gegevens</p>
        <Table
            dataSource={rows}
            columns={columns}
            pagination={false}
            size='small'
            showHeader={false}
            bordered
        />
        <br />
    </div>;
}

/**
 * Normal bar charts.
 */
const ClientsMeasuredPerSubgroupPerPeriod = ({
    data,
    subgroups,
    showPeriodsWithNames,
    figureTitle,
    addNewFigureWithName,
    getFigureNumberForFigureWithName,
    subgroupId
}: {
    data: {
        [periodName: string]: {
            [subgroup: number | string]: number;
        };
    },
    subgroups: FloorWithColor[] | LocationWithColor[],
    showPeriodsWithNames: string[],
    figureTitle: string,
    addNewFigureWithName: (name: string) => void,
    getFigureNumberForFigureWithName: (name: string) => number,
    subgroupId: 'id' | 'name'
}) => {
    const figureName = `clientsPerFloorOrLocation${subgroupId}${showPeriodsWithNames}`;
    addNewFigureWithName(figureName);
    const dataEntries = Object.entries(data)
        .filter(([periodName, dataPerPeriod]) => showPeriodsWithNames.includes(periodName));

    // Display ticks and X axis label only when the periods are multiple.
    // Showing the period name when the whole graph is just for that one period clutters the graph and adds no new information.
    const mustDisplayXAxisTicks =
        !showPeriodsWithNames.includes(TOTAL_STATS_FIELD_NAME) &&
        !showPeriodsWithNames.includes(AGGREGATE_STATS_FIELD_NAME);
    const dataForChart = dataEntries
        .map(([periodName, dataPerPeriod]) => {
            const dataPerPeriodForChart: { [periodNameOrFloorName: string]: number | string } = { periodName };
            subgroups.forEach(s => {
                dataPerPeriodForChart[s.name] = dataPerPeriod[s[subgroupId]] || 0;
            });
            return dataPerPeriodForChart;
        });

    return (<>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={dataForChart}
                margin={{
                    top: 20,
                    right: 5,
                    left: 20,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="periodName"
                    // For now, we are hiding the label, as it does not convey any new information. It also clutters the graph.
                    // label={<CustomAxis text={mustDisplayXAxisTicks ? "Periode" : "Vloeren"} orientation='horizontal' />}
                    tick={mustDisplayXAxisTicks ? { fontSize: 16 } : false}
                />
                <YAxis label={<CustomAxis text="Aantal personen" orientation='vertical' />} />
                <Tooltip />
                <Legend />
                {subgroups.map((f, idx) => (
                    <Bar
                        isAnimationActive={false}
                        key={idx}
                        dataKey={f.name}
                        fill={f.color}
                        barSize={80}
                        label={{ fontSize: 18, position: 'top' }}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
        <p>Figuur {getFigureNumberForFigureWithName(figureName)}: {figureTitle}</p>
        <br />
    </>
    );
}

const ClientsMeasuredMoreOftenTable = ({
    data,
    periods,
    addNewTableWithName,
    getTableNumberForTableWithName
}: {
    data: any[],
    periods: Period[],
    addNewTableWithName: (name: string) => void,
    getTableNumberForTableWithName: (name: string) => number
}) => {
    const tableName = 'measuredMoreOften';
    addNewTableWithName(tableName);

    const columns = [
        {
            title: 'Aantal personen* met:',
            dataIndex: 'numberOfMeasurements',
            key: 'numberOfMeasurements',
        }
    ];

    periods.forEach(p => {
        columns.push({
            title: p.name,
            dataIndex: p.name,
            key: p.name
        });
    });

    const mustDisplayYearlyColumn = data[0][YEAR_TO_DATE_STATS_FIELD_NAME] !== undefined;
    const mustDisplayTotalColumn = data[0][TOTAL_STATS_FIELD_NAME] !== undefined;

    mustDisplayYearlyColumn && columns.push({
        title: YEAR_TO_DATE_STATS_FIELD_NAME,
        dataIndex: YEAR_TO_DATE_STATS_FIELD_NAME,
        key: YEAR_TO_DATE_STATS_FIELD_NAME,
    });

    mustDisplayTotalColumn && columns.push({
        title: TOTAL_STATS_FIELD_NAME,
        dataIndex: TOTAL_STATS_FIELD_NAME,
        key: TOTAL_STATS_FIELD_NAME,
    });

    return <>
        <p>Tabel {getTableNumberForTableWithName(tableName)}: Aantal personen dat vaker gemeten wordt</p>
        <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            size='small'
            bordered
        />
        <p><i>* aantal Smart Floor ID's</i></p>
        <br />
    </>;
}

/**
 * Pie charts
 */
const ClientsPerFRPCategoryPerPeriodOrLocation = ({ data, getFigureNumberForFigureWithName, addNewFigureWithName, isPeriod }: {
    data: {
        [periodNameOrLocation: string]: {
            name: string // FRP category 
            clientCount: number
        }[]
    },
    getFigureNumberForFigureWithName: (name: string) => number,
    addNewFigureWithName: (figureName: string) => void,
    isPeriod: boolean
}) => {
    const RADIAN = Math.PI / 180;

    function getFigureTitle(periodOrLocationName: string) {
        if (!isPeriod) {
            return `Aantal personen per risicocategorie (laag-verhoogd-hoog) in de locatie ${periodOrLocationName}`;
        }
        switch (periodOrLocationName) {
            case TOTAL_STATS_FIELD_NAME:
                return `${periodOrLocationName} aantal personen per risicocategorie (laag-verhoogd-hoog)`;
            case YEAR_TO_DATE_STATS_FIELD_NAME:
                return `Aantal personen per risicocategorie (laag-verhoogd-hoog) per ${periodOrLocationName}`;
            default:
                return `Aantal personen per risicocategorie (laag-verhoogd-hoog) in de periode ${periodOrLocationName}`;
        }
    }

    return <div style={{ flexWrap: 'wrap', display: 'flex' }}>{
        Object.entries(data).map(([periodOrLocation, dataPerPeriodOrLocation]) => {
            let figureName = `pieChart${periodOrLocation}`;
            addNewFigureWithName(figureName);

            const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                return (
                    <text x={x} y={y} fill={getColorForFRPCategory(dataPerPeriodOrLocation[index].name)} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                        {dataPerPeriodOrLocation[index].clientCount} ({`${(percent * 100).toFixed(1)}%`})
                    </text>
                );
            };

            const dataForAnyCategoryExists = dataPerPeriodOrLocation.find(category => category.clientCount > 0);

            return (<div
                key={`pie${periodOrLocation}`}
                style={{
                    width: '50%',
                    borderRadius: 10,
                    padding: 10,
                    pageBreakInside: 'avoid'
                }}>
                {dataForAnyCategoryExists ?
                    <div style={{
                        width: '100%', height: 300, alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <PieChart width={400} height={300}>
                            <Pie
                                isAnimationActive={false}
                                data={dataPerPeriodOrLocation}
                                cx="50%"
                                cy="50%"
                                label={renderCustomizedLabel}
                                fill="#8884d8"
                                dataKey="clientCount"
                                outerRadius={80}
                            >
                                {dataPerPeriodOrLocation.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={getColorForFRPCategory(entry.name)} />
                                ))}
                            </Pie>
                            <Legend width={450} height={30} align='right' />
                        </PieChart>
                    </div> :
                    <div style={{
                        width: '100%',
                        height: 300,
                        paddingBottom: 30,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            width: 160,
                            height: 160,
                            backgroundColor: 'lightgray',
                            borderRadius: '50%',
                            textAlign: 'center',
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <span style={{ color: 'gray' }}>Geen data</span>
                        </div>
                    </div>
                }
                <p>Figuur {getFigureNumberForFigureWithName(figureName)}: {getFigureTitle(periodOrLocation)}</p>
            </div>);
        })
    }</div>
}

/**
 * Stacked bar charts
 */
const ClientsPerFRPCategoryPerFloorOrLocationPerPeriod = ({ data, getFigureNumberForFigureWithName, addNewFigureWithName, isFloor }: {
    data: {
        [periodName: string]: {
            name: string // Floor OR location
            [LOW_RISK_FIELD]: number
            [MEDIUM_RISK_FIELD]: number
            [HIGH_RISK_FIELD]: number
        }[]
    },
    getFigureNumberForFigureWithName: (name: string) => number,
    addNewFigureWithName: (name: string) => void,
    isFloor: boolean
}) => {

    function getFigureTitle(periodName: string) {
        if (!isFloor) {
            return `Aantal personen per risicocategorie (laag-verhoogd-hoog) per locatie in de periode ${periodName}`;
        }
        switch (periodName) {
            case TOTAL_STATS_FIELD_NAME:
                return `${periodName} aantal personen per risicocategorie (laag-verhoogd-hoog) per vloer`;
            case YEAR_TO_DATE_STATS_FIELD_NAME:
                return `Aantal personen per risicocategorie (laag-verhoogd-hoog) per vloer per ${periodName}`;
            default:
                return `Aantal personen per risicocategorie (laag-verhoogd-hoog) per vloer in de periode ${periodName}`;
        }
    }

    return <>{
        Object.entries(data).map(([periodName, dataForPeriod]) => {
            const figureName = `stackedBar${periodName}`;
            addNewFigureWithName(figureName);

            const commonLabel = (props: any) => {
                const { x, y, index } = props;

                const newY = y - 5;
                const newX = x + 30;

                const getPercentage = (category: typeof HIGH_RISK_FIELD | typeof MEDIUM_RISK_FIELD | typeof LOW_RISK_FIELD) => {
                    const totalClientsOnFloorOrLocation = dataForPeriod[index][HIGH_RISK_FIELD] + dataForPeriod[index][MEDIUM_RISK_FIELD] + dataForPeriod[index][LOW_RISK_FIELD];
                    return totalClientsOnFloorOrLocation > 0 ? ((dataForPeriod[index][category] * 100) / totalClientsOnFloorOrLocation).toFixed(1) : 0;
                }

                return (
                    <>
                        <text x={newX} y={newY - 40} fontSize={18} fill={getColorForFRPCategory(HIGH_RISK_FIELD)}>
                            {dataForPeriod[index][HIGH_RISK_FIELD]} ({getPercentage(HIGH_RISK_FIELD)}%)
                        </text>
                        <text x={newX} y={newY - 20} fontSize={18} fill={getColorForFRPCategory(MEDIUM_RISK_FIELD)}>
                            {dataForPeriod[index][MEDIUM_RISK_FIELD]} ({getPercentage(MEDIUM_RISK_FIELD)}%)
                        </text>
                        <text x={newX} y={newY} fontSize={18} fill={getColorForFRPCategory(LOW_RISK_FIELD)}>
                            {dataForPeriod[index][LOW_RISK_FIELD]} ({getPercentage(LOW_RISK_FIELD)}%)
                        </text>
                    </>
                );
            };

            return (<div key={`stackedBar${periodName}`} style={{ pageBreakInside: 'avoid' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        width={500}
                        height={300}
                        data={dataForPeriod}
                        margin={{
                            top: 80,
                            right: 5,
                            left: 20,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 16 }} />
                        <YAxis label={<CustomAxis text="Aantal personen" orientation='vertical' x={5} />} />
                        <Tooltip />
                        <Legend />
                        <Bar
                            isAnimationActive={false}
                            dataKey={LOW_RISK_FIELD}
                            stackId="a"
                            fill={getColorForFRPCategory(LOW_RISK_FIELD)}
                            barSize={80}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey={MEDIUM_RISK_FIELD}
                            stackId="a"
                            fill={getColorForFRPCategory(MEDIUM_RISK_FIELD)}
                            barSize={80}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey={HIGH_RISK_FIELD}
                            stackId="a"
                            fill={getColorForFRPCategory(HIGH_RISK_FIELD)}
                            barSize={80}
                            label={commonLabel}
                        />
                    </BarChart>
                </ResponsiveContainer>
                <p>Figuur {getFigureNumberForFigureWithName(figureName)}: {getFigureTitle(periodName)}</p>
                <br />
            </div>);
        })
    }</>
}

export default ReportLayout;
