import APIUtils from "../../utils/APIUtils";

const passwordCrypto = async (ssid: string, password: string, encrypt: boolean) => {
  const response = await APIUtils.devicePasswordCryptography(ssid, password, encrypt);
  if (response.password) {
    return response.password;
  }
  return null;
}

// NO default export
export { passwordCrypto };