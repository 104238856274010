import React, {useEffect, useState} from "react";
import {FloorData, GroupData} from "./GroupData";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    Drawer,
    Form,
    App,
    Row,
    Space,
    Transfer,
    Input,
    InputNumber,
    Tooltip, Modal
} from "antd";
import {TenantSelector} from "../../SharedComponents/TenantSelector";
import {InfoCircleOutlined, WarningOutlined} from "@ant-design/icons";
import {GraphQLQuery} from "@aws-amplify/api";
import {API} from "aws-amplify";
import {
    AddThingToThingGroupMutation, RemoveThingFromThingGroupMutation,
    SetFloorsForGroupMutation,
    UpdateGroupNameMutation,
    UpdateGroupTenantMutation, UpdateGroupUserLimitMutation
} from "../../../utils/graphql/mutations";
import * as mutations from "../../../utils/graphql/mutations";
import * as queries from "../../../utils/graphql/queries";
import {
    ListAllFloorsQuery,
    ListThingsByThingGroupQuery,
    ListThingsByThingTypeQuery
} from "../../../utils/graphql/queries";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
};

type EditTenantProps = {
    group: GroupData | null;
    reload: () => void;
}

const EditTenant = ({group, reload}: EditTenantProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const updateTenant = async (tenantId: number) => {
        const response = await API.graphql<GraphQLQuery<UpdateGroupTenantMutation>>({
            query: mutations.updateGroupTenant,
            variables: {
                groupId: group?.id,
                tenantId: tenantId,
            }
        }).catch((_) => {
            notification.error({
                message: 'Tenant update failed',
                description: `Tenant of group ${group?.name} could not be updated`,
            });
        });

        if (response?.data?.updateGroupTenant) {
            if (group) {
                group.tenantName = response.data.updateGroupTenant.tenant.name;
                group.tenantId = response.data.updateGroupTenant.tenant.id;
            }

            notification.success({
                message: 'Tenant updated',
                description: `Tenant of group ${group?.name} has been updated`,
            });
            return true;
        } else {
            notification.error({
                message: 'Tenant update failed',
                description: `Tenant of group ${group?.name} could not be updated`,
            });
        }
        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const success = await updateTenant(values.tenant);

        if (success) {
            onClose();
            reload();

        }
        setLoading(false);
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    return (
        <>
            <Drawer
                title="Edit Group Tenant"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Alert
                    message={"Changing the tenant of a Group will effect many other resources. " +
                        "Please make sure you know what you are doing!"}
                    type={"warning"}
                    showIcon
                />
                <div style={{height: 20}}/>
                {/* Show the name of the current tenant*/}
                <div>
                    <b>Current Tenant</b>
                    <p>{group?.tenantName}</p>
                </div>
                <Divider/>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='TenantEdit'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'Tenant Name'}
                        name={'tenant'}
                        tooltip={{title: 'Name of the tenant', icon: <InfoCircleOutlined/>}}
                        rules={[
                            {
                                required: true,
                                message: "Please provide a tenant name!",
                            },
                        ]
                        }

                    >
                        <TenantSelector/>
                    </Form.Item>
                    {/* Checkbox form item that confirms the users knows what they are doing*/}
                    <Form.Item
                        name="confirm"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(
                                        'You need to confirm that you know what you are doing!'
                                    ),
                            },
                        ]}
                        label={'I know what I am doing'}
                    >
                        <Checkbox/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Tenant Information <Tooltip title={'Advanced option'}><WarningOutlined
                    style={{color: '#faad14'}}/></Tooltip></b><br/>
                {group?.tenantName}
                <br/>
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br/>
            </Col>
        </>
    );
}


interface EditFloorsProps {
    group: GroupData | null;
    reload: () => void;
}

const EditFloors = ({group, reload}: EditFloorsProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [availableFloors, setAvailableFloors] = useState<FloorData[]>([]);
    const [selectedFloors, setSelectedFloors] = useState<FloorData[]>([]);

    const fetchAvailableFloors = async () => {
        let nextToken: string | null = null;

        const floors: FloorData[] = [];

        do {
            const response: any = await API.graphql<GraphQLQuery<ListAllFloorsQuery>>({
                query: queries.listAllFloors,
                variables: {
                    page: {
                        limit: 100,
                        nextToken: nextToken,
                    }
                }
            });

            if (response.data?.listAllFloors?.items) {
                floors.push(...response.data.listAllFloors.items);
            } else {
                notification.error({
                    message: 'Floor fetch failed',
                    description: `Floors of group ${group?.name} could not be fetched`,
                });
                return;
            }

            nextToken = response.data?.listAllFloors?.nextToken ?? null;

        } while (nextToken !== null);

        setAvailableFloors(floors);
    }

    useEffect(() => {
        setLoading(true);
        fetchAvailableFloors().then();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (group) {
            setSelectedFloors(group.floors);
        }
    }, [group]);

    const [form] = Form.useForm();

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    const updateFloors = async (floors: number[]) => {
        const response = await API.graphql<GraphQLQuery<SetFloorsForGroupMutation>>({
            query: mutations.setFloorsForGroup,
            variables: {
                groupId: group?.id,
                floorIds: floors,
            }
        }).catch((_) => {
            notification.error({
                message: 'Floors update failed',
                description: `Floors of group ${group?.name} could not be updated`,
            });
        });

        if (response?.data?.setFloorsForGroup) {
            if (group) {
                group.floors = response.data.setFloorsForGroup.floors;
            }

            notification.success({
                message: 'Floors updated',
                description: `Floors of group ${group?.name} have been updated`,
            });
            return true;
        } else {
            notification.error({
                message: 'Floors update failed',
                description: `Floors of group ${group?.name} could not be updated`,
            });
        }
        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const success = await updateFloors(values.floors);

        if (success) {
            onClose();
            reload();

        }
        setLoading(false);
    }

    const onSelectedFloorsChange = (targetKeys: any) => {
        // get the floors from the target keys
        const floors = availableFloors.filter((floor) => {
            return targetKeys.includes(floor.id.toString());
        });

        setSelectedFloors(floors);
    }

    const floorDisplay = () => {
        if (loading) {
            return <>loading...<br/></>;
        }
        // A quick showcase on the edit panel. Should show all the floors
        // that are currently in the group or "No floors" if there are none
        if (group?.floors === undefined) {
            return <>No floors<br/></>;
        }

        if (group?.floors.length === 0) {
            return <>No floors<br/></>;
        }

        // Otherwise show the floors in a list If more than 3 floors are in the group
        // show the first 3 and then a "and x more" at the end
        return (
            <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                {group?.floors.slice(0, 3).map((floor) => {
                    return <li key={floor.id}>{floor.name}</li>
                })}
                {group?.floors.length > 3 ? <li><i>and {group?.floors.length - 3} more</i></li> : null}
            </ul>
        )
    }

    return (
        <>
            <Drawer
                title="Edit Group Floors"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='FloorEdit'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'Floors'}
                        name={'floors'}
                        tooltip={{title: 'Floors of the group', icon: <InfoCircleOutlined/>}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={availableFloors.map((floor) => {
                                return {
                                    key: floor.id.toString(),
                                    title: floor.name,
                                };
                            })}
                            targetKeys={selectedFloors.map((floor) => floor.id.toString())}
                            titles={['Available', 'Selected']}
                            listStyle={{
                                width: '45%',
                                height: '20em',
                            }}
                            render={item => item.title}
                            onChange={onSelectedFloorsChange}
                            disabled={loading}
                            showSearch
                        />

                    </Form.Item>
                    <Form.Item
                        wrapperCol={{span: 24}}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Floor information</b> <br/>
                {floorDisplay()}
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a>
            </Col>
        </>
    );
}


interface EditGroupNameProps {
    group: GroupData | null;
    reload: () => void;
}

const EditGroupName = ({group, reload}: EditGroupNameProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (group) {
            form.setFieldsValue({
                name: group.name,
            });
        }
    }, [group]);

    const changeName = async (name: string) => {
        if (!group) {
            return false;
        }

        const response = await API.graphql<GraphQLQuery<UpdateGroupNameMutation>>({
            query: mutations.updateGroupName,
            variables: {
                groupId: group.id,
                name: name,
            }
        }).catch((reason) => {
            notification.error({
                message: reason.errors[0].message,
            });
        });

        if (response?.data?.updateGroupName) {
            group.name = response.data.updateGroupName.name;
            notification.success({
                message: 'Group name updated',
            });
            return true;
        }
        return false;

    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const success = await changeName(values.name);

        if (success) {
            onClose();
            reload();
        }

        setLoading(false);
    }

    return (
        <>
            <Drawer
                title="Edit Group Name"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='GroupNameEdit'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'Name'}
                        name={'name'}
                        tooltip={{title: 'Name of the group', icon: <InfoCircleOutlined/>}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{span: 24}}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Group name</b><br/>
                {group?.name}
                <br/>
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br/>
            </Col>
        </>
    )
}

interface EditGroupThingAssignmentProps {
    group: GroupData | null;
    reload: () => void;
    title: string;
    thingType: string;
    removeIsReassign: boolean;
}


type ThingData = {
    name: string;
    thingType: string;
}


const EditGroupThingAssignment = ({
                                      group,
                                      reload,
                                      title,
                                      thingType,
                                      removeIsReassign
                                  }: EditGroupThingAssignmentProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [availableThings, setAvailableThings] = useState<ThingData[]>([]);
    const [assignedThings, setAssignedThings] = useState<ThingData[]>([]);
    const [selectedThings, setSelectedThings] = useState<ThingData[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        setSelectedThings(assignedThings);
    }, [assignedThings]);

    const loadAll = async () => {
        setLoading(true);
        await fetchAvailableThings();
        await fetchSelectedThings();
        setLoading(false);
    }


    useEffect(() => {
        loadAll().then();
    }, [group]);

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const fetchAvailableThings = async () => {
        if (!group) {
            return;
        }

        const things: ThingData[] = [];

        // List all things by the set thing type
        let nextToken = null;
        const limit = 100;

        let response: any = null;
        do {
            response = await API.graphql<GraphQLQuery<ListThingsByThingTypeQuery>>({
                query: queries.listThingsByThingType,
                variables: {
                    page: {
                        limit: limit,
                        nextToken: nextToken,
                    },
                    thingType: thingType,
                }
            }).catch((reason) => {
                notification.error({
                    message: reason.errors[0].message,
                });
            });

            if (response?.data?.listThingsByThingType?.items) {
                response.data.listThingsByThingType.items.forEach((thing: any) => {
                    things.push({
                        name: thing.name,
                        thingType: thing.thingType,
                    });
                });
            }

            nextToken = response?.data?.listThingsByThingType?.nextToken ?? null;
        } while (nextToken !== null);

        setAvailableThings(things);
    }

    const fetchSelectedThings = async () => {
        if (!group) {
            return;
        }

        const things: ThingData[] = [];

        // List all things in the thing group (and then later filter by thing type)
        let nextToken = null;
        const limit = 100;

        let response: any = null;

        do {
            response = await API.graphql<GraphQLQuery<ListThingsByThingGroupQuery>>({
                query: queries.listThingsByThingGroup,
                variables: {
                    page: {
                        limit: limit,
                        nextToken: nextToken,
                    },
                    thingGroupName: group.wearableGroupName,
                    recursive: false,
                }
            });

            if (response?.data?.listThingsByThingGroup?.items) {
                response.data.listThingsByThingGroup.items.forEach((thing: any) => {
                    things.push({
                        name: thing.name,
                        thingType: thing.thingType,
                    });
                });
            }

            nextToken = response?.data?.listThingsByThingGroup?.nextToken ?? null;
        } while (nextToken !== null);

        // Filter by thing type
        const filteredThings = things.filter((thing) => thing.thingType === thingType);

        setAssignedThings(filteredThings);
    }

    const openDrawer = () => {
        setOpen(true);
        loadAll().then();
    }

    const onSelectedThingsChange = (nextTargetKeys: any) => {
        const things = availableThings.filter((thing) => nextTargetKeys.includes(thing.name));
        setSelectedThings(things);
    }

    const addSensorToThingGroup = async (thingName: string, thingGroupName: string, removeFromOtherGroups: boolean) => {
        const response = await API.graphql<GraphQLQuery<AddThingToThingGroupMutation>>({
            query: mutations.addThingToThingGroup,
            variables: {
                thingName: thingName,
                thingGroupName: thingGroupName,
                removeFromOtherGroups: removeFromOtherGroups,
            }
        }).catch((reason) => {
            notification.error({
                message: reason.errors[0].message,
            });
        });

        if (response?.data?.addThingToThingGroup) {
            notification.success({
                message: `Successfully added ${thingName} to ${thingGroupName}`,
            });
        }
    }

    const removeSensorFromThingGroup = async (thingName: string, thingGroupName: string) => {
        const response = await API.graphql<GraphQLQuery<RemoveThingFromThingGroupMutation>>({
            query: mutations.removeThingFromThingGroup,
            variables: {
                thingName: thingName,
                thingGroupName: thingGroupName,
            }
        }).catch((reason) => {
            notification.error({
                message: reason.errors[0].message,
            });
        });

        if (response?.data?.removeThingFromThingGroup) {
            notification.success({
                message: `Successfully removed ${thingName} from ${thingGroupName}`,
            });
        }
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        // We need to split the selected things into 2 groups
        // 1. Things that are not in the group (and should be added)
        // 2. Things that are in the group (and should be removed)

        const thingsToAdd: ThingData[] = [];
        const thingsToRemove: ThingData[] = [];

        selectedThings.forEach((thing) => {
            if (assignedThings.find((assignedThing) => assignedThing.name === thing.name)) {
                // Thing is already in the group
                // Do nothing
            } else {
                // Thing is not in the group
                // Add it
                thingsToAdd.push(thing);
            }
        });

        assignedThings.forEach((assignedThing) => {
            if (selectedThings.find((thing) => thing.name === assignedThing.name)) {
                // Thing is already in the group
                // Do nothing
            } else {
                // Thing is not in the group
                // Remove it
                thingsToRemove.push(assignedThing);
            }
        });

        // No actions needs to be taken for the first group

        // We should now prompt the user to confirm the changes

        // If the user confirms the changes we should add/remove the things
        // and then close the drawer

        const content = (
            <>
                <p>Are you sure you want to make these changes? <br/>
                    Some devices may be removed from an existing customer group.
                </p>
                {thingsToAdd.length > 0 && <>
                    <p>Things to add:</p>
                    <ul>
                        {thingsToAdd.map((thing) => {
                            return <li key={thing.name}>{thing.name}</li>
                        })}
                    </ul>
                </>
                }
                {thingsToRemove.length > 0 && <>
                    <p>Things to remove:</p>
                    <ul>
                        {thingsToRemove.map((thing) => {
                            return <li key={thing.name}>{thing.name}</li>
                        })}
                    </ul>
                </>
                }
            </>
        )

        const wearableGroupName = group?.wearableGroupName ?? null;
        if (!wearableGroupName) {
            notification.error({
                message: 'No wearable group name',
                description: 'No wearable group name found',
            });
            return;
        }

        const sleep = async (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        Modal.confirm({
            title: 'Confirm changes',
            content: content,
            onOk: async () => {
                // Add all the things
                for (const thing of thingsToAdd) {
                    console.log(`Adding ${thing.name} to ${wearableGroupName}`);
                    await addSensorToThingGroup(thing.name, wearableGroupName, true);
                    await sleep(1000);
                }

                // Remove all the things
                for (const thing of thingsToRemove) {
                    console.log(`Removing ${thing.name} from ${group?.wearableGroupName ?? ''}`);
                    // if removeIsReassign is true, we should add the sensor to the "sf_new" group
                    // and remove the sensor from all its existing groups

                    if (removeIsReassign) {
                        console.log('Reassigning sensor to sf_new')
                        await addSensorToThingGroup(thing.name, 'sf_new', true);
                    } else {
                        // Use new endpoint to remove the sensor from the group
                        await removeSensorFromThingGroup(thing.name, group?.wearableGroupName ?? '');
                    }

                    await sleep(1000);
                }

                // Reload the data
                await loadAll();

                setAssignedThings(selectedThings);

                // Close the drawer
                onClose();
            },
        })


        setLoading(false);
    }

    const thingsDisplay = () => {
        if (loading) {
            return <>loading...<br/></>;
        }
        // A quick showcase on the edit panel. Should show all the floors
        // that are currently in the group or "No floors" if there are none

        if (assignedThings.length === 0) {
            return <>No {title.toLowerCase()}<br/></>;
        }


        const sortThingsFunc = (a: ThingData, b: ThingData) => {
            const aNum = parseInt(a.name.split('_').pop() ?? a.name);
            const bNum = parseInt(b.name.split('_').pop() ?? b.name);
            if (isNaN(aNum) || isNaN(bNum)) {
                return a.name.localeCompare(b.name);
            }
            return aNum - bNum;
        }
        const sortedThings = assignedThings.sort((a, b) => sortThingsFunc(a, b));
        const thingsShown = showAll ? sortedThings : sortedThings.slice(0, 3);

        // Otherwise show the floors in a list If more than 3 floors are in the group
        // show the first 3 and then a "and x more" at the end
        return (
            <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                {thingsShown.map((thing) => {
                    return <li key={thing.name}>
                        <a href={`./device-diagnostics?device_id=${thing.name}`}>
                            {thing.name}
                        </a>
                    </li>
                })}
                {assignedThings.length > 3 && <li>
                    <Button
                        type={'link'}
                        style={{
                            padding: 0,
                            margin: 0,
                        }}
                        onClick={() => {
                            setShowAll(!showAll);
                        }}
                    >
                        {showAll ? <i>Show less</i> : <i>and {assignedThings.length - 3} more</i>}
                    </Button></li>
                }
            </ul>
        )
    }

    const hasChanges = () => {
        // check if the sensor names between the selected and assigned things are the same
        // if they are the same, there are no changes
        const selectedThingNames = selectedThings.map((thing) => thing.name);
        const assignedThingNames = assignedThings.map((thing) => thing.name);
        // They can be in a different order that doesn't matter
        // So we sort them
        selectedThingNames.sort();
        assignedThingNames.sort();

        // If the lengths are different there are changes
        if (selectedThingNames.length !== assignedThingNames.length) {
            return true;
        }

        // If the lengths are the same we need to check if the names are the same
        for (let i = 0; i < selectedThingNames.length; i++) {
            if (selectedThingNames[i] !== assignedThingNames[i]) {
                return true;
            }
        }

        // If we got here there are no changes
        return false;
    }

    return (
        <>
            <Drawer
                title={`Edit Group ${title}`}
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Alert
                    message={`
                    Devices shown in this list may already be assigned to another group. 
                    Assigning a sensor to this group will un-assign it from the other group.
                    `}
                    type="warning"
                    showIcon
                />
                <div style={{marginTop: '1em'}}/>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='groupThingAssignmentEdit'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={title}
                        name={'things'}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={availableThings.map((thing) => {
                                return {
                                    key: thing.name,
                                    title: thing.name,
                                };
                            })}
                            targetKeys={selectedThings.map((thing) => thing.name)}
                            titles={['Available', 'Selected']}
                            listStyle={{
                                width: '45%',
                                height: '20em',
                            }}
                            render={item => item.title}
                            onChange={onSelectedThingsChange}
                            disabled={loading}
                            showSearch
                        />

                    </Form.Item>
                    <Form.Item
                        wrapperCol={{span: 24}}
                    >
                        <Button type="primary" htmlType="submit" loading={loading} disabled={!hasChanges()}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>{title}</b><br/>
                {thingsDisplay()}
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br/>
            </Col>
        </>
    )
}

type EditGroupUserLimitProps = {
    group: GroupData | null;
    reload: () => void;
}

const EditGroupUserLimit = ({group, reload}: EditGroupUserLimitProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
        if (group) {
            form.setFieldsValue({
                userLimit: group.userLimit,
            });
        }
    }

    useEffect(() => {
        if (group) {
            form.setFieldsValue({
                userLimit: group.userLimit,
            });
        }
    }, [group]);

    const changeUserLimit = async (userLimit: number) => {
        if (!group) {
            return false;
        }

        const response = await API.graphql<GraphQLQuery<UpdateGroupUserLimitMutation>>({
            query: mutations.updateGroupUserLimit,
            variables: {
                groupId: group.id,
                userLimit: userLimit,
            }
        }).catch((reason) => {
            notification.error({
                message: reason.errors[0].message,
            });
        });

        if (response?.data?.updateGroupUserLimit) {
            group.userLimit = response.data.updateGroupUserLimit.userLimit ?? null;
            notification.success({
                message: 'Group user limit updated',
            });
            return true;
        }
        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const success = await changeUserLimit(values.userLimit);

        if (success) {
            onClose();
            reload();
        }

        setLoading(false);
    }

    return (
        <>
            <Drawer
                title="Edit Group User Limit"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='GroupUserLimitEdit'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'User Limit'}
                        name={'userLimit'}
                        tooltip={{
                            title: 'Maximum number of users that can be in this group',
                            icon: <InfoCircleOutlined/>
                        }}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <InputNumber min={0} max={8196}/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{span: 24}}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>User limit</b><br/>
                {group?.userLimit ?? 'No limit'} <br/>
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br/>
            </Col>
        </>
    )
}

type GroupEditProps = {
    group: GroupData | null;
    reload: () => void;
    close: () => void
}


export const GroupEdit = ({close, group, reload}: GroupEditProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const onClose = () => {
        close();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (group) {
            openDrawer();
        }
    }, [group]);

    return (
        <>
            <Drawer
                title="Edit Group"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <h1>{group?.name}</h1>
                <Row
                    gutter={[16, 32]}
                >
                    <EditGroupName
                        group={group}
                        reload={reload}
                    />
                    <EditTenant
                        group={group}
                        reload={reload}
                    />
                    <EditGroupUserLimit
                        group={group}
                        reload={reload}
                    />
                    <EditFloors
                        group={group}
                        reload={reload}
                    />
                    <EditGroupThingAssignment
                        group={group}
                        reload={reload}
                        title={"Sensors"}
                        thingType={"heelable"}
                        removeIsReassign={true}
                    />
                    <EditGroupThingAssignment
                        group={group}
                        reload={reload}
                        title={"Mobile Routers"}
                        thingType={"mobile_router"}
                        removeIsReassign={false}
                    />
                </Row>
            </Drawer>
        </>
    )
}
