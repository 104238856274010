import React from 'react';

const Footnote = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      © {(new Date().getFullYear())} Connective Floors B.V.
    </div>
  );
}

export default Footnote;

