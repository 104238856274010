import { Button, Checkbox, Col, Input, Row, Space, Switch, Tooltip } from 'antd';
import React, { useState } from 'react';
import GroupsTreeSelector from './GroupsTreeSelector';
import RangePickerGroup from './RangePickerGroup';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getYearToDatePeriod } from './reportUtils';
import { Period, ReportType } from './CustomerInsightsView';

interface CustomerInsightsSelectionUIProps {
    municipalityName: string,
    setMunicipalityName: React.Dispatch<React.SetStateAction<string>>,
    selectedGroupIDs: string | undefined,
    setSelectedGroupIDs: React.Dispatch<React.SetStateAction<string | undefined>>,
    periods: Period[],
    setPeriods: React.Dispatch<React.SetStateAction<Period[]>>,
    includeYearToDateStats: boolean,
    setIncludeYearToDateStats: React.Dispatch<React.SetStateAction<boolean>>,
    setMustShowReport: React.Dispatch<React.SetStateAction<boolean>>,
    includeTotalStats: boolean,
    setIncludeTotalStats: React.Dispatch<React.SetStateAction<boolean>>
    reportType: ReportType
    setReportType: React.Dispatch<React.SetStateAction<ReportType>>
    setIncludeLocationBreakdown: React.Dispatch<React.SetStateAction<boolean>>
    includeLocationBreakdown: boolean
}

/**
 * The view for selecting report generation options.
 */
const CustomerInsightsSelectionUI = ({
    municipalityName, setMunicipalityName,
    selectedGroupIDs, setSelectedGroupIDs,
    periods, setPeriods,
    includeYearToDateStats, setIncludeYearToDateStats,
    setMustShowReport,
    includeTotalStats, setIncludeTotalStats,
    reportType, setReportType,
    includeLocationBreakdown, setIncludeLocationBreakdown
}: CustomerInsightsSelectionUIProps) => {
    const [overlapExists, setOverlapExists] = useState(false);
    const [periodNamesAreDuplicated, setPeriodNamesAreDuplicated] = useState(false);
    const [periodNamesAreForbidden, setPeriodNamesAreForbidden] = useState(false);

    const atLeastOneGroupIsSelected = selectedGroupIDs !== undefined && selectedGroupIDs?.length > 0;

    const yearToDateStatsTooltip = 'This includes a comparison to the year all periods are a part of. If the selected periods span through more than one year, all years will be used for year to date statistics.'
    const totalStatsTooltip = 'This will display statistics for all measurements ever made within the selected groups.';

    function getYearToDateString(periods: Period[]) {
        const yearToDatePeriod = getYearToDatePeriod(periods);
        return `${yearToDatePeriod.dates[0].format('DD-MM-YYYY')} till ${yearToDatePeriod.dates[1].format('DD-MM-YYYY')}.`
    }

    /**
     * Some periods might not have been named by the user, so we give them a short alias (P1, P2, etc.).
     */
    function preparePeriodsAndShowReport() {
        const periodsWithNonEmptyNames = periods.map((p, idx) => {
            if (p.name === '') {
                return { dates: p.dates, name: `P${idx + 1}` }
            }
            return p;
        });
        setPeriods([...periodsWithNonEmptyNames])
        setMustShowReport(true);
    }

    const onChange = (checked: boolean) => {
        setReportType(checked ? ReportType.FRP : ReportType.VNL);
    };

    return <div style={{ textAlign: 'left' }}>
        <b>Municipality name</b>
        <Row style={{ margin: 5, marginBottom: 25 }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                <Input
                    value={municipalityName}
                    onChange={(e) => setMunicipalityName(e.target.value)}
                    status={!municipalityName ? 'error' : ''}
                    placeholder='Municipality name'
                />
            </Col>
        </Row>

        <b>Select groups</b>
        <Row style={{ margin: 5, marginBottom: 25 }}>
            <GroupsTreeSelector
                setSelectedGroupIDs={setSelectedGroupIDs}
                selectedGroupIDs={selectedGroupIDs}
                atLeastOneGroupIsSelected={atLeastOneGroupIsSelected}
            />
        </Row>

        <b>Report type</b>
        <Row style={{ margin: 5, marginBottom: 25 }}>
            <Space>
                <span>VeiligheidNL</span>
                <Switch
                    checked={reportType === ReportType.FRP}
                    onChange={onChange}
                />
                <span>SF-VRP</span>
            </Space>
        </Row>


        <b>Select period(s)</b>
        {periods?.length > 0 &&
            <RangePickerGroup
                periods={periods}
                setPeriods={setPeriods}
                overlapExists={overlapExists}
                setOverlapExists={setOverlapExists}
                periodNamesAreDuplicated={periodNamesAreDuplicated}
                setPeriodNamesAreDuplicated={setPeriodNamesAreDuplicated}
                periodNamesAreForbidden={periodNamesAreForbidden}
                setPeriodNamesAreForbidden={setPeriodNamesAreForbidden}
            />
        }

        <b>Include year to date statistics</b> <Checkbox checked={includeYearToDateStats} onChange={(e) => setIncludeYearToDateStats(prev => !prev)} /> <Tooltip title={yearToDateStatsTooltip}><QuestionCircleOutlined /></Tooltip>
        <Row style={{ margin: 5, marginBottom: 25 }}>
            <span>
                Current year to date period: <b>{includeYearToDateStats ? getYearToDateString(periods) : '-'}</b>
            </span>
        </Row>

        <b>Include total statistics</b> <Checkbox checked={includeTotalStats} onChange={(e) => setIncludeTotalStats(prev => !prev)} /> <Tooltip title={totalStatsTooltip}><QuestionCircleOutlined /></Tooltip>
        <br />
        <b>Include SF-VRP breakdown per location</b> <Checkbox checked={includeLocationBreakdown} onChange={(e) => setIncludeLocationBreakdown(prev => !prev)} />
        <Button
            disabled={!municipalityName || overlapExists || !atLeastOneGroupIsSelected || periodNamesAreDuplicated || periodNamesAreForbidden}
            block type='primary'
            onClick={preparePeriodsAndShowReport}
            style={{ marginTop: 25 }}
        >
            Preview and Export
        </Button>
    </div>
}

export default CustomerInsightsSelectionUI;
