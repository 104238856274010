import {CreationStep} from "../CreationStep";
import React from "react";
import {Button, Col, Form, Input, InputNumber, Row, Typography} from "antd";

export const GroupDetailsInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();


  const onFinish = (values: any) => {
    if (instructions.groups) {
      instructions.groups[0].userLimit = values.userLimit ? values.userLimit : null;
    }

    onStepComplete(instructions);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Enter details for group</Typography.Title>
          <Typography.Paragraph>
            Tenant details include various aspects of the group. This includes the following information:
            <ul>
              <li><b>user limit</b>: The maximum number of users that can be created in this group. If this limit is
                reached, no more users can be created in this group.
              </li>
            </ul>
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: 20}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              userLimit: instructions.groups ? instructions.groups[0].userLimit : 0,
              }}
          >
            <Form.Item
              name="userLimit"
              label="user Limit"
              >
              <InputNumber
                min={0}
                style={{width: "50%"}}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button onClick={onStepBack}>Back</Button>
    </>
  )
}
