import React, {useState} from "react";
import {Button, Checkbox, Col, Popover, Row} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {BatteryChart} from "./BatteryChart";

interface BatteryMonitoringViewProps {
  things: string[];
  startTimestamp: number;
  endTimestamp: number;
}

const BatteryMonitoringView = ({things, startTimestamp, endTimestamp}: BatteryMonitoringViewProps) => {
  const [batteryCharts, setBatteryCharts] = useState<JSX.Element[]>([]);
  const [showDeSync, setShowDeSync] = useState<boolean>(false);
  const [showBootHistory, setShowBootHistory] = useState<boolean>(false);

  const getBatteryCharts = () => {
    let batteryCharts: JSX.Element[] = [];
    for (const thing of things) {
      batteryCharts.push(
        <BatteryChart
          thing={thing}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          showDeSync={showDeSync}
          showBootHistory={showBootHistory}
          generatedTimestamp={Date.now().valueOf()}
        />
      )
    }

    setBatteryCharts(batteryCharts);
  }

  const codePopoverContent = (
      <ol>
        <li>PWRON_RESET</li>
        <li>HARD_RESET</li>
        <li>WDT_RESET</li>
        <li>DEEPSLEEP_RESET</li>
        <li>SOFT_RESET</li>
        <li>BROWNOUT_RESET</li>
      </ol>
  );


  return (
    <>
      <Row style={{marginTop: '20px'}}>
        <Col span={24}>
          <Button type="primary" onClick={getBatteryCharts}>Update</Button>
          <Checkbox
            style={{
              marginLeft: '20px',
            }}
            checked={showDeSync}
            onChange={(e) => setShowDeSync(e.target.checked)}
          >
            Show de-sync markers
          </Checkbox>
          <Checkbox
              style={{
                marginLeft: '20px',
              }}
              checked={showBootHistory}
              onChange={(e) => setShowBootHistory(e.target.checked)}
          >
            Include boot history (slow) <Popover content={codePopoverContent}><InfoCircleOutlined/></Popover>
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {batteryCharts}
        </Col>
      </Row>
    </>
  );

}

export default BatteryMonitoringView;
