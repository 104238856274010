import {CreationStep} from "../CreationStep";
import {Button, Col, Form, Row, Select} from "antd";
import React, {useEffect} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../../utils/graphql/queries";
import {ListThingsByThingTypeQuery} from "../../../utils/graphql/queries";

export const MobileRouterInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();

  const [routers, setRouters] = React.useState<string[]>([]);
  const [selectedRouters, setSelectedRouters] = React.useState<string[]>([]);

  const loadRouters = async () => {
    const tmpRouters: string[] = [];
    let nextToken = null;

    do {
      const response: any = await API.graphql<GraphQLQuery<ListThingsByThingTypeQuery>>({
        query: queries.listThingsByThingType,
        variables: {
          page: {
            nextToken: nextToken,
          },
          thingType: "mobile_router",
        },
      });

      response.data?.listThingsByThingType.items.forEach((item: any) => {
        tmpRouters.push(item.name);
      });

      if (response.data?.listThingsByThingType.nextToken) {
        nextToken = response.data?.listThingsByThingType.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken);

    // sort sensors
    tmpRouters.sort();

    setRouters(tmpRouters);
  }

  useEffect(() => {
    loadRouters().then();
  }, [instructions]);

  const onFormFinish = (values: any) => {
    if (instructions.groups) {
      instructions.groups[0].mobileRouters = values.routers;
    }
    onStepComplete(instructions);
  }

  const filterRouters = routers.filter((sensor) => {
    return !selectedRouters.includes(sensor);
  });

  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout={"vertical"}
            onFinish={onFormFinish}
            initialValues={{
              routers: instructions.groups ? instructions.groups[0].mobileRouters : [],
            }}
          >
            <Form.Item
              label={"routers"}
              name={"routers"}
            >
              <Select
                mode="multiple"
                placeholder="select sensors"
                value={selectedRouters}
                onChange={setSelectedRouters}
                style={{width: '100%'}}
                options={filterRouters.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button
        onClick={onStepBack}
      >
        Back
      </Button>
    </>
  )
}