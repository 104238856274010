import React, {useEffect, useState} from 'react';
import {GraphQLQuery} from "@aws-amplify/api";
import {API} from "aws-amplify";
import * as queries from "../../utils/graphql/queries";
import {GetCognitoUserQuery, GetCognitoUserQueryVariables, ListCognitoUsersQuery} from "../../utils/graphql/queries";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Input, Row, Space, Table, Tag} from "antd";
import User from "./UserData";
import CreateUser from "./UserCreate";
import UserEdit from "./UserEdit";

export const UserManagementView = () => {
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);


    const searchColumns = ['username', 'email'];

    useEffect(() => {
        if (searchText) {
            const filteredTenants = users.filter((user) => {
                return searchColumns.some((column) => {
                    return (user as any)[column].toString().toLowerCase().includes(searchText.toLowerCase());
                });
            });

            setFilteredUsers(filteredTenants);
        } else {
            setFilteredUsers(users);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, searchText]);

    const fetchData = async (nextToken: string | null) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListCognitoUsersQuery>>({
            query: queries.listCognitoUsers,
            variables: {
                page: {
                    nextToken: nextToken,
                },
            }
        });

        if (response.data?.listCognitoUsers) {
            const localUsers: User[] = response.data.listCognitoUsers.items.map((user) => {
                return {
                    id: user.id,
                    username: user.username,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    enabled: user.enabled,
                }
            });

            // Update the users
            setUsers([...users, ...localUsers]);

            // Update the next token. We use hooks to update users later as it is async, and
            // can already start showing the users while not yet having all users.
            setNextToken(response.data?.listCognitoUsers?.nextToken ?? null);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (nextToken || users.length === 0) {
            fetchData(nextToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken]);

    useEffect(() => {
        setNextToken('');
        setUsers([]);
    }, []);


    const getFullUserDetails = async (user: User) => {
        const response = await API.graphql<GraphQLQuery<GetCognitoUserQuery>>({
            query: queries.getCognitoUser,
            variables: {
                id: user.username,
            } as GetCognitoUserQueryVariables
        });

        return response.data?.getCognitoUser;
    }

    const editUser = (user: User) => {
        user.databaseUserDetails = undefined;
        setSelectedUser(user);
        getFullUserDetails(user).then((fullUser) => {
            user.databaseUserDetails = fullUser?.databaseUserDetails ?? undefined;
            setSelectedUser(user);
        });
    }


    const columns: ColumnsType<User> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id.localeCompare(b.id),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            sorter: (a, b) => a.enabled === b.enabled ? 0 : a.enabled ? 1 : -1,
            render: (enabled: boolean) => {
                return enabled ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => editUser(record)}>Edit</Button>
                </Space>
            ),
        },
    ]

    const reloadData = () => {
        setUsers([]);
        setNextToken('');
    };


    const search = (searchText: string) => {
        setSearchText(searchText);
    }

    const autoSearch = (searchText: string) => {
        if (!searchText) {
            setSearchText(searchText);
        }
    }

    const onClose = () => {
        setSelectedUser(null);
    }

    return (
        <>
            <UserEdit user={selectedUser} close={onClose} reload={reloadData} />
            <Row>
                <Col span={24}>
                    <div style={{margin: '10px 0'}}/>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Button onClick={reloadData}>Reload</Button>
                </Col>
                <Col span={12}>
                    <Input.Search
                        size={'large'}
                        placeholder="Search"
                        onSearch={search}
                        onChange={(e) => autoSearch(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col span={6} style={{textAlign: 'right'}}>
                    <CreateUser
                        reload={reloadData}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{margin: '10px 0'}}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table<User>
                        columns={columns}
                        dataSource={filteredUsers}
                        loading={loading}
                        pagination={{
                            pageSize: 10,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}