import React, {useEffect, useState} from "react";

import {FileOutlined, PieChartOutlined, PlayCircleOutlined, SlidersOutlined, UserOutlined,} from '@ant-design/icons';

import {Layout, Menu, MenuProps, Tag} from 'antd';
import {NavLink, useLocation} from "react-router-dom";
import dayjs from "dayjs";

type MenuItem = Required<MenuProps>['items'][number];

interface TagData {
    text: string;
    color: string;
    expires_on?: dayjs.Dayjs;
}

interface TextWithTagsProps {
    text: string;
    tags?: TagData[];
}

const TextWithTags = ({text, tags}: TextWithTagsProps) => {
    return <span>
        {text}
        <> </>
        {tags?.map((tag) => {
            if (tag?.expires_on?.isBefore(dayjs())) {
                return null;
            }
            return <Tag color={tag.color} key={tag.text}>{tag.text}</Tag>
        })}
    </span>
}


function getItem(
    label: React.ReactNode,
    link: string | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key: link,
        icon,
        children,
        label: (link && !children) ? <NavLink to={link} className="nav-text">{label}</NavLink> : label,
    } as MenuItem;
}


// 2024-09-06 09:27:00 UTC
const tagExpiryDate = dayjs('2024-10-01 00:00:00 UTC');

const items: MenuItem[] = [
    getItem('Live View', 'live-view', <PlayCircleOutlined/>),
    getItem('Floors', 'floor-tool', <FileOutlined/>),
    getItem('Devices', 'devices', <SlidersOutlined />, [
        getItem('Monitoring', 'monitoring'),
        getItem('Diagnostics', 'device-diagnostics'),
        getItem('Control', 'control'),
        getItem('Mobile Routers', 'device-management'),
        getItem('Configuration', 'config'),
    ]),
    getItem('Users', 'users', <UserOutlined/>, [
        getItem(<TextWithTags text={'Platform Users'} tags={[{text: 'V.U.M.', color: '#ff0000', expires_on: tagExpiryDate}]}/>, 'vitality-user-management'),
        getItem(<TextWithTags text={'Groups'} tags={[{text: 'G.A.T.', color: '#0000ff', expires_on: tagExpiryDate}]}/>, 'group-management'),
        getItem(<TextWithTags text={'Tenants'} tags={[{text: 'G.A.T.', color: '#0000ff', expires_on: tagExpiryDate}]}/>, 'tenant-management'),
    ]),
    getItem('Customers', 'customers', <PieChartOutlined/>, [
        getItem('Customer Insights', 'customer-insights'),
        getItem('Customer Creation', 'customer-creation'),
    ]),
];

const {Sider} = Layout;

const SideMenu = () => {
    const location = useLocation();

    const getSelectedKeys = () => {
        let path = location.pathname.substring(1);
        if (path === '') {
            path = 'live-view';
        }
        return [path];
    }

    const [collapsed, setCollapsed] = useState(localStorage.getItem('menu_collapsed') === 'true');
    const [selectedKeys, setSelectedKeys] = useState<string[]>(getSelectedKeys());

    useEffect(() => {
        setSelectedKeys(getSelectedKeys());
    }, [location]);

    const updateCollapsed = (value: boolean) => {
        localStorage.setItem('menu_collapsed', JSON.stringify(value));
        setCollapsed(value);
    }


    const defaultOpenKeys = localStorage.getItem('menu_collapsed') === 'true' ? [] : ['devices', 'users', 'customers'];

    return <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => updateCollapsed(value)}
        width={225}
    >
        <div style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
            <img src={'logo512.png'} alt={'logo'} style={{width: '50%', height: 'auto'}}/>
        </div>
        <Menu
            theme="dark"
            defaultSelectedKeys={selectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            mode="inline"
            items={items}
        />
    </Sider>
}

export default SideMenu;
