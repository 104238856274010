import { Auth } from "aws-amplify";
import {XMLParser} from "fast-xml-parser";

export default class APIUtils {

    static parser = new XMLParser();

    static get LOCAL_API_URL() {
        return 'http://localhost:8080/';
    }

    static get LAT_API_URL() {
        return process.env.REACT_APP_AWS_API_GATEWAY_URL;
    }

    static get API_URL() {
        return process.env.NODE_ENV === 'production' || process.env.REACT_APP_PROD_ENV ? this.LAT_API_URL : this.LOCAL_API_URL;
    }

    static get DEFAULT_REQUEST_HEADERS() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    static async authRequestHeader() {
        const user = await Auth.currentSession();
        const authToken = user.idToken.jwtToken;
        return {
            'Authorization': `Bearer ${authToken}`
        };
    }

    static shouldIncludeAuthHeader() {
        return process.env.NODE_ENV === 'production' || process.env.REACT_APP_PROD_ENV;
    }

    static async postWithURL(api_url, endpoint, include_auth_header, request_body) {
        let options;
        if (include_auth_header) {
            const authRequestHeader = await this.authRequestHeader();
            options = {
                method: 'POST',
                headers: { ...this.DEFAULT_REQUEST_HEADERS, ...authRequestHeader },
                body: request_body
            };
        } else {
            options = {
                method: 'POST',
                headers: { ...this.DEFAULT_REQUEST_HEADERS },
                body: request_body
            };
        }
        const response = await fetch(api_url + endpoint, options);
        return await response.json();
    }

    static async patchWithURL(api_url, endpoint, include_auth_header, request_body) {
        let options;
        if (include_auth_header) {
            const authRequestHeader = await this.authRequestHeader();
            options = {
                method: 'PATCH',
                headers: { ...this.DEFAULT_REQUEST_HEADERS, ...authRequestHeader },
                body: request_body
            };
        } else {
            options = {
                method: 'PATCH',
                headers: { ...this.DEFAULT_REQUEST_HEADERS },
                body: request_body
            };
        }
        const response = await fetch(api_url + endpoint, options);
        return await response.json();
    }

    static async getWithURL(api_url, endpoint, include_auth_header) {
        let options;
        if (include_auth_header) {
            const authRequestHeader = await this.authRequestHeader();
            options = {
                method: 'GET',
                headers: { ...this.DEFAULT_REQUEST_HEADERS, ...authRequestHeader }
            };
        } else {
            options = {
                method: 'GET',
                headers: { ...this.DEFAULT_REQUEST_HEADERS }
            };
        }
        const response = await fetch(api_url + endpoint, options);
        return await response.json();
    }

    static async getWithURLForXML(api_url, endpoint, include_auth_header) {
        let options;
        if (include_auth_header) {
            const authRequestHeader = await this.authRequestHeader();
            options = {
                method: 'GET',
                headers: { ...this.DEFAULT_REQUEST_HEADERS, ...authRequestHeader }
            };
        } else {
            options = {
                method: 'GET',
                headers: { ...this.DEFAULT_REQUEST_HEADERS }
            };
        }
        const response = await fetch(api_url + endpoint, options);
        let responseXML = await response.text()

        return this.parser.parse(responseXML);
    }

    static async get(endpoint) {
        return this.getWithURL(this.API_URL, endpoint, this.shouldIncludeAuthHeader());
    }

    static async getUsers() {
        return this.get('users');
    }

    static async getGroups() {
        return this.get('groups');
    }

    static async getFloors() {
        return this.get('floors');
    }

    static async getUserProfile() {
        const currentlyAuthenticatedUserEndpoint = 'users/me';
        return this.get(currentlyAuthenticatedUserEndpoint);
    }

    static async getUniqueLinkedDeviceIds(userId) {
        const userWearableLinksEndpoint = `user-wearable-links/users/${userId}`;
        const userWearableLinks = await this.get(userWearableLinksEndpoint);
        const wearableIds = userWearableLinks.map(uwl => uwl.wearable.id);
        return Array.from(new Set(wearableIds));
    }

    /**
     * Get the thing Group config for the given thing Group name
     * @param thingGroupName
     * @returns {Promise<any>}
     */
    static async getThingGroupConfig(thingGroupName) {
        const iotGetThingGroupConfigEndpoint = `config/thing-group?thingGroupName=${thingGroupName}`;
        return this.getWithURL(this.LAT_API_URL, iotGetThingGroupConfigEndpoint, true);
    }

    /**
     * Update the thing Group config for the given thing Group name
     * @param thingGroupName
     * @param config
     * @returns {Promise<any>}
     */
    static async updateThingGroupConfig(thingGroupName, config) {
        // Post to endpoint with thingGroupName and config as parameters both are MUST
        const iotPostThingGroupConfigEndpoint = 'config/thing-group';
        const body = JSON.stringify({
            "thingGroupName": `${thingGroupName}`,
            "config": `${config}`
        });
        return this.postWithURL(this.LAT_API_URL, iotPostThingGroupConfigEndpoint, true, body);
    }

    static async uploadFloor(filename, content) {
        const endpoint = 'floor/upload';
        const body = JSON.stringify({
            "filename": `${filename}`,
            "content": `${content}`
        });
        return this.postWithURL(this.LAT_API_URL, endpoint, true, body);
    }


    /**
     * Encrypt or decrypt a string using endpoint
     * @param ssid
     * @param password
     * @param encrypt
     * @returns {Promise<any>}
     */
    static async devicePasswordCryptography(ssid, password, encrypt) {
        const iotDevicePasswordCryptographyEndpoint = `config/util/device-password-crypto`;
        const body = JSON.stringify({
            "ssid": `${ssid}`,
            "password": `${password}`,
            "encrypt": `${encrypt}`
        });
        return this.postWithURL(this.LAT_API_URL, iotDevicePasswordCryptographyEndpoint, true, body)
    }


    /**
     * List all vitality cognito users
     * @param limit Limit the number of users returned
     * @param paginationToken Pagination token
     * @returns {Promise<any>}
     */
    static async listVitalityCognitoUsers(limit, paginationToken) {
        let vitalityUsersListEndpoint = `vitality/users/list?limit=${limit}`;
        if (paginationToken !== undefined && paginationToken !== null) {
            vitalityUsersListEndpoint += `&page=${paginationToken}`;
        }
        return this.getWithURL(this.LAT_API_URL, vitalityUsersListEndpoint, true);
    }

    /**
     * List all lat cognito users
     * @param limit Limit the number of users returned
     * @param paginationToken Pagination token
     * @returns {Promise<any>}
     */
    static async listLatCognitoUsers(limit, paginationToken) {
        let vitalityUsersListEndpoint = `lat/users/list?limit=${limit}`;
        if (paginationToken !== undefined && paginationToken !== null) {
            vitalityUsersListEndpoint += `&page=${paginationToken}`;
        }
        return this.getWithURL(this.LAT_API_URL, vitalityUsersListEndpoint, true);
    }

    /**
     * List all groups for vitality cognito user
     * @param cognitoUserId The cognito user id to update
     * @returns {Promise<any>}
     */
    static async listGroupsForVitalityUser(cognitoUserId) {
        const vitalityUserGroupsEndpoint = `vitality/users/${cognitoUserId}/groups`;
        return this.getWithURL(this.LAT_API_URL, vitalityUserGroupsEndpoint, true);
    }

    static async setTenantUserLimit(tenantId, userLimit) {
        const setTenantUserLimitEndpoint = `vitality/tenant/${tenantId}/userLimit`
        const body = JSON.stringify({
            "userLimit": userLimit,
        });
        return this.postWithURL(this.LAT_API_URL, setTenantUserLimitEndpoint, true, body);
    }

    static async sendControlActionRFID(thingName, action, stickyDurationMs, positioningArgs, silent, keepAwake) {
        const thingsControlEndpoint = `things/control/rfid`;
        const body = JSON.stringify({
            "thingName": thingName,
            "action": action,
            "stickyDurationMs": stickyDurationMs,
            "positioningArgs": positioningArgs,
            "silent": silent,
            "keepAwake": keepAwake,
        });
        return this.postWithURL(this.LAT_API_URL, thingsControlEndpoint, true, body);
    }

    /**
     * Set the groups for vitality cognito user
     * @param cognitoUserId The cognito user id to update
     * @param groups A list of groups the user manages
     * @returns {Promise<any>}
     */
    static async setGroupsForVitalityUser(cognitoUserId, groups) {
        const vitalityUserGroupsEndpoint = `vitality/users/${cognitoUserId}/groups`;
        const body = JSON.stringify({
            "groups": groups
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserGroupsEndpoint, true, body);
    }

    static async listFloorsForVitalityUser(cognitoUserId) {
        const vitalityUserFloorsEndpoint = `vitality/users/${cognitoUserId}/floors`;
        return this.getWithURL(this.LAT_API_URL, vitalityUserFloorsEndpoint, true);
    }

    static async setFloorsForVitalityUser(cognitoUserId, floors, hasMetadataAccess) {
        const vitalityUserFloorsEndpoint = `vitality/users/${cognitoUserId}/floors`;
        const body = JSON.stringify({
            "floors": floors,
            "view_metadata": hasMetadataAccess,
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserFloorsEndpoint, true, body);
    }

    static async listApplicationsForVitalityUser(cognitoUserId) {
        const vitalityUserFloorsEndpoint = `vitality/users/${cognitoUserId}/applications`;
        return this.getWithURL(this.LAT_API_URL, vitalityUserFloorsEndpoint, true);
    }

    static async setApplicationsForVitalityUser(cognitoUserId, floors) {
        const vitalityUserFloorsEndpoint = `vitality/users/${cognitoUserId}/applications`;
        const body = JSON.stringify({
            "applications": floors
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserFloorsEndpoint, true, body);
    }

    static async setTenantForUser(cognitoUserId, tenantId) {
        const vitalityUserTenantEndpoint = `vitality/users/${cognitoUserId}/tenant`;
        const body = JSON.stringify({
            "tenantId": tenantId
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserTenantEndpoint, true, body);
    }

    static async getTenantForUser(cognitoUserId) {
        const vitalityUserTenantEndpoint = `vitality/users/${cognitoUserId}/tenant`;
        return this.getWithURL(this.LAT_API_URL, vitalityUserTenantEndpoint, true);
    }

    /**
     * Update the password for vitality cognito user
     * @param cognitoUserId The cognito user id to update
     * @param password new password
     * @param temporary if the password should be changed by the user
     * @param signOut if the user should be signed out
     * @returns {Promise<any>}
     */
    static async setPasswordForVitalityUser(cognitoUserId, password, temporary, signOut) {
        const vitalityUserPasswordEndpoint = `vitality/users/${cognitoUserId}/password`;
        const body = JSON.stringify({
            "newPassword": password,
            "isTemporary": temporary,
            "signOut": signOut
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserPasswordEndpoint, true, body);
    }

    /**
     * Update the password for lat cognito user
     * @param cognitoUserId The cognito user id to update
     * @param password new password
     * @param temporary if the password should be changed by the user
     * @returns {Promise<any>}
     */
    static async setPasswordForLatUser(cognitoUserId, password, temporary, signOut) {
        const vitalityUserPasswordEndpoint = `lat/users/${cognitoUserId}/password`;
        const body = JSON.stringify({
            "newPassword": password,
            "isTemporary": temporary,
            "signOut": signOut
        });
        return this.postWithURL(this.LAT_API_URL, vitalityUserPasswordEndpoint, true, body);
    }

    /**
     * List all vitality groups
     * @returns {Promise<any>}
     */
    static async listAllVitalityGroups() {
        const vitalityGroupsEndpoint = `vitality/groups/list`;
        return this.getWithURL(this.LAT_API_URL, vitalityGroupsEndpoint, true);
    }

    /**
     * List all vitality floors
     * @returns {Promise<any>}
     */
    static async listAllVitalityFloors() {
        const vitalityFloorsEndpoint = `vitality/floors/list`;
        return this.getWithURL(this.LAT_API_URL, vitalityFloorsEndpoint, true);
    }

    /**
     * List all vitality applications
     * @returns {Promise<any>}
     */
    static async listAllVitalityApplications() {
        const vitalityFloorsEndpoint = `vitality/applications/list`;
        return this.getWithURL(this.LAT_API_URL, vitalityFloorsEndpoint, true);
    }

    /**
     * List all vitality tenants
     * @returns {Promise<any>}
     */
    static async listVitalityTenants() {
        const vitalityTenantsEndpoint = `vitality/tenants/list`;
        return this.getWithURL(this.LAT_API_URL, vitalityTenantsEndpoint, true);
    }

    /**
     * Create a new vitality Group
     * @param groupName
     * @param groupTenantId
     * @returns {Promise<any>}
     */
    static async createNewVitalityGroup(groupName, groupTenantId, thingGroup) {
        const vitalityGroupsEndpoint = `vitality/groups/create`;
        const body = JSON.stringify({
            "groupName": groupName,
            "groupTenantId": groupTenantId,
            "thingGroup": thingGroup,
        });
        return this.postWithURL(this.LAT_API_URL, vitalityGroupsEndpoint, true, body);
    }

    /**
     * Create a new vitality tenant
     * @param tenantName
     * @returns {Promise<any>}
     */
    static async createNewTenant(tenantName) {
        const vitalityTenantsEndpoint = `vitality/tenants/create`;
        const body = JSON.stringify({
            "tenantName": tenantName,
        });
        return this.postWithURL(this.LAT_API_URL, vitalityTenantsEndpoint, true, body);
    }

    /**
     * Update Tenant ID for a Group
     * @param groupId
     * @param tenantId
     * @returns {Promise<any>}
     */
    static async updateTenantIdForGroup(groupId, tenantId) {
        const vitalityTenantsEndpoint = `vitality/groups/${groupId}/tenant`;
        const body = JSON.stringify({
            "tenantId": tenantId,
        });
        return this.postWithURL(this.LAT_API_URL, vitalityTenantsEndpoint, true, body);
    }



    /**
     * Create a new vitality cognito user
     * @param email
     * @param password
     * @param autoVerify
     * @returns {Promise<any>}
     */
    static async createVitalityCognitoUser(email, password, autoVerify) {
        const cognitoUserCreateEndpoint = `vitality/users/create`;
        const body = JSON.stringify({
            "email": email,
            "password": password,
            "autoVerify": autoVerify
        });
        return this.postWithURL(this.LAT_API_URL, cognitoUserCreateEndpoint, true, body);
    }

    /**
     * Create a new lat cognito user
     * @param email
     * @param password
     * @param autoVerify
     * @returns {Promise<any>}
     */
    static async createLatCognitoUser(email, password, autoVerify) {
        const cognitoUserCreateEndpoint = `lat/users/create`;
        const body = JSON.stringify({
            "email": email,
            "password": password,
            "autoVerify": autoVerify
        });
        return this.postWithURL(this.LAT_API_URL, cognitoUserCreateEndpoint, true, body);
    }

    /**
     * Add a new vitality user to the database
     * @param cognitoUUID
     * @param tenantId
     * @param notes
     * @returns {Promise<any>}
     */
    static async addVitalityCognitoUserToDatabase(cognitoUUID, tenantId, notes) {
        const cognitoUserCreateEndpoint = `vitality/users/${cognitoUUID}/add`;
        const body = JSON.stringify({
            "tenantId": tenantId,
            "notes": notes,
        });
        return this.postWithURL(this.LAT_API_URL, cognitoUserCreateEndpoint, true, body);
    }

    /**
     * This function is responsible for retrieving the shadow for a specified wearable
     * @param wearableId
     * @returns {Promise<*>}
     */
    static async getShadow(wearableId) {
        const iotShadowEndpoint = `shadows/${wearableId}`;
        return this.getWithURL(this.LAT_API_URL, iotShadowEndpoint, true);
    }

    static async updateShadow(wearableId, config) {
        const iotShadowEndpoint = `shadows/${wearableId}`;
        const body = JSON.stringify({
            'config': config,
        })

        return this.postWithURL(this.LAT_API_URL, iotShadowEndpoint, true, body)
    }

    /**
     * This function is responsible for retrieving the available thing groups to which a wearable config can be changed
     * @returns {Promise<*>}
     */
    static async getThingGroups() {
        const iotThingGroupsEndpoint = 'thing-groups/all';
        return this.getWithURL(this.LAT_API_URL, iotThingGroupsEndpoint, true);
    }

    static async getFormattedThingGroups() {
        const iotThingGroupsEndpoint = 'thing-groups/all/formatted';
        return this.getWithURL(this.LAT_API_URL, iotThingGroupsEndpoint, true);
    }

    /**
     * This function is responsible for retrieving the available firmware version that can be flashed onto a wearable
     * @returns {Promise<*>}
     */
    static async getAvailableFirmware() {
        const s3PossibleFirmwareEndpoint = 'ota/available-firmware';
        return this.getWithURLForXML(this.LAT_API_URL, s3PossibleFirmwareEndpoint, true);
    }

    /**
     * This function is responsible for updating a wearable over the air to the selected firmware version
     * @param wearableId
     * @param version
     * @returns {Promise<*>}
     */
    static async postOTAUpdate(wearableId, version) {
        const otaUpdateEndpoint = 'ota/update';
        const target = `arn:aws:iot:eu-central-1:223057836478:thing/${wearableId}`;
        const body = JSON.stringify({
            "targets": `${target}`,
            "version": `${version}`
        });

        return this.postWithURL(this.LAT_API_URL, otaUpdateEndpoint, true, body);
    }

    /**
     * This function is responsible for creating (registering) a new device with the device manager.
     * This registered device can then be used by the uploadstation to create and setup a new wearable.
     * @param selectedThingGroup
     * @returns {Promise<*>}
     */
    static async postCreateNewDevice(selectedThingGroup) {
        const createNewDeviceEndpoint = 'devices/create';
        const body = JSON.stringify({
            "thingGroup": `${selectedThingGroup}`
        });
        return this.postWithURL(this.LAT_API_URL, createNewDeviceEndpoint, true, body);
    }

    /**
     * This function is responsible for creating a thing Group
     * @param thingGroupName
     * @returns {Promise<*>}
     */
    static async postCreateNewThingGroup(thingGroupName) {
        const createNewThingGroupEndpoint = 'thing-groups/create';
        const body = JSON.stringify({
            "thingGroupName": `${thingGroupName}`
        });
        return this.postWithURL(this.LAT_API_URL, createNewThingGroupEndpoint, true, body);
    }

    /**
     * This function is responsible for updating the thing Group and thus the tenant config of a device
     * @param wearableId
     * @param thingGroup
     * @returns {Promise<*>}
     */
    static async postUpdateDeviceThingGroup(wearableId, thingGroup) {
        const updateDeviceThingGroupEndpoint = 'things/update-config';
        const body = JSON.stringify({
            "wearableId": `${wearableId}`,
            "thingGroup": `${thingGroup}`
        });
        return this.postWithURL(this.LAT_API_URL, updateDeviceThingGroupEndpoint, true, body);
    }

    /**
     * Return the connection status (whether a device is/was connected and when this status was last observed).
     * @param {*} wearableId
     */
    static async getDeviceConnectivityStatus(wearableId) {
        const deviceStatusEndpoint = "devices/status";
        /* The device status endpoint maps to the SearchIndex API action on the AWS IoT service, hence this request body.  */
        const requestBody = JSON.stringify({
            "indexName": "AWS_Things",
            "queryString": `thingName:${wearableId}`
        });
        const thingInfo = await this.postWithURL(this.LAT_API_URL, deviceStatusEndpoint, true, requestBody);
        if (thingInfo.things && thingInfo.things.length > 0) {
            const firstThing = thingInfo.things[0]; // we're querying a single wearable device anyway
            return firstThing.connectivity;
        }
        else return { connected: false, timestamp: null };
    }

    /**
     * Return all the devices we have currently maxed to 250 devices per page
     */
    static async getAllDevices() {
        const allDevicesEndpoint = "devices/all";
        return this.getWithURL(this.LAT_API_URL, allDevicesEndpoint, true);
    }

    /**
     * Returns the devices currently present in the thingGroup
     * @param {*} thingGroup
     */
    static async getAllDevicesInThingGroup(thingGroup) {
        const allDevicesInThingGroupEndpoint = `thing-groups/devices/${thingGroup}`;
        return this.getWithURL(this.LAT_API_URL, allDevicesInThingGroupEndpoint, true);
    }

    /**
     * Patches the thing with a new comment as an attribute
     * @param wearableId
     * @param comments
     */
    static postUpdateDeviceComments(wearableId, comments) {
        const updateDeviceEndpoint = `things/update/${wearableId}`;
        const requestBody = JSON.stringify({
            "attributePayload": {
                "attributes": {
                    "comment": comments
                },
                "merge": true
            }
        });
        return this.patchWithURL(this.LAT_API_URL, updateDeviceEndpoint, true, requestBody);
    }

    static async getBatteryPercentageStatus(wearableId, firstTimestamp, secondTimestamp) {
        const batteryPercentageStatusendpoint = `device-battery-percentage?wearableId=${wearableId}&firstTimestamp=${firstTimestamp}&secondTimestamp=${secondTimestamp}`;
        return this.getWithURL(this.LAT_API_URL, batteryPercentageStatusendpoint, true);
    }

    static async getFootstepsForWearableWithinTimeWindow(wearableId, beginTime, endTime) {
        const footstepsForUserWithinTimeWindowEndpoint = `footsteps/wearables/${wearableId}?begin=${beginTime}&end=${endTime}`;
        return this.getWithURL(this.LAT_API_URL, footstepsForUserWithinTimeWindowEndpoint, true);
    }


    /**
     * This function sends a reset request for the specified wearable resulting in the battery cycles and start date
     * of the battery to be reset. The reset button should only be used when a new battery is supplied for use by the device
     * @param wearableId
     * @returns {Promise<*>}
     */
    static async postResetDeviceBatteryCycles(wearableId) {
        const resetDeviceBatteryCyclesEndpoint = 'things/reset-battery-cycles';
        const body = JSON.stringify({
            "wearableId": `${wearableId}`
        });
        return this.postWithURL(this.LAT_API_URL, resetDeviceBatteryCyclesEndpoint, true, body);
    }

    /**
     * This function patches an existing wearable id with the calibrated values.
     */
    static async postCalibratedValues(wearableId, calibratedX, calibratedZ) {
        const patchCalibratedValuesEndpoint = `wearables/${wearableId}`;
        const body = JSON.stringify({
            "calibratedX":`${calibratedX}`,
            "calibratedZ":`${calibratedZ}`
        });
        let response = this.postWithURL(this.LAT_API_URL, patchCalibratedValuesEndpoint, true, body);
        console.log(patchCalibratedValuesEndpoint)
        return response;
    }

    static async getWearable(wearableId) {
        let wearableEndpoint = `wearables/${wearableId}`;
        const response = this.getWithURL(this.LAT_API_URL, wearableEndpoint, true);
        return response;
    }
}
