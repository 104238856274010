import React, {useState} from "react";
import {Button, Checkbox, Drawer, Form, Input, App, Space} from "antd";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {CreateCognitoUserMutation, CreateCognitoUserMutationVariables} from "../../utils/graphql/mutations";
import * as mutations from "../../utils/graphql/mutations";
import {InfoCircleOutlined} from "@ant-design/icons";
import {TenantSelector} from "../SharedComponents/TenantSelector";


interface CreateUserProps {
    reload: () => void;
}


const CreateUser = ({reload}: CreateUserProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    const createUser = async (
        email: string,
        password: string,
        autoVerify: boolean,
        tenantId: number,
        requirePasswordChange: boolean
    ) => {
        const response = await API.graphql<GraphQLQuery<CreateCognitoUserMutation>>({
            query: mutations.createCognitoUser,
            variables: {
                email: email,
                password: password,
                autoVerifyEmail: autoVerify,
                tenantId: tenantId,
                poolName: 'vitality',
                requirePasswordChange: requirePasswordChange,
            } as CreateCognitoUserMutationVariables
        }).catch((_) => {
            throw new Error("User creation failed");
        });

        return !!response?.data?.createCognitoUser;
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        console.log(values)
        const {email, userPassword, tenantId, autoVerify, requirePasswordChange} = values;
        const response = await createUser(
            email,
            userPassword,
            autoVerify,
            tenantId,
            requirePasswordChange).catch(
            (_) => {
                notification.error({
                    message: 'User creation failed',
                    description: `User ${email} could not be created`,
                });
            }
        );
        if (response) {
            notification.success({
                message: 'User created',
                description: `User ${email} was created successfully`,
            });
            form.resetFields();
            reload();
            onClose();
        } else {
            notification.error({
                message: 'User creation failed',
                description: `User ${email} could not be created`,
            });
        }
        setLoading(false);
    }


    return (
        <>
            <Button type="primary" onClick={openDrawer}>
                Create User
            </Button>
            <Drawer
                title="Create User"
                width={720}
                onClose={onClose}
                open={open}
                extra={[
                    <Space>
                        <Button key="cancel" onClick={onClose}>
                            Cancel
                        </Button>
                    </Space>
                ]}
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='UserCreation'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'User Email'}
                        name={'email'}
                        tooltip={{title: 'Email of the User', icon: <InfoCircleOutlined/>}}
                        rules={[
                            {
                                required: true,
                                message: "Please provide a User email!",
                            }]
                        }
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'User Password'}
                        name={'userPassword'}
                        rules={[
                            {
                                required: true,
                                message: "Please provide a user password!",
                            }
                        ]}
                    >
                        <Input autoComplete='off'/>
                    </Form.Item>
                    <Form.Item
                        label={'Tenant'}
                        name={'tenantId'}
                        tooltip={{title: 'Set the tenant for this user', icon: <InfoCircleOutlined/>}}
                        rules={[
                            {
                                required: true,
                                message: "Please select a tenant!",
                            }
                        ]}
                    >
                        <TenantSelector/>
                    </Form.Item>
                    <Form.Item
                        name="autoVerify"
                        valuePropName="checked"
                        label={'Auto Verify Email'}
                        initialValue={true}
                    >
                        <Checkbox/>
                    </Form.Item>
                    <Form.Item
                        name="requirePasswordChange"
                        valuePropName="checked"
                        label={'Require Password Change'}
                        initialValue={false}
                    >
                        <Checkbox/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

        </>
    );
}


export default CreateUser;