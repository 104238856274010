import React, {useEffect, useState} from 'react'
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../utils/graphql/queries";
import {ListMobileRoutersQuery} from "../../utils/graphql/queries";
import * as mutations from "../../utils/graphql/mutations";
import {CreateMobileRouterMutation, UpdateMobileRouterNetworkConfigMutation} from "../../utils/graphql/mutations";
import {App, Button, Col, Drawer, Form, Input, Row, Select} from "antd";
import DeviceNetworkConfigView from "../DeviceConfigView/DeviceNetworkConfigView";
import {NetworkEntry} from "../DeviceConfigView/NetworkEntry";
import EditNetworkEntry from "../DeviceConfigView/EditNetworkEntry";
import {EncryptionStatus} from "../DeviceConfigView/EncryptionStatus";


type MobileRouter = {
  id: string,
  configuredNetworks: Array<any> | null,
  simEid: string | null,
}

interface CreateMobileRouterProps {
  onComplete?: (router: MobileRouter) => void;
}

const CreateMobileRouter = ({onComplete}: CreateMobileRouterProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [network, setNetwork] = useState<NetworkEntry | null>(null);
  const [form] = Form.useForm();
  const {message} = App.useApp();

  const close = () => {
    form.resetFields();
    setNetwork(null);
    setOpen(false);
  }

  const onFinish = async (values: any) => {
    const routerId = values.routerId;
    const simEid = values.simEid;
    const tmpNetworks = []
    if (network !== null) {
      tmpNetworks.push(JSON.stringify(network));
    }

    try {
      const response = await API.graphql<GraphQLQuery<CreateMobileRouterMutation>>({
        query: mutations.createMobileRouter,
        variables: {
          id: routerId,
          simEid: simEid,
          configuredNetworks: tmpNetworks,
        }
      });
      console.log(response);

      if (response.data?.createMobileRouter) {
        close();
      }

      if (onComplete) {
        onComplete({
          id: routerId,
          simEid: simEid,
          configuredNetworks: tmpNetworks,
        });
      }
    } catch (error) {
      message.error('Failed to create mobile router');
      throw error;
    }
  }

  return (
    <>
      {/* Button goes on the right*/}
      <Button
        onClick={() => setOpen(true)}
        type="primary"
        style={{
          marginBottom: 16,
          float: 'right',
      }}
      >
        Create Mobile Router
      </Button>
      <Drawer
        title="Create Mobile Router"
        placement="right"
        closable={true}
        onClose={close}
        visible={open}
        width={700}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="routerId"
            label="Router ID"
            rules={[{required: true, message: 'Please enter the Router ID'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="simEid"
            label="SIM EID"
            rules={[{required: true, message: 'Please enter the SIM EID'}]}
          >
            <Input />
          </Form.Item>
          <EditNetworkEntry
            title="Add Network"
            network={network}
            onComplete={(network: NetworkEntry) => {
              setNetwork(network);
            }}
            showOpenButton={true}
          />
          {/* Display the configured network if there is one*/}
          {network &&
            <>
                <div style={{marginTop: 5}} />
                <br />
                <b> SSID: </b> {network.wifi_ssid} <br />
                <b> Password: </b> {network.wifi_password} <br />
                <b> Encryption: </b> {network.encrypt_status === EncryptionStatus.ENCRYPTED ? 'Encrypted' : 'Unencrypted'} <br />
                <b> Timeouts: </b> {network.wifi_timeout} <br />
                <b> Priority: </b> {network.wifi_priority} <br />
                <Button
                  onClick={() => setNetwork(null)}
                  style={{
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Clear Network
                </Button>
            </>
          }
          {
            !network &&
             <p>No network configured</p>
          }

          <div style={{marginTop: 16}} />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

const DeviceManagementView = () => {
  const [mobileRouters, setMobileRouters] = useState<MobileRouter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMobileRouter, setSelectedMobileRouter] = useState<MobileRouter | null>(null);

  const onLoad = async ({forceReload = false} = {}) => {
    setLoading(true);
    if (mobileRouters.length === 0 || forceReload) {
      const tmpMobileRouters = await getMobileRouters();
      setMobileRouters(tmpMobileRouters);
    }
    setLoading(false);
  }

  useEffect(() => {
    (async () => {
      await onLoad();
    })();
  }, []);

  const getMobileRouters = async () => {
    const mobileRouters: MobileRouter[] = []

    let response;
    let nextToken = null;
    do {
      response = await API.graphql<GraphQLQuery<ListMobileRoutersQuery>>({
        query: queries.listMobileRouters,
        variables: {
          page: {
            nextToken: nextToken,
          }
        }
      });

      console.log(response);

      if (response.data?.listMobileRouters?.items) {
        const items = response.data.listMobileRouters.items;
        // json parse the field configured_networks

        console.log(items)

        items.forEach((item) => {
          let configuredNetworks = item.configuredNetworks ?? null;
          if (configuredNetworks) {
            // configuredNetworks is an array of strings loop over the array and parse each string
            // and replace the string with the parsed object
            configuredNetworks = configuredNetworks.map((configuredNetwork) => {
              return JSON.parse(configuredNetwork) ?? null;
            });
          }
          item.configuredNetworks = configuredNetworks;
          item.simEid = item.simEid ?? null;
          mobileRouters.push({
            id: item.id,
            configuredNetworks: item.configuredNetworks,
            simEid: item.simEid,
          });
        });
      }

      if (response.data?.listMobileRouters?.nextToken) {
        nextToken = response.data.listMobileRouters.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken !== null);

    return mobileRouters;
  }

  const selectableMobileRouters = mobileRouters.map((mobileRouter) => {
    return {
      label: mobileRouter.id,
      value: mobileRouter.id,
    };
  });

  const filterRouters = (input: string, option: any) => {
    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  const selectMobileRouter = (mobileRouterId: string) => {
    const mobileRouter = mobileRouters.find((mobileRouter) => mobileRouter.id === mobileRouterId);
    if (mobileRouter) {
      setSelectedMobileRouter(mobileRouter);
    }
  }

  const updateMobileRouterNetworkConfig = async (mobileRouterId: string, networkConfig: NetworkEntry[] | null) => {
    // Make a graphql mutation to update the network config
    let formattedNetworkConfig: string[] | null = null;
    if (networkConfig) {
      formattedNetworkConfig = networkConfig?.map((networkEntry) => {
        return JSON.stringify(networkEntry);
      });
    }

    const response = await API.graphql<GraphQLQuery<UpdateMobileRouterNetworkConfigMutation>>({
      query: mutations.updateMobileRouterNetworkConfig,
      variables: {
        id: mobileRouterId,
        configuredNetworks: formattedNetworkConfig,
      }
    });

    console.log(response);

    await onLoad({forceReload: true});
  }

  const onNetworkConfigChange = (networkConfig: NetworkEntry[] | null) => {
    if (selectedMobileRouter) {
      updateMobileRouterNetworkConfig(selectedMobileRouter.id, networkConfig).then();
    }
  }

  const onMobileRouterCreate = async (router: MobileRouter) => {
    await onLoad({forceReload: true});
    setSelectedMobileRouter(null);
  }

  return (
    <>
      <Row>
        <Col span={18}>
          <Select
            showSearch
            style={{width: '100%'}}
            allowClear={true}
            placeholder="Select a Mobile Router"
            optionFilterProp="children"
            filterOption={filterRouters}
            loading={loading}
            onChange={(value) => {
              if (value) {
                selectMobileRouter(value.toString());
              } else {
                setSelectedMobileRouter(null);
              }
            }}
            options={selectableMobileRouters}
            value={selectedMobileRouter?.id ?? undefined}
          />
        </Col>
        <Col span={6}>
          < CreateMobileRouter
            onComplete={onMobileRouterCreate}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {selectedMobileRouter &&
              <DeviceNetworkConfigView
                  configNetworks={selectedMobileRouter?.configuredNetworks}
                  showAutoField={false}
                  onSave={onNetworkConfigChange}
                  saveButtonText={"Update Network Config"}
                  askForConfirmation={true}
              />
          }
        </Col>
      </Row>
    </>
  )
}

export default DeviceManagementView;
