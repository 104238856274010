import React from "react";
import {Col, Row, Steps} from "antd";
import {CustomerCreationInstructions} from "./CustomerCreationInstructions";
import {TenantCreationStep} from "./steps/TenantCreationStep";
import {GroupCreationStep} from "./steps/GroupCreationStep";
import {SensorInstructions} from "./steps/SensorInstructions";
import {MobileRouterInstructions} from "./steps/MobileRouterInstructions";
import {UserCreateInstructions} from "./steps/UserCreateInstructions";
import {FloorInstructions} from "./steps/FloorInstructions";
import {ApplicationInstructions} from "./steps/ApplicationInstructions";
import {TenantDetailsInstructions} from "./steps/TenantDetailsInstructions";
import {GroupDetailsInstructions} from "./steps/GroupDetailsInstructions";
import {OverviewInstructions} from "./steps/OverviewInstructions";
import {ExecutionInstructions} from "./steps/ExecutionInstructions";
import {InstructionsDone} from "./steps/InstructionsDone";
import {CustomerCreationStep} from "./CustomerCreationStep";


interface CustomerCreationViewProps {}

const ITEMS = [
  {
    title: "Create a tenant",
  },
  {
    title: "Tenant details",
  },
  {
    title: "Create a group",
  },
  {
    title: "Group details",
  },
  {
    title: "Select sensors",
  },
  {
    title: "Select mobile routers",
  },
  {
    title: "Add users",
  },
  {
    title: "Select floors",
  },
  {
    title: "Select applications",
  },
  {
    title: "Confirm",
  },
  {
    title: "Creating",
  },
  {
    title: "Done",
  },
]


const CustomerCreationView = ({}: CustomerCreationViewProps) => {
  const [step, setStep] = React.useState<CustomerCreationStep>(CustomerCreationStep.TENANT);
  const [instructions, setInstructions] = React.useState<CustomerCreationInstructions>({});

  const onStepComplete = (instructions: CustomerCreationInstructions) => {
    setInstructions(instructions);
    let newStep = step + 1;
    if (newStep === CustomerCreationStep.DONE + 1) {
      newStep = CustomerCreationStep.TENANT;
    }
    setStep(newStep);
  }

  const onStepBack = (): void => {
    setStep(step - 1 > 0 ? step - 1 : 0);
  }

  const onReset = (): void => {
    setStep(CustomerCreationStep.TENANT);
  }

  return (
    <>
      <Row>
        <Col span={6}>
          <Steps
            current={step}
            size={"small"}
            direction={"vertical"}
            items={ITEMS}
          />
        </Col>
        <Col span={18}>
          {step === CustomerCreationStep.TENANT && <TenantCreationStep instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.TENANT_DETAILS && <TenantDetailsInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_NAME && <GroupCreationStep instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_DETAILS && <GroupDetailsInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_SENSORS && <SensorInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_MOBILE_ROUTERS && <MobileRouterInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_USERS && <UserCreateInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_FLOORS && <FloorInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.GROUP_APPLICATIONS && <ApplicationInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.CONFIRMATION && <OverviewInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete}/>}
          {step === CustomerCreationStep.CREATION && <ExecutionInstructions instructions={instructions} onStepBack={onStepBack} onStepComplete={onStepComplete} onReset={onReset}/>}
          {step === CustomerCreationStep.DONE && <InstructionsDone instructions={instructions} onStepBack={onReset} onStepComplete={onStepComplete}/>}
        </Col>
      </Row>
    </>
  )
}

export default CustomerCreationView;
