import {Alert, App, Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select} from "antd";
import React, {useState} from "react";
import {
    CheckCircleOutlined,
    CloseCircleOutlined, DeleteOutlined,
    PlayCircleOutlined,
    SoundOutlined,
    StopOutlined
} from "@ant-design/icons";
import APIUtils from "../../utils/APIUtils";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as mutations from "../../utils/graphql/mutations";
import {
    StartDurationTestMutation,
    StartDurationTestMutationVariables, StopDurationTestMutation,
    StopDurationTestMutationVariables
} from "../../utils/graphql/mutations";

const ControlRFID = () => {
    const {message} = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [selectedAction, setSelectedAction] = useState(null);
    const [useJob, setUseJob] = useState(false);

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        setLoading(true);

        let stickyDuration = values.stickyDuration;
        if (stickyDuration === '' || stickyDuration === undefined) {
            stickyDuration = null
        } else if (stickyDuration) {
            const [hours, minutes] = stickyDuration.split(':');
            stickyDuration = (parseInt(hours) * 60 * 60 * 1000) + (parseInt(minutes) * 60 * 1000);
        }

        let positioningArgs = values.positioningArgs;
        if (positioningArgs === '' || positioningArgs === undefined) {
            positioningArgs = null
        } else if (positioningArgs) {
            positioningArgs = JSON.parse(positioningArgs);
        }

        //value.thingName is a string that either has a single value
        // or a list separated by commas
        const thingNames = values.thingName.split(',');
        if (values.useJob) {
            if (values.controlActionRFID === 'start') {
                const response = await API.graphql<GraphQLQuery<StartDurationTestMutation>>({
                    query: mutations.startDurationTest,
                    variables: {
                        heelableIds: thingNames,
                        duration: stickyDuration,
                        silent: values.silent,
                        keepAwake: values.keepAwake,
                        positioningArgs: positioningArgs != null ? JSON.stringify(positioningArgs) : null,
                    } as StartDurationTestMutationVariables
                }).catch((error) => {
                    console.log('Failed:', error);
                    message.error(error.errors[0].message);
                });

                if (response?.data?.startDurationTest) {
                    message.success('Command sent');
                }
            }
            else if (values.controlActionRFID === 'stop') {
                const response = await API.graphql<GraphQLQuery<StopDurationTestMutation>>({
                    query: mutations.stopDurationTest,
                    variables: {
                        heelableIds: thingNames,
                    } as StopDurationTestMutationVariables
                }).catch((error) => {
                    console.log('Failed:', error);
                    message.error('Failed to send command');
                });

                if (response?.data?.stopDurationTest) {
                    message.success('Command sent');
                }
            }
        } else {
            for (const thingName of thingNames) {
                const response = await APIUtils.sendControlActionRFID(
                    thingName,
                    values.controlActionRFID,
                    stickyDuration,
                    positioningArgs,
                    values.silent,
                    values.keepAwake
                );
                if (response.message !== 'OK') {
                    message.error(`Failed to send command to ${thingName}`);
                }
            }
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return <Card>
        <Card.Meta
            title="RFID Control"
            style={{
                marginBottom: '1em'
            }}
        />
        <Form
            form={form}
            name='GroupCreation'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            disabled={loading}
        >
            <Form.Item
                label={'Thing Name'}
                name={'thingName'}
                rules={[
                    {
                        required: true,
                        message: "Please provide a thing name",
                    }
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={'RFID Action'}
                name={'controlActionRFID'}
                rules={[
                    {
                        required: true,
                        message: "Please select a control action",
                    }
                ]}
            >
                <Radio.Group
                    onChange={(e) => setSelectedAction(e.target.value)}
                >
                    <Radio.Button value='once'><SoundOutlined/> Once</Radio.Button>
                    <Radio.Button value='start'><PlayCircleOutlined/> Start</Radio.Button>
                    <Radio.Button value='stop'><StopOutlined/> Stop</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {
                (selectedAction === 'stop' || selectedAction === 'start') && (
                    <>
                        <Form.Item
                            label={'Use Job'}
                            name={'useJob'}
                            valuePropName={'checked'}
                        >
                            <Checkbox onChange={(e) => setUseJob(e.target.checked)}/>
                        </Form.Item>
                    </>
                )
            }
            {
                (selectedAction === 'once' || selectedAction === 'start') &&
                <Alert
                    message="Following fields are optional"
                    type="info"
                    showIcon
                    style={{margin: '1em'}}
                />
            }
            {selectedAction === 'start' &&
                <>
                    {/* Stick duration must be the format hh:mm (or as many h as needed*/}
                    <Form.Item
                        label={'Sticky Duration'}
                        name={'stickyDuration'}
                        rules={[
                            {
                                pattern: new RegExp('^([0-9]+):([0-9]{2})$'),
                            },
                        ]}
                    >
                        <Input placeholder={'hh:mm'}/>
                    </Form.Item>
                    <Form.Item
                        label={'Silent'}
                        name={'silent'}
                        valuePropName={'checked'}
                    >
                        <Checkbox/>
                    </Form.Item>
                    <Form.Item
                        label={'Keep awake'}
                        name={'keepAwake'}
                        valuePropName={'checked'}
                    >
                        <Checkbox/>
                    </Form.Item>
                </>
            }
            {(selectedAction === 'once' || selectedAction === 'start') &&
                <>
                    <Form.Item
                        label={'Positioning Arguments'}
                        name={'positioningArgs'}
                        rules={[
                            {
                                required: useJob,
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                </>
            }
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Send Control Message
                </Button>
            </Form.Item>
        </Form>
    </Card>
}

function DebugControl() {
    const {message} = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        setLoading(true);

        let debug: number | null = 0
        if (values.debug === 'enabled') {
            debug = 1
        }
        if (values.debug === 'removed') {
            debug = null
        }

        const config = {
            'debug': debug,
        }

        const response = await APIUtils.updateShadow(values.thingName, config);
        if (response.status !== 'OK') {
            message.error('Failed to send command');
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return <Card>
        <Card.Meta
            title="Debug Control"
            style={{
                marginBottom: '1em'
            }}
        />
        <Form
            form={form}
            name='GroupCreation'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            disabled={loading}
        >
            <Form.Item
                label={'Thing Name'}
                name={'thingName'}
                rules={[
                    {
                        required: true,
                        message: "Please provide a thing name",
                    }
                ]}
            >
                <Input/>
            </Form.Item>
            <Alert message="Thing name must exist. No checks are performed to confirm thing existence." type="info"
                   showIcon style={{margin: '1em'}}/>
            <Form.Item
                label={'Debugging'}
                name={'debug'}
                rules={[
                    {
                        required: true,
                        message: "Please select a debug setting",
                    }
                ]}
            >
                <Radio.Group>
                    <Radio.Button value='enabled'><CheckCircleOutlined/> Enabled</Radio.Button>
                    <Radio.Button value='disabled'><CloseCircleOutlined/> Disabled</Radio.Button>
                    <Radio.Button value='removed'><DeleteOutlined/> Remove</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Set Debug State
                </Button>
            </Form.Item>
        </Form>
    </Card>
}


function TelemetryLevelControl() {
    const {message} = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        setLoading(true);

        let level: number | null;
        if (values.debug === 'removed') {
            level = null
        } else {
            // else number and convert to int
            level = parseInt(values.debug)
        }

        const config = {
            'telemetry_level': level,
        }

        const response = await APIUtils.updateShadow(values.thingName, config);
        if (response.status !== 'OK') {
            message.error('Failed to send command');
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const selectOptions = [
        {
            label: 'Remove',
            value: 'removed',
        },
        {
            label: 'Debug',
            value: '4',
        },
        {
            label: 'Info',
            value: '6',
        },
        {
            label: 'Warning',
            value: '8',
        },
        {
            label: 'Error',
            value: '10',
        },
        {
            label: 'Critical',
            value: '12',
        },
    ]

    return <Card>
        <Card.Meta
            title="Debug Control"
            style={{
                marginBottom: '1em'
            }}
        />
        <Form
            form={form}
            name='GroupCreation'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            disabled={loading}
        >
            <Form.Item
                label={'Thing Name'}
                name={'thingName'}
                rules={[
                    {
                        required: true,
                        message: "Please provide a thing name",
                    }
                ]}
            >
                <Input/>
            </Form.Item>
            <Alert message="Thing name must exist. No checks are performed to confirm thing existence." type="info"
                   showIcon style={{margin: '1em'}}/>
            <Form.Item
                label={'Telelmetry Level'}
                name={'telemetryLevel'}
                rules={[
                    {
                        required: true,
                        message: "Please select a debug setting",
                    }
                ]}
            >
                <Select options={selectOptions}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Set Debug State
                </Button>
            </Form.Item>
        </Form>
    </Card>
}


export default function DeviceControlView() {
    return <div>
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <ControlRFID/>
            </Col>
            <Col span={12}>
                <DebugControl/>
            </Col>
            <Col span={12}>
                <TelemetryLevelControl/>
            </Col>
        </Row>
    </div>
}
