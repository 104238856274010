import { GraphQLQuery } from '@aws-amplify/api';
import { TreeSelect } from 'antd';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ListGroupsQuery, Group } from '../../utils/graphql/queries';
import * as queries from "../../utils/graphql/queries";

interface TreeSelectGroup {
    value: number;
    title: string;
}

interface TreeSelectTenant {
    value: string;
    title: string;
    children: TreeSelectGroup[]
}

interface GroupsTreeSelectorProps {
    selectedGroupIDs: string | undefined,
    setSelectedGroupIDs: React.Dispatch<React.SetStateAction<string | undefined>>,
    atLeastOneGroupIsSelected: boolean,
    groupsExternal?: Group[]
}

const GroupsTreeSelector = ({ selectedGroupIDs, setSelectedGroupIDs, atLeastOneGroupIsSelected, groupsExternal}: GroupsTreeSelectorProps) => {
    const [groups, setGroups] = useState<Group[]>(groupsExternal || []);
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const treeSelectData: TreeSelectTenant[] = convertToTenantGroupTree(groups);

    /**
     * Fetch data from AppSync
     * @param nextToken The next token to fetch the next page of data
     */
    const fetchGroups = async (nextToken: string | null) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListGroupsQuery>>({
            query: queries.listGroups,
            variables: {
                page: {
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listGroups) {
            const localGroups = response.data.listGroups.items;

            // Update the groups
            setGroups([...groups, ...localGroups]);

            // Update the next token. We use hooks to update groups later as it is async, and
            // can already start showing the groups while not yet having all groups.
            setNextToken(response.data.listGroups.nextToken);
        }
        setLoading(false);
    }

    function convertToTenantGroupTree(groups: any[]) {
        const byTenantName = (tenantTreeAcc: TreeSelectTenant[], currentGroup: { id: number, name: string, tenant: { id: number, name: string } }) => {
            // if the obj contains the tenant of the current group, we add the group to the children array
            const tenantBranch = tenantTreeAcc.find(t => t.title === currentGroup.tenant.name);
            if (tenantBranch !== undefined) {
                const idxOfBranch = tenantTreeAcc.indexOf(tenantBranch);
                tenantBranch.children = [
                    ...tenantBranch.children,
                    {
                        value: currentGroup.id,
                        title: currentGroup.name
                    }
                ];
                tenantTreeAcc = [
                    ...tenantTreeAcc.slice(0, idxOfBranch),
                    { ...tenantBranch },
                    ...tenantTreeAcc.slice(idxOfBranch + 1)
                ];
            }
            // if not, we need to create a new TreeSelectTenant with one child, the currentGroup as a TreeSelectGroup
            else {
                tenantTreeAcc.push({
                    value: currentGroup.tenant.name,
                    title: currentGroup.tenant.name,
                    children: [{
                        value: currentGroup.id,
                        title: currentGroup.name
                    }]
                })
            }
            return tenantTreeAcc
        }

        const groupsGroupedByTenantName: TreeSelectTenant[] = groups.reduce(byTenantName, []);
        return groupsGroupedByTenantName;
    }

    /**
     * This effect is triggered when the next token changes.
     * The groups are fetched when the next token changes.
     */
    useEffect(() => {
        // use the next token to fetch results.
        // If next token is null we only fetch data if there are no groups yet.
        // in all other cases we fetch data even if there are groups already.
        if ((nextToken || groups.length === 0) && groupsExternal === undefined) {
            fetchGroups(nextToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken, groupsExternal]);

    const onSelectionChange = (newValue: string) => {
        setSelectedGroupIDs(newValue);
    };

    return (
        <TreeSelect
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Select groups"
            allowClear
            treeCheckable={true}
            loading={isLoading}
            onChange={onSelectionChange}
            treeData={treeSelectData}
            value={selectedGroupIDs}
            status={atLeastOneGroupIsSelected ? '' : 'error'}
        />
    );
}

export default GroupsTreeSelector;