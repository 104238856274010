import {CreationStep} from "../CreationStep";
import {App, Button, Col, Form, Input, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../../utils/graphql/queries";
import {GetTenantByNameQuery, GetThingGroupByNameQuery} from "../../../utils/graphql/queries";

export const TenantCreationStep = ({instructions, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {notification} = App.useApp();

  useEffect(() => {
    form.setFieldsValue({
      customerName: instructions.customerName,
      tenantName: instructions.tenant?.name,
      thingGroupName: instructions.tenant?.thingGroupName,
    })
  }, [instructions]);

  const customerNameToTenantName = (customerName: string) => {
    return customerName.toLowerCase().replace(/\s/g, '_');
  }

  const customerNameToThingGroupName = (customerName: string) => {
    let newName: string =  customerName.toLowerCase().split(' ').map((word) => {
      if (word[0] === undefined) {
        return '';
      }
      return word[0].toUpperCase() + word.slice(1);
    }).join('');

    return newName + 'Root';
}

  const onCustomerNameChange = (value: string) => {
    form.setFieldsValue({
      tenantName: customerNameToTenantName(value),
      thingGroupName: customerNameToThingGroupName(value),
    })
  }

  const isTenantNameAvailable = async (tenantName: string) => {
    setLoading(true)

    const response: any = await API.graphql<GraphQLQuery<GetTenantByNameQuery>>({
      query: queries.getTenantByName,
      variables: {
        name: tenantName,
      }
    }).catch((error) => {
      notification.error({
        message: 'Error',
        description: error.errors[0].message,
      });
    });

    setLoading(false)
    return !response.data.getTenantByName;
  }

  const isThingGroupNameAvailable = async (thingGroupName: string) => {
    setLoading(true)

    const response: any = await API.graphql<GraphQLQuery<GetThingGroupByNameQuery>>({
      query: queries.getThingGroupByName,
      variables: {
        name: thingGroupName,
      },
    }).catch((error) => {
      notification.error({
        message: 'Error',
        description: error.errors[0].message,
      });
    });

    setLoading(false)

    return !response.data.getThingGroupByName;
  }

  const onFormFinish = async (values: any) => {
    instructions.customerName = values.friendlyTenantName;
    instructions.tenant = {
      ...instructions.tenant,
      name: values.tenantName,
      thingGroupName: values.thingGroupName,
      alreadyExists: false,
    }

    onStepComplete(instructions);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Create a tenant</Typography.Title>
          <Typography.Paragraph>
            A tenant is the top level of the hierarchy and represents a customer. By customer we mean a physiotherapy
            practice, or other care provider. It is not a municipality or a region.
          </Typography.Paragraph>
          <Typography.Paragraph>
            A tenant contains all resources that a customers "owns" and serves as a security boundary. A tenant can only
            be accessed by users that are assigned to the tenant.
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: 20}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFormFinish}
            initialValues={{
              friendlyTenantName: instructions.customerName,
              tenantName: instructions.tenant?.name,
              thingGroupName: instructions.tenant?.thingGroupName,
            }}
            disabled={loading}
          >
            <div style={{height: 10}}/>
            <Form.Item
              label={'Friendly Tenant Name'}
              name={'friendlyTenantName'}
              rules={
                [
                  {
                    required: true,
                    message: "This is a required field",
                  },
                  {
                    validator: (rule, value) => {
                      if (value === undefined || value === null || value === "") {
                        return Promise.resolve();
                      }
                      if (!value.match(/^[a-zA-Z0-9 ]+$/)) {
                        return Promise.reject("Customer name must only contain letters, numbers and spaces");
                      }
                      if (value.length > 60) {
                        return Promise.reject("Customer name must 60 or fewer characters");
                      }
                      return Promise.resolve();
                    },
                    validateTrigger: 'onChange',
                  },
                  {
                    validator: async (rule, value) => {
                      const tenantName = form.getFieldValue('tenantName');
                      if (!await isTenantNameAvailable(tenantName)) {
                        return Promise.reject("Tenant name is not available");
                      }

                      const thingGroupName = form.getFieldValue('thingGroupName');
                      if (!await isThingGroupNameAvailable(thingGroupName)) {
                        return Promise.reject("Thing group name is not available");
                      }
                      return Promise.resolve();

                    },
                    validateTrigger: 'onBlur',
                  }
                ]
              }
            >
              <Input
                placeholder="Example Customer Name"
                onChange={(e) => onCustomerNameChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={'Tenant name'}
              name={'tenantName'}
              tooltip={'This is the name of the tenant that will be created in the database'}
              validateTrigger={'onChange'}
              // validator
              rules={
                [
                  // must match the regex ^[a-z0-9_]+$ and update while typing
                  {
                    validator: (rule, value) => {
                      if (value === undefined || value === null || value === "") {
                        return Promise.resolve();
                      }
                      if (value.match(/^[a-z0-9_]+$/)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Tenant name must match the regex [a-z_]+");
                    }
                  }
                ]
              }
            >
              <Input placeholder="example_customer_name" disabled/>
            </Form.Item>
            <Form.Item
              label={'Thing group name'}
              name={'thingGroupName'}
              tooltip={'This is the name of the thing group that will be created in AWS IoT. It cannot be changed after creation.'}
            >
              <Input
                placeholder="ExampleCustomerName"
                disabled
                onChange={(e) => console.log(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Next
              </Button>
            </Form.Item>

          </Form>
        </Col>
      </Row>
    </>
  )
}