import { ConfigProvider, App } from 'antd';
import React, { ReactNode } from 'react'

const Theme = ({ children }: { children: ReactNode }) => {
    return <ConfigProvider
        theme={{
            token: {
            },
        }}
    >
        <App>{children}</App>
    </ConfigProvider>
}

export default Theme;
