import React from 'react';
import { stripFileName } from './NewFloorToolView';

type FloorsRejectionProps = {
    selectedFloormaps: string[],
}

const FloorsRejection = ({ selectedFloormaps }: FloorsRejectionProps) => (
    <>
        <p>Are you sure? The following floors will be rejected:</p>
        <ul>
            {
                selectedFloormaps.map((name, idx) => <li key={idx}>{stripFileName(name)}</li>)
            }
        </ul>
    </>
)

export default FloorsRejection;