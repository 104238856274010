import { Alert, Button, Card, Divider, Input, Space, Spin, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import * as queries from '../../utils/graphql/queries';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListGroupsQuery, Group } from '../../utils/graphql/queries';
import GroupsTreeSelector from '../CustomerInsights/GroupsTreeSelector';
import { stripFileName } from './NewFloorToolView';
import { FloormapToApprove } from '../../utils/graphql/mutations';


type FloorsApprovalProps = {
    closeModal: any,
    selectedFloormaps: string[],
    approveFloormaps: (floormapsForApproval: FloormapToApprove[]) => Promise<void>,
    isApprovalLoading: boolean
}


const FloorNamesStep = ({ approvalBody, setApprovalBody }: { approvalBody: FloormapToApprove[], setApprovalBody: any }) => {
    function updateApprovalBody(fileName: string, value: string) {
        const idx = approvalBody.indexOf(approvalBody.find(fm => fm.fileName === fileName)!);
        setApprovalBody((prev: any) => ([
            ...prev.slice(0, idx),
            { ...prev[idx], finalFileName: value },
            ...prev.slice(idx + 1)
        ]))
    }

    return <Space direction='vertical' style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <Alert message="If left empty, the current file name will be used." type="info" showIcon />
        <Alert message="File extension is not allowed in the name." type="info" showIcon />
        {approvalBody.map((fm, idx) => (<div key={idx}>
            <span>{stripFileName(fm.fileName)}</span>
            <Input
                onChange={e => !e.target.value.includes('.') && e.target.value !== ' ' && updateApprovalBody(fm.fileName, e.target.value)}
                value={approvalBody[idx].finalFileName || ''}
                placeholder="Namespace - FloorName"
            />
        </div>))}
    </Space>
}

const GroupAssignmentStep = ({
    approvalBody,
    setApprovalBody,
    groups
}: { approvalBody: FloormapToApprove[], setApprovalBody: any, groups: Group[] }) => {

    function updateApprovalBody(fileName: string, groupIds: any) {
        const idx = approvalBody.indexOf(approvalBody.find(fm => fm.fileName === fileName)!);

        setApprovalBody((prev: any) => ([
            ...prev.slice(0, idx),
            { ...prev[idx], groupIds: groupIds },
            ...prev.slice(idx + 1)
        ]))
    }

    return <Space direction='vertical' style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <Alert message="Groups can be assigned later too (from the 'Floors' tab)." type="info" showIcon />
        {approvalBody.map((fm, idx) => (<div key={idx}>
            <span>{stripFileName(fm.finalFileName || fm.fileName)}</span>
            <GroupsTreeSelector
                selectedGroupIDs={approvalBody[idx].groupIds as any || []}
                setSelectedGroupIDs={(newValue) => updateApprovalBody(fm.fileName, newValue)}
                atLeastOneGroupIsSelected={true} // To avoid the input from complaining
                groupsExternal={groups}
            />
        </div>))}
    </Space>
}

const ReviewStep = ({ approvalBody, groups }: { approvalBody: FloormapToApprove[], groups: Group[] }) => {
    function groupIdsToNameString(groupIds: number[] | any) {
        if (!groupIds || groupIds?.length === 0) return '-';

        let str = '';
        groupIds!.forEach((id: number) => str += `${groups.find(g => g.id === id)!.name}, `);
        return str.substring(0, str.length - 2);
    }

    return <Space direction='vertical' style={{ width: '100%' }}>
        {approvalBody.map((fm, idx) => (<Space key={idx} direction='vertical' style={{ width: '100%' }}>
            <strong>{stripFileName(fm.finalFileName || fm.fileName)}</strong>
            <span>Groups: {groupIdsToNameString(fm.groupIds)}</span>
            {idx < approvalBody.length - 1 && <Divider style={{ marginBottom: 10, width: '100%' }} />}
        </Space>))}
    </Space>
}

const FloorsApproval = ({
    closeModal,
    selectedFloormaps,
    approveFloormaps,
    isApprovalLoading
}: FloorsApprovalProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [approvalBody, setApprovalBody] = useState<FloormapToApprove[]>([]);

    const [groups, setGroups] = useState<Group[]>([]);
    const [nextToken, setNextToken] = useState<string | null>(null);

    useEffect(() => {
        setApprovalBody(selectedFloormaps.map(fileName => {
            const regex = new RegExp('S\\d{4}_{0,1}\\d{1,}(?:\\.txt|\\.csv){0,}');
            const fm = { fileName } as FloormapToApprove;
            if (regex.test(fileName)) {
                fm.finalFileName = 'Smartfloor - ' + fileName.split('.')[0];
            }
            return fm;
        }));
    }, [selectedFloormaps]);

    const fetchGroups = async (nextToken: string | null) => {
        const response = await API.graphql<GraphQLQuery<ListGroupsQuery>>({
            query: queries.listGroups,
            variables: {
                page: {
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listGroups) {
            const localGroups = response.data.listGroups.items;
            setGroups([...groups, ...localGroups]);
            setNextToken(response.data.listGroups.nextToken);
        }
    }

    useEffect(() => {
        if (nextToken || groups.length === 0) {
            fetchGroups(nextToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken]);

    const steps = [
        {
            title: 'Finalize floor name',
            content: <FloorNamesStep
                approvalBody={approvalBody}
                setApprovalBody={setApprovalBody}
            />,
        },
        {
            title: 'Group assignment',
            content: <GroupAssignmentStep
                approvalBody={approvalBody}
                setApprovalBody={setApprovalBody}
                groups={groups}
            />,
        },
        {
            title: 'Review',
            content: <ReviewStep
                approvalBody={approvalBody}
                groups={groups}
            />,
        },
    ];

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    function okButtonText() {
        if (isApprovalLoading) return <Spin />
        if (currentStep < steps.length - 1) return 'Next';
        if (currentStep === steps.length - 1) return 'Approve';
    }

    function onOk(currentStep: number, approvalBody: FloormapToApprove[]) {
        if (currentStep < steps.length - 1) nextStep();
        if (currentStep === steps.length - 1) approveFloormaps(approvalBody);
    }

    return <div>
        <Steps current={currentStep} items={items} />
        <Card style={{ marginTop: 24 }}>{steps[currentStep].content}</Card>
        <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
            <div>
                {currentStep > 0 && (
                    <Button onClick={() => prevStep()} disabled={isApprovalLoading}>
                        Back
                    </Button>
                )}
            </div>

            <Space>
                <Button onClick={closeModal} disabled={isApprovalLoading}>
                    Cancel
                </Button>
                <Button type="primary" onClick={() => onOk(currentStep, approvalBody)} disabled={isApprovalLoading}>
                    {okButtonText()}
                </Button>
            </Space>
        </div>
    </div>
}

export default FloorsApproval;
