import {CreationStep} from "../CreationStep";
import React from "react";
import {Button, Col, Row, Typography} from "antd";

export const InstructionsDone = ({onStepComplete}: CreationStep) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Done</Typography.Title>
          <Typography.Paragraph>
            You have successfully created a new customer. This customer can now be managed using internal tools.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Button
        type="primary"
        onClick={() => {
          onStepComplete({});
        }}
      >
        Done
      </Button>
    </>
  );
}
