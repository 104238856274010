import {Button, Drawer, Form, Input, InputNumber, App, Space} from "antd";
import React, {useState} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {CreateTenantMutation} from "../../../utils/graphql/mutations";
import * as mutations from "../../../utils/graphql/mutations";


interface TenantCreateProps {
    reload: () => void;
}

export const TenantCreate = ({reload}: TenantCreateProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        setLoading(true);

        const {tenantName, userLimit} = values;

        const response = await API.graphql<GraphQLQuery<CreateTenantMutation>>({
            query: mutations.createTenant,
            variables: {
                name: tenantName,
                userLimit: userLimit,
            }
        }).catch(
            (_) => {
                notification.error({
                    message: 'Tenant creation failed',
                    description: `Tenant ${tenantName} could not be created`,
                });
            }
        );

        if (response?.data?.createTenant) {
            notification.success({
                message: 'Tenant created',
                description: `Tenant ${tenantName} was created successfully`,
            });
            form.resetFields();
            reload();
            onClose();
        } else {
            notification.error({
                message: 'Tenant creation failed',
                description: `Tenant ${tenantName} could not be created`,
            });
        }

        setLoading(false);
    }

    const showDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                Create Tenant
            </Button>
            <Drawer
                title="Create a new tenant"
                width={720}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }

            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='GroupCreation'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'Tenant Name'}
                        name={'tenantName'}
                        tooltip={{ title: 'Name of the Tenant', icon: <InfoCircleOutlined /> }}
                        rules={[
                            {
                                required: true,
                                message: "Please provide a Tenant name!",
                            }
                        ]}

                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'UserLimit'}
                        name={'userLimit'}
                        tooltip={{ title: 'User Limit of the Tenant', icon: <InfoCircleOutlined /> }}

                    >
                        <InputNumber min={1} max={8196} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Create
                        </Button>
                    </Form.Item>
                </Form>

            </Drawer>
        </>
    );
}