import React, {useState} from "react";
import {Button, Drawer, Form, Input, InputNumber, App, Space} from "antd";
import ThingGroupTree from "../../ThingGroupTree";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../../utils/graphql/queries";
import {GetGroupByNameQuery} from "../../../utils/graphql/queries";
import * as mutations from "../../../utils/graphql/mutations";
import {CreateGroupMutation} from "../../../utils/graphql/mutations";
import {ExclamationCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {TenantSelector} from "../../SharedComponents/TenantSelector";

export type GroupCreateProps = {
    reload: () => void;
}

const validateGroupName = async (name: string) => {
    if (name === undefined) {
        return false;
    }
    const response = await API.graphql<GraphQLQuery<GetGroupByNameQuery>>({
        query: queries.getGroupByName,
        variables: {
            name: name,
        }
    });

    if (response.data?.getGroupByName) {
        throw new Error('Group name already exists');
    }

    return true;
}

const GroupCreate = (props: GroupCreateProps) => {
    const {reload} = props;
    const {notification} = App.useApp();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values: any) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<CreateGroupMutation>>({
            query: mutations.createGroup,
            variables: {
                name: values.groupName,
                wearableGroupName: values.thingGroupName,
                tenantId: values.tenant,
                userLimit: values.userLimit === undefined ? null : values.userLimit,
            }
        }).catch(
            (_) => {
                notification.open({
                    message: 'Group creation failed',
                    description:
                        'Group creation failed',
                    icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
                });
            }
        );

        if (response?.data?.createGroup) {
            notification.open({
                message: 'Group created',
                description:
                    'Group has been created successfully',
                icon: <ExclamationCircleOutlined style={{ color: 'green' }} />,
            });
            form.resetFields();
            reload();
            onClose();
        }
        setLoading(false);
    };

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>Create Group</Button>
            <Drawer
                title="Create a new group"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name='GroupCreation'
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        label={'Group Name'}
                        name={'groupName'}
                        tooltip={{ title: 'Name of the Group', icon: <InfoCircleOutlined /> }}
                        rules={[
                            {
                                required: true,
                                message: "Please provide a Group name!",
                            },
                            {
                                validator: (_, value) => validateGroupName(value),
                            }
                        ]}
                        validateTrigger={'onBlur'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'Thing Group Name'}
                        name={'thingGroupName'}
                        tooltip={{ title: 'Set the thing Group used for wearables', icon: <InfoCircleOutlined /> }}
                        rules={[
                            {
                                required: true,
                                message: "Please select a thing Group!",
                            }
                        ]}
                    >
                        <ThingGroupTree />
                    </Form.Item>
                    <Form.Item
                        label={'Tenant'}
                        name={'tenant'}
                        tooltip={{ title: 'Set the tenant for this Group', icon: <InfoCircleOutlined /> }}
                        rules={[
                            {
                                required: true,
                                message: "Please select a tenant!",
                            }
                        ]}
                    >
                        <TenantSelector />
                    </Form.Item>
                    <Form.Item
                        label={'User Limit'}
                        name={'userLimit'}
                        tooltip={{ title: 'Optional: Set the user limit for the Group', icon: <InfoCircleOutlined /> }}
                        >
                    {/*    Number input for Tenant */}
                        <InputNumber min={1} max={8196} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}

export default GroupCreate;
