import {CreationStep} from "../CreationStep";
import {Button, Col, Form, Row, Select, Tag} from "antd";
import React, {useEffect} from "react";
import * as queries from "../../../utils/graphql/queries";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {ListApplicationsQuery} from "../../../utils/graphql/queries";

interface Application {
  id: number;
  name: string;
}

export const ApplicationInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();

  const [applications, setApplications] = React.useState<Application[]>([]);
  const [selectedApplications, setSelectedApplications] = React.useState<number[]>([]);

  const filterApplications = applications.filter((application) => {
    return !selectedApplications.includes(application.id);
  });


  const loadApplications = async () => {
    const tmpApplications: Application[] = [];
    let nextToken = null;

    do {
      const response: any = await API.graphql<GraphQLQuery<ListApplicationsQuery>>({
        query: queries.listApplications,
        variables: {
          page: {
            nextToken: nextToken,
          }
        },
      });

      if (response?.data?.listApplications?.items) {
        response.data.listApplications.items.forEach((item: any) => {
          tmpApplications.push({
            id: item.id,
            name: item.name,
          });
        });
      }

      if (response?.date?.nextToken) {
        nextToken = response.data.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken);

    setApplications(tmpApplications);
  }

  useEffect(() => {
    loadApplications().then();
  }, [instructions]);

  const onFormFinish = (values: any) => {
    instructions.applicationIds = values.applications;
    onStepComplete(instructions);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFormFinish}
            initialValues={{
              applications: instructions.applicationIds,
            }}
          >
            <Form.Item
              label="Applications"
              name="applications"
              rules={[
                {
                  required: true,
                  message: "Please select at least one application",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="select applications"
                value={selectedApplications}
                onChange={setSelectedApplications}
                style={{width: '100%'}}
                options={filterApplications.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                // Show the label for the selected floors instead of the value
                tagRender={(props) => {
                  const app = applications.find((application) => application.id === props.value);
                  const appText = app ? app.name : props.value;
                  return <Tag key={props.value}>{appText}</Tag>
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button
        onClick={onStepBack}
      >
        Back
      </Button>
    </>
  )
}