import {CreationStep} from "../CreationStep";
import {App, Button, Col, Form, Input, Row, Typography} from "antd";
import React from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {GetThingGroupByNameQuery} from "../../../utils/graphql/queries";
import * as queries from "../../../utils/graphql/queries";

export const GroupCreationStep = ({instructions, onStepComplete, onStepBack}: CreationStep) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const {notification} = App.useApp();

  const onFormFinish = (values: any) => {
    instructions.groups = instructions.groups || [];
    const group = instructions.groups[0] || {};
    group.name = values.groupName;
    group.thingGroupName = values.thingGroupName;
    instructions.groups[0] = group;

    onStepComplete(instructions);
  }
  const groupNameToThingGroupName = (groupName: string) => {
    // replace -_ with space
    const adjustedGroupName = groupName.replace(/[-_]/g, ' ');
    let newName: string =  adjustedGroupName.toLowerCase().split(' ').map((word) => {
      if (word[0] === undefined) {
        return '';
      }
      return word[0].toUpperCase() + word.slice(1);
    }).join('');

    return newName;
  }

  const onGroupNameChange = (groupName: string) => {
    const thingGroupName = groupNameToThingGroupName(groupName);
    form.setFieldValue("thingGroupName", thingGroupName);
  }

  const isThingGroupNameAvailable = async (thingGroupName: string) => {
    setLoading(true)

    const response: any = await API.graphql<GraphQLQuery<GetThingGroupByNameQuery>>({
      query: queries.getThingGroupByName,
      variables: {
        name: thingGroupName,
      },
    }).catch((error) => {
      notification.error({
        message: 'Error',
        description: error.errors[0].message,
      });
    });

    setLoading(false)

    return !response.data.getThingGroupByName;
  }

  const isGroupNameAvailable = async (groupName: string) => {
    setLoading(true)

    const response: any = await API.graphql<GraphQLQuery<GetThingGroupByNameQuery>>({
        query: queries.getThingGroupByName,
        variables: {
            name: groupName,
        }
    }).catch((error) => {
        notification.error({
            message: 'Error',
            description: error.errors[0].message,
        });
    });

    setLoading(false)

    return !response.data.getThingGroupByName;
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Create a group</Typography.Title>
          <Typography.Paragraph>
            A group is a collection of devices and users. Whereas a customer can only have a single tenant, they can
            have multiple groups.
            In general each "location" of a customer is a group. But a customer can also have multiple groups for a
            single location. Or if they share many resources,
            they can have a single group for multiple locations.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Generally, a group is named after the location it represents. But it can also be named after the type of
            devices that are in the group.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Each group has a "Thing Group", Thing Groups are used to organize devices in our system. The name of these
            thing groups is not visible to the customer. But should generally be named after the group.
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: 20}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFormFinish}
            initialValues={{
              groupName: instructions.groups ? instructions.groups[0].name : "",
              thingGroupName: instructions.groups ? instructions.groups[0].thingGroupName : "",
            }}
          >
            <Form.Item
              label="Group name"
              name="groupName"
              rules={[
                {
                  required: true,
                  message: "Please enter a group name",
                },
                {
                  validator: (rule, value) => {
                    if (value === undefined || value === null || value === "") {
                      return Promise.resolve();
                    }
                    if (value.length < 3) {
                      return Promise.reject("Group name must be at least 3 characters long");
                    }
                    if (value.length > 50) {
                      return Promise.reject("Group name can be at most 50 characters long");
                    }
                    if (!value.match(/^[a-zA-Z0-9_\- ]+$/)) {
                      return Promise.reject("Group name can only contain letters, numbers, spaces, underscores and dashes");
                    }
                    return Promise.resolve();
                  },
                  validateTrigger: "onChange"
                },
                {
                  validator: async (rule, value) => {
                    if (!await isGroupNameAvailable(value)) {
                      return Promise.reject("Group name is already taken");
                    }
                    return Promise.resolve();
                  },
                }
              ]}
            >
              <Input
                placeholder="Group name"
                onChange={(e) => onGroupNameChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Thing group name"
              name="thingGroupName"
              rules={[
                {
                  required: true,
                  message: "Please enter a thing group name",
                },
                {
                  validator: (rule, value) => {
                    if (value === undefined || value === null || value === "") {
                      return Promise.resolve();
                    }
                    if (value.length < 3) {
                      return Promise.reject("Thing group name must be at least 3 characters long");
                    }
                    if (value.length > 50) {
                      return Promise.reject("Thing group name can be at most 50 characters long");
                    }
                    if (!value.match(/^[a-zA-Z0-9]+$/)) {
                      return Promise.reject("Thing group name can only contain letters, numbers");
                    }
                    return Promise.resolve();
                  },
                  validateTrigger: "onChange"
                },
                {
                  validator: async (rule, value) => {
                    if (!await isThingGroupNameAvailable(value)) {
                      return Promise.reject("Thing group name is already taken");
                    }
                    return Promise.resolve();
                  },
                }
              ]}
            >
              <Input
                placeholder="Thing group name"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button
        onClick={onStepBack}
      >
        Back
      </Button>
    </>
  )
}