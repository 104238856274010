import {App, Button, Col, Popover, Row} from "antd";
import React, {useEffect, useState} from "react";

import Editor from '@monaco-editor/react';

interface ConfigEditorProps {
  config: any | null;
  onSave: (config: any) => void;
  selectedGroup: string | null;
}

const ConfigEditor = ({config, onSave, selectedGroup}: ConfigEditorProps) => {
  const [newConfig, setNewConfig] = useState<any | null>(config);
  const [hasError, setHasError] = useState<boolean>(false);
  const {message} = App.useApp()

  useEffect(() => {
    setNewConfig(config);
  }, [config, selectedGroup]);


  const save = () => {
    onSave(newConfig);
    message.success("Configuration saved").then();
  }

  return (
    <>
      <Row>
        <Col span={24}>
          {newConfig === null &&
              <>
                  <Button
                      type="primary"
                      onClick={() => {
                        setNewConfig({});
                      }}
                  >
                      Create new configuration
                  </Button>
              </>
          }
          {newConfig !== null &&
              <Editor
                  height="40vh"
                  defaultLanguage="json"
                  value={JSON.stringify(newConfig || {}, null, 2)}
                  onChange={(value) => {
                    try {
                      setNewConfig(JSON.parse(value || "{}"));
                      setHasError(false);
                    } catch (e) {
                      setHasError(true);
                    }
                  }}
              />
          }

        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Popover
            content={hasError ? "Invalid JSON" : "Save the current configuration"}
            placement={"bottomLeft"}
          >

            <Button
              type="primary"
              onClick={save}
              style={{
                marginTop: '20px',
              }}
              disabled={hasError}
            >
              Save
            </Button>
          </Popover>
        </Col>
      </Row>
    </>
  );
}

export default ConfigEditor;