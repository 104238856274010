import React from 'react';
import './App.css';
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Amplify, {Auth, I18n} from 'aws-amplify';
import Footnote from '../components/Partial/Footnote';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import APIUtils from '../utils/APIUtils';
import DeviceControlView from "../components/DeviceControlView/DeviceControlView";
import locale_nl from '../locale/nl';
import MonitoringView from "../components/MonitoringView/MonitoringView";
import DeviceConfigView from "../components/DeviceConfigView/DeviceConfigView";
import DeviceManagementView from "../components/DeviceManagement/DeviceManagementView";

import CustomerInsightsView from '../components/CustomerInsights/CustomerInsightsView';
import CustomerCreationView from "../components/CustomerCreation/CustomerCreationView";
import DeviceDiagnosticsView from '../components/DeviceDiagnostics/DeviceDiagnosticsView';
import NewFloorToolView from '../components/FloorToolView/NewFloorToolView';
import LiveViewWrapper from "../components/LiveView/LiveViewWrapper";
import {Layout, theme} from "antd";
import SideMenu from "../components/Partial/Navigation";
import UserWearableLinkExplorer from "../components/UserWearableLinkExplorer/UserWearableLinkExplorer";
import Theme from "../components/Theme";
import {GroupManagement} from "../components/GroupAndTenantManagement/Group/GroupManagement";
import {TenantManagement} from "../components/GroupAndTenantManagement/Tenant/TenantManagement";
import {UserManagementView} from "../components/VitalityUserManagementView/UserManagementView";


I18n.putVocabularies(locale_nl);
I18n.setLanguage('nl');


const {Content, Footer} = Layout;

// AWS resources configuration parameters
const awsconfig = {
    aws_project_region: process.env.REACT_APP_AWS_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_AWS_REGION,
    API: {
        endpoints: [
            {
                name: "LiveAnalysisToolAPI", // API Gateway for LAT
                endpoint: APIUtils.LAT_API_URL,
                "custom_header": async () => {
                    return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
                },
            },
        ]
    },
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_API_URL,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTH_TYPE,
    oauth: {
        domain: process.env.REACT_APP_AWS_COGNITO_USER_POOL_AUTH_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code'
    }
};

Amplify.configure(awsconfig);

const App: React.FC = () => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <Theme>
            <BrowserRouter>
                <Layout style={{minHeight: '100vh'}}>
                    <SideMenu/>
                    <Layout>
                        <Content style={{margin: '16px'}}>
                            <div style={{background: colorBgContainer, padding: 24, minHeight: 380}}>
                                <div style={{padding: 35}}>
                                    <Routes>
                                        <Route path="/" element={<LiveViewWrapper/>}/>
                                        <Route path='/live-view' element={<LiveViewWrapper/>}/>
                                        <Route path="/monitoring" element={<MonitoringView/>}/>
                                        <Route path="/device-diagnostics" element={<DeviceDiagnosticsView/>}/>
                                        <Route path="/control" element={<DeviceControlView/>}/>
                                        <Route path="/config" element={<DeviceConfigView/>}/>
                                        <Route path='/vitality-user-management' element={<UserManagementView/>}/>
                                        <Route path='/group-management' element={<GroupManagement/>}/>
                                        <Route path='/tenant-management' element={<TenantManagement/>}/>
                                        <Route path='/floor-tool' element={<NewFloorToolView/>}/>
                                        <Route path='/device-management' element={<DeviceManagementView/>}/>
                                        <Route path='/customer-insights' element={<CustomerInsightsView/>}/>
                                        <Route path='/customer-creation' element={<CustomerCreationView/>}/>
                                        <Route path='/user-wearable-links' element={<UserWearableLinkExplorer/>}/>
                                    </Routes>
                                </div>
                            </div>
                        </Content>
                        <Footer>
                            <Footnote/>
                        </Footer>
                    </Layout>
                </Layout>
            </BrowserRouter>
        </Theme>
    );
};


export default withAuthenticator(App, {hideSignUp: true});
