/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import DatePicker from '../DatePicker';
import { Period } from './CustomerInsightsView';
import { AGGREGATE_STATS_FIELD_NAME, TOTAL_STATS_FIELD_NAME, YEAR_TO_DATE_STATS_FIELD_NAME, determineLatestCompletedQuarter, getNameOfPreset } from './reportUtils';

const { RangePicker } = DatePicker;

interface RangePickerGroupProps {
    periods: Period[],
    setPeriods: React.Dispatch<React.SetStateAction<Period[]>>,
    overlapExists: boolean,
    setOverlapExists: React.Dispatch<React.SetStateAction<boolean>>,
    periodNamesAreDuplicated: boolean,
    setPeriodNamesAreDuplicated: React.Dispatch<React.SetStateAction<boolean>>,
    periodNamesAreForbidden: boolean,
    setPeriodNamesAreForbidden: React.Dispatch<React.SetStateAction<boolean>>,
}

const RangePickerGroup = ({
    periods,
    setPeriods,
    overlapExists,
    setOverlapExists,
    periodNamesAreDuplicated,
    setPeriodNamesAreDuplicated,
    periodNamesAreForbidden,
    setPeriodNamesAreForbidden,
}: RangePickerGroupProps) => {

    /**
     * Get calendar presets. Lists only the last 5 completed quarters.
     */
    function getPresets(): any[] {
        const presets = [];

        const [startOfLastCompletedQuarter, endOfLastCompletedQuarter] = determineLatestCompletedQuarter();
        presets.push({
            label: getNameOfPreset(moment(startOfLastCompletedQuarter)),
            value: [
                moment(startOfLastCompletedQuarter),
                moment(endOfLastCompletedQuarter)
            ]
        })
        for (let index = 0; index < 4; index++) {
            const quarterStart = moment(startOfLastCompletedQuarter.subtract(3, 'months'));
            const quarterEnd = moment(endOfLastCompletedQuarter.subtract(3, 'months').endOf('month'))
            presets.push({
                label: getNameOfPreset(quarterStart),
                value: [quarterStart, quarterEnd]
            })
        }

        return presets;
    }

    const updateSelectedPeriodTimestamps = (periodIndex: any, updatedDates: Moment[]) => {
        setPeriods(prev => ([
            ...prev.slice(0, periodIndex),
            {
                ...prev[periodIndex],
                dates: [
                    updatedDates[0].startOf('day'),
                    updatedDates[1].endOf('day')
                ]
            },
            ...prev.slice(periodIndex + 1)
        ]));
    }

    function updatePeriodName(periodIndex: number, updatedName: string) {
        setPeriods(prev => ([
            ...prev.slice(0, periodIndex),
            { ...prev[periodIndex], name: updatedName },
            ...prev.slice(periodIndex + 1)
        ]));
    }

    /**
     * Adds a new period to the list of periods. The new period has the same length as the latest one in the list and ends the day before
     * the latest one starts.
     */
    function addEmptyPeriod() {
        const lastPeriod = periods[periods.length - 1];
        let newPeriodDates: any;
        if (lastPeriod && lastPeriod.dates && lastPeriod.dates.length === 2) {
            // The difference between the last period's start and end date is:
            const atLeastAYear = lastPeriod.dates[1].diff(moment(lastPeriod.dates[0]).subtract(1, 'millisecond'), 'year');
            const atLeastAMonth = lastPeriod.dates[1].diff(moment(lastPeriod.dates[0]).subtract(1, 'millisecond'), 'month');
            const atLeastADay = lastPeriod.dates[1].diff(moment(lastPeriod.dates[0]).subtract(1, 'millisecond'), 'day');

            if (atLeastAYear) {
                newPeriodDates = [
                    moment(lastPeriod.dates[0]).subtract(atLeastAYear, 'year').startOf('year'),
                    moment(lastPeriod.dates[1]).subtract(atLeastAYear, 'year').endOf('year')
                ];
            } else if (atLeastAMonth) {
                newPeriodDates = [
                    moment(lastPeriod.dates[0]).subtract(atLeastAMonth, 'month').startOf('month'),
                    moment(lastPeriod.dates[1]).subtract(atLeastAMonth, 'month').endOf('month')
                ];
            } else if (atLeastADay) {
                newPeriodDates = [
                    moment(lastPeriod.dates[0]).subtract(atLeastADay, 'day').startOf('day'),
                    moment(lastPeriod.dates[1]).subtract(atLeastADay, 'day').endOf('day')
                ];
            }
        }
        const period: Period = {
            dates: newPeriodDates,
            name: ''
        }
        setPeriods(prev => [...prev, period]);
    }

    function removePeriod(periodIndex: number) {
        setPeriods(prev => ([...prev.slice(0, periodIndex), ...prev.slice(periodIndex + 1)]));
    }

    useEffect(function checkForOverlappingDatesAndNames() {
        const sortedByBeginTime = periods
            .filter(p => p.dates?.length === 2)
            .sort((a, b) => a.dates[0].valueOf() - b.dates[0].valueOf());

        if (sortedByBeginTime.length < 2) return;

        for (let index = 0; index < sortedByBeginTime.length - 1; index++) {
            if (sortedByBeginTime[index].dates[1] > sortedByBeginTime[index + 1].dates[0]) {
                setOverlapExists(true);
                break;
            } else {
                setOverlapExists(false);
            }
        }

        /**
         * Period names should be unique.
         */
        const containsDuplicateNames = (array: string[]) => {
            return (new Set(array)).size !== array.length;
        }
        /**
         * Period names should not be 'Total', 'Year' or 'Combined', since this could mess up data
         * grouping or calculations. In general, it is ambiguous.
         */
        const containsForbiddenNames = (array: string[]) => {
            return array.find(periodName => periodName === YEAR_TO_DATE_STATS_FIELD_NAME ||
                periodName === TOTAL_STATS_FIELD_NAME ||
                periodName === AGGREGATE_STATS_FIELD_NAME) !== undefined;
        }
        const periodNames = periods
            // First filter out the empty period names, since they will be substituted for a short alias anyway
            .filter(p => p.name !== '')
            .map(p => p.name);
        setPeriodNamesAreDuplicated(containsDuplicateNames(periodNames));
        setPeriodNamesAreForbidden(containsForbiddenNames(periodNames));
    }, [periods]);

    const periodNameTooltip = 'If provided, the name of the period will be displayed in graphs and tables. If no name is provided, the begin and end date of the period will be displayed instead.'

    return (
        <div style={{ margin: 5, marginBottom: 25 }}>
            <Row style={{ marginBottom: 8 }} >
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                    Start & end
                </Col>
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                    Display name (Optional) <Tooltip title={periodNameTooltip}><QuestionCircleOutlined /></Tooltip>
                </Col>
            </Row>
            {periods.map((period, key) => (
                <Row key={key} style={{ display: 'flex', marginBottom: 8 }} >
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                        <RangePicker
                            key={key}
                            style={{ marginRight: '20px' }}
                            allowClear={false}
                            format="DD-MM-YYYY"
                            onChange={(e: any) => updateSelectedPeriodTimestamps(key, e)}
                            defaultValue={
                                period.dates && period.dates[0] && period.dates[1] ?
                                    [period.dates[0], period.dates[1]]
                                    : null
                            }
                            value={
                                period.dates && period.dates[0] && period.dates[1] ?
                                    [period.dates[0], period.dates[1]]
                                    : null
                            }
                            presets={getPresets()}
                            picker='date'
                            status={period.dates === null ? 'error' : ''}
                        />
                    </Col>
                    <Col style={{ display: 'flex' }} xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                        <Input
                            placeholder="e.g., `Q2 2023`"
                            value={period.name}
                            onChange={e => updatePeriodName(key, e.target.value)}
                        />

                        {key > 0 && <MinusCircleOutlined onClick={() => removePeriod(key)} style={{ marginLeft: 5 }} />}
                    </Col>
                </Row>
            ))}

            {overlapExists &&
                <p style={{ color: 'red' }}>
                    Periods must not overlap!
                </p>
            }

            {periodNamesAreDuplicated &&
                <p style={{ color: 'red' }}>
                    Period names must be unique!
                </p>
            }

            {periodNamesAreForbidden &&
                <p style={{ color: 'red' }}>
                    Period names "{YEAR_TO_DATE_STATS_FIELD_NAME}", "{TOTAL_STATS_FIELD_NAME}" or "{AGGREGATE_STATS_FIELD_NAME}" must not be used!
                </p>
            }

            {periods.length < 4 &&
                <Button
                    type="dashed" block
                    onClick={() => addEmptyPeriod()}
                    style={{ marginTop: 20 }}
                    icon={<PlusOutlined />}
                >
                    Add another period
                </Button>
            }
        </div>
    )
}

export default RangePickerGroup;