import {CreationStep} from "../CreationStep";
import {Button, Col, Row} from "antd";
import React from "react";
import * as mutations from "../../../utils/graphql/mutations";
import {
  AddThingToThingGroupMutation,
  CreateCognitoUserMutation,
  CreateGroupMutation,
  CreateTenantMutation,
  CreateThingGroupMutation,
  SetApplicationsForUserMutation,
  SetFloorsForGroupMutation,
  SetFloorsForUserMutation,
  SetGroupsForUserMutation
} from "../../../utils/graphql/mutations";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";


const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

interface ExecutionInstructionsProps extends CreationStep {
  onReset: () => void;
}


export const ExecutionInstructions = ({instructions, onStepBack, onStepComplete, onReset}: ExecutionInstructionsProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [displayMessages, setDisplayMessages] = React.useState<string[]>([]);

  const logMessage = (message: string) => {
    setDisplayMessages((prevMessages) => [...prevMessages, message]);
  }

  const createTenant = async (name: string, userLimit: number | null) => {
    const response = await API.graphql<GraphQLQuery<CreateTenantMutation>>({
      query: mutations.createTenant,
      variables: {
        name: name,
        userLimit: userLimit,
      }
    });

    return response.data?.createTenant?.id;
  }

  const createGroup = async (name: string, tenantId: number, wearableGroupName: string, userLimit: number | null) => {
    const response = await API.graphql<GraphQLQuery<CreateGroupMutation>>({
      query: mutations.createGroup,
      variables: {
        name: name,
        tenantId: tenantId,
        wearableGroupName: wearableGroupName,
        userLimit: userLimit,
      }
    })

    return response.data?.createGroup?.id;
  }

  const createThingGroup = async (name: string, parentGroupName: string | null) => {
    const response = await API.graphql<GraphQLQuery<CreateThingGroupMutation>>({
      query: mutations.createThingGroup,
      variables: {
        name: name,
        parentGroupName: parentGroupName,
      }
    });

    return response.data?.createThingGroup?.name;
  }

  const addThingToThingGroup = async (thingName: string, thingGroupName: string) => {
    const response = await API.graphql<GraphQLQuery<AddThingToThingGroupMutation>>({
      query: mutations.addThingToThingGroup,
      variables: {
        thingName: thingName,
        thingGroupName: thingGroupName,
        removeFromOtherGroups: true,
      }
    });

    return !!response.data?.addThingToThingGroup;
  }

  const setApplicationsForUser = async (cognitoUserId: string, applicationIds: number[]) => {
    const response = await API.graphql<GraphQLQuery<SetApplicationsForUserMutation>>({
      query: mutations.setApplicationsForUser,
      variables: {
        userId: cognitoUserId,
        applicationIds: applicationIds,
      }
    });

    return !!response.data?.setApplicationsForUser;
  }

  const setFloorsForUser = async (cognitoUserId: string, floorIds: number[]) => {
    const response = await API.graphql<GraphQLQuery<SetFloorsForUserMutation>>({
      query: mutations.setFloorsForUser,
      variables: {
        userId: cognitoUserId,
        floorIds: floorIds,
        canViewMetadata: false,
      }
    });

    return !!response.data?.setFloorsForUser;
  }

  const setFloorsForGroup = async (groupId: number, floorIds: number[]) => {
    const response = await API.graphql<GraphQLQuery<SetFloorsForGroupMutation>>({
      query: mutations.setFloorsForGroup,
      variables: {
        groupId: groupId,
        floorIds: floorIds,
      }
    });

    return !!response.data?.setFloorsForGroup;
  }

  const setGroupsForUser = async (cognitoUserId: string, groupIds: number[]) => {
    const response = await API.graphql<GraphQLQuery<SetGroupsForUserMutation>>({
      query: mutations.setGroupsForUser,
      variables: {
        userId: cognitoUserId,
        groupIds: groupIds,
      }
    });

    return !!response.data?.setGroupsForUser;
  }

  const createUser = async (
    email: string,
    password: string,
    autoVerify: boolean,
    tenantId: number,
    requirePasswordChange: boolean
  ) => {
    const response = await API.graphql<GraphQLQuery<CreateCognitoUserMutation>>({
      query: mutations.createCognitoUser,
      variables: {
        email: email,
        password: password,
        autoVerifyEmail: autoVerify,
        tenantId: tenantId,
        poolName: 'vitality',
        requirePasswordChange: requirePasswordChange,

      }
    }).catch((_) => {
      throw new Error("User creation failed");
    });

    if (!response.data?.createCognitoUser) {
      throw new Error("User creation failed");
    }

    return response.data.createCognitoUser
  }

  const executeInstructions = async () => {
    setLoading(true);
    // validate instructions
    // create tenant
    if (!instructions.tenant) {
      throw new Error('Missing tenant instructions');
    }

    if (instructions.tenant.userLimit === undefined) {
      throw new Error('Missing tenant user limit');
    }

    if (!instructions.groups) {
      throw new Error('Missing groups');
    }

    logMessage('Creating tenant');
    const tenantId = await createTenant(instructions.tenant.name, instructions.tenant.userLimit).catch((_) => {
      throw new Error('Failed to create tenant');
    });

    if (!tenantId) {
      throw new Error('Failed to create tenant');
    }

    logMessage('Creating root thing group');
    // create thing group
    const rootThingGroupName = await createThingGroup(instructions.tenant.thingGroupName, null).catch(
      (_) => {
        throw new Error('Failed to create root thing group');
      }
    );
    if (!rootThingGroupName) {
      throw new Error('Failed to create root thing group');
    }

    logMessage('Creating groups');
    // groups and devices
    const groups = instructions.groups;
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (!group.name) {
        throw new Error('Missing group name');
      }

      if (!group.thingGroupName) {
        throw new Error('Missing group thing group name');
      }
      logMessage(`Creating group ${group.name}`);

      if (group.userLimit === undefined) {
        throw new Error('Missing group user limit');
      }

      logMessage(`Creating thing group ${group.thingGroupName}`);
      const thingGroupName = await createThingGroup(group.thingGroupName, rootThingGroupName).catch(
        (_) => {
          throw new Error('Failed to create thing group');
        });

      if (!thingGroupName) {
        throw new Error('Failed to create thing group');
      }

      logMessage(`Creating group ${group.name}`);
      const groupId = await createGroup(group.name, tenantId, thingGroupName, group.userLimit).catch(
        (_) => {
          throw new Error('Failed to create group');
        }
      );
      logMessage(`Created group ${group.name} with id ${groupId}`);

      if (!groupId) {
        throw new Error('Failed to create group');
      }

      if (!group.sensors) {
        throw new Error('Missing sensors');
      }

      await sleep(500);

      const sensors = group.sensors;
      for (let j = 0; j < sensors.length; j++) {
        const sensor = sensors[j];
        logMessage(`Adding sensor ${sensor} to group ${group.name}`);
        await addThingToThingGroup(sensor, thingGroupName).catch((_) => {
          throw new Error('Failed to add sensor to group');
        });
        await sleep(500);
      }

      if (group.floorsIds) {
        await sleep(500);
        logMessage(`Setting floors for group ${group.name}`)
        await setFloorsForGroup(groupId, group.floorsIds).catch(
            (_) => {
              throw new Error('Failed to set floors for user');
            }
        )
      } else {
        logMessage(`No floors for group ${group.name}`)
      }

      if (group.mobileRouters) {
        const mobileRouters = group.mobileRouters;
        for (let j = 0; j < mobileRouters.length; j++) {
          const mobileRouter = mobileRouters[j];
          logMessage(`Adding mobile router ${mobileRouter} to group ${group.name}`);
          await addThingToThingGroup(mobileRouter, thingGroupName).catch((_) => {
            throw new Error('Failed to add mobile router to group');
          });
          await sleep(500);
        }
      }

      if (group.users === undefined) {
        throw new Error('Missing users');
      }
      const cognitoUsers: string[] = [];
      const users = group.users;
      for (let j = 0; j < users.length; j++) {
        const user = users[j];
        logMessage(`Creating user ${user.email} for group ${group.name}`);
        const createdUser = await createUser(
          user.email,
          user.password,
          user.autoVerifyEmail,
          tenantId,
          user.isTemporaryPassword
        ).catch((_) => {
          throw new Error('Failed to create user');
        });

        cognitoUsers.push(createdUser);
      }

      if (!instructions.applicationIds) {
        throw new Error('Missing application ids');
      }

      for (let j = 0; j < cognitoUsers.length; j++) {
        const cognitoUser = cognitoUsers[j];
        await sleep(500);
        logMessage(`Setting applications for user ${cognitoUser}`);
        await setApplicationsForUser(cognitoUser, instructions.applicationIds).catch(
          (_) => {
            throw new Error('Failed to set applications for user');
          }
        )
        await sleep(500);
        logMessage(`Setting groups for user ${cognitoUser}`)
        await setGroupsForUser(cognitoUser, [groupId]).catch(
          (_) => {
            throw new Error('Failed to set groups for user');
          }
        )
        await sleep(500);
      }
    }
    logMessage('Done');
    setLoading(false);
    onSuccess();
  }

  const onSuccess = () => {
    onStepComplete(instructions);
  }

  const runInstructions = async () => {
    logMessage('Running instructions')
    try {
      await executeInstructions();
    } catch (e) {
      logMessage((e as Error).message);
      setLoading(false);
      setFailed(true);
    }
  }

  return (
    <>
      <Row>
        <Col span={24}>
          {!failed && (
            <>
              <Button
                onClick={runInstructions}
                loading={loading}
                type="primary"
              >
                Execute
              </Button>
              <Button
                onClick={onStepBack}
              >
                Back
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/*  Console like thing for display messages*/}
          <div style={{height: '100%', overflow: 'auto'}}>
            {displayMessages.map((message, index) => (
              <>
                <div key={index}>
                  {message}
                </div>
              </>
            ))}
          </div>
        </Col>
      </Row>
      {failed && (
        <>
          <Button
            onClick={onReset}
          >
            Restart
          </Button>
        </>
      )}
    </>
  )
}