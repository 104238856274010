import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";


interface ConfirmProps {
  title: string;
  content: string;
  action: () => void;
}

const Confirm = ({title, content, action}: ConfirmProps) => {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined/>,
    content: content,
    okText: 'Yes',
    cancelText: 'Cancel',
    onOk() {
      console.log("Confirmed: " + title + " " + content);
      action();
    },
    onCancel() {
      console.log("Cancelled: " + title + " " + content);
    },
  });
}

export default Confirm;