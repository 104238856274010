import { Floormap, FloorStatus } from "./queries";

export const createGroup = /* GraphQL */ `
    mutation CreateGroup($name: String!, $tenantId: Int!, $wearableGroupName: String!, $userLimit: Int) {
        createGroup(name: $name, tenantId: $tenantId, wearableGroupName: $wearableGroupName userLimit: $userLimit) {
            id
        }
    }
`;

export const updateGroupTenant = /* GraphQL */ `
    mutation UpdateGroupTenant($groupId: Int!, $tenantId: Int!) {
        updateGroupTenant(groupId: $groupId, tenantId: $tenantId) {
            id
            name
            userLimit
            tenant {
                id
                name
            }
        }
    }
`;

export const createTenant = /* GraphQL */ `
    mutation CreateTenant($name: String!, $userLimit: Int) {
        createTenant(name: $name, userLimit: $userLimit) {
            id
            name
            userLimit
        }
    }
`;

export const updateTenantName = /* GraphQL */ `
    mutation UpdateTenantName($tenantId: Int!, $name: String!) {
        updateTenantName(tenantId: $tenantId, name: $name) {
            id
            name
            userLimit
            groups {
                id
                name
                userLimit
            }
            applications {
                id
                name
                priority
            }
        }
    }
`;
export const updateTenantUserLimit = /* GraphQL */ `
    mutation UpdateTenantUserLimit($tenantId: Int!, $userLimit: Int) {
        updateTenantUserLimit(tenantId: $tenantId, userLimit: $userLimit) {
            id
            name
            userLimit
            groups {
                id
                name
                userLimit
            }
            applications {
                id
                name
                priority
            }
        }
    }
`;

export const setApplicationsForTenant = /* GraphQL */ `
    mutation SetApplicationsForTenant($tenantId: Int!, $applicationIds: [Int!]!) {
        setApplicationsForTenant(tenantId: $tenantId, applicationIds: $applicationIds) {
            id
            name
            userLimit
            groups {
                id
                name
                userLimit
            }
            applications {
                id
                name
                priority
            }
        }
    }
`;

export const createCognitoUser = /* GraphQL */ `
    mutation CreateCognitoUser(
        $username: String
        $email: AWSEmail!
        $password: String!
        $autoVerifyEmail: Boolean!
        $tenantId: Int!
        $poolName: String
        $requirePasswordChange: Boolean
    ) {
        createCognitoUser(
            username: $username
            email: $email
            password: $password
            autoVerifyEmail: $autoVerifyEmail
            tenantId: $tenantId
            poolName: $poolName
            requirePasswordChange: $requirePasswordChange
        )
    }
`;

export const createInvite = /* GraphQL */ `
    mutation CreateInvite($invite: InviteInput!) {
        createInvite(invite: $invite) {
            token
            valid_until
            created_at
            created_by
            claimed_by
            claimed_at
            invite_type
            cognito_groups
            email
            db_user_id
            tenant_id
            group_ids
        }
    }
`;

export const createMobileRouter = /* GraphQL */ `
    mutation CreateMobileRouter(
        $id: String!
        $configuredNetworks: [AWSJSON!]!
        $simEid: String!
    ) {
        createMobileRouter(
            id: $id
            configuredNetworks: $configuredNetworks
            simEid: $simEid
        ) {
            id
            configuredNetworks
            simEid
        }
    }
`;
export const updateMobileRouterNetworkConfig = /* GraphQL */ `
    mutation UpdateMobileRouterNetworkConfig(
        $id: String!
        $configuredNetworks: [AWSJSON!]
    ) {
        updateMobileRouterNetworkConfig(
            id: $id
            configuredNetworks: $configuredNetworks
        ) {
            id
            configuredNetworks
            simEid
        }
    }
`;
export const deleteMobileRouter = /* GraphQL */ `
    mutation DeleteMobileRouter($id: String!) {
        deleteMobileRouter(id: $id)
    }
`;

export const createThingGroup = /* GraphQL */ `
    mutation CreateThingGroup($name: String!, $parentGroupName: String) {
        createThingGroup(name: $name, parentGroupName: $parentGroupName) {
            name
            parentGroupName
        }
    }
`;
export const addThingToThingGroup = /* GraphQL */ `
    mutation AddThingToThingGroup(
        $thingGroupName: String!
        $thingName: String!
        $removeFromOtherGroups: Boolean
    ) {
        addThingToThingGroup(
            thingGroupName: $thingGroupName
            thingName: $thingName
            removeFromOtherGroups: $removeFromOtherGroups
        ) {
            name
            parentGroupName
        }
    }
`;

export const setApplicationsForUser = /* GraphQL */ `
    mutation SetApplicationsForUser($userId: String!, $applicationIds: [Int!]!) {
        setApplicationsForUser(userId: $userId, applicationIds: $applicationIds)
    }
`;

export const setFloorsForUser = /* GraphQL */ `
    mutation SetFloorsForUser(
        $userId: String!
        $floorIds: [Int!]!
        $canViewMetadata: Boolean!
    ) {
        setFloorsForUser(
            userId: $userId
            floorIds: $floorIds
            canViewMetadata: $canViewMetadata
        )
    }
`;

export const setGroupsForUser = /* GraphQL */ `
    mutation SetGroupsForUser($userId: String!, $groupIds: [Int!]!) {
        setGroupsForUser(userId: $userId, groupIds: $groupIds)
    }
`;


export const updateGroupUserLimit = /* GraphQL */ `
    mutation UpdateGroupUserLimit($groupId: Int!, $userLimit: Int) {
        updateGroupUserLimit(groupId: $groupId, userLimit: $userLimit) {
            id
            name
            userLimit
            tenant {
                id
                name
                userLimit
            }
            wearableGroup {
                id
                name
            }
            floors {
                id
                name
                maxX
                maxY
            }
        }
    }
`;
export const updateGroupName = /* GraphQL */ `
    mutation UpdateGroupName($groupId: Int!, $name: String!) {
        updateGroupName(groupId: $groupId, name: $name) {
            id
            name
            userLimit
            tenant {
                id
                name
                userLimit
            }
            wearableGroup {
                id
                name
            }
            floors {
                id
                name
                maxX
                maxY
            }
        }
    }
`;

export const setFloorsForGroup = /* GraphQL */ `
    mutation SetFloorsForGroup($groupId: Int!, $floorIds: [Int!]!) {
        setFloorsForGroup(groupId: $groupId, floorIds: $floorIds) {
            id
            name
            userLimit
            tenant {
                id
                name
                userLimit
            }
            wearableGroup {
                id
                name
            }
            floors {
                id
                name
                maxX
                maxY
            }
        }
    }
`;

export type SetFloorsForGroupMutationVariables = {
    groupId: number,
    floorIds: Array< number >,
};

export type SetFloorsForGroupMutation = {
    setFloorsForGroup:  {
        __typename: "Group",
        id: number,
        name: string,
        userLimit?: number | null,
        tenant:  {
            __typename: "Tenant",
            // A tenant is the highest level of organization in the system.
            id: number,
            name: string,
            userLimit?: number | null,
        },
        wearableGroup?:  {
            __typename: "WearableGroup",
            id: number,
            name: string,
        } | null,
        floors:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
    },
};

export type UpdateGroupUserLimitMutationVariables = {
    groupId: number,
    userLimit: number,
};

export type UpdateGroupUserLimitMutation = {
    updateGroupUserLimit:  {
        __typename: "Group",
        id: number,
        name: string,
        userLimit?: number | null,
        tenant:  {
            __typename: "Tenant",
            // A tenant is the highest level of organization in the system.
            id: number,
            name: string,
            userLimit?: number | null,
        },
        wearableGroup?:  {
            __typename: "WearableGroup",
            id: number,
            name: string,
        } | null,
        floors:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
    },
};

export type UpdateGroupNameMutationVariables = {
    groupId: number,
    name: string,
};

export type UpdateGroupNameMutation = {
    updateGroupName:  {
        __typename: "Group",
        id: number,
        name: string,
        userLimit?: number | null,
        tenant:  {
            __typename: "Tenant",
            // A tenant is the highest level of organization in the system.
            id: number,
            name: string,
            userLimit?: number | null,
        },
        wearableGroup?:  {
            __typename: "WearableGroup",
            id: number,
            name: string,
        } | null,
        floors:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
    },
};


export type InviteInput = {
    valid_until: string,
    invite_type: string,
    cognito_groups: Array< string >,
    email?: string | null,
    token?: string | null,
    db_user_id?: number | null,
    tenant_id?: number | null,
    group_ids?: Array< number | null > | null,
};

export type CreateInviteMutationVariables = {
    invite: InviteInput,
};

export type CreateInviteMutation = {
    createInvite?:  {
        __typename: "Invite",
        token: string,
        valid_until: string,
        created_at: string,
        created_by?: string | null,
        claimed_by: string,
        claimed_at?: string | null,
        invite_type: string,
        cognito_groups: Array< string >,
        email?: string | null,
        db_user_id?: number | null,
        tenant_id?: number | null,
        group_ids?: Array< number | null > | null,
    } | null,
};


export type CreateCognitoUserMutationVariables = {
    username?: string | null,
    email: string,
    password: string,
    autoVerifyEmail: boolean,
    tenantId: number,
    poolName?: string | null,
    requirePasswordChange?: boolean | null,
};

export type CreateCognitoUserMutation = {
    createCognitoUser: string,
};


export type UpdateTenantNameMutationVariables = {
    tenantId: number,
    name: string,
};

export type UpdateTenantNameMutation = {
    updateTenantName?:  {
        __typename: "Tenant",
        // A tenant is the highest level of organization in the system.
        id: number,
        name: string,
        userLimit?: number | null,
        groups:  Array< {
            __typename: "Group",
            id: number,
            name: string,
            userLimit?: number | null,
        } >,
        applications:  Array< {
            __typename: "Application",
            id: number,
            name: string,
            priority: number,
        } >,
    } | null,
};

export type UpdateTenantUserLimitMutationVariables = {
    tenantId: number,
    userLimit?: number | null,
};

export type UpdateTenantUserLimitMutation = {
    updateTenantUserLimit?:  {
        __typename: "Tenant",
        // A tenant is the highest level of organization in the system.
        id: number,
        name: string,
        userLimit?: number | null,
        groups:  Array< {
            __typename: "Group",
            id: number,
            name: string,
            userLimit?: number | null,
        } >,
        applications:  Array< {
            __typename: "Application",
            id: number,
            name: string,
            priority: number,
        } >,
    } | null,
};

export type SetApplicationsForTenantMutationVariables = {
    tenantId: number,
    applicationIds: Array< number >,
};

export type SetApplicationsForTenantMutation = {
    setApplicationsForTenant?:  {
        __typename: "Tenant",
        // A tenant is the highest level of organization in the system.
        id: number,
        name: string,
        userLimit?: number | null,
        groups:  Array< {
            __typename: "Group",
            id: number,
            name: string,
            userLimit?: number | null,
        } >,
        applications:  Array< {
            __typename: "Application",
            id: number,
            name: string,
            priority: number,
        } >,
    } | null,
};


export type CreateTenantMutationVariables = {
    name: string,
    userLimit: number | null,
}

export type CreateTenantMutation = {
    createTenant?:  {
        id: number,
        name: string,
        userLimit: number | null,
    }
}


export type UpdateGroupTenantMutationVariables = {
    groupId: number,
    tenantId: number,
}

export type UpdateGroupTenantMutation = {
    updateGroupTenant?:  {
        id: number,
        name: string,
        userLimit: number | null,
        tenant:  {
            id: number,
            name: string,
        },
    } | null,
};

export type CreateGroupMutationVariables = {
    name: string,
    tenantId: number,
    wearableGroupName: string | null,
    userLimit: number | null,
}

export type CreateGroupMutation = {
    createGroup?: {
        id: number,
    }
}

export type CreateMobileRouterMutationVariables = {
    id: string,
    configuredNetworks: Array< string >,
    simEid: string,
};

export type CreateMobileRouterMutation = {
    createMobileRouter:  {
        __typename: "MobileRouter",
        id: string,
        configuredNetworks?: Array< string > | null,
        simEid?: string | null,
    },
};

export type UpdateMobileRouterNetworkConfigMutationVariables = {
    id: string,
    configuredNetworks?: Array< string > | null,
};

export type UpdateMobileRouterNetworkConfigMutation = {
    updateMobileRouterNetworkConfig:  {
        __typename: "MobileRouter",
        id: string,
        configuredNetworks?: Array< string > | null,
        simEid?: string | null,
    },
};

export type DeleteMobileRouterMutationVariables = {
    id: string,
};

export type DeleteMobileRouterMutation = {
    deleteMobileRouter: boolean,
};

export type CreateThingGroupMutationVariables = {
    name: string,
    parentGroupName?: string | null,
};

export type CreateThingGroupMutation = {
    createThingGroup:  {
        __typename: "ThingGroup",
        name: string,
        parentGroupName?: string | null,
    },
};

export type AddThingToThingGroupMutationVariables = {
    thingGroupName: string,
    thingName: string,
    removeFromOtherGroups?: boolean | null,
};

export type AddThingToThingGroupMutation = {
    addThingToThingGroup:  {
        __typename: "ThingGroup",
        name: string,
        parentGroupName?: string | null,
    },
};

export type SetFloorsForUserMutationVariables = {
    userId: string,
    floorIds: Array< number >,
    canViewMetadata: boolean,
};

export type SetFloorsForUserMutation = {
    setFloorsForUser: boolean,
};

export type SetApplicationsForUserMutationVariables = {
    userId: string,
    applicationIds: Array< number >,
};

export type SetApplicationsForUserMutation = {
    setApplicationsForUser: boolean,
};

export type SetGroupsForUserMutationVariables = {
    userId: string,
    groupIds: Array< number >,
};

export type SetGroupsForUserMutation = {
    setGroupsForUser: boolean,
};

export const updateUserTenant = /* GraphQL */ `
    mutation UpdateUserTenant($id: ID!, $tenantId: Int!) {
        updateUserTenant(id: $id, tenantId: $tenantId)
    }
`;

export type UpdateUserTenantMutationVariables = {
    id: string,
    tenantId: number,
};

export type UpdateUserTenantMutation = {
    updateUserTenant: string,
};


export type UpdateUserEmailMutationVariables = {
    id: string,
    email: string,
    isVerified?: boolean | null,
    poolName?: string | null,
};

export type UpdateUserEmailMutation = {
    updateUserEmail: string,
};

export type UpdateUserPasswordMutationVariables = {
    id: string,
    password: string,
    isPermanent: boolean,
    poolName?: string | null,
};

export type UpdateUserPasswordMutation = {
    updateUserPassword: string,
};

export const updateUserEmail = /* GraphQL */ `
    mutation UpdateUserEmail($id: ID!, $email: AWSEmail!, $isVerified: Boolean!, $poolName: String) {
        updateUserEmail(id: $id, email: $email, isVerified: $isVerified, poolName: $poolName)
    }
`;
export const updateUserPassword = /* GraphQL */ `
    mutation UpdateUserPassword(
        $id: ID!
        $password: String!
        $isPermanent: Boolean!
        $poolName: String
    ) {
        updateUserPassword(
            id: $id
            password: $password
            isPermanent: $isPermanent
            poolName: $poolName
        )
    }
`;

export const setHeelableTelemetryLevel = /* GraphQL */ `
    mutation SetHeelableTelemetryLevel(
        $heelableId: String!
        $telemetryLevel: Int
    ) {
        setHeelableTelemetryLevel(
            heelableId: $heelableId
            telemetryLevel: $telemetryLevel
        )
    }
`;
export const setHeelableDebugLevel = /* GraphQL */ `
    mutation SetHeelableDebugLevel($heelableId: String!, $debugLevel: Int) {
        setHeelableDebugLevel(heelableId: $heelableId, debugLevel: $debugLevel)
    }
`;

export type SetHeelableTelemetryLevelMutationVariables = {
    heelableId: string,
    telemetryLevel?: number | null,
};

export type SetHeelableTelemetryLevelMutation = {
    setHeelableTelemetryLevel?: number | null,
};

export type SetHeelableDebugLevelMutationVariables = {
    heelableId: string,
    debugLevel?: number | null,
};

export type SetHeelableDebugLevelMutation = {
    setHeelableDebugLevel?: number | null,
};

export const removeThingFromThingGroup = /* GraphQL */ `
    mutation RemoveThingFromThingGroup(
        $thingGroupName: String!
        $thingName: String!
    ) {
        removeThingFromThingGroup(
            thingGroupName: $thingGroupName
            thingName: $thingName
        ) {
            name
            parentGroupName
        }
    }
`;

export type RemoveThingFromThingGroupMutationVariables = {
    thingGroupName: string,
    thingName: string,
};

export type RemoveThingFromThingGroupMutation = {
    removeThingFromThingGroup:  {
        __typename: "ThingGroup",
        name: string,
        parentGroupName?: string | null,
    },
};

export const approvePendingFloormap = /* GraphQL */ `
    mutation ApprovePendingFloormap(
        $fileName: String!
        $finalFileName: String
        $groupIds: [Int!]
    ) {
        approvePendingFloormap(
        fileName: $fileName
        finalFileName: $finalFileName
        groupIds: $groupIds
        ) {
            fileName
            tagsHaveConflict
            nameHasConflict
            status
            maxX
            maxY
            tagCount
            __typename
        }
    }
`;

export type FloormapToApprove = {
    fileName: string,
    finalFileName?: string | null,
    groupIds?: Array< number > | null,
};

export type ApprovePendingFloormapMutationVariables = {
    fileName: string,
    finalFileName?: string | null,
    groupIds?: Array< number > | null,
};

export type ApprovePendingFloormapMutation = {
    approvePendingFloormap:  {
        __typename: "Floormap",
        fileName: string,
        tagsHaveConflict: boolean,
        nameHasConflict: boolean,
        status: FloorStatus,
        maxX: number,
        maxY: number,
        tagCount: number,
    },
};

export const rejectPendingFloormaps = /* GraphQL */ `
    mutation RejectPendingFloormaps($fileNames: [String!]!) {
        rejectPendingFloormaps(fileNames: $fileNames) {
            fileName
            tagsHaveConflict
            nameHasConflict
            status
            maxX
            maxY
            tagCount
            __typename
        }
    }
`;

export type RejectPendingFloormapsMutationVariables = {
    fileNames: Array< string >,
};

export type RejectPendingFloormapsMutation = {
    rejectPendingFloormaps:  Array< {
        __typename: "Floormap",
        fileName: string,
        tagsHaveConflict: boolean,
        nameHasConflict: boolean,
        status: FloorStatus,
        maxX: number,
        maxY: number,
        tagCount: number,
    } >,
};

export const uploadFloormap = /* GraphQL */ `
mutation UploadFloormap($fileName: String!, $content: String!) {
    uploadFloormap(fileName: $fileName, content: $content) {
        fileName
        tagsHaveConflict
        nameHasConflict
        status
        maxX
        maxY
        tagCount
    }
}
`;

export type UploadFloormapMutationVariables = {
    fileName: string,
    content: string,
};

export type UploadFloormapMutation = {
    uploadFloormap: Floormap
};

export const startDurationTest = /* GraphQL */ `
    mutation StartDurationTest(
        $heelableIds: [String!]!
        $silent: Boolean
        $stickyDuration: Long
        $keepAwake: Boolean
        $positioningArgs: String!
    ) {
        startDurationTest(
            heelableIds: $heelableIds
            silent: $silent
            stickyDuration: $stickyDuration
            keepAwake: $keepAwake
            positioningArgs: $positioningArgs
        )
    }
`;
export const stopDurationTest = /* GraphQL */ `
    mutation StopDurationTest($heelableIds: [String!]!) {
        stopDurationTest(heelableIds: $heelableIds)
    }
`;

export type StartDurationTestMutationVariables = {
    heelableIds: Array< string >,
    silent?: boolean | null,
    stickyDuration?: string | null,
    keepAwake?: boolean | null,
    positioningArgs: string,
};

export type StartDurationTestMutation = {
    startDurationTest: boolean,
};

export type StopDurationTestMutationVariables = {
    heelableIds: Array< string >,
};

export type StopDurationTestMutation = {
    stopDurationTest: boolean,
};


export const addMaintenanceInfo = /* GraphQL */ `
mutation AddMaintenanceInfo(
  $thingName: String!
  $timestamp: Long!
  $note: String!
) {
  addMaintenanceInfo(
    thingName: $thingName
    timestamp: $timestamp
    note: $note
  ) {
    thingName
    timestamp
    note
    createdBy
    createdAt
    __typename
  }
}
`;

export type AddMaintenanceInfoMutationVariables = {
    thingName: string,
    timestamp: number,
    note: string,
};

export type AddMaintenanceInfoMutation = {
    addMaintenanceInfo:  {
        __typename: "MaintenanceInfo",
        thingName: string,
        timestamp: number,
        note: string,
        createdBy: string,
        createdAt: string,
    },
};


export type DeleteUserWearableLinkMutationVariables = {
    id: number,
};

export type DeleteUserWearableLinkMutation = {
    deleteUserWearableLink:  {
        __typename: "UserWearableLink",
        id: number,
        wearableId: string,
        userId: number,
        startTime: number,
        endTime?: number | null,
    },
};

export type UpdateUserWearableLinkUserMutationVariables = {
    id: number,
    userId: number,
};

export type UpdateUserWearableLinkUserMutation = {
    updateUserWearableLinkUser:  {
        __typename: "UserWearableLink",
        id: number,
        wearableId: string,
        userId: number,
        startTime: number,
        endTime?: number | null,
    },
};

export type UpdateUserWearableLinkWearableMutationVariables = {
    id: number,
    wearableId: string,
};

export type UpdateUserWearableLinkWearableMutation = {
    updateUserWearableLinkWearable:  {
        __typename: "UserWearableLink",
        id: number,
        wearableId: string,
        userId: number,
        startTime: number,
        endTime?: number | null,
    },
};

export const deleteUserWearableLink = /* GraphQL */ `mutation DeleteUserWearableLink($id: Int!) {
  deleteUserWearableLink(id: $id) {
    id
    wearableId
    userId
    startTime
    endTime
    __typename
  }
}
`;
export const updateUserWearableLinkUser = /* GraphQL */ `mutation UpdateUserWearableLinkUser($id: Int!, $userId: Int!) {
  updateUserWearableLinkUser(id: $id, userId: $userId) {
    id
    wearableId
    userId
    startTime
    endTime
    __typename
  }
}
`;
export const updateUserWearableLinkWearable = /* GraphQL */ `mutation UpdateUserWearableLinkWearable($id: Int!, $wearableId: String!) {
  updateUserWearableLinkWearable(id: $id, wearableId: $wearableId) {
    id
    wearableId
    userId
    startTime
    endTime
    __typename
  }
}
`;
