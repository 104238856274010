export enum CustomerCreationStep {
  TENANT,
  TENANT_DETAILS,
  GROUP_NAME,
  GROUP_DETAILS,
  GROUP_SENSORS,
  GROUP_MOBILE_ROUTERS,
  GROUP_USERS,
  GROUP_FLOORS,
  GROUP_APPLICATIONS,
  CONFIRMATION,
  CREATION,
  DONE,
}
