import {CreationStep} from "../CreationStep";
import React from "react";
import {Button, Col, Form, InputNumber, Row, Typography} from "antd";

export const TenantDetailsInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (instructions.tenant) {
      instructions.tenant.userLimit = values.userLimit === undefined ? null : values.userLimit;
    }
    onStepComplete(instructions);
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Enter details for tenant</Typography.Title>
          <Typography.Paragraph>
            Tenant details include various aspects of the tenant. This includes the following information:
            <ul>
              <li><b>user limit</b>: The maximum number of users that can be created in this tenant. If this limit is
                reached, no more users can be created in this tenant. Even if groups have a higher user limit, no more
                users can be created in this tenant.
              </li>
            </ul>
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: 20}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              userLimit: instructions.tenant ? instructions.tenant.userLimit : undefined,
            }}
          >
            <Form.Item
              name="userLimit"
              label="user Limit"
            >
              <InputNumber
                min={0}
                style={{width: "50%"}}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
          <Button onClick={onStepBack}>Back</Button>
        </Col>
      </Row>
    </>
  )
}