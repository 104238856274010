import {CreationStep} from "../CreationStep";
import React from "react";
import {Button, Col, Divider, Row} from "antd";

export const OverviewInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  return (
    // Display all options selected in previous steps
    <>
      <Row>
        <Col span={24}>
          <h2>Tenant</h2>
          <p>
            <b>Name:</b> {instructions.tenant?.name}<br/>
            <b>User Limit:</b> {instructions.tenant?.userLimit ? instructions.tenant.userLimit : "Unlimited"}<br/>
            <b>Thing group:</b> {instructions.tenant?.thingGroupName}
          </p>
          <h2>Groups</h2>
          {instructions.groups?.map((group, index) => (
            <>
              <Divider>
                <h3>Group {index + 1}</h3>
              </Divider>
              <p key={index}>
                  <b>Name:</b> {group.name}<br/>
                  <b>User Limit:</b> {group.userLimit ? group.userLimit : "Unlimited"}<br/>
                  <b>Thing group:</b> {group.thingGroupName} <br/>
                  <b>Floor Ids:</b> {group.floorsIds?.join(", ")}<br/>
              </p>
              {group.users?.map((user, index) => (
                <>
                  <p key={index}>
                    <b>E-mail:</b> {user.email}<br/>
                    <b>password:</b> {user.password}<br/>
                    <b>autoVerifyEmail:</b> {user.autoVerifyEmail ? "true" : "false"}<br/>
                    <b>temporaryPassword:</b> {user.isTemporaryPassword ? "true" : "false"}
                  </p>
                </>
              ))}
            </>
          ))}
        </Col>
      </Row>
      <Button type="primary" onClick={() => onStepComplete(instructions)}>Create</Button>
      <Button onClick={onStepBack}>Back</Button>
    </>
  )
}
