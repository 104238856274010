import { Button, Space } from 'antd';
import React from 'react';


type PendingActionsProps = {
    onApproveClick: any
    onRejectClick: any
    buttonsDisabled?: boolean
}


const PendingActions = ({
    onApproveClick,
    onRejectClick,
    buttonsDisabled
}: PendingActionsProps) => {
    return <Space>
        <Button
            onClick={onApproveClick}
            type='link'
            size='small'
            disabled={buttonsDisabled}
        >
            Approve
        </Button>
        <Button
            onClick={onRejectClick}
            danger
            type='link'
            size='small'
            disabled={buttonsDisabled}
        >
            Reject
        </Button>
    </Space>
}

export default PendingActions;
