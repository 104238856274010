import React, {useEffect, useState} from "react";
import {App, Button, Col, Row, Space, Switch, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {NetworkEntry} from "./NetworkEntry";
import Confirm from "../Utils/Confirm";
import EditNetworkEntry from "./EditNetworkEntry";
import {EncryptionStatus} from "./EncryptionStatus";
import {passwordCrypto} from "./PasswordUtil";

const PasswordEntry = ({ssid, password, encrypt}: { ssid: string, password: string, encrypt: number }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [decryptedPassword, setDecryptedPassword] = useState<string>(password);

  const toggleShowPassword = async () => {
    if (!showPassword) {
      // We need to decrypt the password, first check if it is not already decrypted
      if (decryptedPassword === password) {
        // We need to decrypt the password
        const decryptedPassword = await passwordCrypto(ssid, password, false);
        if (decryptedPassword) {
          setDecryptedPassword(decryptedPassword);
        } else {
          console.log('Failed to decrypt password');
          setDecryptedPassword('Failed to decrypt password');

        }
      }
    }
    setShowPassword(!showPassword);
  }

  return (
    <Row>
      <Col span={22}>
        {showPassword ? decryptedPassword : password}
      </Col>
      <Col span={2}>
        {encrypt === EncryptionStatus.ENCRYPTED &&
            <Button
                icon={showPassword ? <UnlockOutlined/> : <LockOutlined/>}
                onClick={() => toggleShowPassword()}
            />
        }
      </Col>
    </Row>
  )
}

interface DeviceNetworkConfigViewProps {
  configNetworks: NetworkEntry[] | null;
  onSave?: (networks: NetworkEntry[] | null) => void;
  showAutoField?: boolean;
  saveButtonText?: string;
  askForConfirmation?: boolean;
}

const DeviceNetworkConfigView = ({
                                   configNetworks,
                                   onSave,
                                   showAutoField,
                                   saveButtonText,
                                   askForConfirmation
                                 }: DeviceNetworkConfigViewProps) => {
  const [networks, setNetworks] = useState<NetworkEntry[] | null>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkEntry | null>(null);
  const [enableNetworkConfiguration, setEnableNetworkConfiguration] = useState<boolean>(false);

  const setNetworkConfiguration = (enabled: boolean) => {
    setEnableNetworkConfiguration(enabled);
    // There was no configuration yet, so we need to create an empty array
    if (networks === null) {
      setNetworks([]);
    }
  }

  const save = () => {
    if (!onSave) {
      return;
    }

    if (!enableNetworkConfiguration) {
      onSave(null);
    } else {
      // Remove the _internal_id from the networks
      const newNetworks = [...networks || []].map((n) => {
        delete n._internal_id;
        return n;
      });
      onSave(newNetworks || []);
    }
  }

  useEffect(() => {
    // copy config_networks to a temporary variable and add an internal id, make sure it is a deep copy

    let tmpNetworks = null;
    if (configNetworks) {
      tmpNetworks = [];
      for (const n of configNetworks) {
        tmpNetworks.push({
          ...n,
          _internal_id: Math.random().toString(36).substring(7)
        });
      }
    }
    setNetworks(tmpNetworks || []);
    if (configNetworks === null) {
      setEnableNetworkConfiguration(false);
    } else {
      setEnableNetworkConfiguration(true);
    }
  }, [configNetworks]);

  const deleteNetwork = (network: any) => {
    if (!networks) {
      return;
    }
    const newNetworks = networks.filter((n) => n._internal_id !== network._internal_id);

    Confirm({
      title: 'Are you sure you want to delete this network?',
      content: `You are about to delete the network ${network.wifi_ssid}, this cannot be undone.`,
      action: () => {
        setNetworks(newNetworks);
      }
    });
  }

  const editNetwork = (network: any) => {
    console.log('Edit network', network);
    setSelectedNetwork(network);
  }

  const stopEditingNetwork = () => {
    setSelectedNetwork(null);
  }

  const columns = [
    {
      title: 'SSID',
      dataIndex: 'wifi_ssid',
      key: 'wifi_ssid',
      sorter: (a: any, b: any) => a.wifi_ssid.localeCompare(b.wifi_ssid),
    },
    {
      title: 'Password',
      dataIndex: 'wifi_password',
      key: 'wifi_password',
      render: (wifi_password: string, record: any) => {
        return (
          <PasswordEntry
            ssid={record.wifi_ssid}
            password={wifi_password}
            encrypt={record.encryption_status}
          />
        );
      }
    },
    {
      title: 'Timeout',
      dataIndex: 'wifi_timeout',
      key: 'wifi_timeout',
      // List should only contain null and numbers, but may be formatted as strings
      sorter: (a: any, b: any) => a.wifi_timeout || 0 - b.wifi_timeout || 0,
    },
    {
      title: 'Priority',
      dataIndex: 'wifi_priority',
      key: 'wifi_priority',
      sorter: (a: any, b: any) => a.wifi_priority || 50 - b.wifi_priority || 50,
    },
    {
      title: 'Auto',
      dataIndex: '_',
      key: '_',
      hidden: !(showAutoField ?? true),
      render: (short_code: string) => {
        // show a tag
        if (short_code) {
          return (
            // Show on popover
            <>
              <Tooltip title={short_code}>
                <Tag color={'green'}>Yes</Tag>
              </Tooltip>
            </>

          )
        } else {
          return <Tag color={'red'}>No</Tag>
        }
      }
    },
    {
      title: 'Actions',
      dataIndex: '_',
      key: '_',
      render: (_: string, record: any) => {
        return (
          <>
            {/*   Button with trashcan*/}
            <Button
              type={'primary'}
              danger
              onClick={() => {
                deleteNetwork(record);
              }}
            >
              <DeleteOutlined/>
            </Button>
            <Button
              type={'primary'}
              style={{
                marginLeft: '10px',
              }}
              onClick={() => {
                editNetwork(record);
              }}
            >
              <EditOutlined/>
            </Button>
          </>
        );
      }
    }
  ].filter((c) => !c.hidden);

  const completeEditNetwork = (network: NetworkEntry) => {
    if (!networks) {
      return;
    }
    // Remove entry from networks by _internal_id get the id.
    const index = networks.findIndex((n) => n._internal_id === network._internal_id);
    // filter it out
    const newNetworks = networks.filter((n) => n._internal_id !== network._internal_id);

    network._internal_id = new Date().getTime().toString();
    newNetworks.splice(index, 0, network);
    setNetworks(newNetworks);
  }

  const completeAddNetwork = (network: NetworkEntry) => {
    if (!networks) {
      return;
    }
    // add network to networks
    const newNetworks = [...networks];
    // network needs _internal_id to be unique and has to be added here. Timestamp as string?
    network._internal_id = new Date().getTime().toString();
    newNetworks.push(network);
    setNetworks(newNetworks);
  }

  return (
    <>
      <EditNetworkEntry
        network={selectedNetwork}
        onClose={() => stopEditingNetwork()}
        onComplete={(network) => completeEditNetwork(network)}
      />
      <Row
        style={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Col span={12}>
          {/*  Switch on weather to have a network configuration or not*/}
          <span style={{marginRight: '10px'}}>Enable network configuration</span>
          <Switch
            defaultChecked
            onChange={(checked) => setNetworkConfiguration(checked)}
            checked={enableNetworkConfiguration}
          />

        </Col>
        <Col span={12}>
          <Space
            style={{
              float: 'right',
            }}
          >
            {enableNetworkConfiguration &&
                <EditNetworkEntry
                    onComplete={(network) => completeAddNetwork(network)}
                    showOpenButton={true}
                />
            }
          </Space>
        </Col>
      </Row>
      {enableNetworkConfiguration &&
          <Row>
              <Col span={24}>
                  <Table
                      style={{
                        width: '100%',
                      }}
                      dataSource={networks || []}
                      columns={columns}
                    // table has 10 rows per page
                      pagination={{pageSize: 10}}
                      rowKey={'_internal_id'}
                    // fixed layout
                      scroll={{x: 1000}}

                  />
              </Col>
          </Row>
      }
      <Row>
        <Col span={24}>
          <Button
            type={'primary'}
            onClick={() => {
              if (askForConfirmation ?? false) {
                Confirm({
                  title: 'Are you sure you want to save?',
                  content: 'You are about to save the network configuration, this cannot be undone.',
                  action: () => {
                    save();
                  }
                });
              } else {
                save();
              }
            }}
            style={{
              marginTop: '20px',
            }}
          >
            {saveButtonText || 'Save'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DeviceNetworkConfigView;
