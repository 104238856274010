import React, { useState } from 'react';
import { Moment } from 'moment';
import ReportLayoutWrapper from './ReportLayoutWrapper';
import { determineLatestCompletedQuarter, getNameOfPreset, getYearToDatePeriod } from './reportUtils';
import CustomerInsightsSelectionUI from './CustomerInsightsSelectionUI';


export interface Period {
    dates: [Moment, Moment],
    name: string,
}

export enum ReportType {
    FRP,
    VNL
}

/**
 * Displays either the selection options for generating the report, or the report itself.
 */
const CustomerInsightsView = () => {
    const [municipalityName, setMunicipalityName] = useState<string>('');
    const [periods, setPeriods] = useState<Period[]>(getInitialPeriodsState());
    const [includeYearToDateStats, setIncludeYearToDateStats] = useState(false);
    const [includeTotalStats, setIncludeTotalStats] = useState(false);
    const [selectedGroupIDs, setSelectedGroupIDs] = useState<string>();
    const [mustShowReport, setMustShowReport] = useState(false);
    const [reportType, setReportType] = useState(ReportType.FRP);
    const [includeLocationBreakdown, setIncludeLocationBreakdown] = useState(false);

    function getInitialPeriodsState() {
        const [firstPeriodStart, firstPeriodEnd] = determineLatestCompletedQuarter();
        const firstPeriod: Period = {
            dates: [firstPeriodStart, firstPeriodEnd],
            name: getNameOfPreset(firstPeriodStart)
        }
        return [firstPeriod];
    }

    return mustShowReport ?
        <ReportLayoutWrapper
            setIsPreviewMode={setMustShowReport}
            municipalityName={municipalityName}
            periods={periods}
            groupIds={selectedGroupIDs as unknown as Array<number>}
            yearToDatePeriod={includeYearToDateStats ? getYearToDatePeriod(periods) : undefined}
            mustIncludeTotalStats={includeTotalStats}
            reportType={reportType}
            includeLocationBreakdown={includeLocationBreakdown}
        />
        :
        <CustomerInsightsSelectionUI
            municipalityName={municipalityName}
            setMunicipalityName={setMunicipalityName}
            selectedGroupIDs={selectedGroupIDs}
            setSelectedGroupIDs={setSelectedGroupIDs}
            periods={periods}
            setPeriods={setPeriods}
            includeYearToDateStats={includeYearToDateStats}
            setIncludeYearToDateStats={setIncludeYearToDateStats}
            setMustShowReport={setMustShowReport}
            includeTotalStats={includeTotalStats}
            setIncludeTotalStats={setIncludeTotalStats}
            reportType={reportType}
            setReportType={setReportType}
            includeLocationBreakdown={includeLocationBreakdown}
            setIncludeLocationBreakdown={setIncludeLocationBreakdown}
        />
}

export default CustomerInsightsView;
