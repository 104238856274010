import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../utils/graphql/queries";
import {
    GetHeelableInfoQuery,
    GetHeelableInfoQueryVariables,
    GetPositionEstimatesQuery,
    GetPositionEstimatesQueryVariables,
    GetTelemetryForHeelableQuery,
    GetTelemetryForHeelableQueryVariables,
    ListFootstepsQuery,
    ListFootstepsQueryVariables,
    ListFrpsForWearableQuery,
    ListFrpsForWearableQueryVariables,
    ListMaintenanceInfoQuery,
    ListMaintenanceInfoQueryVariables,
    ListPresenceEventsQuery,
    ListPresenceEventsQueryVariables,
    ListTenantWearableLeasesQuery,
    ListUserWearableLinksQuery,
    ListUserWearableLinksQueryVariables
} from "../../utils/graphql/queries";
import * as mutations from "../../utils/graphql/mutations";
import {
    AddMaintenanceInfoMutation,
    AddMaintenanceInfoMutationVariables,
    SetHeelableDebugLevelMutation,
    SetHeelableDebugLevelMutationVariables,
    SetHeelableTelemetryLevelMutation,
    SetHeelableTelemetryLevelMutationVariables
} from "../../utils/graphql/mutations";
import {
    Alert,
    App,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Tabs,
    TimeRangePickerProps
} from "antd";
import {BatteryChart} from "../MonitoringView/BatteryChart";
import {useLocation} from 'react-router-dom'
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {ThingSelector} from "./ThingSelector";


const dayjs = require('dayjs')

const {RangePicker} = DatePicker;

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')]},
    {label: 'Last 7 Days', value: [dayjs().subtract(7, 'd'), dayjs().endOf('day')]},
    {label: 'Last 14 Days', value: [dayjs().subtract(14, 'd'), dayjs().endOf('day')]},
    {label: 'Last 30 Days', value: [dayjs().subtract(30, 'd'), dayjs().endOf('day')]},
    {label: 'Last 90 Days', value: [dayjs().subtract(90, 'd'), dayjs().endOf('day')]},
    {label: 'Last 180 Days', value: [dayjs().subtract(180, 'd'), dayjs().endOf('day')]},
    {label: 'Last 365 Days', value: [dayjs().subtract(365, 'd'), dayjs().endOf('day')]},
];

interface FootstepDetailsViewProps {
    deviceId: string;
}

interface FootstepsData {
    id: number;
    heelableId: string;
    timestamp: number;
    position: {
        x: number;
        y: number;
    } | null;
    floor: {
        id: number;
        name: string;
    } | null;
}

const FootstepDetailsView = ({deviceId}: FootstepDetailsViewProps) => {
    const {notification} = App.useApp();
    const [footsteps, setFootsteps] = useState<FootstepsData[]>([]);
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchFootsteps(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
    }, [deviceId]);

    const fetchFootsteps = async (startTime: number, endTime: number) => {
        let nextToken = null;
        let response = null;

        setLoading(true);

        let tmpFootsteps: FootstepsData[] = [];

        do {
            response = await API.graphql<GraphQLQuery<ListFootstepsQuery>>({
                query: queries.listFootsteps,
                variables: {
                    heelableId: deviceId,
                    page: {
                        nextToken: nextToken,
                        limit: 500,
                    },
                    startTime: startTime,
                    endTime: endTime,
                } as ListFootstepsQueryVariables
            }).catch(
                (error) => {
                    notification.error({
                        message: 'Footsteps failed to fetch',
                        description: error.errors[0].message,
                    });
                }
            )

            if (response?.data?.listFootsteps?.items) {
                response.data.listFootsteps.items.forEach((item) => {
                    tmpFootsteps.push({
                        id: item.id,
                        heelableId: item.heelableId,
                        timestamp: item.timestamp,
                        position: item.position ?? null,
                        floor: item.floor ?? null,
                    });
                });
            }

            nextToken = response?.data?.listFootsteps?.nextToken ?? null;

        } while (nextToken !== null);

        setFootsteps(tmpFootsteps);
        setLoading(false);
    }

    return (
        <Col span={12}>
            <Card title="Footstep Details">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        fetchFootsteps(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
                    }}
                    type={"primary"}
                    loading={loading}
                >
                    Fetch Footsteps
                </Button>
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timestamp',
                            key: 'timestamp',
                            render: (timestamp: number) => {
                                return new Date(timestamp).toLocaleString();
                            }
                        },
                        {
                            title: 'Position',
                            dataIndex: 'position',
                            key: 'position',
                            render: (position: { x: number, y: number } | null) => {
                                if (position) {
                                    return `(${position.x}, ${position.y})`;
                                }
                                return 'N/A';
                            },
                            filters: [
                                {
                                    text: 'Has position',
                                    value: 'hasPosition',
                                }
                            ],
                            onFilter: (value, record) => {
                                if (value === 'hasPosition') {
                                    return record.position !== null;
                                }
                                return false;
                            }
                        },
                        {
                            title: 'Floor',
                            dataIndex: 'floor',
                            key: 'floor',
                            render: (floor: { id: number, name: string } | null) => {
                                if (floor) {
                                    return `${floor.name} (${floor.id})`
                                }
                                return 'N/A';
                            },
                        },
                    ]}
                    dataSource={footsteps}
                    size={'small'}
                />
            </Card>
        </Col>
    );
}


interface TelemetryDetailsViewProps {
    deviceId: string;
}


interface TelemetryData {
    timestamp: number;
    messageIndex: number;
    logLevel: string;
    logMessage: string;
}

const TelemetryDetailsView = ({deviceId}: TelemetryDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [includeDebug, setIncludeDebug] = useState<boolean>(false);
    const [telemetry, setTelemetry] = useState<TelemetryData[]>([]);

    useEffect(() => {
        setTelemetry([]);
    }, [deviceId]);

    const loadTelemetry = async (startTime: number, endTime: number) => {
        setLoading(true);
        let response = await API.graphql<GraphQLQuery<GetTelemetryForHeelableQuery>>({
            query: queries.getTelemetryForHeelable,
            variables: {
                heelableId: deviceId,
                startTime: startTime,
                endTime: endTime,
                logLevel: includeDebug ? 'DBG' : 'INF'
            } as GetTelemetryForHeelableQueryVariables
        }).catch(error => {
            notification.error({
                message: 'Telemetry failed to fetch',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.getTelemetryForHeelable) {
            setTelemetry(response.data.getTelemetryForHeelable);
        }

        setLoading(false);
    }


    return (
        <Col span={24}>
            <Card title="Telemetry Details">
                <Alert
                    message="Telemetry is not fetched automatically"
                    type="info"
                    showIcon
                />
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Checkbox
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onChange={(e) => {
                        setIncludeDebug(e.target.checked);
                    }}
                    checked={includeDebug}
                >
                    Include Debug
                </Checkbox>
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        loadTelemetry(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
                    }}
                    type={"primary"}
                    loading={loading}
                >
                    Fetch Telemetry
                </Button>
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'timestamp',
                            key: 'timestamp',
                            render: (timestamp: number) => {
                                return <div
                                    style={{whiteSpace: 'nowrap'}}>{new Date(timestamp * 1000).toLocaleString()}</div>;
                            },

                        },
                        {
                            title: 'MI',
                            dataIndex: 'messageIndex',
                            key: 'messageIndex',
                        },
                        {
                            title: 'Log Level',
                            dataIndex: 'logLevel',
                            key: 'logLevel',
                        },
                        {
                            title: 'Log Message',
                            dataIndex: 'logMessage',
                            key: 'logMessage',
                        },
                    ]}
                    dataSource={telemetry}
                    size={'small'}
                />
            </Card>
        </Col>
    );
}

interface UserWearableLinkDetailsViewProps {
    deviceId: string;
}

interface UserWearableLinkData {
    id: number,
    wearableId: string,
    userId: number,
    startTime: number,
    endTime?: number | null,
}

const UserWearableLinkDetailsView = ({deviceId}: UserWearableLinkDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [userWearableLinks, setUserWearableLinks] = useState<UserWearableLinkData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchUserWearableLinks(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
    }, [deviceId]);

    const fetchUserWearableLinks = async (startTime: number, endTime: number) => {
        setLoading(true);

        const tmpUWLs: UserWearableLinkData[] = [];
        let nextToken = null;
        let response = null;

        do {
            response = await API.graphql<GraphQLQuery<ListUserWearableLinksQuery>>({
                query: queries.listUserWearableLinks,
                variables: {
                    wearableId: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListUserWearableLinksQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'User wearable links failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listUserWearableLinks.items) {
                response.data.listUserWearableLinks.items.forEach((item) => {
                    tmpUWLs.push({
                        id: item.id,
                        wearableId: item.wearableId,
                        userId: item.userId,
                        startTime: item.startTime,
                        endTime: item.endTime ?? null,
                    });
                });
            }

            nextToken = response?.data?.listUserWearableLinks.nextToken ?? null;
        } while (nextToken !== null);

        setUserWearableLinks(tmpUWLs);
        setLoading(false);
    }

    return (
        <Col span={12}>
            <Card title="User Wearable Links">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        fetchUserWearableLinks(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
                    }}
                    type={"primary"}
                    loading={loading}
                >
                    Fetch User Wearable Links
                </Button>
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'UWL ID',
                            dataIndex: 'id',
                            key: 'id',
                            // render a link to /user-weareable-links?id=<id>
                            render: (id: number) => {
                                return <a href={`/user-wearable-links?id=${id}`}>{id}</a>;
                            }
                        },
                        {
                            title: 'User ID',
                            dataIndex: 'userId',
                            key: 'userId',
                        },
                        {
                            title: 'Start Time',
                            dataIndex: 'startTime',
                            key: 'startTime',
                            render: (timestamp: number) => {
                                return new Date(timestamp * 1000).toLocaleString();
                            }
                        },
                        {
                            title: 'End Time',
                            dataIndex: 'endTime',
                            key: 'endTime',
                            render: (timestamp: number | null) => {
                                if (timestamp) {
                                    return new Date(timestamp * 1000).toLocaleString();
                                }
                                return 'N/A';
                            }
                        },
                    ]}
                    dataSource={userWearableLinks}
                    size={'small'}
                />
            </Card>
        </Col>
    )
}

interface TenantWearableLeaseDetailsViewProps {
    deviceId: string;
}

interface TenantWearableLeaseData {
    wearableId: string,
    tenant: {
        id: number,
        name: string,
    },
    startTime: number,
    endTime?: number | null,
}


const TenantWearableLeaseDetailsView = ({deviceId}: TenantWearableLeaseDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [tenantWearableLeases, setTenantWearableLeases] = useState<TenantWearableLeaseData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchTenantWearableLeases(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
    }, [deviceId]);

    const fetchTenantWearableLeases = async (startTime: number, endTime: number) => {
        setLoading(true);

        const tmpTWLs: TenantWearableLeaseData[] = [];
        let nextToken = null;
        let response = null;

        do {
            response = await API.graphql<GraphQLQuery<ListTenantWearableLeasesQuery>>({
                query: queries.listTenantWearableLeases,
                variables: {
                    wearableId: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListUserWearableLinksQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'User wearable links failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listTenantWearableLeases.items) {
                response.data.listTenantWearableLeases.items.forEach((item) => {
                    tmpTWLs.push({
                        wearableId: item.wearableId,
                        tenant: item.tenant,
                        startTime: item.startTime,
                        endTime: item.endTime ?? null,
                    });
                });
            }

            nextToken = response?.data?.listTenantWearableLeases.nextToken ?? null;
        } while (nextToken !== null);

        setTenantWearableLeases(tmpTWLs);
        setLoading(false);
    }

    return (
        <Col span={12}>
            <Card title="Tenant Wearable Leases">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        fetchTenantWearableLeases(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
                    }}
                    type={"primary"}
                    loading={loading}
                >
                    Fetch Tenant Wearable Leases
                </Button>
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'Tenant',
                            dataIndex: 'tenant',
                            key: 'tenant',
                            render: (tenant: { id: number, name: string }) => {
                                return `${tenant.name} (${tenant.id})`;
                            }
                        },
                        {
                            title: 'Start Time',
                            dataIndex: 'startTime',
                            key: 'startTime',
                            render: (timestamp: number) => {
                                return new Date(timestamp * 1000).toLocaleString();
                            }
                        },
                        {
                            title: 'End Time',
                            dataIndex: 'endTime',
                            key: 'endTime',
                            render: (timestamp: number | null) => {
                                if (timestamp) {
                                    return new Date(timestamp * 1000).toLocaleString();
                                }
                                return 'N/A';
                            }
                        },
                    ]}
                    dataSource={tenantWearableLeases}
                    size={'small'}
                />
            </Card>
        </Col>
    )
}


interface ThingSelectorProps {
    onSelect: (deviceId: string) => void;
    defaultDevice?: string;
}


interface BatteryDetailsViewProps {
    deviceId: string;
}

const BatteryDetailsView = ({deviceId}: BatteryDetailsViewProps) => {
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [generatedTimestamp, setGeneratedTimestamp] = useState<number | null>(null);

    useEffect(() => {
        if (deviceId) {
            setGeneratedTimestamp(Date.now().valueOf());
        } else {
            setGeneratedTimestamp(null);
        }
    }, [deviceId]);

    return (
        <Col span={24}>
            <Card title="Battery Details">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        setGeneratedTimestamp(Date.now().valueOf());
                    }}
                    type={"primary"}
                >
                    Fetch Battery Details
                </Button>
                <BatteryChart
                    thing={deviceId}
                    startTimestamp={dateRange[0]}
                    endTimestamp={dateRange[1]}
                    showDeSync={true}
                    showBootHistory={true}
                    generatedTimestamp={generatedTimestamp}
                />
            </Card>
        </Col>
    );
}

interface DeviceInfoProps {
    deviceId: string;
}

interface DeviceInfoData {
    software: {
        __typename: "SoftwareInfo",
        version: string,
        isRelease: boolean,
    },
    hardwareVersion: string,
    rfidFirmwareVersion: string,
    macAddress: string,
    telemetryLevel: number,
    debugLevel: number,
    shadowMatch: boolean,
}


enum TelemetryLevel {
    DEBUG = 4,
    INFO = 6,
    WARN = 8,
    ERROR = 10,
    CRITICAL = 12,
    UNKNOWN = 0xFF,
}

interface UpdateTelemetryLevelProps {
    deviceId: string;
    open: boolean;
    onClose: () => void;
}

const UpdateTelemetryLevel = ({deviceId, open, onClose}: UpdateTelemetryLevelProps) => {
    const {notification} = App.useApp();
    const [newLevel, setNewLevel] = useState<number | null>(null);
    const updateTelemetryLevel = async (deviceId: string, level: number | null) => {

        await API.graphql<GraphQLQuery<SetHeelableTelemetryLevelMutation>>({
            query: mutations.setHeelableTelemetryLevel,
            variables: {
                heelableId: deviceId,
                telemetryLevel: level,
            } as SetHeelableTelemetryLevelMutationVariables
        }).catch(error => {
            notification.error({
                message: 'Failed to update telemetry level',
                description: error.errors[0].message,
            });
        });
    }

    return (
        <Modal
            title="Update Telemetry Level"
            open={open}
            onOk={() => {
                updateTelemetryLevel(deviceId, newLevel).then();
                onClose();
            }}
            onCancel={() => {
                onClose();
            }
            }
        >
            <Select
                style={{width: '100%'}}
                options={[
                    {
                        label: 'DEBUG',
                        value: TelemetryLevel.DEBUG,
                    },
                    {
                        label: 'INFO',
                        value: TelemetryLevel.INFO,
                    },
                    {
                        label: 'WARNING',
                        value: TelemetryLevel.WARN,
                    },
                    {
                        label: 'ERROR',
                        value: TelemetryLevel.ERROR,
                    },
                    {
                        label: 'CRITICAL',
                        value: TelemetryLevel.CRITICAL,
                    },
                    {
                        label: 'NOT SET',
                        value: 'not set',
                    },
                ]}
                onChange={(value: number | string) => {
                    // if it is a string the value needs to be not set
                    if (typeof value === 'string') {
                        setNewLevel(null);
                    } else {
                        setNewLevel(value);
                    }
                }}
            />

        </Modal>
    )
}

interface UpdateDebugLevelProps {
    deviceId: string;
    open: boolean;
    onClose: () => void;
}

const UpdateDebugLevel = ({deviceId, open, onClose}: UpdateDebugLevelProps) => {
    const {notification} = App.useApp();
    const [newLevel, setNewLevel] = useState<number | null>(null);
    const updateDebugLevel = async (deviceId: string, level: number | null) => {

        await API.graphql<GraphQLQuery<SetHeelableDebugLevelMutation>>({
            query: mutations.setHeelableDebugLevel,
            variables: {
                heelableId: deviceId,
                debugLevel: level,
            } as SetHeelableDebugLevelMutationVariables
        }).catch(error => {
            notification.error({
                message: 'Failed to update debug level',
                description: error.errors[0].message,
            });
        });
    }

    return (
        <Modal
            title="Update Debug Level"
            open={open}
            onOk={() => {
                updateDebugLevel(deviceId, newLevel).then();
                onClose();
            }}
            onCancel={() => {
                onClose();
            }}
        >
            <Select
                style={{width: '100%'}}
                options={[
                    {
                        label: 'Debug Enabled',
                        value: 1,
                    },
                    {
                        label: 'Debug Disabled',
                        value: 0,
                    },
                    {
                        label: 'Debug Not Set',
                        value: 'not set',
                    },
                ]}
                onChange={(value: number | string) => {
                    // if it is a string the value needs to be not set
                    if (typeof value === 'string') {
                        if (value === 'not set') {
                            setNewLevel(null);
                        }
                    } else {
                        setNewLevel(value);
                    }
                }}
            />

        </Modal>
    )
}

const DeviceInfo = ({deviceId}: DeviceInfoProps) => {
    const {notification} = App.useApp();
    const [info, setInfo] = useState<DeviceInfoData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [updateTelemetryLevelOpen, setUpdateTelemetryLevelOpen] = useState<boolean>(false);
    const [updateDebugLevelOpen, setUpdateDebugLevelOpen] = useState<boolean>(false);

    const fetchInfo = async (deviceId: string) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<GetHeelableInfoQuery>>({
            query: queries.getHeelableInfo,
            variables: {
                heelableId: deviceId,
            } as GetHeelableInfoQueryVariables
        }).catch(error => {
            notification.error({
                message: 'Failed to fetch device info',
                description: error.errors[0].message,
            });
        })

        if (response?.data?.getHeelableInfo) {
            setInfo(response?.data.getHeelableInfo);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (deviceId) {
            fetchInfo(deviceId).then();
        }
    }, [deviceId]);

    return (
        <>
            <UpdateTelemetryLevel deviceId={deviceId} open={updateTelemetryLevelOpen} onClose={() => {
                setUpdateTelemetryLevelOpen(false);
                // wait for 1 second to fetch the new data
                setTimeout(() => {
                    fetchInfo(deviceId).then();
                }, 1000);
            }}/>
            <UpdateDebugLevel deviceId={deviceId} open={updateDebugLevelOpen} onClose={() => {
                setUpdateDebugLevelOpen(false);
                // wait for 1 second to fetch the new data
                setTimeout(() => {
                    fetchInfo(deviceId).then();
                }, 1000);
            }}/>
            <Col span={12}>
                <Card title="Device Info">
                    {/*    Use a table with the firm column is the attribute name, and the 2nd is the value*/}
                    {/*    Table should have no sort options, and not display headers*/}
                    {/*    The table should have a loading state while fetching the data*/}
                    {/* Table should have a reload button */}
                    <Button
                        onClick={() => {
                            fetchInfo(deviceId).then();
                        }}
                        style={{marginBottom: '10px'}}
                        type={"primary"}
                        loading={loading}
                    >
                        Reload
                    </Button>
                    <Table
                        loading={loading}
                        dataSource={[
                            {
                                key: 'software',
                                attribute: 'Software Version',
                                value: info?.software.version,
                            },
                            {
                                key: 'hardware',
                                attribute: 'Hardware Version',
                                value: info?.hardwareVersion,
                            },
                            {
                                key: 'rfidFirmware',
                                attribute: 'RFID Firmware Version',
                                value: info?.rfidFirmwareVersion,
                            },
                            {
                                key: 'macAddress',
                                attribute: 'MAC Address',
                                value: info?.macAddress,
                            },
                            {
                                key: 'telemetryLevel',
                                attribute: 'Telemetry Level',
                                value: <>{TelemetryLevel[info?.telemetryLevel ?? 0xFF]} ({info?.telemetryLevel})</>,
                                edit: <Button
                                    type={"link"}
                                    onClick={() => setUpdateTelemetryLevelOpen(true)}
                                >
                                    Edit
                                </Button>,
                            },
                            {
                                key: 'debugLevel',
                                attribute: 'Debug Level',
                                value: info?.debugLevel,
                                edit: <Button
                                    type={"link"}
                                    onClick={() => setUpdateDebugLevelOpen(true)}
                                >
                                    Edit
                                </Button>,
                            },
                            {
                                key: 'shadowMatch',
                                attribute: 'Shadow Match',
                                value: info?.shadowMatch ? 'Yes' : 'No',
                            },
                        ]}
                        columns={[
                            {
                                title: 'Attribute',
                                dataIndex: 'attribute',
                                key: 'attribute',
                            },
                            {
                                title: 'Value',
                                dataIndex: 'value',
                                key: 'value',
                            },
                            {
                                title: 'Edit',
                                dataIndex: 'edit',
                                key: 'edit',
                            }
                        ]}
                        showHeader={false}
                        pagination={false}
                        size={'small'}
                        rowClassName={(record, _) => {
                            if (record.key === 'software') {
                                if (!info?.software.isRelease) {
                                    return 'table-row-warning';
                                }
                            }
                            if (record.key === 'shadowMatch') {
                                if (!info?.shadowMatch) {
                                    return 'table-row-warning';
                                }
                            }
                            return '';
                        }}

                    />
                </Card>
            </Col>
        </>
    );
}

interface FallRiskProfileDetailsViewProps {
    deviceId: string;
}

interface FallRiskProfileData {
    wearableId: string;
    createdAt: number;
    floor: {
        id: number;
        name: string;
    };
    walkingSpeed: number;
    strideLength: number;
    strideFrequency: number,
    total: number
}

const FallRiskProfileDetailsView = ({deviceId}: FallRiskProfileDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fallRiskProfiles, setFallRiskProfiles] = useState<FallRiskProfileData[]>([]);


    const loadFallRiskProfiles = async (startTime: number, endTime: number) => {
        setLoading(true);
        let nextToken = null;
        let response = null;
        let fallRiskProfiles: FallRiskProfileData[] = [];

        do {
            response = await API.graphql<GraphQLQuery<ListFrpsForWearableQuery>>({
                query: queries.listFrpsForWearable,
                variables: {
                    wearableId: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListFrpsForWearableQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'Fall risk profiles failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listFrpsForWearable.items) {
                response.data.listFrpsForWearable.items.forEach((item) => {
                    fallRiskProfiles.push(item);
                });
            }

            nextToken = response?.data?.listFrpsForWearable.nextToken ?? null;
        } while (nextToken !== null);

        setFallRiskProfiles(fallRiskProfiles);
        setLoading(false);
    }

    useEffect(() => {
        loadFallRiskProfiles(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
    }, [deviceId]);

    return (
        <Col span={12}>
            <Card title="Fall Risk Profiles">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        loadFallRiskProfiles(Math.floor(dateRange[0] / 1000), Math.floor(dateRange[1] / 1000)).then();
                    }}
                    type={"primary"}
                    loading={loading}
                >
                    Fetch Fall Risk Profiles
                </Button>
                <Table
                    loading={loading}
                    columns={[
                        {
                            title: 'Timestamp',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            render: (timestamp: number) => {
                                return new Date(timestamp * 1000).toLocaleString();
                            }
                        },
                        {
                            title: 'Floor',
                            dataIndex: 'floor',
                            key: 'floor',
                            render: (floor: { id: number, name: string }) => {
                                return `${floor.name} (${floor.id})`;
                            }
                        },
                        {
                            title: 'Walking Speed',
                            dataIndex: 'walkingSpeed',
                            key: 'walkingSpeed',
                        },
                        {
                            title: 'Stride Length',
                            dataIndex: 'strideLength',
                            key: 'strideLength',
                        },
                        {
                            title: 'Stride Frequency',
                            dataIndex: 'strideFrequency',
                            key: 'strideFrequency',
                        },
                        {
                            title: 'Total',
                            dataIndex: 'total',
                            key: 'total',
                        },
                    ]}
                    dataSource={fallRiskProfiles}
                    size={'small'}
                />
            </Card>
        </Col>
    );
}

interface PresenceDetailsViewProps {
    deviceId: string;
}

interface ConnectEvent {
    key: string,
    thingName: string,
    timestamp: number,
    eventType: string,
    ipAddress: string,
}

interface DisconnectEvent {
    key: string,
    thingName: string,
    timestamp: number,
    eventType: string,
    disconnectReason: string,
}

const PresenceDetailsView = ({deviceId}: PresenceDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        // start is 1 year ago start of day
        dayjs().subtract(1, 'week').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    const [presenceEvents, setPresenceEvents] = useState<(ConnectEvent | DisconnectEvent)[]>([]);

    const fetchPresenceEvents = async (startTime: number, endTime: number) => {
        setLoading(true);
        let nextToken = null;
        let response = null;
        let presenceEvents: (ConnectEvent | DisconnectEvent)[] = [];

        do {
            response = await API.graphql<GraphQLQuery<ListPresenceEventsQuery>>({
                query: queries.listPresenceEvents,
                variables: {
                    thingName: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListPresenceEventsQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'Presence events failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listPresenceEvents.items) {
                response.data.listPresenceEvents.items.forEach((item) => {
                    if (item.__typename === "ConnectEvent") {
                        presenceEvents.push({
                            key: item.thingName + item.timestamp,
                            thingName: item.thingName,
                            timestamp: item.timestamp,
                            eventType: item.eventType,
                            ipAddress: item.ipAddress,
                        });
                    } else if (item.__typename === "DisconnectEvent") {
                        presenceEvents.push({
                            key: item.thingName + item.timestamp,
                            thingName: item.thingName,
                            timestamp: item.timestamp,
                            eventType: item.eventType,
                            disconnectReason: item.disconnectReason,
                        });
                    }
                });
            }

            nextToken = response?.data?.listPresenceEvents.nextToken ?? null;
        } while (nextToken !== null);

        setPresenceEvents(presenceEvents);
        setLoading(false);
    }

    useEffect(() => {
        fetchPresenceEvents(Math.floor(dateRange[0]), Math.floor(dateRange[1])).then();
    }, [deviceId]);

    const expandableRowRender = (record: ConnectEvent | DisconnectEvent) => {
        if ('disconnectReason' in record) {
            return (
                <div>
                    <p>Disconnect Reason: {record.disconnectReason}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <p>IP Address: {record.ipAddress}</p>
                </div>
            );
        }
    }

    return (
        <Col span={12}>
            <Card title="Presence Details">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={rangePresets}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        fetchPresenceEvents(Math.floor(dateRange[0]), Math.floor(dateRange[1])).then();
                    }}
                    type={"primary"}
                >
                    Fetch Presence Details
                </Button>
            </Card>
            <Table
                loading={loading}
                columns={[
                    {
                        title: 'Timestamp',
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (timestamp: number) => {
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    {
                        title: 'Event Type',
                        dataIndex: 'eventType',
                        key: 'eventType',
                    },
                ]}
                expandable={{
                    expandedRowRender: expandableRowRender,
                }}
                dataSource={presenceEvents}
                size={'small'}
            />
        </Col>
    );
}


interface MaintenanceDetailsViewProps {
    deviceId: string;
}

interface MaintenanceInfo {
    key: string,
    thingName: string,
    timestamp: number,
    note: string,
    createdAt: number,
    createdBy: string,
}


const AddMaintenanceInfo = ({deviceId, onSuccess}: { deviceId: string, onSuccess: () => void }) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [form] = Form.useForm();

    const addMaintenanceInfo = async (note: string, timestamp: number) => {
        const response = await API.graphql<GraphQLQuery<AddMaintenanceInfoMutation>>({
            query: mutations.addMaintenanceInfo,
            variables: {
                thingName: deviceId,
                note: note,
                timestamp: timestamp,
            } as AddMaintenanceInfoMutationVariables
        }).catch(error => {
            notification.error({
                message: 'Failed to add maintenance info',
                description: error.errors[0].message,
            });
        });
        if (response?.data?.addMaintenanceInfo) {
            notification.success({
                message: 'Maintenance info added successfully',
            });
            onSuccess();
        }
    }

    const onFinish = async (values: any) => {
        await addMaintenanceInfo(values.note, values.timestamp.valueOf());
        form.resetFields();
    }

    return (
        <>
            <Modal
                title="Add Maintenance Info"
                open={open}
                onOk={() => {
                    setOpen(false);
                    form.submit();
                }}
                onCancel={() => {
                    setOpen(false);
                    form.resetFields();
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="add_maintenance_info"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Note"
                        name="note"
                        rules={[{required: true, message: 'Please input a note'}]}
                    >
                        <Input.TextArea
                            maxLength={4096 - 256}
                            showCount
                            autoSize={{minRows: 3, maxRows: 6}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Timestamp"
                        name="timestamp"
                        rules={[{required: true, message: 'Please input a timestamp'}]}
                    >
                        <DatePicker showTime/>
                    </Form.Item>
                </Form>
            </Modal>
            <Button
                onClick={() => {
                    setOpen(true);
                    form.setFieldsValue({
                        'timestamp': dayjs(),
                    });
                }}
                type={"primary"}
            >
                Add Maintenance Info
            </Button>
        </>
    )
}


const MaintenanceDetailsView = ({deviceId}: MaintenanceDetailsViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        dayjs().subtract(1, 'year').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    const [maintenanceEvents, setMaintenanceEvents] = useState<(MaintenanceInfo)[]>([]);

    const fetchMaintenanceEvents = async (startTime: number, endTime: number) => {
        setLoading(true);
        let nextToken = null;
        let response = null;
        let maintenanceEvents: (MaintenanceInfo)[] = [];

        do {
            response = await API.graphql<GraphQLQuery<ListMaintenanceInfoQuery>>({
                query: queries.listMaintenanceInfo,
                variables: {
                    thingName: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as ListMaintenanceInfoQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'Maintenance events failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.listMaintenanceInfo.items) {
                response.data.listMaintenanceInfo.items.forEach((item) => {
                    maintenanceEvents.push({
                        key: item.thingName + item.createdAt,
                        ...item,
                    });
                });
            }

            nextToken = response?.data?.listMaintenanceInfo.nextToken ?? null;

        } while (nextToken !== null);

        setMaintenanceEvents(maintenanceEvents);
        setLoading(false);
    }

    useEffect(() => {
        fetchMaintenanceEvents(Math.floor(dateRange[0]), Math.floor(dateRange[1])).then();
    }, [deviceId]);

    return (
        <Col span={12}>
            <Card title="Maintenance Details">
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        if (dates === null) {
                            return;
                        }
                        let startDay = dates[0]
                        let endDay = dates[1]
                        if (!startDay || !endDay) {
                            return;
                        }
                        setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                    }}
                    defaultValue={[
                        dayjs(dateRange[0]),
                        dayjs(dateRange[1])
                    ]}
                    presets={[
                        {
                            label: 'Last month',
                            value: [dayjs().subtract(1, 'month'), dayjs()],
                        },
                        {
                            label: 'Last 6 months',
                            value: [dayjs().subtract(6, 'month'), dayjs()],
                        },
                        {
                            label: 'Last year',
                            value: [dayjs().subtract(1, 'year'), dayjs()],
                        },
                        {
                            label: 'Last 2 years',
                            value: [dayjs().subtract(2, 'year'), dayjs()],
                        },
                        {
                            label: 'Last 5 years',
                            value: [dayjs().subtract(5, 'year'), dayjs()],
                        },
                    ]}
                />
                <Button
                    style={{
                        marginRight: '10px',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        fetchMaintenanceEvents(Math.floor(dateRange[0]), Math.floor(dateRange[1])).then();
                    }}
                    type={"primary"}
                >
                    Fetch Maintenance Details
                </Button>
                <AddMaintenanceInfo deviceId={deviceId} onSuccess={() => {
                    fetchMaintenanceEvents(Math.floor(dateRange[0]), Math.floor(dateRange[1])).then();
                }}/>
            </Card>
            <Table
                loading={loading}
                columns={[
                    {
                        title: 'Timestamp',
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (timestamp: number) => {
                            return new Date(timestamp).toLocaleString();
                        }
                    },
                    {
                        title: 'Note',
                        dataIndex: 'note',
                        key: 'note',
                        render: (note: string) => {
                            if (note.length > 53) {
                                return note.substring(0, 50) + '...';
                            }
                            return note;
                        }
                    },
                ]}
                dataSource={maintenanceEvents}
                expandable={{
                    expandedRowRender: (record: MaintenanceInfo) => {
                        return (
                            <div>
                                {record.note.split('\n').map((line, index) => {
                                    if (line === '') {
                                        return <br key={index}/>
                                    } else {
                                        return <div key={index}>{line}</div>
                                    }
                                })}
                                <p>Created By: {record.createdBy}</p>
                                <p>Created At: {new Date(record.createdAt).toLocaleString()}</p>
                            </div>
                        );
                    }
                }}
                size={'small'}
            />
        </Col>
    );
}


interface DevicePositionViewProps {
    deviceId: string;
}

interface PositionEstimate {
    latitude: number | null,
    longitude: number | null,
    accuracy: number | null,
    confidence: number | null,
    timestamp: string,
}

const DevicePositionView = ({deviceId}: DevicePositionViewProps) => {
    const {notification} = App.useApp();
    const [dateRange, setDateRange] = useState<[number, number]>([
        dayjs().subtract(1, 'year').startOf('day').valueOf(),
        // end is today end of day
        dayjs().endOf('day').valueOf()
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [positionEstimates, setPositionEstimates] = useState<PositionEstimate[]>([]);

    const loadPositionEstimates = async (startTime: number, endTime: number) => {
        setLoading(true);
        let nextToken = null;
        let response = null;
        let positionEstimates: PositionEstimate[] = [];

        do {
            response = await API.graphql<GraphQLQuery<GetPositionEstimatesQuery>>({
                query: queries.getPositionEstimates,
                variables: {
                    heelableId: deviceId,
                    startTime: startTime,
                    endTime: endTime,
                    page: {
                        nextToken: nextToken,
                    }
                } as GetPositionEstimatesQueryVariables
            }).catch(error => {
                notification.error({
                    message: 'Position estimates failed to fetch',
                    description: error.errors[0].message,
                });
            });

            if (response?.data?.getPositionEstimates.items) {
                response.data.getPositionEstimates.items.forEach((item) => {
                    positionEstimates.push({
                        latitude: item.latitude ?? null,
                        longitude: item.longitude ?? null,
                        accuracy: item.accuracy ?? null,
                        confidence: item.confidence ?? null,
                        timestamp: item.timestamp,
                    });
                });
            }

            nextToken = response?.data?.getPositionEstimates.nextToken ?? null;
        } while (nextToken !== null);

        setPositionEstimates(positionEstimates);
        setLoading(false);
    }

    const mapTab = (
        <MapContainer
            center={[52.1326, 5.2913]}
            zoom={7}
            style={{
                height: '600px',
                width: '100%',
            }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[51.4453443, 5.4594115]}>
                <Popup>
                    The Office
                </Popup>
            </Marker>
            {positionEstimates.map((position, index) => {
                if (position.latitude && position.longitude) {
                    return (
                        <Marker
                            key={index}
                            position={[position.latitude, position.longitude]}
                        >
                            <Popup>
                                {/* Nice display of timestamp in human time*/}
                                <p>Timestamp: {dayjs(position.timestamp).format('YYYY-MM-DD HH:mm:ss')}<br/>
                                    Latitude: {position.latitude}<br/>
                                    Longitude: {position.longitude}<br/>
                                    Accuracy: {position.accuracy}<br/>
                                    Confidence: {position.confidence}
                                </p>
                            </Popup>
                        </Marker>
                    );
                }
                return <></>
            })}
        </MapContainer>
    )

    const tableTab = (
        <Table
            loading={loading}
            columns={[
                {
                    title: 'Timestamp',
                    dataIndex: 'timestamp',
                    key: 'timestamp',
                    render: (timestamp: string) => {
                        return new Date(timestamp).toLocaleString();
                    },
                    sorter: (a: PositionEstimate, b: PositionEstimate) => {
                        return a.timestamp > b.timestamp ? 1 : -1;
                    }
                },
                {
                    title: 'Latitude',
                    dataIndex: 'latitude',
                    key: 'latitude',
                    render: (latitude: number | null) => {
                        if (latitude === null) {
                            return 'N/A';
                        }
                        return latitude;
                    }
                },
                {
                    title: 'Longitude',
                    dataIndex: 'longitude',
                    key: 'longitude',
                    render: (longitude: number | null) => {
                        if (longitude === null) {
                            return 'N/A';
                        }
                        return longitude;
                    }
                },
                {
                    title: 'Accuracy',
                    dataIndex: 'accuracy',
                    key: 'accuracy',
                    render: (accuracy: number | null) => {
                        if (accuracy === null) {
                            return 'N/A';
                        }
                        return accuracy;
                    }
                },
                {
                    title: 'Confidence',
                    dataIndex: 'confidence',
                    key: 'confidence',
                    render: (confidence: number | null) => {
                        if (confidence === null) {
                            return 'N/A';
                        }
                        return confidence;
                    }
                },
            ]}
            dataSource={positionEstimates}
            size={'small'}
        />
    )

    return (
        <>
            <Col span={24}>
                <Card title="Device Position Estimates">
                    <Alert
                        message="Device Position Estimates are not fetched automatically. Positions might be inaccurate or missing."
                        type="info"
                        showIcon
                        style={{marginBottom: '10px'}}
                    />
                    <RangePicker
                        onChange={(dates, dateStrings) => {
                            if (dates === null) {
                                return;
                            }
                            let startDay = dates[0]
                            let endDay = dates[1]
                            if (!startDay || !endDay) {
                                return;
                            }
                            setDateRange([startDay.startOf('day').valueOf(), endDay.endOf('day').valueOf()]);
                        }}
                        defaultValue={[
                            dayjs(dateRange[0]),
                            dayjs(dateRange[1])
                        ]}
                        presets={[
                            {
                                label: 'Last month',
                                value: [dayjs().subtract(1, 'month'), dayjs()],
                            },
                            {
                                label: 'Last 6 months',
                                value: [dayjs().subtract(6, 'month'), dayjs()],
                            },
                            {
                                label: 'Last year',
                                value: [dayjs().subtract(1, 'year'), dayjs()],
                            },
                            {
                                label: 'Last 2 years',
                                value: [dayjs().subtract(2, 'year'), dayjs()],
                            },
                            {
                                label: 'Last 5 years',
                                value: [dayjs().subtract(5, 'year'), dayjs()],
                            },
                        ]}
                    />
                    <Button
                        style={{
                            marginRight: '10px',
                            marginLeft: '10px'
                        }}
                        onClick={() => {
                            loadPositionEstimates(dateRange[0], dateRange[1]).then();
                        }}
                        type={"primary"}
                        loading={loading}
                    >
                        Fetch Position Estimates
                    </Button>
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size="small"
                        items={[
                            {
                                key: '1',
                                label: 'Table',
                                children: tableTab,
                            },
                            {
                                key: '2',
                                label: 'Map',
                                children: mapTab,
                            },
                        ]}
                    />


                </Card>
            </Col>
        </>
    );
}


interface DeviceDetailsViewProps {
    deviceId: string;
}

const DeviceDetailsView = ({deviceId}: DeviceDetailsViewProps) => {

    return (
        <>
            <Row gutter={[16, 16]}>
                <BatteryDetailsView deviceId={deviceId}/>
                <DeviceInfo deviceId={deviceId}/>
                <PresenceDetailsView deviceId={deviceId}/>
                <MaintenanceDetailsView deviceId={deviceId}/>
                <FootstepDetailsView deviceId={deviceId}/>
                <UserWearableLinkDetailsView deviceId={deviceId}/>
                <TenantWearableLeaseDetailsView deviceId={deviceId}/>
                <FallRiskProfileDetailsView deviceId={deviceId}/>
                <TelemetryDetailsView deviceId={deviceId}/>
                <DevicePositionView deviceId={deviceId}/>
            </Row>
        </>
    );
}


const getDeviceFromPath = (location: any) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('device_id') ?? null;
}

const DeviceDiagnosticsView = () => {
    const location = useLocation();

    const device = getDeviceFromPath(location);

    const [selectedDevice, setSelectedDevice] = useState<string | null>(device);

    const changeSelectedDevice = (deviceId: string) => {
        setSelectedDevice(deviceId);

        // update the url (without reloading the page)
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('device_id', deviceId);
        window.history.pushState({}, '', newUrl.toString());
    }

    // On navigation change (back/forward) update the selected device
    useEffect(() => {
        console.log(location);
        console.log('test');
        const device = getDeviceFromPath(location);
        if (device !== selectedDevice && device !== null) {
            setSelectedDevice(device);
        }
    }, [location, window.open]);

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ThingSelector
                        onSelect={(deviceId) => {
                            changeSelectedDevice(deviceId)
                        }}
                        defaultDevice={device || ''}
                    />
                </Col>
                <Col span={24}>
                    {selectedDevice && <DeviceDetailsView deviceId={selectedDevice}/>}
                </Col>
            </Row>
        </>
    );
}


export default DeviceDiagnosticsView;
