import {CreationStep} from "../CreationStep";
import {Button, Form, Select, Tag} from "antd";
import React, {useEffect} from "react";
import {GraphQLQuery} from "@aws-amplify/api";
import {API} from "aws-amplify";
import {ListAllFloorsQuery} from "../../../utils/graphql/queries";
import * as queries from "../../../utils/graphql/queries";


interface Floor {
  id: number;
  name: string;
}

export const FloorInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();

  const [floors, setFloors] = React.useState<Floor[]>([]);
  const [selectedFloors, setSelectedFloors] = React.useState<number[]>([]);

  const loadFloors = async () => {
    const tmpFloors: Floor[] = [];
    let nextToken = null;

    do {
      const response: any = await API.graphql<GraphQLQuery<ListAllFloorsQuery>>({
        query: queries.listAllFloors,
        variables: {
          page: {
            nextToken: nextToken,
            limit: 5,
          }
        }
      });

      response.data?.listAllFloors.items.forEach((item: any) => {
        tmpFloors.push({
          id: item.id,
          name: item.name,
        });
      });

      if (response.data?.listAllFloors?.nextToken) {
        nextToken = response.data?.listAllFloors?.nextToken;
      } else {
        nextToken = null;
      }
    } while (nextToken);

    setFloors(tmpFloors);
  }

  useEffect(() => {
    loadFloors().then();
  }, [instructions]);

  const onFormFinish = (values: any) => {
    if (instructions.groups) {
      console.log(values);
      instructions.groups[0].floorsIds = values.floors;
    }
    onStepComplete(instructions);
  }

  const filterFloors = (input: string, option: any) => {
    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={{
          floors: instructions.groups ? instructions.groups[0].floorsIds : [],
          }}
      >
        <Form.Item
          label="Floors"
          name="floors"
        >
          <Select
            mode="multiple"
            placeholder="select floors"
            value={selectedFloors}
            onChange={setSelectedFloors}
            style={{width: '100%'}}
            options={floors.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            showSearch={true}
            optionFilterProp="children"
            filterOption={filterFloors}
            // Show the label for the selected floors instead of the value
            tagRender={(props) => {
              const floor = floors.find((floor) => floor.id === props.value);
              const floorText = floor ? floor.name : props.value;
              return <Tag key={props.value}>{floorText}</Tag>
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
      <Button
        onClick={onStepBack}
      >
        Back
      </Button>
    </>
  )
}
