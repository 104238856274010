/* eslint-disable react-hooks/exhaustive-deps */
import { Period, ReportType } from './CustomerInsightsView';
import * as queries from "../../utils/graphql/queries";
import moment, { Moment } from 'moment';

/**
 * This file is a collection of constants and helper methods for grouping and filtering data
 * used for generating customer insight reports.
 */

export const YEAR_TO_DATE_STATS_FIELD_NAME = 'Jaar';
export const TOTAL_STATS_FIELD_NAME = 'Totaal';
export const AGGREGATE_STATS_FIELD_NAME = 'Gecombineerde periodes';
export const LOW_RISK_FIELD = 'Laag valrisico';
export const MEDIUM_RISK_FIELD = 'Verhoogd valrisico';
export const HIGH_RISK_FIELD = 'Hoog valrisico';
export const FRP_CATEGORY_FIELD_NAMES = [LOW_RISK_FIELD, MEDIUM_RISK_FIELD, HIGH_RISK_FIELD];
const MEDIUM_RISK_GROUP_THRESHOLD = 6.78571;
const LOW_RISK_GROUP_THRESHOLD = 8.57143;
export const NO_LOCATION_NAME = 'Geen';

export enum Months {
    JAN,
    FEB,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUG,
    SEPT,
    OCT,
    NOV,
    DEC
}

export interface FloorWithColor extends queries.Floor {
    color: string
}

export interface LocationWithColor extends queries.ClientLocation {
    color: string
}

export type GenderBreakdown = {
    f: { count: number, percentage: number },
    m: { count: number, percentage: number },
    x: { count: number, percentage: number },
    missingData: { count: number, percentage: number },
}

export type AgeStats = {
    avgAge: number,
    sd: number
}

export type LocationClientCount = {
    [locationName: string]: number
}

export function generateRandomColor(colorsUsed: string[]) {
    const letters = '0123456789ABCDEF';
    let color;
    do {
        color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
    } while (colorsUsed.includes(color));
    colorsUsed.push(color);
    return color;
}

export const extractClientsFromMeasurements = (measurements: queries.FallRiskProfile[] | queries.UserMeasurement[]) => {
    const clients: queries.DatabaseUserDetails[] = [];

    for (const measurement of measurements) {
        const client = measurement.user;
        const isClientUnique = !clients.some((c) => c.id === client.id);
        if (isClientUnique) {
            clients.push(client);
        }
    };

    return clients;
}

/**
 * Not clear from the template whether we want to include all floors for the selected groups
 * or just the floors that have been utilized. For now, the floor count on the first page of the report will be only for utilized floors.
 * TODO: discuss.
 */
export const extractFloorsFromFRPs = (frps: queries.FallRiskProfile[]) => {
    const floors: queries.Floor[] = [];

    for (const frp of frps) {
        const floor = frp.floor;
        const isFloorUnique = !floors.some((f) => f.id === floor.id);
        if (isFloorUnique) {
            floors.push(floor);
        }
    };

    return floors;
}

export function getStandardDeviation(array: any[]) {
    const arrayLength = array.length;
    if (arrayLength === 0) return 0;
    const mean = array.reduce((a, b) => a + b) / arrayLength;
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / arrayLength);
}

export const getFRPsBetweenTimes = (beginTime: moment.Moment, endTime: moment.Moment, frps: queries.FallRiskProfile[]) => {
    if (beginTime !== null && endTime !== null) {
        return frps.filter(frp => beginTime <= moment(frp.createdAt * 1000) && moment(frp.createdAt * 1000) <= endTime);
    }
    // If the timestamps are null, then we are probably trying to get all FRPs (Total) 
    return frps;
}

export const getUserMeasurementsBetweenTimes = (beginTime: moment.Moment, endTime: moment.Moment, measurements: queries.UserMeasurement[]) => {
    if (beginTime !== null && endTime !== null) {
        return measurements.filter(measurement => beginTime <= moment((measurement.timestamp) as any) && moment((measurement.timestamp) as any) <= endTime);
    }
    // If the timestamps are null, then we are probably trying to get all FRPs (Total)
    return measurements;
}

export const getClientsForPeriod = (period: Period, measurements: queries.FallRiskProfile[] | queries.UserMeasurement[], reportType: ReportType) => {
    const filteredByDate = reportType === ReportType.FRP ?
        getFRPsBetweenTimes(period.dates[0], period.dates[1], measurements as queries.FallRiskProfile[]) :
        getUserMeasurementsBetweenTimes(period.dates[0], period.dates[1], measurements as queries.UserMeasurement[]);
    return extractClientsFromMeasurements(filteredByDate);
}

export const getAvgClientCountPerPeriod = (periods: Period[], frps: queries.FallRiskProfile[], reportType: ReportType) => {
    const clientCountPerPeriod = periods.map(period => getClientsForPeriod(period, frps, reportType).length);
    return clientCountPerPeriod.reduce((a, b) => a + b) / (clientCountPerPeriod.length || 1);
}

export const getMeasurementsBetweenTimesGroupedByClient = (beginTime: moment.Moment, endTime: moment.Moment, measurements: queries.FallRiskProfile[] | queries.UserMeasurement[], reportType: ReportType) => {
    let measurementsBetweenTimes;
    if (beginTime !== null && endTime !== null) {
        measurementsBetweenTimes = reportType === ReportType.FRP ?
            getFRPsBetweenTimes(beginTime, endTime, measurements as queries.FallRiskProfile[])
            : getUserMeasurementsBetweenTimes(beginTime, endTime, measurements as queries.UserMeasurement[]);
    } else {
        // For Total
        measurementsBetweenTimes = measurements;
    }
    const groupByClient = (accumulator: { [clientId: number]: any[] }, measurement: any) => {
        accumulator[measurement.user.id] = (measurement.user.id in accumulator) ? [...accumulator[measurement.user.id], measurement] : [measurement]
        return accumulator;
    }

    const frpsBetweenTimesGroupedByClient = (measurementsBetweenTimes as any[]).reduce(groupByClient, {});
    const frpCountBetweenTimesPerClient: { [clientId: number]: number } = {};
    Object.entries(frpsBetweenTimesGroupedByClient).forEach(([clientId, frps]) => frpCountBetweenTimesPerClient[parseInt(clientId)] = frps.length)
    return frpCountBetweenTimesPerClient;
}

export const reduceFRPsByFloor = (accumulator: { [floorId: number]: queries.FallRiskProfile[] }, frp: queries.FallRiskProfile) => {
    accumulator[frp.floor.id] = (frp.floor.id in accumulator) ? [...accumulator[frp.floor.id], frp] : [frp]
    return accumulator;
}

export const getFRPsPerPeriod = (periods: Period[], measurements: queries.FallRiskProfile[] | queries.UserMeasurement[], reportType: ReportType) => {
    const frpsPerPeriod: { [periodName: string]: queries.FallRiskProfile[] | queries.UserMeasurement[] } = {};
    periods.forEach(p => {
        frpsPerPeriod[p.name] = reportType === ReportType.FRP ?
            getFRPsBetweenTimes(p.dates[0], p.dates[1], measurements as queries.FallRiskProfile[])
            : getUserMeasurementsBetweenTimes(p.dates[0], p.dates[1], measurements as queries.UserMeasurement[]);
    });
    return frpsPerPeriod;
}

export const getFRPsPerFloorPerPeriod = (periods: Period[], frps: queries.FallRiskProfile[]) => {
    // Floor statistics are only for FRPs
    const frpsPerPeriod = getFRPsPerPeriod(periods, frps, ReportType.FRP) as { [periodName: string]: queries.FallRiskProfile[] };

    const frpsPerFloorPerPeriod: { [periodName: string]: { [floorId: number]: queries.FallRiskProfile[] } } = {};

    Object.entries(frpsPerPeriod).forEach(([periodName, frpsPerPeriod]) => {
        frpsPerFloorPerPeriod[`${periodName}`] = frpsPerPeriod.reduce(reduceFRPsByFloor, {});
    });
    return frpsPerFloorPerPeriod;
}

export const getClientCountPerFloorPerPeriod = (periods: Period[], frps: queries.FallRiskProfile[]) => {
    const frpsPerFloorPerPeriod = getFRPsPerFloorPerPeriod(periods, frps);
    const clientCountPerFloorPerPeriod: { [periodName: string]: { [floorId: number]: number } } = {};

    Object.entries(frpsPerFloorPerPeriod).forEach(([periodName, frpsGroupedByFloor]) => {
        const floorGroups: { [floorId: number]: number } = {};
        Object.entries(frpsGroupedByFloor).forEach(([floorId, frpsPerFloor]) => {
            const clientsPerFloor = extractClientsFromMeasurements(frpsPerFloor);
            floorGroups[parseInt(floorId)] = clientsPerFloor.length;
        });
        clientCountPerFloorPerPeriod[periodName] = floorGroups;
    })

    return clientCountPerFloorPerPeriod;
}

export function getClientsPerLocationPerPeriod(
    clientsPerPeriod: { [periodName: string]: queries.DatabaseUserDetails[] },
    locations: queries.ClientLocation[]
) {
    const clientCountPerLocationPerPeriod: { [periodName: string]: { [locationName: string]: number } } = {};

    Object.entries(clientsPerPeriod).forEach(([periodName, clients]) => {
        clientCountPerLocationPerPeriod[periodName] = {};
        // First set the client count for each available location to 0
        locations.forEach(loc => {
            clientCountPerLocationPerPeriod[periodName][loc.name] = 0;
        });
        // Then count the clients per location
        clients.forEach(client => {
            clientCountPerLocationPerPeriod[periodName][client?.userInfo?.location?.name || 'Geen'] += 1
        })
    });

    return clientCountPerLocationPerPeriod;
}

export const getFRPCountPerClientPerPeriod = (periods: Period[], frps: queries.FallRiskProfile[] | queries.UserMeasurement[], getPeriodByName: Function, reportType: ReportType) => {
    const groupedByPeriod = getFRPsPerPeriod(periods, frps, reportType);

    const frpCountPerClientPerPeriod: { [periodName: string]: { [clientId: number]: number } } = {};

    Object.entries(groupedByPeriod).forEach(([periodName, frpsPerPeriod]) => {
        const period = getPeriodByName(periodName);
        frpCountPerClientPerPeriod[periodName] = getMeasurementsBetweenTimesGroupedByClient(period.dates[0], period.dates[1], frpsPerPeriod, reportType);
    });
    return frpCountPerClientPerPeriod;
}

export const getClientCountPerFRPCountPerPeriod = (
    periods: Period[],
    frpCountPerClientPerPeriod: { [periodName: string]: { [clientId: number]: number } }
) => {
    // Those will be translated in the report. Should probably extract them somewhere. TODO
    const frpCountColumnValues: string[] = [
        '1 meting',
        '2 metingen',
        '3 metingen',
        '4 metingen',
        '5 metingen',
        '>5 metingen'
    ];

    let tableRows: any[] = frpCountColumnValues.map((numberOfMeasurements, key) => {
        const defaultFieldPerPeriod = periods.reduce((acc: { [periodName: string]: number }, current) => {
            acc[current.name] = 0;
            return acc;
        }, {});
        return {
            key: key + 1,
            numberOfMeasurements,
            ...defaultFieldPerPeriod
        }
    });

    function increaseUserCountForRowWithIndex(idx: number, periodName: string) {
        tableRows = [
            ...tableRows.slice(0, idx),
            { ...tableRows[idx], [periodName]: tableRows[idx][periodName] + 1 },
            ...tableRows.slice(idx + 1)
        ]
    }

    Object.entries(frpCountPerClientPerPeriod).forEach(([periodName, frpCountPerClient]) => {
        Object.entries(frpCountPerClient).forEach(([clientId, frpCount]) => {
            if (frpCount < 6) {
                increaseUserCountForRowWithIndex(frpCount - 1, periodName);
            } else {
                increaseUserCountForRowWithIndex(5, periodName);
            }
        });
    });

    return tableRows;
}

function getDate(measurement: queries.FallRiskProfile | queries.UserMeasurement, reportType: ReportType) {
    return reportType === ReportType.FRP ? (measurement as queries.FallRiskProfile).createdAt * 1000 : parseInt((measurement as queries.UserMeasurement).timestamp);
}

function getScore(measurement: queries.FallRiskProfile | queries.UserMeasurement, reportType: ReportType) {
    return reportType === ReportType.FRP ? (measurement as queries.FallRiskProfile).total : (measurement as queries.UserMeasurement).value;
}

/**
 * Since a client can be measured multiple times in a period we sometimes need only their latest best measurement for that period.
 */
function getLatestBestClientFRPsForLastDayOfPeriod(sortedFRPsForPeriod: queries.FallRiskProfile[] | queries.UserMeasurement[], reportType: ReportType) {
    let latestMeasurementsOfClients: queries.FallRiskProfile[] | queries.UserMeasurement[] = [];
    sortedFRPsForPeriod.forEach(currentFRP => {
        // Only add an FRP to latestFRPsOfClients if there isn't one for the current FRP's client.
        // This way we end up with a single (latest) FRP per client.
        const latestMeasurementForAClient = (latestMeasurementsOfClients as any[]).find((filteredFRP: any) => filteredFRP?.user?.id === currentFRP.user.id);
        const currentMeasurementIsLatestForClient = latestMeasurementForAClient === undefined;
        if (currentMeasurementIsLatestForClient) {
            latestMeasurementsOfClients.push(currentFRP as any);
        } else {
            // This means we have added the latest FRP of that client to the array already. However, this
            // does not ensure that's the latest BEST frp for that client. So, while still in the loop, we check
            // if the current FRP has a higher total than the one currently in the latestFRPsOfClients list.
            // If the current FRP is better, we replace it in the list.

            const currentFRPIsLatestAndBestOfClient = getScore(latestMeasurementForAClient, reportType) < getScore(currentFRP, reportType);
            const currentFRPIsInTheSameDayAsLatest = moment(getDate(currentFRP, reportType)).format('DD-MM-YYYY') === moment(getDate(latestMeasurementForAClient, reportType)).format('DD-MM-YYYY');
            if (currentFRPIsLatestAndBestOfClient && currentFRPIsInTheSameDayAsLatest) {
                const indexOfLatestNotBest = latestMeasurementsOfClients.indexOf(latestMeasurementForAClient as any);
                latestMeasurementsOfClients = [
                    ...latestMeasurementsOfClients.slice(0, indexOfLatestNotBest) as any[],
                    currentFRP,
                    ...latestMeasurementsOfClients.slice(indexOfLatestNotBest + 1) as any[]
                ];
            }
        }
    });
    return latestMeasurementsOfClients;
}

/**
 * First groups FRPs by period and filters only the latest (best) FRPs of clients for each period. That way we end up with one client FRP per period.
 * FRPs are then further grouped by category (low, med, high risk), which allows counting the number of clients per FRP category.
 */
export const getClientCountPerFRPCategoryPerPeriod = (periods: Period[], measurements: queries.FallRiskProfile[] | queries.UserMeasurement[], reportType: ReportType) => {
    const groupedByPeriod = getFRPsPerPeriod(periods, measurements, reportType);

    const clientCountPerFRPCategoryPerPeriod: {
        [periodName: string]: {
            name: string, clientCount: number
        }[]
    } = {};

    // After grouping the FRPs per period, we filter the FRPs to only latest per client.
    Object.entries(groupedByPeriod).forEach(([periodName, frpsInPeriod]) => {
        // Sort the FRPs from newest to oldest
        const sortedByDateDesc = frpsInPeriod.sort((a, b) => getDate(b, reportType) - getDate(a, reportType));
        const latestFRPsOfClients = getLatestBestClientFRPsForLastDayOfPeriod(sortedByDateDesc, reportType);
        // Group by FRP categories and count the FRPs in each category (essentially counting the clients too).
        const clientCountPerFRPCategory: { name: string, clientCount: number }[] = FRP_CATEGORY_FIELD_NAMES.map((c => (
            { name: c, clientCount: 0 }
        )));
        latestFRPsOfClients.forEach(frp => {
            const score = getScore(frp, reportType);
            if (reportType === ReportType.FRP) {
                if (score >= LOW_RISK_GROUP_THRESHOLD) {
                    clientCountPerFRPCategory[0].clientCount++;
                } else if (score >= MEDIUM_RISK_GROUP_THRESHOLD) {
                    clientCountPerFRPCategory[1].clientCount++;
                } else {
                    clientCountPerFRPCategory[2].clientCount++;
                }
            } else {
                clientCountPerFRPCategory[score].clientCount++;
            }
        });
        // Append category breakdown to the period.
        clientCountPerFRPCategoryPerPeriod[periodName] = clientCountPerFRPCategory;
    });
    return clientCountPerFRPCategoryPerPeriod;
}

export const getClientCountPerFRPCategoryPerLocation = (frps: queries.FallRiskProfile[], period: Period) => {
    const clientCountPerFRPCategoryPerLocation: {
        [periodName: string]: {
            name: string, clientCount: number
        }[]
    } = {};

    // Aggregate period only
    const frpsForGivenPeriod = getFRPsPerPeriod([period], frps, ReportType.FRP)[period.name] as queries.FallRiskProfile[];
    // Sort the FRPs from newest to oldest and get latest measurement per client
    const sortedByDateDesc = frpsForGivenPeriod.sort((a, b) => b.createdAt - a.createdAt);
    const latestFRPsOfClients = getLatestBestClientFRPsForLastDayOfPeriod(sortedByDateDesc, ReportType.FRP) as queries.FallRiskProfile[];
    // Then group them by client location (current location, not considering user info history)
    let frpsGroupedByClientLocation: { [locationName: string]: queries.FallRiskProfile[] } = {};
    // Loop through FRPs and distribute them by locations. Locations with no measurements will not produce a pie chart.
    latestFRPsOfClients.forEach(frp => {
        const locationName = frp.user.userInfo?.location?.name || NO_LOCATION_NAME;
        frpsGroupedByClientLocation[locationName] = frpsGroupedByClientLocation[locationName]
            ? [...frpsGroupedByClientLocation[locationName], frp]
            : [frp];
    });

    // After grouping the FRPs per location, group by FRP categories and count the FRPs in each category.
    Object.entries(frpsGroupedByClientLocation).forEach(([locationName, frpsOnLocation]) => {
        const clientCountPerFRPCategory: { name: string, clientCount: number }[] = FRP_CATEGORY_FIELD_NAMES.map((c => (
            { name: c, clientCount: 0 }
        )));
        frpsOnLocation.forEach(frp => {
            if (frp.total >= LOW_RISK_GROUP_THRESHOLD) {
                clientCountPerFRPCategory[0].clientCount++;
            } else if (frp.total >= MEDIUM_RISK_GROUP_THRESHOLD) {
                clientCountPerFRPCategory[1].clientCount++;
            } else {
                clientCountPerFRPCategory[2].clientCount++;
            }
        });
        // Append category breakdown to the location.
        clientCountPerFRPCategoryPerLocation[locationName] = clientCountPerFRPCategory;
    });

    return clientCountPerFRPCategoryPerLocation;
}

export const getClientCountPerFRPCategoryPerFloorPerPeriod = (periods: Period[], frps: queries.FallRiskProfile[]) => {
    const groupedByPeriod = getFRPsPerPeriod(periods, frps, ReportType.FRP) as { [periodName: string]: queries.FallRiskProfile[] };

    const clientCountPerFRPCategoryPerFloorPerPeriod: {
        [periodName: string]: {
            name: string,
            [LOW_RISK_FIELD]: number,
            [MEDIUM_RISK_FIELD]: number,
            [HIGH_RISK_FIELD]: number
        }[]
    } = {};

    // After grouping the FRPs per period, we group them by floorId, and finally filter the FRPs to only latest per client.
    Object.entries(groupedByPeriod).forEach(([periodName, frpsInPeriod]) => {
        const byFloor = frpsInPeriod.reduce(reduceFRPsByFloor, {});
        const floors = extractFloorsFromFRPs(frps);
        const dataPerFloor = floors.map(f => ({
            name: f.name,
            [LOW_RISK_FIELD]: 0,
            [MEDIUM_RISK_FIELD]: 0,
            [HIGH_RISK_FIELD]: 0
        }));

        function increaseClientCountForFloor(floorName: string, frpCategoryName: typeof LOW_RISK_FIELD | typeof MEDIUM_RISK_FIELD | typeof HIGH_RISK_FIELD) {
            const objToUpdate = dataPerFloor.find((f: any) => f.name === floorName)!;
            const idxToUpdate = dataPerFloor.indexOf(objToUpdate);
            dataPerFloor[idxToUpdate] = {
                ...dataPerFloor[idxToUpdate],
                [frpCategoryName]: dataPerFloor[idxToUpdate][frpCategoryName] + 1
            }
        }

        Object.entries(byFloor).forEach(([floorId, frpsOnFloor]) => {
            const currentFloorName = floors.find(f => f.id === parseInt(floorId))!.name;
            // Sort the FRPs from newest to oldest
            const sortedByDateDesc = frpsOnFloor.sort((a, b) => b.createdAt - a.createdAt);
            const latestFRPsOfClients = getLatestBestClientFRPsForLastDayOfPeriod(sortedByDateDesc, ReportType.FRP) as queries.FallRiskProfile[];
            sortedByDateDesc.forEach(frp => {
                // Only add an FRP to latestFRPsOfClients if there isn't one for the current FRP's client.
                // This way we end up with a single (latest) FRP per client.
                if (latestFRPsOfClients.find(filteredFRP => filteredFRP.user.id === frp.user.id) === undefined) {
                    latestFRPsOfClients.push(frp);
                }
            });
            latestFRPsOfClients.forEach(frp => {
                if (frp.total >= LOW_RISK_GROUP_THRESHOLD) {
                    increaseClientCountForFloor(currentFloorName, LOW_RISK_FIELD);
                } else if (frp.total >= MEDIUM_RISK_GROUP_THRESHOLD) {
                    increaseClientCountForFloor(currentFloorName, MEDIUM_RISK_FIELD);
                } else {
                    increaseClientCountForFloor(currentFloorName, HIGH_RISK_FIELD);
                }
            });
        })

        // Append category breakdown to the period.
        clientCountPerFRPCategoryPerFloorPerPeriod[periodName] = dataPerFloor;
    });
    return clientCountPerFRPCategoryPerFloorPerPeriod;
}

/**
 * Based on the Moment parameter, determines the display name of the quarter inside the calendar popup.
 * The format we want is, e.g., Q1 2023
 */
export function getNameOfPreset(startOfPresetPeriod: Moment) {
    let Q;
    switch (startOfPresetPeriod.month()) {
        case Months.JAN:
            Q = 1
            break;
        case Months.APRIL:
            Q = 2
            break;
        case Months.JULY:
            Q = 3
            break;

        case Months.OCT:
            Q = 4
            break;
        default:
            break;
    }
    return `Q${Q} ${startOfPresetPeriod.year()}`
}

/**
 * Set the default period to the latest completed quarter (3mo) of this year.
 */
export function determineLatestCompletedQuarter() {
    const endOfSeptember = moment().month(Months.SEPT).endOf('month');
    const endOfJune = moment().month(Months.JUNE).endOf('month');
    const endOfMarch = moment().month(Months.MARCH).endOf('month');

    const now = moment();
    if (now.isAfter(endOfSeptember)) {
        const beginningOfJuly = moment().month(Months.JULY).startOf('month');
        return [beginningOfJuly, endOfSeptember];
    }
    else if (now.isAfter(endOfJune)) {
        const beginningOfApril = moment().month(Months.APRIL).startOf('month');
        return [beginningOfApril, endOfJune];
    }
    else if (now.isAfter(endOfMarch)) {
        const beginningOfJanuary = moment().month(Months.JAN).startOf('month');
        return [beginningOfJanuary, endOfMarch];
    }

    // No completed quarter for this year, so we take last year's Q4.
    const beginningOfOctober = moment().month(Months.OCT).subtract(1, 'year').startOf('month');
    const endOfDecember = moment().month(Months.DEC).subtract(1, 'year').endOf('month');
    return [beginningOfOctober, endOfDecember];
}

export function getYearToDatePeriod(periods: Period[]) {
    const sortedBeginTimesMs = periods
        .map(p => p.dates[0].valueOf())
        .sort((a, b) => a - b);
    const sortedEndTimesMs = periods
        .map(p => p.dates[1].valueOf())
        .sort((a, b) => b - a);
    const earliestBeginTime = moment(sortedBeginTimesMs[0]).startOf('year');
    const latestEndTime = moment(sortedEndTimesMs[0]).endOf('day');
    return { dates: [earliestBeginTime, latestEndTime], name: YEAR_TO_DATE_STATS_FIELD_NAME } as Period
}

/**
 * Get an aggregate period, which encompasses all selected periods. For readability, we set its `name` to
 * the value of AGGREGATE_STATS_FIELD_NAME.
 */
export function getAggregatePeriod(periods: Period[]) {
    const sortedBeginTimesMs = periods
        .map(p => p.dates[0].valueOf())
        .sort((a, b) => a - b);
    const sortedEndTimesMs = periods
        .map(p => p.dates[1].valueOf())
        .sort((a, b) => b - a);
    const earliestBeginTime = moment(sortedBeginTimesMs[0]).startOf('day');
    const latestEndTime = moment(sortedEndTimesMs[0]).endOf('day');
    return { dates: [earliestBeginTime, latestEndTime], name: AGGREGATE_STATS_FIELD_NAME } as Period
}

export function getClientCountPerPeriod(clientsPerPeriod: { [periodName: string]: queries.DatabaseUserDetails[] }) {
    const clientCountPerPeriod: { [periodName: string]: number } = {};
    Object.entries(clientsPerPeriod).forEach(([periodName, clientsForPeriod]) => {
        clientCountPerPeriod[periodName] = clientsForPeriod.length;
    });
    return clientCountPerPeriod;
}

export function getClientGenderBreakdownPerPeriod(clientsPerPeriod: { [periodName: string]: queries.DatabaseUserDetails[] }) {
    const clientGenderBreakdownPerPeriod: { [periodName: string]: GenderBreakdown } = {};
    const infoIsMissing = (client: queries.DatabaseUserDetails) => client.userInfo === null || client.userInfo === undefined;

    Object.entries(clientsPerPeriod).forEach(([periodName, clientsForPeriod]) => {
        const femaleClientsCount = clientsForPeriod.filter(c => c.userInfo?.gender === 'f').length;
        const maleClientsCount = clientsForPeriod.filter(c => c.userInfo?.gender === 'm').length;
        const unspecifiedClientsCount = clientsForPeriod.filter(c => c.userInfo?.gender === 'x').length;
        const missingDataClientsCount = clientsForPeriod.filter(c => infoIsMissing(c) || !c.userInfo!.gender).length;

        clientGenderBreakdownPerPeriod[periodName] = {
            f: {
                count: femaleClientsCount,
                percentage: (femaleClientsCount * 100) / (clientsForPeriod.length || 1)
            },
            m: {
                count: maleClientsCount,
                percentage: (maleClientsCount * 100) / (clientsForPeriod.length || 1)
            },
            x: {
                count: unspecifiedClientsCount,
                percentage: (unspecifiedClientsCount * 100) / (clientsForPeriod.length || 1)
            },
            missingData: {
                count: missingDataClientsCount,
                percentage: (missingDataClientsCount * 100) / (clientsForPeriod.length || 1)
            }
        };
    });
    return clientGenderBreakdownPerPeriod;
}

export function getClientAgesPerPeriod(clientsPerPeriod: { [periodName: string]: queries.DatabaseUserDetails[] }) {
    const clientAgesPerPeriod: { [periodName: string]: AgeStats } = {};
    Object.entries(clientsPerPeriod).forEach(([periodName, clientsForPeriod]) => {
        const agesOfClientsWithAge = clientsForPeriod
            .filter(c => c.userInfo?.age !== null && c.userInfo?.age !== undefined)
            .map(c => c.userInfo?.age!);

        clientAgesPerPeriod[periodName] = {
            avgAge: agesOfClientsWithAge.reduce((x, y) => x + y, 0) / (agesOfClientsWithAge.length || 1),
            sd: getStandardDeviation(agesOfClientsWithAge)
        };
    });
    return clientAgesPerPeriod;
}

export function getUsedFloorsPerPeriod(periods: Period[], allFRPs: queries.FallRiskProfile[]) {
    const floorsPerPeriod: { [periodName: string]: FloorWithColor[] } = {};

    const frpsPerPeriod = getFRPsPerPeriod(periods, allFRPs, ReportType.FRP) as {
        [periodName: string]: queries.FallRiskProfile[];
    };
    const colorsUsed: string[] = [];

    // We first do this for the total period, as we need to make sure to go through a period that encompasses
    // all possible measurements. This way, we extract all used floors and we assign a unique color to them ONCE.
    // This will allow to look up a floor with its color as we're looping through the rest of the periods, so that we
    // end up with the same colors per floor across all charts.
    const allFloors = extractFloorsFromFRPs(allFRPs);
    // We need a different colored bar for each floor that we display, so we keep track of the unique colors.
    const allFloorsWithColors: FloorWithColor[] = allFloors.map(f => ({
        ...f,
        color: generateRandomColor(colorsUsed)
    }));
    floorsPerPeriod[TOTAL_STATS_FIELD_NAME] = allFloorsWithColors;

    Object.entries(frpsPerPeriod).forEach(([periodName, frps]) => {
        // Extract used floors only for the given period
        const floorsUsedInCurrentPeriod = extractFloorsFromFRPs(frps);
        // Assign 
        const floorsWithColorsUsedInCurrentPeriod: FloorWithColor[] = floorsUsedInCurrentPeriod
            .map(floorUsedInThisPeriod => (allFloorsWithColors
                .find(f => f.id === floorUsedInThisPeriod.id)!
            ));
        floorsPerPeriod[periodName] = floorsWithColorsUsedInCurrentPeriod;
    });

    return floorsPerPeriod;
}
