import React, {useEffect, useState} from "react";
import {TreeSelect} from "antd";
import APIUtils from "../utils/APIUtils";

function ThingGroupTree(props) {
    const [groups, setGroups] = useState([]);

    const convert = (obj) => {
        const children = [];

        for (const key in obj) {
            const value = obj[key];
            const title = key;

            if (Object.keys(value).length > 0) {
                // Recursive call if the value has children
                children.push({
                    value: title,
                    title: title,
                    children: convert(value),
                });
            } else {
                children.push({
                    value: title,
                    title: title,
                });
            }
        }

        return children;
    }

    const onThingGroupChange = (thingGroupName) => {
        const onChange = props.onChange;
        if (onChange !== null && typeof onChange === 'function') {
            onChange(thingGroupName)
        }
    }

    const getThingGroups = async () => {
        const thingGroups = await APIUtils.getFormattedThingGroups();
        setGroups(convert(thingGroups.tree));
    }

    useEffect(() => {
        getThingGroups();
    }, []);

    return (
        <TreeSelect
            showSearch
            style={{width: '100%'}}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            placeholder="Please select"
            treeDefaultExpandAll
            onChange={onThingGroupChange}
            treeData={groups}
        />
    )
}

export default ThingGroupTree;
