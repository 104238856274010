import React, {useEffect, useState} from "react";

import User from "./UserData";
import {Alert, App, Button, Checkbox, Col, Divider, Drawer, Form, Input, Row, Space, Tag, Transfer} from "antd";
import {TenantSelector} from "../SharedComponents/TenantSelector";
import {InfoCircleOutlined} from "@ant-design/icons";

import * as mutations from "../../utils/graphql/mutations";
import * as queries from "../../utils/graphql/queries";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {
    SetApplicationsForUserMutation,
    SetApplicationsForUserMutationVariables,
    SetFloorsForUserMutation,
    SetFloorsForUserMutationVariables,
    SetGroupsForUserMutation,
    SetGroupsForUserMutationVariables,
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserTenantMutation,
    UpdateUserTenantMutationVariables
} from "../../utils/graphql/mutations";
import {
    GetCognitoUserQuery,
    GetCognitoUserQueryVariables, ListAllFloorsQuery, ListApplicationsQuery,
    ListGroupsQuery,
    ListGroupsQueryVariables
} from "../../utils/graphql/queries";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};


interface EditUserEmailProps {
    user: User | null;
}

const EditUserEmail = ({user}: EditUserEmailProps) => {
    const { notification } = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm();

    const openDrawer = () => {
        setOpen(true);
        form.setFieldsValue({
            email: user?.email,
            isVerified: user?.emailVerified,
        });
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const updateUserEmail = async (email: string, isVerified: boolean) => {
        const response = await API.graphql<GraphQLQuery<UpdateUserEmailMutation>>({
            query: mutations.updateUserEmail,
            variables: {
                id: user?.id,
                email: email,
                isVerified: isVerified,
            } as UpdateUserEmailMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.updateUserEmail) {
            notification.success({
                message: 'Success',
                description: 'User email updated',
            });

            if (user) {
                user.email = email;
            }
        }
    }

    const onFormFinish = async (values: any) => {
        setLoading(true);
        await updateUserEmail(values.email, values.isVerified);
        setLoading(false);
        onClose();
    }

    return (
      <>
          <Drawer
              title="Edit Email"
              width={720}
              onClose={onClose}
              open={open}
              bodyStyle={{paddingBottom: 80}}
              extra={
                  <Space>
                      <Button onClick={onClose}>Cancel</Button>
                  </Space>
              }
            >
                <Form
                    form={form}
                    {...formItemLayout}
                    onFinish={onFormFinish}
                    initialValues={{
                        email: user?.email,
                    }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{required: true, message: 'Please input the email of the user!'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="isVerified"
                        label="Verified"
                        valuePropName="checked"
                    >
                        <Checkbox/>
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 12, offset: 8}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
          </Drawer>
          <Col span={12}>
              <b>E-mail</b><br />
              {user?.email} <br />
              {/* Suppress linter, we need this primary for formatting reasons*/}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={openDrawer}>Edit</a><br />
          </Col>
      </>
    );
}

interface UserResetPasswordProps {
    user: User | null;
}

const UserResetPassword = ({user}: UserResetPasswordProps) => {
    const {notification} = App.useApp();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [form] = Form.useForm();

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const resetPassword = async (newPassword: string, isPermanent: boolean) => {
        const response = await API.graphql<GraphQLQuery<UpdateUserPasswordMutation>>({
            query: mutations.updateUserPassword,
            variables: {
                id: user?.id,
                password: newPassword,
                isPermanent: isPermanent,
            } as UpdateUserPasswordMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.updateUserPassword) {
            notification.success({
                message: 'Success',
                description: 'User password updated',
            });
        }
    }

    const onFormFinish = async (values: any) => {
        setLoading(true);
        await resetPassword(values.password, values.isPermanent);
        setLoading(false);
        onClose();
    }

    return (
        <>
            <Drawer
                title="Reset Password"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    {...formItemLayout}
                    onFinish={onFormFinish}
                >
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{required: true, message: 'Please input the new password!'}]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="isPermanent"
                        label="Permanent"
                        valuePropName="checked"
                    >
                        <Checkbox/>
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 12, offset: 8}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Password</b><br />
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Reset Password</a><br />
            </Col>
        </>
    );
}

interface UserEditGroupProps {
    user: User | null;
    reloadUserDetails: () => void;
}


interface Group {
    id: number;
    name: string;
    tenant: {
        id: number;
    }
}

const UserEditGroup = ({user, reloadUserDetails}: UserEditGroupProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
    const [allGroups, setAllGroups] = useState<Group[]>([]);
    const [allowGroupsOutsideTenant, setAllowGroupsOutsideTenant] = useState<boolean>(false);
    const [form] = Form.useForm();

    const fetchAllGroups = async () => {
        let nextToken: string | null = null;
        let groups: Group[] = [];

        let response;

        do {
            response = await API.graphql<GraphQLQuery<ListGroupsQuery>>({
                query: queries.listGroups,
                variables: {
                    page: {
                        nextToken: nextToken,
                    }
                } as ListGroupsQueryVariables
            });

            if (response.data?.listGroups) {
                groups = groups.concat(response.data.listGroups.items.map((group) => {
                    return {
                        id: group.id,
                        name: group.name,
                        tenant: {
                            id: group.tenant.id,
                        }
                    }
                }));

                nextToken = response.data.listGroups.nextToken;
            }
        } while (nextToken);

        setAllGroups(groups);
    }


    useEffect(() => {
        if (!user) {
            return;
        }
        (async () => {
            setLoading(true);
            await fetchAllGroups();

            const tmpSelectedGroups: Group[] = [];
            for (const group of user.databaseUserDetails?.groups ?? []) {
                const tmpGroup = {
                    id: group.id,
                    name: group.name,
                    tenant: {
                        id: group.tenant.id,
                    }
                }
                tmpSelectedGroups.push(tmpGroup);
            }
            setSelectedGroups(tmpSelectedGroups);

            setLoading(false);
        })().then();
    }, [user]);

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }


    const setGroupsForUser = async (groupsIds: number[]) => {
        const response = await API.graphql<GraphQLQuery<SetGroupsForUserMutation>>({
            query: mutations.setGroupsForUser,
            variables: {
                userId: user?.id,
                groupIds: groupsIds,
            } as SetGroupsForUserMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.setGroupsForUser) {
            notification.success({
                message: 'Success',
                description: 'User groups updated',
            });
        }
    }

    const onFormFinish = async (values: any) => {
        console.log(values);
        setLoading(true);
        await setGroupsForUser(selectedGroups.map((group) => group.id));
        setLoading(false);
        onClose();
        reloadUserDetails();
    }

    const onSelectedGroupsChange = (targetKeys: string[]) => {
        const newSelectedGroups = allGroups.filter((group) => {
            return targetKeys.includes(group.id.toString());
        });
        setSelectedGroups(newSelectedGroups);
    }

    const getAvailableGroups = () => {
        const returnGroups: Group[] = [];

        for (const group of allGroups) {
            if (allowGroupsOutsideTenant || user?.databaseUserDetails?.tenant?.id === group.tenant.id) {
                returnGroups.push(group);
            }
        }

        return returnGroups;
    }

    const displayGroups = () => {
        if (loading) {
            return <>Loading...<br/></>;
        }
        if (selectedGroups.length === 0) {
            return <>No groups<br/></>;
        }
        else {
            return (
                <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                    {selectedGroups.slice(0, 3).map((group, index) => {
                        return <li key={index}>{group.name}</li>;
                    })}
                    {selectedGroups.length > 3 && <li><i>and {selectedGroups.length - 3} more groups</i></li>}

                </ul>

            )
        }
    }


    return (
        <>
            <Drawer
                title="Edit Groups"
                width={720}
                onClose={onClose}
                visible={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Alert
                    message="Info"
                    description="You can only add groups that are in the same tenant as the user"
                    type="info"
                    showIcon
                />
                <div style={{marginTop: '1em'}}/>
                <Checkbox onChange={(e) => setAllowGroupsOutsideTenant(e.target.checked)}>Allow groups outside tenant</Checkbox>
                <div style={{marginTop: '1em'}}/>
                <Form
                    form={form}
                    {...formItemLayout}
                    onFinish={onFormFinish}
                >
                    <Form.Item
                        label={'Groups'}
                        name={'groups'}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={getAvailableGroups().map((group) => {
                                return {
                                    key: group.id.toString(),
                                    name: group.name,
                                }
                            })}
                            targetKeys={selectedGroups.map((group) => group.id.toString())}
                            render={item => item.name}
                            titles={['Available', 'Selected']}
                            listStyle={{
                                width: '45%',
                                height: '20em',
                            }}
                            onChange={onSelectedGroupsChange}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 12, offset: 8}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Groups</b><br />
                {displayGroups()}
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br />
            </Col>
        </>
    );
}

interface UserEditTenantProps {
    user: User | null;
    reloadUserDetails: () => void;
}

const UserEditTenant = ({user, reloadUserDetails}: UserEditTenantProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const updateTenant = async (tenantId: number) => {
        const response = await API.graphql<GraphQLQuery<UpdateUserTenantMutation>>({
            query: mutations.updateUserTenant,
            variables: {
                id: user?.id,
                tenantId: tenantId,
            } as UpdateUserTenantMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.updateUserTenant) {
            notification.success({
                message: 'Success',
                description: 'User tenant updated',
            });
        }
    }

    const onFormFinish = async (values: any) => {
        setLoading(true);
        await updateTenant(values.tenant);
        setLoading(false);
        onClose();
        reloadUserDetails();
    }

    return (
        <>
            <Drawer
                title="Edit Tenant"
                width={720}
                onClose={onClose}
                visible={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    {...formItemLayout}
                    onFinish={onFormFinish}
                >
                    <Form.Item
                        name="tenant"
                        label="Tenant"
                        rules={[{required: true, message: 'Please select the tenant of the user!'}]}
                    >
                        <TenantSelector />
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 12, offset: 8}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Tenant</b><br />
                {user?.databaseUserDetails?.tenant?.name} <br />
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a><br />
            </Col>
        </>
    );
}

interface UserEditApplicationsProps {
    user: User | null;
    reloadUserDetails: () => void;
}

interface Application {
    applicationId: number,
    name: string,
}

const UserEditApplications = ({user, reloadUserDetails}: UserEditApplicationsProps) => {
    const {notification} = App.useApp();
    const [form] = Form.useForm();

    const [open, setOpen] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [applications, setApplications] = useState<Application[]>([]);
    const [userApplications, setUserApplications] = useState<Application[]>([]);


    const loadApplications = async () => {
        if (!user) {
            return;
        }
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListApplicationsQuery>>({
            query: queries.listApplications,
        });

        if (response.data?.listApplications?.items) {
            const localApplications = response.data.listApplications.items.map((application) => {
                return {
                    applicationId: application.id,
                    name: application.name,
                }
            });

            setApplications(localApplications);
        }

        const tmpUserApplications: Application[] = [];
        user.databaseUserDetails?.applications.forEach((application) => {
            const tmpApp = {
                applicationId: application.id,
                name: application.name,
            }
            tmpUserApplications.push(tmpApp);
        });

        setUserApplications(tmpUserApplications);

        setLoading(false);
    }

    useEffect(() => {
        if (!user) {
            return;
        }
        (async () => {
            await loadApplications();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const displayApplications = () => {
        if (loading) {
            return <>Loading...<br/></>;
        }
        if (userApplications.length === 0) {
            return <>No applications<br/></>;
        } else {
            return (
                <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                    {userApplications.slice(0, 3).map((application, index) => {
                        return <li key={index}>{application.name}</li>
                    })}
                    {userApplications.length > 3 && <li><i>and {userApplications.length - 3} more applications</i></li>}

                </ul>

            )
        }
    }

    const updateApplicationsForUser = async (selectedApplications: number[]) => {
        setLoading(true);

        const response = await API.graphql<GraphQLQuery<SetApplicationsForUserMutation>>({
            query: mutations.setApplicationsForUser,
            variables: {
                userId: user?.id,
                applicationIds: selectedApplications,
            } as SetApplicationsForUserMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.setApplicationsForUser) {
            notification.success({
                message: 'Success',
                description: 'User applications updated',
            });
        }

        reloadUserDetails();

        setLoading(false);
    }

    const onFinish = async ({applications}: {applications: string[]}) => {
        const applicationsIds = applications.map((application) => parseInt(application));
        await updateApplicationsForUser(applicationsIds);
        onClose();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo);
    }

    const filterOption = (inputValue: string, option: any) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

    const handleChange = (newUserApplicationsIds: any) => {
        const newUserApplications = applications.filter((application) => newUserApplicationsIds.includes(application.applicationId.toString()));
        setUserApplications(newUserApplications);
    }

    return (
        <>
            <Drawer
                title="Edit User Applications"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    {/*    Add a Transfer component with the applications and show the label above*/}
                    <Form.Item
                        // add attribute to display the label above the transfer component
                        label="Applications"
                        name="applications"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={applications}
                            showSearch
                            filterOption={filterOption}
                            onChange={handleChange}
                            targetKeys={userApplications.map((application) => application.applicationId.toString())}
                            render={(item) => item.name}
                            rowKey={(item) => item.applicationId.toString()}
                            listStyle={{
                                width: '45%',
                                height: '20em',
                            }}
                            titles={['Available', 'Selected']}
                            disabled={loading}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginTop: 16}}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>


            </Drawer>
            <Col span={12}>
                <b>Applications</b><br/>
                {displayApplications()}

                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit applications</a>
            </Col>
        </>
    );
}

interface UserEditFloorsProps {
    user: User | null;
    reloadUserDetails: () => void;
}

interface Floor {
    id: number;
    name: string;
}

const UserEditFloors = ({user, reloadUserDetails}: UserEditFloorsProps) => {
    const {notification} = App.useApp();
    const [form] = Form.useForm();

    const [open, setOpen] = useState<boolean>(false);

    const [floors, setFloors] = useState<Floor[]>([]);
    const [userFloors, setUserFloors] = useState<Floor[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadAllFloors = async () => {
        if (!user) {
            return;
        }
        setLoading(true);

        const tmpFloors: Floor[] = [];
        let nextToken: string | null = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<ListAllFloorsQuery>>({
                query: queries.listAllFloors,
                variables: {
                    page: {
                        limit: 100,
                        nextToken: nextToken,
                    }
                }
            });

            if (response.data?.listAllFloors?.items) {
                tmpFloors.push(...response.data.listAllFloors.items);
            } else {
                notification.error({
                    message: 'Floor fetch failed',
                    description: `Failed to fetch floors: ${response.errors[0].message}`,
                });
                return;
            }

            nextToken = response.data?.listAllFloors?.nextToken ?? null;

        } while (nextToken !== null);

        setFloors(tmpFloors);

        const tmpUserFloors: Floor[] = [];
        user.databaseUserDetails?.floors.forEach((floor) => {
            const tmpFloor = {
                id: floor.id,
                name: floor.name,
            }
            tmpUserFloors.push(tmpFloor);
        });

        setUserFloors(tmpUserFloors);

        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            await loadAllFloors();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const openDrawer = () => {
        setOpen(true);
        form.setFieldsValue({
            viewMetadata: false,
        });
    }

    const onClose = () => {
        setOpen(false);
    }

    const onFinish = async ({floorIds, viewMetadata}: {floorIds: string[], viewMetadata: boolean}) => {
        console.log(floorIds, viewMetadata);
        setLoading(true);
        let floors;
        if (floorIds === undefined) {
            floors = userFloors.map((floor) => floor.id);
        } else {
            floors = floorIds.map((floorId) => parseInt(floorId));
        }

        const response = await API.graphql<GraphQLQuery<SetFloorsForUserMutation>>({
            query: mutations.setFloorsForUser,
            variables: {
                userId: user?.id,
                floorIds: floors,
                canViewMetadata: viewMetadata ?? false,
            } as SetFloorsForUserMutationVariables
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: error.errors[0].message,
            });
        });

        if (response?.data?.setFloorsForUser) {
            notification.success({
                message: 'Success',
                description: 'User floors updated',
            });
        }

        setLoading(false);
        onClose();
        reloadUserDetails();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.error(errorInfo);
    }

    const filterOption = (inputValue: string, option: any) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

    const handleChange = (newUserFloorsIds: any) => {
        const newUserFloors = floors.filter((floor) => newUserFloorsIds.includes(floor.id.toString()));
        setUserFloors(newUserFloors);
    }

    const displayFloors = () => {
        if (loading) {
            return <>Loading...<br/></>;
        }
        if (userFloors.length === 0) {
            return <>No floors<br/></>;
        } else {
            return (
                <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                    {userFloors.slice(0, 3).map((floor, index) => {
                        return <li key={index}>{floor.name}</li>
                    })}
                    {userFloors.length > 3 && <li><i>and {userFloors.length - 3} more floors</i></li>}
                </ul>
            )
        }
    }

    return (
        <>
            <Drawer
                title="Edit User Floors"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Alert
                    type={'error'}
                    message={'Deprecated'}
                    description={'Floors should be assigned at a group level.'}
                    showIcon
                    style={{marginBottom: '20px'}}
                />
                <Form
                    form={form}
                    name={'user-floors'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Floors"
                        name="floorIds"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={floors}
                            showSearch
                            filterOption={filterOption}
                            targetKeys={userFloors.map((floor) => floor.id.toString())}
                            onChange={handleChange}
                            render={(item) => item.name}
                            rowKey={(item) => item.id.toString()}
                            listStyle={{
                                width: '45%',
                                height: '20em',
                            }}
                            titles={['Available', 'Selected']}
                            disabled={loading}
                        />
                    </Form.Item>
                    <Form.Item
                        // View metadata checkbox
                        label="View Metadata"
                        name="viewMetadata"
                        valuePropName={'checked'}
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item>
                        <Button type={'primary'} htmlType={'submit'}>Update Floors</Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Floors</b> <Tag color={'red'}>DEPRECATED</Tag><br/>
                {displayFloors()}
                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* This usage is deprecated, indicate to the user that they should use
                assign floors to the group instead */}
                <a onClick={openDrawer}>Edit floors</a> <br/>
            </Col>
        </>
    );
}



interface UserEditProps {
    user: User | null;
    close: () => void;
    reload: () => void;
}

const UserEdit = ({user, close, reload}: UserEditProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [fullUser, setFullUser] = useState<User | null>(null);

    const openDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
        close();
    }

    const getFullUserDetails = async (user: User) => {
        const response = await API.graphql<GraphQLQuery<GetCognitoUserQuery>>({
            query: queries.getCognitoUser,
            variables: {
                id: user.username,
            } as GetCognitoUserQueryVariables
        });

        return response.data?.getCognitoUser;
    }

    useEffect(() => {
        if (user) {
            openDrawer();
            reloadDatabaseDetails();
        }
        if (!user) {
            setFullUser(null);
        }
    }, [user]);


    const reloadDatabaseDetails = () => {
        if (!user) {
            return;
        }
        setFullUser(null);
        getFullUserDetails(user).then((fullUser) => {
            if (fullUser?.databaseUserDetails === undefined) {
                return;
            }
            const newUser = {
                ...user,
                databaseUserDetails: fullUser?.databaseUserDetails ?? undefined,
            }
            setFullUser(newUser);
        });
    }

    return (
        <>
            <Drawer
                title="Edit User"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                {/* If user.databaseDetails is undefined data is still loading*/}
                {fullUser === null && <p>Loading...</p>}
                {fullUser !== null && (
                    <Row gutter={[0, 32]}>
                        <EditUserEmail user={fullUser}/>
                        <UserResetPassword user={fullUser} />
                        <UserEditGroup user={fullUser} reloadUserDetails={reloadDatabaseDetails}/>
                        <UserEditFloors user={fullUser} reloadUserDetails={reloadDatabaseDetails}/>
                        <UserEditApplications user={fullUser} reloadUserDetails={reloadDatabaseDetails}/>
                        <UserEditTenant user={fullUser} reloadUserDetails={reloadDatabaseDetails}/>
                    </Row>
                )}
            </Drawer>
        </>
    );
}


export default UserEdit;
