import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';

/**
 * The current version of antd (5.x) utilizes a different library for time in its components, not MomentJS.
 * Since we are using MomentJS everywhere in our app, it would look a bit out of place to use both of the libraries. 
 * Below we configure the DatePicker to use MomentJS, to reduce the amount of changes we need to make in other files.
 */
const DatePicker = generatePicker<Moment>(momentGenerateConfig);

export default DatePicker;
