export const listGroups = /* GraphQL */ `
    query ListGroups($page: PaginationInput) {
        listGroups(page: $page) {
            items {
                tenant {
                    id
                    name
                }
                id
                name
                userLimit
                wearableGroup {
                    name
                }
                floors {
                    id
                    name
                }
            }
            nextToken
        }
    }
`;

export const getGroup = /* GraphQL */ `
    query GetGroup($id: Int!) {
        getGroup(id: $id) {
            id
            name
            userLimit
            tenant {
                id
                name
                userLimit
            }
            wearableGroup {
                id
                name
            }
            floors {
                id
                name
                maxX
                maxY
            }
        }
    }
`;

export const listTenants = /* GraphQL */ `
    query ListTenants($page: PaginationInput) {
        listTenants(page: $page) {
            nextToken
            items {
                id
                name
                userLimit
                groups {
                    id
                    name
                    userLimit
                }
                applications {
                    id
                    name
                    priority
                }
            }
        }
    }
`;

export const getGroupByName = /* GraphQL */ `
    query GetGroupByName($name: String!) {
        getGroupByName(name: $name) {
            name
        }
    }
`;

export const listCognitoUsers = /* GraphQL */ `
    query ListCognitoUsers($page: PaginationInput, $poolName: String) {
        listCognitoUsers(page: $page, poolName: $poolName) {
            nextToken
            items {
                id
                username
                email
                emailVerified
                enabled
            }
        }
    }
`;

export const listInvites = /* GraphQL */ `
    query ListInvites($page: PaginationInput) {
        listInvites(page: $page) {
            nextToken
            items {
                token
                valid_until
                created_at
                created_by
                claimed_by
                claimed_at
                invite_type
                cognito_groups
                email
                db_user_id
                tenant_id
                group_ids
            }
        }
    }
`;

export const fetchBatteryStatus = /* GraphQL */ `
    query FetchBatteryStatus(
        $page: PaginationInput
        $wearableId: String!
        $start: Int!
        $end: Int!
    ) {
        fetchBatteryStatus(
            page: $page
            wearableId: $wearableId
            start: $start
            end: $end
        ) {
            nextToken
            items {
                percentage
                isCharging
                voltage
                usb
                timestamp
                receivedAt
            }
        }
    }
`;

export const listFloors = /* GraphQL */ `
    query ListFloors($page: PaginationInput) {
        listFloors(page: $page) {
            items {
                id
                name
                maxX
                maxY
            }
            nextToken
        }
    }
`;

export const getThingGroupByName = /* GraphQL */ `
    query GetThingGroupByName($name: String!) {
        getThingGroupByName(name: $name) {
            name
            parentGroupName
        }
    }
`;
export const listThingGroups = /* GraphQL */ `
    query ListThingGroups($page: PaginationInput) {
        listThingGroups(page: $page) {
            items {
                name
                parentGroupName
            }
            nextToken
        }
    }
`;
export const listThings = /* GraphQL */ `
    query ListThings($page: PaginationInput) {
        listThings(page: $page) {
            items {
                name
                thingType
            }
            nextToken
        }
    }
`;
export const listThingsByThingGroup = /* GraphQL */ `
    query ListThingsByThingGroup(
        $thingGroupName: String!
        $recursive: Boolean
        $page: PaginationInput
    ) {
        listThingsByThingGroup(
            thingGroupName: $thingGroupName
            recursive: $recursive
            page: $page
        ) {
            items {
                name
                thingType
            }
            nextToken
        }
    }
`;
export const listThingsByThingType = /* GraphQL */ `
    query ListThingsByThingType($thingType: String!, $page: PaginationInput) {
        listThingsByThingType(thingType: $thingType, page: $page) {
            items {
                name
                thingType
            }
            nextToken
        }
    }
`;

export const getTenantByName = /* GraphQL */ `
    query GetTenantByName($name: String!) {
        getTenantByName(name: $name) {
            id
            name
            userLimit
            groups {
                id
                name
                userLimit
            }
            applications {
                id
                name
                priority
            }
        }
    }
`;

export const listAllFloors = /* GraphQL */ `
    query ListAllFloors($page: PaginationInput) {
        listAllFloors(page: $page) {
            items {
                id
                name
                maxX
                maxY
            }
            nextToken
        }
    }
`;


export const listApplications = /* GraphQL */ `
    query ListApplications($page: PaginationInput) {
        listApplications(page: $page) {
            items {
                id
                name
                priority
            }
            nextToken
        }
    }
`;


export const listFrpsForGroups = /* GraphQL */ `
    query ListFrpsForGroups($page: PaginationInput, $groupIds: [Int!]!, $bestPerDay: Boolean) {
        listFrpsForGroups(page: $page, groupIds: $groupIds, bestPerDay: $bestPerDay) {
            items {
                id
                user {
                    id
                    groupMemberships {
                        id
                        name
                    }
                    userInfo {
                        age
                        gender
                        walkingAid
                        location {
                            id
                            name
                        }
                    }
                }
                floor {
                    id
                    name
                }
                wearableId
                createdAt
                walkingSpeed
                strideLength
                strideFrequency
                total
            }
            nextToken
        }
    }
`;

export const getFrpsForGroupsBetweenTimes = /* GraphQL */ `query GetFrpsForGroupsBetweenTimes(
        $page: PaginationInput
        $beginTime: Int!
        $endTime: Int!
        $groupIds: [Int!]!
        $bestPerDay: Boolean
    ) {
        getFrpsForGroupsBetweenTimes(
        page: $page
        beginTime: $beginTime
        endTime: $endTime
        groupIds: $groupIds
        bestPerDay: $bestPerDay
        ) {
            items {
                id
                user {
                    id
                    groupMemberships {
                        id
                        name
                    }
                    userInfo {
                        age
                        gender
                        walkingAid
                        location {
                            id
                            name
                        }
                    }
                }
                floor {
                    id
                    name
                }
                wearableId
                createdAt
                walkingSpeed
                strideLength
                strideFrequency
                total
            }
            nextToken
        }
    }
`;

export const getBootHistoryForHeelable = /* GraphQL */ `
    query GetBootHistoryForHeelable(
        $heelableId: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        getBootHistoryForHeelable(
            heelableId: $heelableId
            startTime: $startTime
            endTime: $endTime
        ) {
            timestamp
            bootTimeMs
            bootReasonCode
        }
    }
`;


export type GetBootHistoryForHeelableQueryVariables = {
    heelableId: string,
    startTime: number,
    endTime: number,
};

export type GetBootHistoryForHeelableQuery = {
    getBootHistoryForHeelable:  Array< {
        __typename: "BootEvent",
        timestamp: number,
        bootTimeMs: number,
        bootReasonCode: number,
    } | null >,
};


export type ListFloorsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListFloorsQuery = {
    listFloors:  {
        __typename: "FloorConnection",
        items:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
        nextToken?: string | null,
    },
};


export type ListInvitesQueryVariables = {
    page?: PaginationInput | null,
};

export type ListInvitesQuery = {
    listInvites:  {
        __typename: "InviteConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "Invite",
            token: string,
            valid_until: string,
            created_at: string,
            created_by?: string | null,
            claimed_by: string,
            claimed_at?: string | null,
            invite_type: string,
            cognito_groups: Array< string >,
            email?: string | null,
            db_user_id?: number | null,
            tenant_id?: number | null,
            group_ids?: Array< number | null > | null,
        } >,
    },
};


export type ListCognitoUsersQueryVariables = {
    page?: PaginationInput | null,
    poolName?: string | null,
};

export type ListCognitoUsersQuery = {
    listCognitoUsers:  {
        __typename: "CognitoUserConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "CognitoUser",
            id: string,
            username: string,
            email: string,
            emailVerified: boolean,
            enabled: boolean,
        } >,
    },
};

export type GetCognitoUserQueryVariables = {
    id: string,
    poolName?: string | null,
};

export type GetCognitoUserQuery = {
    getCognitoUser:  {
        __typename: "CognitoUser",
        id: string,
        username: string,
        email: string,
        emailVerified: boolean,
        enabled: boolean,
        databaseUserDetails?:  {
            __typename: "DatabaseUserDetails",
            id: number,
            tenant:  {
                __typename: "Tenant",
                // A tenant is the highest level of organization in the system.
                id: number,
                name: string,
                userLimit?: number | null,
            },
            groups:  Array< {
                __typename: "Group",
                id: number,
                name: string,
                userLimit?: number | null,
                tenant:  {
                    __typename: "Tenant",
                    id: number,
                },
            } >,
            floors:  Array< {
                __typename: "Floor",
                id: number,
                name: string,
                maxX: number,
                maxY: number,
            } >,
            applications:  Array< {
                __typename: "Application",
                id: number,
                name: string,
                priority: number,
            } >,
            groupMemberships:  Array< {
                __typename: "Group",
                id: number,
                name: string,
                userLimit?: number | null,
            } >,
            userInfo?:  {
                __typename: "UserInfo",
                age?: number | null,
                gender?: string | null,
                walkingAid?: string | null,
                location?: ClientLocation | null
            } | null,
        } | null,
    },
};

export const getCognitoUser = /* GraphQL */ `
    query GetCognitoUser($id: ID!, $poolName: String) {
        getCognitoUser(id: $id, poolName: $poolName) {
            id
            username
            email
            emailVerified
            enabled
            databaseUserDetails {
                id
                tenant {
                    id
                    name
                    userLimit
                }
                groups {
                    id
                    name
                    userLimit
                    tenant {
                        id
                    }
                }
                floors {
                    id
                    name
                    maxX
                    maxY
                }
                applications {
                    id
                    name
                    priority
                }
                groupMemberships {
                    id
                    name
                    userLimit
                }
                userInfo {
                    age
                    gender
                    walkingAid
                }
            }
        }
    }
`;



export type GetGroupByNameQueryVariables = {
    name: string,
};

export type GetGroupByNameQuery = {
    getGroupByName?: {
        name: string;
    }
}

export type PaginationInput = {
    // Standard pagination input for queries that support pagination. This
    // simplifies the pagination interface.
    limit?: number | null,
    nextToken?: string | null,
};

export type ListGroupsQueryVariables = {
    page?: PaginationInput | null,
};

export type Group = {
    id: number,
    name: string,
    userLimit: number | null,
    tenant: {
        id: number,
        name: string,
    },
    wearableGroup?: {
        name: string,
    },
    floors: Array<{
        id: number,
        name: string,
    }>,
}

export type ListGroupsQuery = {
    listGroups?: {
        nextToken: string | null,
        items: Array<Group>,
    } | null,
};

export type GetGroupQueryVariables = {
    id: number,
};

export type GetGroupQuery = {
    getGroup?:  {
        __typename: "Group",
        id: number,
        name: string,
        userLimit?: number | null,
        tenant:  {
            __typename: "Tenant",
            // A tenant is the highest level of organization in the system.
            id: number,
            name: string,
            userLimit?: number | null,
        },
        wearableGroup?:  {
            __typename: "WearableGroup",
            id: number,
            name: string,
        } | null,
        floors:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
    } | null,
};

export type ListTenantsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListTenantsQuery = {
    listTenants:  {
        __typename: "TenantConnection",
        // "
        // A connection to a list of tenants.
        nextToken?: string | null,
        items:  Array< {
            __typename: "Tenant",
            // A tenant is the highest level of organization in the system.
            id: number,
            name: string,
            userLimit?: number | null,
            groups:  Array< {
                __typename: "Group",
                id: number,
                name: string,
                userLimit?: number | null,
            } >,
            applications:  Array< {
                __typename: "Application",
                id: number,
                name: string,
                priority: number,
            } >,
        } >,
    },
};

export type FetchBatteryStatusQueryVariables = {
    page?: PaginationInput | null,
    wearableId: string,
    start: number,
    end: number,
};


export type FetchBatteryStatusQuery = {
    fetchBatteryStatus:  {
        nextToken?: string | null,
        items:  Array< {
            percentage: number,
            isCharging: boolean,
            voltage: number,
            usb: boolean,
            timestamp: number,
            receivedAt: number,
        } >,
    },
};


export const getMobileRouter = /* GraphQL */ `
    query GetMobileRouter($id: String!) {
        getMobileRouter(id: $id) {
            id
            configuredNetworks
            simEid
        }
    }
`;
export const listMobileRouters = /* GraphQL */ `
    query ListMobileRouters($page: PaginationInput) {
        listMobileRouters(page: $page) {
            items {
                id
                configuredNetworks
                simEid
            }
            nextToken
        }
    }
`;

export type GetMobileRouterQueryVariables = {
    id: string,
};

export type GetMobileRouterQuery = {
    getMobileRouter?:  {
        __typename: "MobileRouter",
        id: string,
        configuredNetworks?: Array< string > | null,
        simEid?: string | null,
    } | null,
};

export type ListMobileRoutersQueryVariables = {
    page?: PaginationInput | null,
};

export type ListMobileRoutersQuery = {
    listMobileRouters:  {
        __typename: "MobileRouterConnection",
        items:  Array< {
            __typename: "MobileRouter",
            id: string,
            configuredNetworks?: Array< string > | null,
            simEid?: string | null,
        } >,
        nextToken?: string | null,
    },
};

export type GetThingGroupByNameQueryVariables = {
    name: string,
};

export type GetThingGroupByNameQuery = {
    getThingGroupByName?:  {
        __typename: "ThingGroup",
        name: string,
        parentGroupName?: string | null,
    } | null,
};

export type ListThingGroupsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListThingGroupsQuery = {
    listThingGroups:  {
        __typename: "ThingGroupConnection",
        items:  Array< {
            __typename: "ThingGroup",
            name: string,
            parentGroupName?: string | null,
        } >,
        nextToken?: string | null,
    },
};

export type ListThingsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListThingsQuery = {
    listThings:  {
        __typename: "ThingConnection",
        items:  Array< {
            __typename: "Thing",
            name: string,
            thingType: string,
        } >,
        nextToken?: string | null,
    },
};

export type ListThingsByThingGroupQueryVariables = {
    thingGroupName: string,
    recursive?: boolean | null,
    page?: PaginationInput | null,
};

export type ListThingsByThingGroupQuery = {
    listThingsByThingGroup:  {
        __typename: "ThingConnection",
        items:  Array< {
            __typename: "Thing",
            name: string,
            thingType: string,
        } >,
        nextToken?: string | null,
    },
};

export type ListThingsByThingTypeQueryVariables = {
    thingType: string,
    page?: PaginationInput | null,
};

export type ListThingsByThingTypeQuery = {
    listThingsByThingType:  {
        __typename: "ThingConnection",
        items:  Array< {
            __typename: "Thing",
            name: string,
            thingType: string,
        } >,
        nextToken?: string | null,
    },
};


export type GetTenantByNameQueryVariables = {
    name: string,
};

export type GetTenantByNameQuery = {
    getTenantByName?:  {
        __typename: "Tenant",
        // A tenant is the highest level of organization in the system.
        id: number,
        name: string,
        userLimit?: number | null,
        groups:  Array< {
            __typename: "Group",
            id: number,
            name: string,
            userLimit?: number | null,
        } >,
        applications:  Array< {
            __typename: "Application",
            id: number,
            name: string,
            priority: number,
        } >,
    } | null,
};


export type ListAllFloorsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListAllFloorsQuery = {
    listAllFloors:  {
        __typename: "FloorConnection",
        items:  Array< {
            __typename: "Floor",
            id: number,
            name: string,
            maxX: number,
            maxY: number,
        } >,
        nextToken?: string | null,
    },
};

export type ListApplicationsQueryVariables = {
    page?: PaginationInput | null,
};

export type ListApplicationsQuery = {
    listApplications:  {
        __typename: "ApplicationConnection",
        items:  Array< {
            __typename: "Application",
            id: number,
            name: string,
            priority: number,
        } >,
        nextToken?: string | null,
    },
};

export type Floor = {
    id: number,
    name: string,
    maxX: number,
    maxY: number,
};

export type UserInfo = {
    __typename: "UserInfo",
    age?: number | null,
    gender?: string | null,
    walkingAid?: string | null,
    location?: {
        id: number,
        name: string
    }
};

export type DatabaseUserDetails = {
    id: number,
    groupMemberships:  Array<Group >,
    userInfo?: UserInfo | null,
};

export type ListFrpsForGroupsQueryVariables = {
    page?: PaginationInput | null,
    groupIds: Array< number >,
    bestPerDay?: boolean | null,
};

export type FallRiskProfile = {
    id: number,
    wearableId: string,
    user: DatabaseUserDetails,
    floor: Floor,
    createdAt: number,
    walkingSpeed: number,
    strideLength: number,
    strideFrequency: number,
    total: number,
}

export type ListFrpsForGroupsQuery = {
    listFrpsForGroups:  {
        nextToken?: string | null,
        items: Array<FallRiskProfile>,
    },
};

export type GetFrpsForGroupsBetweenTimesQueryVariables = {
    page?: PaginationInput | null,
    beginTime: number,
    endTime: number,
    groupIds: Array< number >,
    bestPerDay?: boolean | null,
};

export type GetFrpsForGroupsBetweenTimesQuery = {
    getFrpsForGroupsBetweenTimes:  {
        nextToken?: string | null,
        items: Array<FallRiskProfile>,
    },
};

export const getTelemetryForHeelable = /* GraphQL */ `
    query GetTelemetryForHeelable(
        $heelableId: String!
        $startTime: Int!
        $endTime: Int!
        $logLevel: String
    ) {
        getTelemetryForHeelable(
            heelableId: $heelableId
            startTime: $startTime
            endTime: $endTime
            logLevel: $logLevel
        ) {
            timestamp
            messageIndex
            logLevel
            logMessage
        }
    }
`;
export const listFootsteps = /* GraphQL */ `
    query ListFootsteps(
        $page: PaginationInput
        $heelableId: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        listFootsteps(
            page: $page
            heelableId: $heelableId
            startTime: $startTime
            endTime: $endTime
        ) {
            items {
                id
                heelableId
                timestamp
                position {
                    x
                    y
                }
                floor {
                    id
                    name
                    maxX
                    maxY
                }
            }
            nextToken
        }
    }
`;

export const listUserWearableLinks = /* GraphQL */ `
    query ListUserWearableLinks(
        $page: PaginationInput
        $wearableId: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        listUserWearableLinks(
            page: $page
            wearableId: $wearableId
            startTime: $startTime
            endTime: $endTime
        ) {
            nextToken
            items {
                id
                wearableId
                userId
                startTime
                endTime
            }
        }
    }
`;

export type GetTelemetryForHeelableQueryVariables = {
    heelableId: string,
    startTime: number,
    endTime: number,
    logLevel?: string | null,
};

export type GetTelemetryForHeelableQuery = {
    getTelemetryForHeelable:  Array< {
        __typename: "Telemetry",
        timestamp: number,
        messageIndex: number,
        logLevel: string,
        logMessage: string,
    }>,
};

export type ListFootstepsQueryVariables = {
    page?: PaginationInput | null,
    heelableId: string,
    startTime: number,
    endTime: number,
};

export type ListFootstepsQuery = {
    listFootsteps:  {
        __typename: "FootstepConnection",
        items:  Array< {
            __typename: "Footstep",
            id: number,
            heelableId: string,
            timestamp: number,
            position?:  {
                __typename: "FootstepPosition",
                x: number,
                y: number,
            } | null,
            floor?:  {
                __typename: "Floor",
                id: number,
                name: string,
                maxX: number,
                maxY: number,
            } | null,
        } >,
        nextToken?: string | null,
    },
};

export type ListUserWearableLinksQueryVariables = {
    page?: PaginationInput | null,
    wearableId: string,
    startTime: number,
    endTime: number,
};

export type ListUserWearableLinksQuery = {
    listUserWearableLinks:  {
        __typename: "UserWearableLinkConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "UserWearableLink",
            id: number,
            wearableId: string,
            userId: number,
            startTime: number,
            endTime?: number | null,
        } >,
    },
};

export const getHeelableInfo = /* GraphQL */ `
    query GetHeelableInfo($heelableId: String!) {
        getHeelableInfo(heelableId: $heelableId) {
            software {
                version
                isRelease
            }
            hardwareVersion
            rfidFirmwareVersion
            macAddress
            telemetryLevel
            debugLevel
            shadowMatch
        }
    }
`;

export type GetHeelableInfoQueryVariables = {
    heelableId: string,
};

export type GetHeelableInfoQuery = {
    getHeelableInfo:  {
        __typename: "HeelableInfo",
        software:  {
            __typename: "SoftwareInfo",
            version: string,
            isRelease: boolean,
        },
        hardwareVersion: string,
        rfidFirmwareVersion: string,
        macAddress: string,
        telemetryLevel: number,
        debugLevel: number,
        shadowMatch: boolean,
    },
};

export const listTenantWearableLeases = /* GraphQL */ `
    query ListTenantWearableLeases(
        $page: PaginationInput
        $wearableId: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        listTenantWearableLeases(
            page: $page
            wearableId: $wearableId
            startTime: $startTime
            endTime: $endTime
        ) {
            nextToken
            items {
                wearableId
                tenant {
                    id
                    name
                    userLimit
                }
                startTime
                endTime
            }
        }
    }
`;

export type ListTenantWearableLeasesQueryVariables = {
    page?: PaginationInput | null,
    wearableId: string,
    startTime: number,
    endTime: number,
};

export type ListTenantWearableLeasesQuery = {
    listTenantWearableLeases:  {
        __typename: "TenantWearableLeaseConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "TenantWearableLease",
            wearableId: string,
            tenant: {
                __typename: "Tenant",
                id: number,
                name: string,
                userLimit?: number | null,
            },
            startTime: number,
            endTime?: number | null,
        } >,
    },
};

export const listFrpsForWearable = /* GraphQL */ `
    query ListFrpsForWearable(
        $page: PaginationInput
        $wearableId: String!
        $startTime: Int!
        $endTime: Int!
    ) {
        listFrpsForWearable(
            page: $page
            wearableId: $wearableId
            startTime: $startTime
            endTime: $endTime
        ) {
            nextToken
            items {
                id
                wearableId
                floor {
                    id
                    name
                }
                createdAt
                walkingSpeed
                strideLength
                strideFrequency
                total
            }
        }
    }
`;

export type ListFrpsForWearableQueryVariables = {
    page?: PaginationInput | null,
    wearableId: string,
    startTime: number,
    endTime: number,
};

export type ListFrpsForWearableQuery = {
    listFrpsForWearable:  {
        __typename: "FallRiskProfileConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "FallRiskProfile",
            id: number,
            wearableId: string,
            floor:  {
                __typename: "Floor",
                id: number,
                name: string,
            },
            createdAt: number,
            walkingSpeed: number,
            strideLength: number,
            strideFrequency: number,
            total: number,
        } >,
    },
};

export enum FloorStatus {
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
}

export type Floormap = {
    fileName: string,
    tagsHaveConflict: boolean,
    nameHasConflict: boolean,
    status: FloorStatus,
    maxX: number,
    maxY: number,
    tagCount: number,
};


export const listPendingFloormaps = /* GraphQL */ `
    query ListPendingFloormaps {
        listPendingFloormaps {
            fileName
            tagsHaveConflict
            nameHasConflict
            status
            maxX
            maxY
            tagCount
            __typename
        }
    }
`;

export type ListPendingFloormapsQueryVariables = {
};

export type ListPendingFloormapsQuery = {
    listPendingFloormaps: Array<{
        __typename: "Floormap",
        fileName: string,
        tagsHaveConflict: boolean,
        nameHasConflict: boolean,
        status: FloorStatus,
        maxX: number,
        maxY: number,
        tagCount: number,
    }>
};

export const checkFloormapForConflicts = /* GraphQL */ `
    query CheckFloormapForConflicts($fileName: String!) {
        checkFloormapForConflicts(fileName: $fileName) {
            fileName
            tagsHaveConflict
            nameHasConflict
            status
            maxX
            maxY
            tagCount
            __typename
        }
    }
`;

export type CheckFloormapForConflictsQueryVariables = {
    fileName: string
};

export type CheckFloormapForConflictsQuery = {
    checkFloormapForConflicts: {
        __typename: "Floormap",
        fileName: string,
        tagsHaveConflict: boolean,
        nameHasConflict: boolean,
        status: FloorStatus,
        maxX: number,
        maxY: number,
        tagCount: number
    }
};

export const listPresenceEvents = /* GraphQL */ `
    query ListPresenceEvents(
        $page: PaginationInput
        $thingName: String!
        $startTime: Long!
        $endTime: Long!
    ) {
        listPresenceEvents(
            page: $page
            thingName: $thingName
            startTime: $startTime
            endTime: $endTime
        ) {
            nextToken
            items {
                ... on ConnectEvent {
                    __typename
                    thingName
                    timestamp
                    eventType
                    ipAddress
                }
                ... on DisconnectEvent {
                    __typename
                    thingName
                    timestamp
                    eventType
                    disconnectReason
                }
            }
        }
    }
`;

export type ListPresenceEventsQueryVariables = {
    page?: PaginationInput | null,
    thingName: string,
    startTime: number,
    endTime: number,
};

export type ListPresenceEventsQuery = {
    listPresenceEvents:  {
        __typename: "PresenceEventConnection",
        nextToken?: string | null,
        items:  Array<( {
            __typename: "ConnectEvent",
            thingName: string,
            timestamp: number,
            eventType: string,
            ipAddress: string,
        } | {
            __typename: "DisconnectEvent",
            thingName: string,
            timestamp: number,
            eventType: string,
            disconnectReason: string,
        }
            ) >,
    },
};

export const listMaintenanceInfo = /* GraphQL */ `
    query ListMaintenanceInfo(
  $page: PaginationInput
  $thingName: String!
  $startTime: Long!
  $endTime: Long!
) {
  listMaintenanceInfo(
    page: $page
    thingName: $thingName
    startTime: $startTime
    endTime: $endTime
  ) {
    nextToken
    items {
      thingName
      timestamp
      note
      createdBy
      createdAt
      __typename
    }
    __typename
  }
}`;

export type ListMaintenanceInfoQueryVariables = {
    page?: PaginationInput | null,
    thingName: string,
    startTime: number,
    endTime: number,
};

export type ListMaintenanceInfoQuery = {
    listMaintenanceInfo:  {
        __typename: "MaintenanceInfoConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "MaintenanceInfo",
            thingName: string,
            timestamp: number,
            note: string,
            createdBy: string,
            createdAt: number,
        } >,
    },
};

export type ClientLocation = {
    id: number,
    name: string,
};

export type GetLocationsForGroupsQueryVariables = {
    groupIds: Array< number >,
};

export type GetLocationsForGroupsQuery = {
    getLocationsForGroups?:  Array<ClientLocation>,
};

export const getLocationsForGroups = /* GraphQL */ `
query GetLocationsForGroups($groupIds: [Int!]!) {
    getLocationsForGroups(groupIds: $groupIds) {
      id
      name
      __typename
    }
  }
`;

export type GetPositionEstimatesQueryVariables = {
    heelableId: string,
    startTime: number,
    endTime: number,
    page?: PaginationInput | null,
};
export type GetPositionEstimatesQuery = {
    getPositionEstimates:  {
        __typename: "HeelablePositionConnection",
        items:  Array< {
            __typename: "HeelablePosition",
            latitude?: number | null,
            longitude?: number | null,
            accuracy?: number | null,
            confidence?: number | null,
            timestamp: string,
        } >,
        nextToken?: string | null,
    },
};

export const getPositionEstimates = /* GraphQL */ `query GetPositionEstimates(
  $heelableId: String!
  $startTime: Long!
  $endTime: Long!
) {
  getPositionEstimates(
    heelableId: $heelableId
    startTime: $startTime
    endTime: $endTime
  ) {
    items {
      latitude
      longitude
      accuracy
      confidence
      timestamp
      __typename
    }
    nextToken
    __typename
  }
}
`;

export type UserMeasurement = {
    id: number,
    value: number,
    timestamp: string,
    details: any,
    user: DatabaseUserDetails
}

export type ListUserMeasurementsForGroupsQueryVariables = {
    page?: PaginationInput | null,
    groupIds: Array< number >,
    measurementType: string,
};

export type ListUserMeasurementsForGroupsQuery = {
    listUserMeasurementsForGroups:  {
        __typename: "UserMeasurementConnection",
        nextToken?: string | null,
        items:  Array< {
            __typename: "UserMeasurement",
            id: number,
            value: number,
            timestamp: string,
            details?: string | null,
            user: DatabaseUserDetails,
        } >,
    },
};

export const listUserMeasurementsForGroups = /* GraphQL */ `query ListUserMeasurementsForGroups(
    $page: PaginationInput
    $groupIds: [Int!]!
    $measurementType: String!
    ) {
    listUserMeasurementsForGroups(
        page: $page
        groupIds: $groupIds
        measurementType: $measurementType
    ) {
            nextToken
            items {
                id
                value
                timestamp
                details
                user {
                    id
                    userInfo {
                        age
                        gender
                        height
                        notes
                        walkingAid
                        weight
                        location {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export type GetFloorPreSignedUrlQueryVariables = {
    fileName: string,
};

export type GetFloorPreSignedUrlQuery = {
    getFloorPreSignedUrl:  {
        __typename: "FloorMapPreSignedUrl",
        url: string,
    },
};

export const getFloorPreSignedUrl = /* GraphQL */ `query GetFloorPreSignedUrl($fileName: String!) {
  getFloorPreSignedUrl(fileName: $fileName) {
    url
    __typename
  }
}
`;

export type GetUserWearableLinkQueryVariables = {
    id: number,
};

export type GetUserWearableLinkQuery = {
    getUserWearableLink:  {
        __typename: "UserWearableLink",
        id: number,
        wearableId: string,
        userId: number,
        startTime: number,
        endTime?: number | null,
    },
};

export const getUserWearableLink = /* GraphQL */ `query GetUserWearableLink($id: Int!) {
  getUserWearableLink(id: $id) {
    id
    wearableId
    userId
    startTime
    endTime
    __typename
  }
}
`;
