import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {ListTenantsQuery} from "../../utils/graphql/queries";
import * as queries from "../../utils/graphql/queries";
import {Select} from "antd";

export type Tenant = {
    id: number;
    name: string;
    userLimit: number | null;
}

interface TenantSelectorProps {
    value?: number;
    onChange?: (value: number) => void;
}

export const TenantSelector = (props: TenantSelectorProps) => {
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const loadTenants = async (nextToken: string | null) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListTenantsQuery>>({
            query: queries.listTenants,
            variables: {
                page: {
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listTenants?.items) {
            const localTenants = response.data.listTenants.items.map((tenant) => {
                return {
                    id: tenant.id,
                    name: tenant.name,
                    userLimit: tenant.userLimit ?? null,
                }
            });

            setTenants([...tenants, ...localTenants]);

            setNextToken(response.data.listTenants.nextToken ?? null);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (nextToken || tenants.length === 0) {
            loadTenants(nextToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken]);

    const filterTenants = (input: string, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return (
        <Select
            loading={loading}
            showSearch
            placeholder="Select a tenant"
            optionFilterProp="children"
            filterOption={filterTenants}
            onChange={props.onChange}
        >
            {tenants.map((tenant) => {
                return (
                    <Select.Option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                    </Select.Option>
                )
            })}
        </Select>
    )
}
