import { App, Button, Modal, Spin, Table, Tabs, TabsProps, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Floor } from '../../utils/graphql/queries';
import * as queries from '../../utils/graphql/queries';
import * as mutations from '../../utils/graphql/mutations';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListAllFloorsQuery, ListPendingFloormapsQuery, Floormap } from '../../utils/graphql/queries';
import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import PendingTableHeader from './PendingTableHeader';
import PendingActions from './PendingActions';
import FloorsApproval from './FloorsApproval';
import { FloormapToApprove, RejectPendingFloormapsMutation, ApprovePendingFloormapMutation } from '../../utils/graphql/mutations';
import FloorsRejection from './FloorsRejection';
import { ColumnsType } from 'antd/es/table';


export const stripFileName = (filename: string) => {
    filename = filename.substring(0, filename.lastIndexOf('.')) || filename
    return filename;
}

const FloorsTab = () => {
    const [floors, setFloors] = useState<Floor[]>();
    const [isLoading, setLoading] = useState(false);

    async function fetchFloors() {
        setLoading(true);
        const tmpFloors: Floor[] = [];
        let nextToken = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<ListAllFloorsQuery>>({
                query: queries.listAllFloors,
                variables: {
                    page: {
                        nextToken: nextToken,
                    }
                },
            });

            if (response?.data?.listAllFloors?.items) {
                response.data.listAllFloors.items.forEach((item: any) => {
                    tmpFloors.push(item);
                });
            }

            if (response?.data?.listAllFloors?.nextToken) {
                nextToken = response.data.listAllFloors.nextToken;
            } else {
                nextToken = null;
            }
        } while (nextToken);

        setFloors(tmpFloors);
        setLoading(false);
    }

    useEffect(() => {
        fetchFloors();
    }, []);

    const columns = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
        },
        {
            key: 'width',
            dataIndex: 'width',
            title: 'Width (mm)',
        },
        {
            key: 'height',
            dataIndex: 'height',
            title: 'Height (mm)',
        },
        // {
        //     key: 'actions',
        //     dataIndex: 'actions',
        //     title: 'Actions',
        //     render: (floorId: number) => (<Button onClick={() => console.log('TODO')}>Edit groups</Button>)
        // }
    ];

    const dataSource = floors?.map((floor, idx) => ({
        key: idx,
        name: floor.name,
        width: floor.maxX,
        height: floor.maxY,
        actions: floor.id
    }));

    return <>
        <Button style={{ float: 'right', marginBottom: 10 }} onClick={fetchFloors}>
            <ReloadOutlined /> Reload
        </Button>
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={isLoading}
        />
    </>
}

const PendingFloorsTab = () => {
    const [pendingFloormaps, setPendingFloormaps] = useState<Floormap[]>();

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [singleApproveRow, setSingleApproveRow] = useState<string>();
    const [singleRejectRow, setSingleRejectRow] = useState<string>();

    const [isApprovalModalOpen, setApprovalModalOpen] = useState(false);
    const [isRejectionModalOpen, setRejectionModalOpen] = useState(false);

    const [isTableLoading, setTableLoading] = useState(false);
    const [isRejectionLoading, setRejectionLoading] = useState(false);
    const [isApprovalLoading, setApprovalLoading] = useState(false);

    const { message } = App.useApp();

    async function fetchPendingFloormaps() {
        setTableLoading(true);
        const pending = await API.graphql<GraphQLQuery<ListPendingFloormapsQuery>>({
            query: queries.listPendingFloormaps
        });

        if (pending.data?.listPendingFloormaps) {
            setPendingFloormaps(pending.data?.listPendingFloormaps);
        }
        setTableLoading(false);
    }

    useEffect(() => {
        fetchPendingFloormaps();
    }, []);

    const TagConflictsTooltip = () => (
        <Tooltip title='The file contains tags that are already present in our system.'>
            <QuestionCircleOutlined />
        </Tooltip>
    );

    const NameConflictsTooltip = () => (
        <Tooltip title='The file name corresponds to an existing floor.'>
            <QuestionCircleOutlined />
        </Tooltip>
    );

    interface DataType {
        key: any;
        name: string;
        width: number;
        height: number;
        tagCount: number;
        tagsHaveConflict: boolean;
        nameHasConflict: boolean;
        actions: any;
    }

    const columns: ColumnsType<DataType> = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'File name',
            width: 200,
            render: (name: string) => (<span>{stripFileName(name)}</span>),
        },
        {
            key: 'width',
            dataIndex: 'width',
            title: 'Width (mm)',
            width: 50,
        },
        {
            key: 'height',
            dataIndex: 'height',
            title: 'Height (mm)',
            width: 50,
        },
        {
            key: 'tagCount',
            dataIndex: 'tagCount',
            title: 'Tag count',
            width: 50,
        },
        {
            key: 'tagsHaveConflict',
            dataIndex: 'tagsHaveConflict',
            title: () => (<><span>Tag conflict</span> <TagConflictsTooltip /></>),
            width: 50,
            render: (tagsHaveConflict: boolean) => (<Tag color={tagsHaveConflict ? 'red' : 'green'}>{tagsHaveConflict ? 'Conflict' : 'OK'}</Tag>),
            sorter: (a: any, b: any) => a ? 1 : 0 - b ? 1 : 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            key: 'nameHasConflict',
            dataIndex: 'nameHasConflict',
            title: () => (<><span>Floor name conflict</span> <NameConflictsTooltip /></>),
            width: 50,
            render: (nameHasConflict: boolean) => (<Tag color={nameHasConflict ? 'red' : 'green'}>{nameHasConflict ? 'Conflict' : 'OK'}</Tag>),
            sorter: (a: any, b: any) => a ? 1 : 0 - b ? 1 : 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            key: 'actions',
            width: 200,
            dataIndex: 'actions',
            title: 'Actions',
            render: (fileName: string, record: any, idx: number) => (
                <PendingActions
                    key={fileName}
                    onApproveClick={() => setSingleApproveRow(fileName)}
                    onRejectClick={() => setSingleRejectRow(fileName)}
                />
            )
        }
    ];

    const dataSource = pendingFloormaps?.map((floormap, idx) => ({
        key: floormap.fileName,
        name: floormap.fileName,
        width: floormap.maxX,
        height: floormap.maxY,
        tagsHaveConflict: floormap.tagsHaveConflict,
        nameHasConflict: floormap.nameHasConflict,
        tagCount: floormap.tagCount,
        actions: floormap.fileName,
    }));

    const onSelectRowsChange = (newSelectedRowKeys: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectRowsChange,
    }

    function onApproveModalCancel() {
        if (isApprovalLoading) return;
        setApprovalModalOpen(false);
        setSingleApproveRow(undefined);
    }

    function onRejectModalCancel() {
        if (isRejectionLoading) return;
        setRejectionModalOpen(false);
        setSingleRejectRow(undefined);
    }

    /**
     * In case we have (accidentally) just added a file with an existing name, we want to overwrite it in the current
     * `pendingFloormaps` list, just to get the most recent conflicting status. If it really is new, we just push it
     * to the list.
     *
     * @param newPending Newly uploaded pending floormaps to add to the list
     */
    function addNewPendingFloormaps(newPending: Floormap[]) {
        if (newPending?.length > 0) {
            newPending.forEach(newFm => {
                const newFmAlreadyInList = pendingFloormaps?.find(oldFm => oldFm.fileName === newFm.fileName);
                if (pendingFloormaps) {
                    if (newFmAlreadyInList !== undefined) {
                        const idx = pendingFloormaps.indexOf(newFmAlreadyInList);
                        setPendingFloormaps(prev => ([
                            ...prev!.slice(0, idx),
                            newFm,
                            ...prev!.slice(idx + 1)
                        ]))
                    } else {
                        setPendingFloormaps(prev => ([...prev!, newFm]));
                    }
                }
            });
        }
    }

    /**
     * Remove rejected OR approved floormaps from the `pendingFloormaps` list (and table).
     *
     * @param floormaps Floormaps to remove from the list
     */
    function removeFromPending(floormaps: Floormap[]) {
        const rejectedFloormapNames = floormaps.map(fm => fm.fileName);
        setPendingFloormaps(prev => ([...prev!.filter(fm => !rejectedFloormapNames.includes(fm.fileName))]));
    }

    async function approveSingleFloormap(floormapToApprove: FloormapToApprove) {
        return await API.graphql<GraphQLQuery<ApprovePendingFloormapMutation>>({
            query: mutations.approvePendingFloormap,
            variables: { ...floormapToApprove }
        })
            .catch(e => {
                return e;
            });
    }

    async function onApproveModalOK(floormapsForApproval: FloormapToApprove[]) {
        setApprovalLoading(true);
        const initialPromise: any = Promise.resolve(null);
        const approved: Floormap[] = [];
        const notApproved: Floormap[] = [];
        const errors: any[] = [];

        await floormapsForApproval.reduce(
            (promise, fm) => promise.then(() => approveSingleFloormap(fm)
                .then((resp: any) => {
                    if (resp?.data?.approvePendingFloormap) {
                        const processedFm = resp.data.approvePendingFloormap as Floormap;
                        if (processedFm.status === queries.FloorStatus.APPROVED) {
                            // Adjust file name back to original, so we can remove the floormap from the table
                            approved.push({ ...processedFm, fileName: fm.fileName });
                        } else {
                            notApproved.push(processedFm);
                        }
                    } else {
                        const respErrors = resp?.errors;
                        if (respErrors) {
                            respErrors.map((e: any) => errors.push(e.message));
                        }
                    }
                    return resp;
                })
            ),
            initialPromise
        );

        displayInfoAfterApproval(floormapsForApproval, approved, notApproved, errors)
        removeFromPending(approved);
        setApprovalLoading(false);
        setApprovalModalOpen(false);
        setSingleApproveRow(undefined);
    }

    async function onRejectModalOK(floormapsForRejection: string[]) {
        setRejectionLoading(true);
        API.graphql<GraphQLQuery<RejectPendingFloormapsMutation>>({
            query: mutations.rejectPendingFloormaps,
            variables: {
                fileNames: floormapsForRejection
            }
        })
            .then(resp => {
                const rejectedFloormaps = resp?.data?.rejectPendingFloormaps as Floormap[];
                if (rejectedFloormaps) {
                    message.success(`Success! Files rejected: ${rejectedFloormaps.length} / ${floormapsForRejection.length}.`);
                    removeFromPending(rejectedFloormaps);
                }
            })
            .catch(e => message.error(`Failure: ${e}`))
            .finally(() => {
                setRejectionLoading(false);
                setRejectionModalOpen(false);
                setSingleRejectRow(undefined);
            });
    }

    function displayInfoAfterApproval(floormapsForApproval: FloormapToApprove[], approved: Floormap[], notApproved: Floormap[], errors: string[]) {
        message.info(<div style={{ textAlign: 'left', minWidth: 200 }}>
            <p><strong>Approved:</strong> {approved.length} / {floormapsForApproval.length}</p>
            <p><strong>Conflicting:</strong> {notApproved.length} / {floormapsForApproval.length}</p>
            <p><strong>Errors:</strong> {errors.length === 0 && 'None'}</p>
            {errors.length > 0 && <ul>{
                Array(new Set(errors)).map((e, idx) => (<li key={idx}>{e}</li>))
            }</ul>}
        </div>, 4);
    }

    return <>
        <Table
            title={() => <PendingTableHeader
                selectedFloormaps={selectedRowKeys}
                setApprovalModalOpen={setApprovalModalOpen}
                setRejectionModalOpen={setRejectionModalOpen}
                addNewPendingFloormaps={addNewPendingFloormaps}
                fetchPendingFloormaps={fetchPendingFloormaps}
            />}
            bordered
            loading={isTableLoading}
            columns={columns}
            dataSource={dataSource}
            rowSelection={rowSelection}
        />
        <Modal
            title='Approve floors'
            width={1000}
            centered
            open={isApprovalModalOpen || singleApproveRow !== undefined}
            destroyOnClose
            footer={null}
            onCancel={onApproveModalCancel}
        >
            <FloorsApproval
                selectedFloormaps={singleApproveRow ? [singleApproveRow] : selectedRowKeys}
                closeModal={onApproveModalCancel}
                approveFloormaps={onApproveModalOK}
                isApprovalLoading={isApprovalLoading}
            />
        </Modal>

        <Modal
            title='Reject floors'
            width={600}
            centered
            open={isRejectionModalOpen || singleRejectRow !== undefined}
            destroyOnClose
            okText={isRejectionLoading ? <Spin /> : 'Reject'}
            okType='danger'
            onCancel={onRejectModalCancel}
            onOk={() => onRejectModalOK(singleRejectRow ? [singleRejectRow] : selectedRowKeys)}
            okButtonProps={{ disabled: isRejectionLoading }}
            cancelButtonProps={{ disabled: isRejectionLoading }}
        >
            <FloorsRejection selectedFloormaps={singleRejectRow ? [singleRejectRow] : selectedRowKeys} />
        </Modal>
    </>
}


const NewFloorToolView = () => {
    const [activeTabKey, setActiveTabKey] = useState<'pending' | 'floors'>('pending');
    const items: TabsProps['items'] = [
        {
            key: 'pending',
            label: 'Pending floors',
            children: <PendingFloorsTab />
        },
        {
            key: 'floors',
            label: 'Floors',
            children: <FloorsTab />
        }
    ]

    return <Tabs items={items} activeKey={activeTabKey} onTabClick={(e: any) => setActiveTabKey(e)} />
}

export default NewFloorToolView;
