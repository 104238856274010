import React, {useEffect, useState} from "react";
import {GraphQLQuery} from "@aws-amplify/api";
import {API} from "aws-amplify";

import * as queries from "../../../utils/graphql/queries";
import {ListTenantsQuery} from "../../../utils/graphql/queries";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Input, Row, Space, Table, Tag} from "antd";
import {TenantCreate} from "./TenantCreate";
import {TenantData} from "./TenantData";
import {TenantEdit} from "./TenantEdit";

export const TenantManagement = () => {
    const [tenants, setTenants] = useState<TenantData[]>([]);
    const [filteredTenants, setFilteredTenants] = useState<TenantData[]>(tenants);
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [selectedTenant, setSelectedTenant] = useState<TenantData | null>(null);

    const searchColumns = ['name'];

    const fetchData = async (nextToken: string | null) => {
        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListTenantsQuery>>({
            query: queries.listTenants,
            variables: {
                page: {
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listTenants) {
            const localTenants: TenantData[] = response.data.listTenants.items.map((tenant) => {
                return {
                    id: tenant.id,
                    name: tenant.name,
                    userLimit: tenant.userLimit ?? null,
                    applications: tenant.applications,
                }
            });

            // Update the tenants
            setTenants([...tenants, ...localTenants]);

            // Update the next token. We use hooks to update tenants later as it is async, and
            // can already start showing the tenants while not yet having all tenants.
            setNextToken(response.data.listTenants.nextToken ?? null);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (nextToken || tenants.length === 0) {
            fetchData(nextToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken]);

    useEffect(() => {
        if (searchText) {
            const filteredTenants = tenants.filter((tenant) => {
                return searchColumns.some((column) => {
                    return (tenant as any)[column].toString().toLowerCase().includes(searchText.toLowerCase());
                });
            });

            setFilteredTenants(filteredTenants);
        } else {
            setFilteredTenants(tenants);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenants, searchText]);

    const editTenant = (tenant: TenantData) => {
        setSelectedTenant(tenant);
    }

    const columns: ColumnsType<TenantData> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id > b.id ? 1 : -1,
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'userLimit',
            dataIndex: 'userLimit',
            key: 'userLimit',
            render: (userLimit) => {
                if (userLimit === null) {
                    return (
                        <Tag color="blue">No Limit</Tag>
                    );
                }

                return userLimit;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => editTenant(record)}>Edit</Button>
                </Space>
            ),
        },
    ];

    const reloadData = async () => {
        setTenants([]);
        // Set next token to "" which will trigger a fetch of the first page.
        setNextToken("");
    }

    const search = (searchText: string) => {
        setSearchText(searchText);
    }

    const autoSearch = (searchText: string) => {
        if(!searchText) {
            setSearchText(searchText);
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <TenantEdit
                        tenant={selectedTenant}
                        reload={reloadData}
                        close={() => setSelectedTenant(null)}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} style={{textAlign: 'left'}}>
                    <Button onClick={reloadData} loading={loading}>Reload</Button>
                </Col>
                <Col span={12}>
                    <Input.Search
                        size={'large'}
                        placeholder="Search"
                        onSearch={search}
                        onChange={(e) => autoSearch(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col span={6} style={{textAlign: 'right'}}>
                    <TenantCreate reload={reloadData}/>
                </Col>
            </Row>
            {/* Need margin between these*/}
            <div style={{margin: '10px 0'}}/>
            <Table<TenantData>
                columns={columns}
                dataSource={filteredTenants}
                pagination={{
                    pageSize: 10,
                }}
                loading={loading}
            />
        </>
    );
}
