import {Button, Col, Divider, Drawer, Form, InputNumber, App, Row, Space, Input, Transfer} from "antd";
import {ApplicationData, TenantData} from "./TenantData";
import React, {useEffect, useState} from "react";
import * as mutations from "../../../utils/graphql/mutations";
import * as queries from "../../../utils/graphql/queries";
import {UpdateTenantUserLimitMutation} from "../../../utils/graphql/mutations";
import {GraphQLQuery} from "@aws-amplify/api";
import {API} from "aws-amplify";
import {Simulate} from "react-dom/test-utils";

interface TenantEditProps {
    close: () => void;
    tenant: TenantData | null;
    reload: () => void;
}


interface EditUserLimitProps {
    tenant: TenantData | null;
    reload: () => void;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

const EditUserLimit = ({tenant, reload}: EditUserLimitProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
        if (tenant) {
            form.setFieldsValue({
                userLimit: tenant.userLimit,
            });
        }
    }

    const updateUserLimit = async (userLimit: number | null) => {
        const response = await API.graphql<GraphQLQuery<UpdateTenantUserLimitMutation>>({
            query: mutations.updateTenantUserLimit,
            variables: {
                tenantId: tenant?.id,
                userLimit: userLimit,
            }
        }).catch(
            (_) => {
                notification.error({
                    message: 'User Limit Update Failed',
                });
            }
        );

        if (response?.data?.updateTenantUserLimit) {
            if (tenant) {
                tenant.userLimit = userLimit ?? null;
            }
            return true;
        }
        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        console.log(values);
        const success = await updateUserLimit(values.userLimit);
        if (success) {
            notification.success({
                message: 'User Limit Updated',
                description: `User limit of tenant ${tenant?.name} has been updated`,
            });
            reload();
            onClose();
        } else {
            notification.error({
                message: 'User Limit Update Failed',
                description: `User limit of tenant ${tenant?.name} could not be updated`,
            });
        }
        setLoading(false);
    }

    return (
        <>
            <Drawer
                title="Edit User Limit"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name={"editUserLimit"}
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        wrapperCol={{span: 24}}
                        labelCol={{span: 24}}
                        name={"userLimit"}
                        label={"User Limit"}
                    >
                        <InputNumber
                            min={1}
                            max={8196}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>User limit</b> <br/>
                {/* If user limit is Null show a tag else show the number*/}
                {tenant?.userLimit === null ? "No Limit" : tenant?.userLimit} <br/>

                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a>
            </Col>
        </>
    )
}

interface EditTenantNameProps {
    tenant: TenantData | null;
    reload: () => void;
}

const EditTenantName = ({tenant, reload}: EditTenantNameProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();


    useEffect(() => {
        form.setFieldsValue({
            tenantName: tenant?.name,
        });
    }, [tenant]);

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    const updateTenantName = async (tenantName: string) => {
        const response = await API.graphql<GraphQLQuery<mutations.UpdateTenantNameMutation>>({
            query: mutations.updateTenantName,
            variables: {
                tenantId: tenant?.id,
                name: tenantName,
            }
        }).catch(
            (error) => {
                notification.error({
                    message: 'Tenant Name Update Failed',
                    description: error.errors[0].message,
                });
            }
        );

        if (response?.data?.updateTenantName) {
            if (tenant) {
                tenant.name = tenantName;
            }
            return true;
        }
        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);
        console.log(values);
        const success = await updateTenantName(values.tenantName);
        if (success) {
            notification.success({
                message: 'Tenant Name Updated',
                description: `Name of tenant ${tenant?.name} has been updated`,
            });

            reload();
            onClose();
        }
        setLoading(false);
    }

        return (
            <>
                <Drawer
                    title="Edit Tenant Name"
                    width={720}
                    onClose={onClose}
                    open={open}
                    bodyStyle={{paddingBottom: 80}}
                    extra={
                        <Space>
                            <Button onClick={onClose}>Cancel</Button>
                        </Space>
                    }
                >
                    <Form
                        {...formItemLayout}
                        form={form}
                        name={"editTenantName"}
                        onFinish={onFinish}
                        disabled={loading}
                    >
                        <Form.Item
                            name={"tenantName"}
                            label={"Tenant Name"}
                            initialValue={tenant?.name}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
                <Col span={12}>
                    <b>Tenant Name</b> <br/>
                    {tenant?.name} <br/>

                    {/* Suppress linter, we need this primary for formatting reasons*/}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={openDrawer}>Edit</a>
                </Col>
            </>
        );
}


interface EditTenantApplicationsProps {
    tenant: TenantData | null;
    reload: () => void;
}

const EditTenantApplications = ({tenant, reload}: EditTenantApplicationsProps) => {
    const {notification} = App.useApp();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [availableApplications, setAvailableApplications] = useState<ApplicationData[]>([]);
    const [selectedApplications, setSelectedApplications] = useState<ApplicationData[]>([]);

    const fetchApplications = async () => {
        const limit = 100;
        let nextToken: string | null = null;
        let applications: ApplicationData[] = [];

        do {
            const response: any = await API.graphql<GraphQLQuery<queries.ListApplicationsQuery>>({
                query: queries.listApplications,
                variables: {
                    page: {
                        limit: limit,
                        nextToken: nextToken,
                    }
                }
            }).catch(
                (error) => {
                    notification.error({
                        message: 'Applications Fetch Failed',
                        description: error.errors[0].message,
                    });
                }
            )

            if (response.data?.listApplications?.items) {
                applications = applications.concat(response.data.listApplications.items);
            }

            nextToken = response.data?.listApplications?.nextToken ?? null;
        } while (nextToken !== null);

        setAvailableApplications(applications);
    }

    useEffect(() => {
        fetchApplications().then();
    }, []);


    useEffect(() => {
        if (tenant) {
            setSelectedApplications(tenant.applications);
        }
    }, [tenant]);

    const onClose = () => {
        form.resetFields();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    const updateTenantApplications = async (applicationIds: number[]) => {
        const response = await API.graphql<GraphQLQuery<mutations.SetApplicationsForTenantMutation>>({
            query: mutations.setApplicationsForTenant,
            variables: {
                tenantId: tenant?.id,
                applicationIds: applicationIds,
            }
        }).catch(
            (error) => {
                notification.error({
                    message: 'Tenant Applications Update Failed',
                    description: error.errors[0].message,
                });
            }
        );

        if (response?.data?.setApplicationsForTenant) {
            if (tenant) {
                tenant.applications = selectedApplications;
            }
            return true;
        }

        return false;
    }

    const onFinish = async (values: any) => {
        setLoading(true);

        const applicationIds: Array<number> = values.tenantApplications.map((key: string) => parseInt(key, 10));
        const success = await updateTenantApplications(applicationIds);
        if (success) {
            notification.success({
                message: 'Tenant Applications Updated',
                description: `Applications of tenant ${tenant?.name} have been updated`,
            });

            reload();
            onClose();
        }
        setLoading(false);
    }

    const onSelectedApplicationsChange = (nextTargetKeys: any) => {
        setSelectedApplications(
            nextTargetKeys.map((id: string) => availableApplications.find((application) => application.id.toString() === id)!)
        );
    }

    const applicationsDisplay = () => {
        if (loading) {
            return <>loading...<br /></>
        }

        if (!tenant) {
            return <>No tenant selected<br /></>
        }

        if (tenant.applications.length === 0) {
            return <>No applications<br /></>
        }

        return (
            <ul style={{listStyleType: 'none', padding: '0', marginTop: 0}}>
                {tenant.applications.map((application) => (
                    <li key={application.id}>{application.name}</li>
                ))}
            </ul>
        );

    }

    return (
        <>
            <Drawer
                title="Edit Tenant Applications"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    name={"editTenantApplications"}
                    onFinish={onFinish}
                    disabled={loading}
                >
                    <Form.Item
                        name={"tenantApplications"}
                        label={"Tenant Applications"}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Transfer
                            dataSource={availableApplications}
                            rowKey={(application) => application.id.toString()}
                            targetKeys={selectedApplications.map((application) => application.id.toString())}
                            onChange={onSelectedApplicationsChange}
                            render={(item) => item.name}
                            disabled={loading}
                            listStyle={{
                                width: '45%',
                                height: '20em'
                            }}
                            showSearch

                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
            <Col span={12}>
                <b>Tenant Applications</b> <br/>
                {applicationsDisplay()}

                {/* Suppress linter, we need this primary for formatting reasons*/}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={openDrawer}>Edit</a>
            </Col>
        </>
    );
}

export const TenantEdit = ({close, tenant, reload}: TenantEditProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const onClose = () => {
        close();
        setOpen(false);
    }

    const openDrawer = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (tenant) {
            openDrawer();
        }
    }, [tenant]);

    return (
        <>
            <Drawer
                title="Edit Tenant"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{paddingBottom: 80}}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                    </Space>
                }
            >
                <Row gutter={[32, 32]}>
                    <EditUserLimit
                        tenant={tenant}
                        reload={reload}
                    />
                    <EditTenantName
                        tenant={tenant}
                        reload={reload}
                    />
                    <EditTenantApplications
                        tenant={tenant}
                        reload={reload}
                    />
                </Row>
            </Drawer>
        </>
    )
}