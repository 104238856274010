import {LiveView} from "@smartfloor/vitality-shared-components";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {ListAllFloorsQuery} from "../../utils/graphql/queries";
import * as queries from "../../utils/graphql/queries";

interface Floor {
    id: number;
    name: string;
    maxX: number;
    maxY: number;
}


const listFloors = async (): Promise<Floor[]> => {
    let nextToken = null;
    let response = null;

    const floors: Floor[] = [];

    do {
        response = await API.graphql<GraphQLQuery<ListAllFloorsQuery>>({
            query: queries.listAllFloors,
            variables: {
                page: {
                    limit: 100,
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listAllFloors?.items) {
            floors.push(...response.data.listAllFloors.items);
        }

        if (response.data?.listAllFloors?.nextToken) {
            nextToken = response.data.listAllFloors.nextToken;
        } else {
            nextToken = null;
        }
    } while (nextToken !== null);

    return floors;
}

const LiveViewWrapper = () => {
    const [floors, setFloors] = useState<Floor[]>([]);

    const onLoad = async () => {
        if (floors.length === 0) {
            const tmpFloors = await listFloors();
            setFloors(tmpFloors);
        }
    }

    useEffect(() => {
        (async () => {
            await onLoad();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <LiveView
        webSocketURI={process.env.REACT_APP_AWS_WEBSOCKET_API_URI ?? ''}
        floors={floors}
    />

}

export default LiveViewWrapper;