import React, {useEffect, useState} from "react";
import {GroupData} from "./GroupData";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {ListGroupsQuery} from "../../../utils/graphql/queries";
import * as queries from "../../../utils/graphql/queries";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Input, Row, Space, Table, Tag} from "antd";
import {GroupEdit} from "./GroupEdit";
import GroupCreate from "./GroupCreate";

export const GroupManagement = () => {
    const [groups, setGroups] = useState<GroupData[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<GroupData[]>(groups);
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);

    const searchColumns = ['name', 'tenantName', 'wearableGroupName'];

    /**
     * Fetch data from AppSync
     * @param nextToken The next token to fetch the next page of data
     */
    const fetchData = async (nextToken: string | null) => {

        setLoading(true);
        const response = await API.graphql<GraphQLQuery<ListGroupsQuery>>({
            query: queries.listGroups,
            variables: {
                page: {
                    nextToken: nextToken,
                }
            }
        });

        if (response.data?.listGroups) {
            const localGroups: GroupData[] = response.data.listGroups.items.map((group) => {
                const wearableGroupName = group.wearableGroup?.name ?? null;
                return {
                    key: group.id,
                    id: group.id,
                    name: group.name,
                    userLimit: group.userLimit,
                    tenantName: group.tenant.name,
                    tenantId: group.tenant.id,
                    wearableGroupName: wearableGroupName,
                    floors: group.floors,
                }
            });

            // Update the groups
            setGroups([...groups, ...localGroups]);

            // Update the next token. We use hooks to update groups later as it is async, and
            // can already start showing the groups while not yet having all groups.
            setNextToken(response.data.listGroups.nextToken);
        }
        setLoading(false);
    }

    /**
     * This effect is triggered when the next token changes.
     * The groups are fetched when the next token changes.
     */
    useEffect(() => {
        // use the next token to fetch results.
        // If next token is null we only fetch data if there are no groups yet.
        // in all other cases we fetch data even if there are groups already.
        if (nextToken || groups.length === 0) {
            fetchData(nextToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextToken]);

    /**
     * The primary reason for this effect is to update the filtered groups when the groups change.
     * And to filter the results when the search text changes.
     */
    useEffect(() => {
        if (searchText) {
            const filteredGroups = groups.filter((group) => {
                return searchColumns.some((column) => {
                    return (group as any)[column].toString().toLowerCase().includes(searchText.toLowerCase());
                });
            });

            setFilteredGroups(filteredGroups);
        } else {
            setFilteredGroups(groups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, searchText]);


    const editGroup = (group: GroupData) => {
        setSelectedGroup(group);
    }

    const columns: ColumnsType<GroupData> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id > b.id ? 1 : -1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Tenant Name',
            dataIndex: 'tenantName',
            key: 'tenantName',
            sorter: (a, b) => a.tenantName.localeCompare(b.tenantName),
        },
        {
            title: 'Wearable Group Name',
            dataIndex: 'wearableGroupName',
            key: 'wearableGroupName',
        },
        {
            title: 'UserLimit',
            dataIndex: 'userLimit',
            key: 'userLimit',
            // define special render function for userLimit, show a Tag if userLimit is null
            render: (userLimit) => {
                if (userLimit === null) {
                    return (
                        <Tag color="blue">No Limit</Tag>
                    );
                }

                return userLimit;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => editGroup(record)}>Edit</Button>
                </Space>
            ),
        },
    ];

    /**
     * Reloads the data by clearing the groups and setting the next token to "".
     */
    const reloadData = async () => {
        setGroups([]);
        // Set next token to "" which will trigger a fetch of the first page.
        setNextToken("");
    }

    /**
     * This function is called whenever the users searches for something.
     * @param searchText
     */
    const search = (searchText: string) => {
        setSearchText(searchText);
    }

    /**
     * This is called whenever the user types something in the search box. We use this to have some automatic
     * actions when searching.
     * @param searchText
     */
    const autoSearch = (searchText: string) => {
        if(!searchText) {
            setSearchText(searchText);
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <GroupEdit
                        group={selectedGroup}
                        reload={reloadData}
                        close={() => setSelectedGroup(null)}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} style={{textAlign: 'left'}}>
                    <Button onClick={reloadData} loading={loading}>Reload</Button>
                </Col>
                <Col span={12}>
                    <Input.Search
                        size={'large'}
                        placeholder="Search"
                        onSearch={search}
                        onChange={(e) => autoSearch(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col span={6} style={{textAlign: 'right'}}>
                    <GroupCreate reload={reloadData}/>
                </Col>
            </Row>
            {/* Need margin between these*/}
            <div style={{margin: '10px 0'}}/>
            <Table<GroupData>
                columns={columns}
                dataSource={filteredGroups}
                pagination={{
                    pageSize: 10,
                }}
                loading={loading}
            />
        </>

    )
}
