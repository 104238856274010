import React, {useEffect, useState} from "react";
import APIUtils from "../../utils/APIUtils";
import {Button, Card, Table, Tag} from "antd";

interface DeviceConfigStatusViewProps {
  config: any;
  devices: any[];
}

/**
 * This component has been migrated from js to tsx. Types are not yet complete.
 */
const DeviceConfigStatusView = ({config, devices}: DeviceConfigStatusViewProps) => {
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const isSubset = (subset: any[], set: any[]) => {
    if (typeof subset !== 'object' || typeof set !== 'object') {
      return subset === set;
    }
    for (const key in subset) {
      if (subset.hasOwnProperty(key)) {
        if (!set.hasOwnProperty(key) || !isSubset(subset[key], set[key])) {
          return false;
        }
      }
    }
    return true;
  }

  const getTimestampsFromShadow: (obj: any) => any[] = (obj) => {
    const timestamps = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'timestamp') {
          timestamps.push(obj[key]);
        } else if (typeof obj[key] === 'object') {
          timestamps.push(...getTimestampsFromShadow(obj[key]));
        }
      }
    }

    return timestamps;
  }

  const getLastShadowUpdateTimestamp = (shadow: any) => {
    const timestamps = getTimestampsFromShadow(shadow);
    return Math.max(...timestamps);
  }
  const checkConfigForDevice = async (config: any, device: any) => {
    const shadow = await APIUtils.getShadow(device);
    const configMatch = isSubset(config, shadow.state.reported);

    const lastUpdated = getLastShadowUpdateTimestamp(shadow.metadata.reported);

    return {
      configMatch: configMatch,
      lastUpdated: lastUpdated,
    }
  }
  const updateDeviceConfigMatchTable = async () => {
    setResults([]);
    if (!devices) {
      return;
    }
    if (devices?.length === 0) {
      return;
    }
    setLoading(true);
    let tmpResults = []
    for (const device of devices) {
      // if deviceId does not start with "heelable_", skip
      if (!device.startsWith('heelable_')) {
        continue;
      }
      const result = await checkConfigForDevice(config, device);

      const item = {
        deviceId: device,
        configMatch: result.configMatch,
        lastUpdated: result.lastUpdated,
      };
      tmpResults.push(item);
    }
    // tmpResults.sort((a, b) => a.configMatch - b.configMatch);
    // sort by device ID
    tmpResults.sort((a, b) => a.deviceId.localeCompare(b.deviceId));
    setResults(tmpResults);
    setLoading(false);
  }

  useEffect(() => {
    updateDeviceConfigMatchTable()
  }, [devices, config]);

  const displayUnixTimestamp = (ts: number) => {
    const date = new Date(ts * 1000);
    return date.toLocaleString()
  }

  const userColumns = [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      key: 'deviceId',
    },
    {
      title: 'Config Match',
      dataIndex: 'configMatch',
      key: 'configMatch',
      render: (configMatch: any) => (
        (<Tag color={configMatch ? 'green' : 'red'}>
          {configMatch ? 'TRUE' : 'FALSE'}
        </Tag>)
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (lastUpdated: any) => (
        (displayUnixTimestamp(lastUpdated))
      ),
    },
    {
      title: 'Console Link',
      dataIndex: 'deviceId',
      key: 'consoleLink',
      // https://eu-central-1.console.aws.amazon.com/iot/home?region=eu-central-1#/thing/heelable_128/namedShadow/Classic Shadow
      render: (deviceId: string) => (
        (<a
          href={`https://eu-central-1.console.aws.amazon.com/iot/home?region=eu-central-1#/thing/${deviceId}/namedShadow/Classic Shadow`}
          target={'_blank'}
          rel={"noreferrer"}
        >
          link
        </a>)
      ),
    },
  ]

  return <>
    <Button
      type="primary"
      onClick={updateDeviceConfigMatchTable}
      disabled={loading}
      loading={loading}
      style={{marginBottom: 16}}
    >
      Reload
    </Button>
    <Table
      rowKey={record => record.deviceId}
      columns={userColumns}
      dataSource={results}
      pagination={{
        position: ['topLeft', 'bottomLeft'],
        defaultPageSize: 10,
      }}
      loading={loading}
    />
  </>
}


export default DeviceConfigStatusView;