import React, {useEffect, useState} from "react";
import {Alert, Col, Radio, Row, Select} from "antd";
import APIUtils from "../../utils/APIUtils";
import ThingGroupTree from "../ThingGroupTree";
import moment from "moment/moment";
import DatePicker from "../DatePicker";
import BatteryMonitoringView from "./BatteryMonitoringView";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import {ListThingsByThingTypeQuery} from "../../utils/graphql/queries";
import * as queries from "../../utils/graphql/queries";

const {RangePicker} = DatePicker;

interface ThingSelectorProps {
  setThings: (things: string[]) => void;
}

enum ThingSource {
  THING_GROUPS = "THING_GROUPS",
  DEVICES = "DEVICES"
}

interface ThingGroupThingSelectorProps {
  onThingsChange: (things: string[]) => void;
}

const ThingGroupThingSelector = ({onThingsChange}: ThingGroupThingSelectorProps) => {
  const onThingGroupChange = async (thingGroupName: string) => {
    const response = await APIUtils.getAllDevicesInThingGroup(thingGroupName);
    const things = response.things;
    onThingsChange(things);
  }

  return (
    <ThingGroupTree
      onChange={onThingGroupChange}
    />
  )
}

interface IndividualThingSelectorProps {
  onThingsChange: (things: string[]) => void;
}


const listHeelables = async () => {
  const things: string[] = [];

  // List all things by the set thing type
  let nextToken = null;
  const limit = 100;

  let response: any = null;
  do {
    response = await API.graphql<GraphQLQuery<ListThingsByThingTypeQuery>>({
      query: queries.listThingsByThingType,
      variables: {
        page: {
          limit: limit,
          nextToken: nextToken,
        },
        thingType: 'heelable',
      }
    }).catch((reason) => {
      console.log(reason);
    });

    if (response?.data?.listThingsByThingType?.items) {
      response.data.listThingsByThingType.items.forEach((thing: any) => {
        things.push(thing.name);
      });
    }

    nextToken = response?.data?.listThingsByThingType?.nextToken ?? null;
  } while (nextToken !== null);

  return things
}

const IndividualThingSelector = ({onThingsChange}: IndividualThingSelectorProps) => {
  const [allThings, setAllThings] = useState<string[]>([]);
  const getThings = async () => {

    let things: string[] = await listHeelables();

    things.sort(function (a: string, b: string) {
      let aVal = parseInt(a.replace('heelable_', ''));
      let bVal = parseInt(b.replace('heelable_', ''));
      return aVal - bVal;
    });

    setAllThings(things);
  }

  useEffect(() => {
    getThings().then();
  }, []);

  const handleChange = (value: string[]) => {
    onThingsChange(value);
  };

  return (
    <div>
      <Select
        mode="multiple"
        allowClear
        style={{width: '100%'}}
        placeholder="Please select"
        onChange={handleChange}
        options={allThings.map((thing) => {
          return {
            label: thing,
            value: thing,
          }
        })}
      />
    </div>
  );
}

const ThingSelector = ({setThings}: ThingSelectorProps) => {
  const [mode, setMode] = useState<ThingSource>(ThingSource.THING_GROUPS)
  const [selectedThings, setSelectedThings] = useState<string[]>([])

  useEffect(() => {
    setThings(selectedThings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThings]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Radio.Group onChange={(e) => setMode(e.target.value)} value={mode}>
            <Radio.Button value={ThingSource.THING_GROUPS}>Thing Groups</Radio.Button>
            <Radio.Button value={ThingSource.DEVICES}>Devices</Radio.Button>
          </Radio.Group><br/><br/>

          {mode === ThingSource.THING_GROUPS &&
              <>
                  <ThingGroupThingSelector
                      onThingsChange={setSelectedThings}
                  />
                  <br/><br/>
              </>

          }
          {mode === ThingSource.DEVICES &&
              <>
                  <IndividualThingSelector
                      onThingsChange={setSelectedThings}
                  />
                  <br/>
              </>
          }

          {selectedThings.length > 8 &&
              <>
                  <Alert
                      message="You have selected more than 8 devices. This can cause performance issues."
                      type="warning"
                      showIcon
                  />
                  <br/>
              </>
          }
        </Col>
      </Row>
    </>
  )
}

const MonitoringView = () => {
  const [things, setThings] = useState<string[]>([])

  const [startTimestamp, setStartTimestamp] = useState<number>(moment().startOf('day').subtract(5, 'days').valueOf());
  const [endTimestamp, setEndTimestamp] = useState<number>(moment().endOf('day').valueOf());

  const updateSelectedPeriodTimestamps = (dates: null | (any | null)[]) => {
    if (dates === undefined) return;
    if (dates === null) return;
    if (dates.length !== 2) return;

    setStartTimestamp(dates[0].valueOf());
    setEndTimestamp(dates[1].valueOf());
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <ThingSelector
            setThings={setThings}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <RangePicker
            style={{marginRight: '20px'}}
            showTime={{format: 'HH mm'}}
            format="YYYY-MM-DD HH mm"
            onChange={updateSelectedPeriodTimestamps}
            defaultValue={[moment(startTimestamp), moment(endTimestamp)]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BatteryMonitoringView
            things={things}
            startTimestamp={startTimestamp}
            endTimestamp={endTimestamp}
          />
        </Col>
      </Row>
    </>
  )
}

export default MonitoringView;
