/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReportLayout from './ReportLayout';
import { Period, ReportType } from './CustomerInsightsView';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListFrpsForGroupsQuery, GetFrpsForGroupsBetweenTimesQuery } from '../../utils/graphql/queries';
import * as queries from "../../utils/graphql/queries";
import moment from 'moment';
import { AgeStats, FloorWithColor, GenderBreakdown, HIGH_RISK_FIELD, LOW_RISK_FIELD, LocationWithColor, MEDIUM_RISK_FIELD, NO_LOCATION_NAME, TOTAL_STATS_FIELD_NAME, YEAR_TO_DATE_STATS_FIELD_NAME, extractClientsFromMeasurements, extractFloorsFromFRPs, generateRandomColor, getAggregatePeriod, getClientAgesPerPeriod, getClientCountPerFRPCategoryPerFloorPerPeriod, getClientCountPerFRPCategoryPerLocation, getClientCountPerFRPCategoryPerPeriod, getClientCountPerFRPCountPerPeriod, getClientCountPerFloorPerPeriod, getClientCountPerPeriod, getClientGenderBreakdownPerPeriod, getClientsForPeriod, getClientsPerLocationPerPeriod, getFRPCountPerClientPerPeriod, getMeasurementsBetweenTimesGroupedByClient, getUsedFloorsPerPeriod } from './reportUtils';
import { useReactToPrint } from 'react-to-print';


export type CustomerInsightsReportData = {
    clientCountPerPeriod: {
        [periodName: string]: number
    },
    clientGendersPerPeriod: {
        [periodName: string]: GenderBreakdown
    },
    clientAgesPerPeriod: {
        [periodName: string]: AgeStats
    },
    floorsPerPeriod: {
        [periodName: string]: FloorWithColor[]
    },
    allClientsWalkingAid: { count: number, percentage: number },
    floors: FloorWithColor[],
    locations: LocationWithColor[],
    avgMeasurementsPerClientForYears?: number,
    maxMeasurementsPerClientForYears?: number,
    clientCountPerFloorPerPeriod: {
        [periodName: string]: {
            [floorId: number]: number;
        }
    },
    clientCountPerLocationPerPeriod: {
        [periodName: string]: {
            [locationName: string]: number;
        }
    },
    clientCountPerFRPCountPerPeriod: any[],
    clientCountPerFRPCategoryPerPeriod: {
        [periodName: string]: {
            // The 'name' field refers to the FRP category. This specific property makes the usage of react charts somewhat easier,
            // since they sometimes look specifically for a property "name", or "value".
            name: string
            clientCount: number
        }[]
    },
    clientCountPerFRPCategoryPerLocation: {
        [locationName: string]: {
            name: string
            clientCount: number
        }[]
    },
    clientCountPerFRPCategoryPerPeriodPerFloor: {
        [periodName: string]: {
            name: string // Floor name.
            [LOW_RISK_FIELD]: number
            [MEDIUM_RISK_FIELD]: number
            [HIGH_RISK_FIELD]: number
        }[]
    }
}

interface ReportLayoutWrapperProps {
    setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>
    municipalityName: string,
    periods: Period[],
    groupIds: number[],
    yearToDatePeriod?: Period,
    mustIncludeTotalStats: boolean,
    reportType: ReportType
    includeLocationBreakdown: boolean
}

/**
 * This component:
 * 1. Fetches and formats report data, based on the options selected in the CustomerInsightsSelectionUI. The formatted data is passed
 * to the ReportLayout.
 * 2. Is responsible for configuring and generating the PDF file.
 */
const ReportLayoutWrapper = ({
    setIsPreviewMode,
    municipalityName,
    periods,
    groupIds,
    yearToDatePeriod,
    mustIncludeTotalStats,
    reportType,
    includeLocationBreakdown
}: ReportLayoutWrapperProps) => {
    const [allFRPs, setAllFRPs] = useState<queries.FallRiskProfile[]>();
    const [userMeasurements, setUserMeasurements] = useState<queries.UserMeasurement[]>();
    const [clientLocations, setClientLocations] = useState<any[]>();
    const [formattedReportData, setFormattedReportData] = useState<CustomerInsightsReportData>();
    const aggregatePeriod = getAggregatePeriod(periods);
    // <date-created>_<municipality-name>_<aggregate-start-date>_-_<aggregate-end-date>.pdf
    const fileName = `${moment().format('DD-MM-YYYY')}_${municipalityName}_${aggregatePeriod.dates[0].format('DD-MM-YYYY')}_-_${aggregatePeriod.dates[1].format('DD-MM-YYYY')}.pdf`;

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: fileName
    });

    const getPeriodByName = (periodName: string) => {
        if (periodName === YEAR_TO_DATE_STATS_FIELD_NAME) {
            return yearToDatePeriod!;
        } else if (periodName === TOTAL_STATS_FIELD_NAME) {
            return { dates: [null, null], name: TOTAL_STATS_FIELD_NAME } as unknown as Period
        } else if (periodName === aggregatePeriod.name) {
            return aggregatePeriod;
        }
        return periods.find(p => p.name === periodName)!;
    }

    const formatReportData = (allMeasurements: queries.FallRiskProfile[] | queries.UserMeasurement[], clientLocations: any[], reportType: ReportType) => {
        const reportData: any = {};
        const allClients: queries.DatabaseUserDetails[] = extractClientsFromMeasurements(allMeasurements);

        const clientsWithWalkingAid = allClients
            .filter(c => c.userInfo?.walkingAid !== null && c.userInfo?.walkingAid !== undefined);
        reportData.allClientsWalkingAid = {
            count: clientsWithWalkingAid.length,
            percentage: (clientsWithWalkingAid.length * 100) / (allClients.length | 1)
        }
        if (reportType === ReportType.FRP) {
            const floors = extractFloorsFromFRPs(allMeasurements as queries.FallRiskProfile[]);
            // We need a different colored bar for each floor that we display, so we keep track of the unique colors.
            const usedFloorColors: string[] = [];
            const floorsWithColors: FloorWithColor[] = floors.map(f => ({
                ...f,
                color: generateRandomColor(usedFloorColors)
            }));
            reportData.floors = floorsWithColors;
        }

        // We do the same with locations
        const usedLocationColors: string[] = [];
        const locationsWithColors: LocationWithColor[] = clientLocations.map(loc => ({
            ...loc,
            color: generateRandomColor(usedLocationColors)
        }));
        reportData.locations = locationsWithColors;

        const periodsForFirstTwoTables = [...periods, getPeriodByName(TOTAL_STATS_FIELD_NAME)];
        const clientsPerPeriod = periodsForFirstTwoTables.reduce((acc: { [periodName: string]: queries.DatabaseUserDetails[] }, currentPeriod: Period) => {
            acc[currentPeriod.name] = getClientsForPeriod(currentPeriod, allMeasurements, reportType);
            return acc;
        }, {});
        reportData.clientCountPerPeriod = getClientCountPerPeriod(clientsPerPeriod);
        reportData.clientGendersPerPeriod = getClientGenderBreakdownPerPeriod(clientsPerPeriod);
        reportData.clientAgesPerPeriod = getClientAgesPerPeriod(clientsPerPeriod);
        if (reportType === ReportType.FRP) {
            reportData.floorsPerPeriod = getUsedFloorsPerPeriod(periods, allMeasurements as queries.FallRiskProfile[]);
        }
        if (yearToDatePeriod !== undefined) {
            // Get measurements for the comparison year(s), group them by user, count the measurements per user.
            const measurementCountPerClient = getMeasurementsBetweenTimesGroupedByClient(yearToDatePeriod.dates[0], yearToDatePeriod.dates[1], allMeasurements, reportType);
            const extractedMeasurementCounts = Object.entries(measurementCountPerClient).map(([clientId, measurementCount]) => measurementCount);
            let avgMeasurementsPerClient = 0;
            let maxMeasurementsOfClient = 0;
            if (extractedMeasurementCounts.length > 0) {
                avgMeasurementsPerClient = extractedMeasurementCounts.reduce((a, b) => a + b) / (extractedMeasurementCounts.length || 1);
                maxMeasurementsOfClient = Math.max(...extractedMeasurementCounts);
            }
            reportData.avgMeasurementsPerClientForYears = avgMeasurementsPerClient;
            reportData.maxMeasurementsPerClientForYears = maxMeasurementsOfClient;
        }

        const clientsForAggregatePeriod = getClientsForPeriod(getPeriodByName(aggregatePeriod.name), allMeasurements, reportType);
        reportData.clientCountPerLocationPerPeriod = getClientsPerLocationPerPeriod({ [aggregatePeriod.name]: clientsForAggregatePeriod }, clientLocations);

        if (reportType === ReportType.FRP) {
            // For the regular bar charts
            const periodsForClientsPerFloor = [...periods, aggregatePeriod];
            if (mustIncludeTotalStats) {
                periodsForClientsPerFloor.push(getPeriodByName(TOTAL_STATS_FIELD_NAME));
            }
            const clientCountPerFloorPerPeriod = getClientCountPerFloorPerPeriod(periodsForClientsPerFloor, allMeasurements as queries.FallRiskProfile[]);
            reportData.clientCountPerFloorPerPeriod = clientCountPerFloorPerPeriod;
        }

        const periodsForLastThreeStatistics = [...periods];
        if (yearToDatePeriod !== undefined) {
            periodsForLastThreeStatistics.push(yearToDatePeriod);
        }
        if (mustIncludeTotalStats) {
            periodsForLastThreeStatistics.push(getPeriodByName(TOTAL_STATS_FIELD_NAME));
        }

        // For the clients measured more often table
        const frpCountPerClientPerPeriod = getFRPCountPerClientPerPeriod(periodsForLastThreeStatistics, allMeasurements, getPeriodByName, reportType);
        const clientCountPerFRPCountPerPeriod = getClientCountPerFRPCountPerPeriod(periodsForLastThreeStatistics, frpCountPerClientPerPeriod);
        reportData.clientCountPerFRPCountPerPeriod = clientCountPerFRPCountPerPeriod;

        // Pie charts
        const clientCountPerFRPCategoryPerPeriod = getClientCountPerFRPCategoryPerPeriod(periodsForLastThreeStatistics, allMeasurements, reportType);
        reportData.clientCountPerFRPCategoryPerPeriod = clientCountPerFRPCategoryPerPeriod;

        if (reportType === ReportType.FRP) {
            // FRP total breakdown per location for the aggregate period
            const clientCountPerFRPCategoryPerLocation = getClientCountPerFRPCategoryPerLocation(allMeasurements as queries.FallRiskProfile[], aggregatePeriod);
            reportData.clientCountPerFRPCategoryPerLocation = clientCountPerFRPCategoryPerLocation;
            // Stacked bar charts
            const clientCountPerFRPCategoryPerPeriodPerFloor = getClientCountPerFRPCategoryPerFloorPerPeriod(periodsForLastThreeStatistics, allMeasurements as queries.FallRiskProfile[]);
            reportData.clientCountPerFRPCategoryPerPeriodPerFloor = clientCountPerFRPCategoryPerPeriodPerFloor;
        }
        setFormattedReportData(reportData as CustomerInsightsReportData);
    }

    useEffect(() => {
        if (reportType === undefined) return;
        if (reportType === ReportType.FRP) {
            fetchAllFRPsForSelectedGroups(groupIds);
        } else {
            fetchManualMeasurementsForSelectedGroups(groupIds);
        }
        fetchLocationsForSelectedGroups(groupIds);
    }, [groupIds, reportType]);

    useEffect(() => {
        const measurements = allFRPs || userMeasurements;
        if (measurements !== undefined && clientLocations !== undefined) {
            formatReportData(measurements, clientLocations, reportType);
        }
    }, [allFRPs, userMeasurements, reportType, clientLocations]);

    /**
     * Fetch all FRPs for the selected groups.
     * 
     * Used when the report must provide statistics for all measurements ever made within the groups (usually referred to as "... total").
     * Instead of fetching FRPs for each period, we make use of the collection of all FRPs and filter it on the FE.
     */
    async function fetchAllFRPsForSelectedGroups(groupIds: number[]) {
        const tmpFrps: any[] = [];
        let nextToken = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<ListFrpsForGroupsQuery>>({
                query: queries.listFrpsForGroups,
                variables: {
                    page: {
                        nextToken: nextToken,
                    },
                    groupIds: groupIds,
                    bestPerDay: true
                }
            });

            response.data?.listFrpsForGroups.items.forEach((item: queries.FallRiskProfile) => {
                tmpFrps.push(item);
            });

            if (response.data?.listFrpsForGroups.nextToken) {
                nextToken = response.data?.listFrpsForGroups.nextToken;
            } else {
                nextToken = null;
            }
        } while (nextToken);

        setAllFRPs([...tmpFrps]);
    }

    async function fetchManualMeasurementsForSelectedGroups(groupIds: number[]) {
        const tmpMeasurements: any[] = [];
        let nextToken = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<queries.ListUserMeasurementsForGroupsQuery>>({
                query: queries.listUserMeasurementsForGroups,
                variables: {
                    page: {
                        nextToken: nextToken,
                    },
                    groupIds: groupIds,
                    measurementType: 'VEILIGHEID_NL'
                }
            });

            response.data?.listUserMeasurementsForGroups.items.forEach((item: queries.UserMeasurement) => {
                tmpMeasurements.push(item);
            });

            if (response.data?.listUserMeasurementsForGroups.nextToken) {
                nextToken = response.data?.listUserMeasurementsForGroups.nextToken;
            } else {
                nextToken = null;
            }
        } while (nextToken);

        setUserMeasurements([...tmpMeasurements]);
    }

    async function fetchLocationsForSelectedGroups(groupIds: number[]) {
        const response = await API.graphql<GraphQLQuery<queries.GetLocationsForGroupsQuery>>({
            query: queries.getLocationsForGroups,
            variables: { groupIds }
        });
        const locations = response.data?.getLocationsForGroups;
        const theNoLocationLocation = {
            id: 0,
            name: NO_LOCATION_NAME
        }
        if (locations?.length !== undefined && locations?.length >= 0) {
            // Merge same name locations that belong to different tenants
            const merged: queries.ClientLocation[] = [];
            locations.forEach(l => { merged.find(loc => loc.name === l.name) === undefined && merged.push(l) });
            setClientLocations([theNoLocationLocation, ...merged]);
            return;
        }
        setClientLocations([theNoLocationLocation]);
    }

    /**
     * Fetch FRPs for the selected groups in a specific time window.
     * 
     * This time window could be:
     * 1. The begin date of the earliest period (used as `beginTime`) and the end date of the latest period (used as `endTime`)
     * 2. The begin and end date of the year to date period (if the option for it is ticked)
     * 
     * Used when the option for displaying "total" statistics is disabled. When this is the case, the report will provide
     * statistics only for the selected time periods, and since they are constrained in time, it does not make sense to call
     * `#fetchAllFRPsForSelectedGroups`.
     */
    async function fetchFRPsForSelectedGroupsInTimeWindow(groupIds: number[], beginTime: number, endTime: number) {
        const tmpFrps: any[] = [];
        let nextToken = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<GetFrpsForGroupsBetweenTimesQuery>>({
                query: queries.getFrpsForGroupsBetweenTimes,
                variables: {
                    page: {
                        nextToken: nextToken,
                    },
                    beginTime,
                    endTime,
                    groupIds,
                    bestPerDay: true
                }
            });

            response.data?.listFrpsForGroups.items.forEach((item: queries.FallRiskProfile) => {
                tmpFrps.push(item);
            });

            if (response.data?.listFrpsForGroups.nextToken) {
                nextToken = response.data?.listFrpsForGroups.nextToken;
            } else {
                nextToken = null;
            }
        } while (nextToken);

        setAllFRPs([...tmpFrps]);
    }

    const pageWidth = 250 * 4;

    return <div>
        <Button onClick={() => setIsPreviewMode(false)}>Go back</Button> <Button onClick={handlePrint} type='primary'>Download</Button>
        <Spin tip="Loading..." spinning={formattedReportData === undefined}>
            <div style={{ width: pageWidth }}>
                {formattedReportData !== undefined &&
                    <ReportLayout
                        ref={componentRef}
                        municipalityName={municipalityName}
                        periods={periods}
                        aggregatePeriod={aggregatePeriod}
                        yearToDatePeriod={yearToDatePeriod}
                        totalPeriod={mustIncludeTotalStats ? getPeriodByName(TOTAL_STATS_FIELD_NAME) : undefined}
                        reportData={formattedReportData}
                        reportTypeIsFRP={reportType === ReportType.FRP}
                        includeLocationBreakdown={includeLocationBreakdown}
                    />
                }
            </div>
        </Spin>
    </div>
}

export default ReportLayoutWrapper;