import {CreationStep} from "../CreationStep";
import {App, Button, Checkbox, Col, Form, Input, Row, Space, Typography} from "antd";
import React from "react";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {GeneratePassword} from "../PasswordUtil";

export const UserCreateInstructions = ({instructions, onStepBack, onStepComplete}: CreationStep) => {
  const [form] = Form.useForm();
  const {notification} = App.useApp();

  const onFormFinish = (values: any) => {
    if (!instructions.groups) {
      notification.error({
        message: 'Error',
        description: 'No groups found',
      });
      return
    }
    instructions.groups[0].users = values.users;
    onStepComplete(instructions);
  }

  const templateUser = {
    email: "",
    password: "",
    autoVerifyEmail: true,
    isTemporaryPassword: false
  }

  const defaultGroup = instructions.groups ? instructions.groups[0] : undefined;
  const defaultUsers = defaultGroup?.users ? defaultGroup.users : [JSON.parse(JSON.stringify(templateUser))];

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Create users</Typography.Title>
          <Typography.Paragraph>
            Users are the people who will use the application. Users can login and do any action that their permissions
            allow
            (permissions are assigned in a later step). Users created using this set up wizard are added to the group
            you
            created in the previous step.

            These users will be added to the group as a "Group Manager". Which means that they can create and manage
            users, and take measurements.
          </Typography.Paragraph>
          <Typography.Paragraph>
            There are 2 addition checkboxes that can be selected for each user:
            <ul>
              <li><b>Auto verify email:</b> If selected, the user will not need to verify their email address before
                logging in. This is used for the "fake" users that we create for customers.
              </li>
              <li><b>Temporary password:</b> If selected, the user will be forced to change their password the first
                time they log in. This option is generally disabled for "fake" users.
              </li>
            </ul>
          </Typography.Paragraph>
        </Col>
      </Row>
      <div style={{height: 20}}/>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout={"vertical"}
            onFinish={onFormFinish}
            initialValues={{
              users: defaultUsers,
            }}
          >
            <Form.List name={"users"}>
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({key, name, ...restField}) => (
                    <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        rules={[
                          {required: true, message: 'Missing email'},
                          {type: 'email', message: 'Invalid email'},
                        ]}
                      >
                        <Input placeholder="E-mail"/>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'password']}
                        rules={[
                          {required: true, message: 'Missing password'},
                          // include 1 number, 1 uppercase, 1 lowercase and 1 special character
                          {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
                            message: 'Password must include 1 number, 1 uppercase, 1 lowercase and 1 special character and be at least 8 characters long'
                          },
                        ]}
                      >
                        <Input
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'autoVerifyEmail']}
                        valuePropName="checked"
                      >
                        <Checkbox>autoVerified</Checkbox>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'isTemporaryPassword']}
                        valuePropName="checked"
                      >
                        <Checkbox>Temporary password</Checkbox>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)}/>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({...JSON.parse(JSON.stringify(templateUser))})}
                      block
                      icon={
                      <PlusOutlined/>
                    }
                    >
                      Add another user
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>


        </Col>
      </Row>
      <Button
        onClick={onStepBack}
      >
        Back
      </Button>
    </>
  )
}
