import React, {useEffect} from "react";
import DeviceNetworkConfigView from "./DeviceNetworkConfigView";
import {NetworkEntry} from "./NetworkEntry";
import APIUtils from "../../utils/APIUtils";
import ThingGroupTree from "../ThingGroupTree";
import ConfigEditor from "./ConfigEditor";
import {Alert, App, Button, Col, Divider, Row, Tabs, TabsProps} from "antd";
import DeviceConfigStatusView from "./DeviceConfigStatusView";
import Confirm from "../Utils/Confirm";

const DeviceConfigView = () => {
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);
  const [things, setThings] = React.useState<any[]>([]);
  const [config, setConfig] = React.useState<any | null>(null);
  const [publishedConfig, setPublishedConfig] = React.useState<any | null>(null);

  const {message} = App.useApp()

  const configString = JSON.stringify(config || {});
  const publishedConfigString = JSON.stringify(publishedConfig || {});
  const hasUnpublishedChanges = configString !== publishedConfigString;

  const getThingGroupConfig = async (thingGroupName: string) => {
    const thingGroupConfig = await APIUtils.getThingGroupConfig(thingGroupName);
    console.log(thingGroupConfig);
    return thingGroupConfig;
  }

  const onThingGroupChange = async (thingGroupName: string) => {
    setSelectedGroup(thingGroupName);

    const response = await getThingGroupConfig(thingGroupName);
    const config = response.config;
    const config_things = response.things;
    setThings(config_things);
    setPublishedConfig(config);
    setConfig(config);
  }

  useEffect(() => {
    setConfig(publishedConfig);
  }, [publishedConfig]);

  const getNetworksFromConfig = (config: any) => {
    const wifi_config = config?.tenant_synced_wifi;
    return wifi_config || null;
  }

  const onNetworkConfigChange = (networks: NetworkEntry[] | null) => {
    const tmpConfig = {...config} || {}
    if (networks === null) {
      // If networks is null, then we want to remove the tenant_synced_wifi config
      // but only if there is a config to begin with.
      if (tmpConfig) {
        if (tmpConfig.tenant_synced_wifi) {
          delete tmpConfig.tenant_synced_wifi;
        }
      }
    }
      // If networks is not null, then we want to add the tenant_synced_wifi config
    // if not we create a config
    else {
      tmpConfig.tenant_synced_wifi = networks;
    }

    setConfig(tmpConfig);
    message.success("Configuration saved").then();
  }

  const onRawConfigChange = (config: any) => {
    setConfig(config);
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Network',
      children: <DeviceNetworkConfigView
        configNetworks={getNetworksFromConfig(config || null)}
        onSave={onNetworkConfigChange}
      />,
    },
    {
      key: '2',
      label: 'Raw Editor',
      children: <ConfigEditor
        config={config || null}
        onSave={onRawConfigChange}
        selectedGroup={selectedGroup || null}
      />,
    },
  ];


  const pushConfig = async () => {
    const requestConfig = JSON.stringify(config);
    await APIUtils.updateThingGroupConfig(selectedGroup, requestConfig).catch(
      (error) => {
        console.log(error);
        message.error("Could not push configuration").then();

      }
    );

    message.success("Configuration pushed").then();
    setPublishedConfig(config);
  }

  const askPushConfig = async () => {

    const content = (
      `Are you sure you want to push the configuration to ${selectedGroup}?
      \n
      This will overwrite the current configuration on the device.\n
      The new configuration is ${JSON.stringify(config).length} bytes long.\n
      The current configuration is ${JSON.stringify(publishedConfig ?? '{}').length} bytes long.\n
      
      New Configuration:\n
      ${JSON.stringify(config, null, 2)}
    `)

    Confirm({
      title: "Push Configuration",
      content: content,
      action: async () => {
        await pushConfig();
      }
    });
  }


  return (
    <>
      {hasUnpublishedChanges &&
          <Alert
              message="You still have pending changes. Please push your changes before leaving this page."
              type="warning"
              showIcon
              style={{
                marginBottom: 16,
              }}
          />
      }
      <ThingGroupTree
        onChange={onThingGroupChange}
      />
      {
        selectedGroup &&
          <>
              <Tabs defaultActiveKey="1" items={items}/>
              <Divider/>
              <Row
                  style={{
                    marginTop: 16,
                  }}
              >
                  <Col span={12}>
                      <Button
                          type="primary"
                          onClick={askPushConfig}
                          disabled={!hasUnpublishedChanges}
                          title={hasUnpublishedChanges ? "" : "No saved changes to Push"}
                      >
                          Push Configuration
                      </Button>
                      <Button
                          onClick={() => {
                            setConfig(publishedConfig);
                          }}
                          style={{
                            marginLeft: 8,
                          }}
                          disabled={!hasUnpublishedChanges}
                          title={hasUnpublishedChanges ? "" : "No saved changes to Undo"}
                      >
                          Undo Changes
                      </Button>
                  </Col>
              </Row>
              <Divider/>
              <Row
                  style={{
                    marginTop: 16,
                  }}
              >
                  <Col span={24}>
                      <DeviceConfigStatusView
                          config={publishedConfig}
                          devices={things}
                      />
                  </Col>
              </Row>
          </>
      }
    </>
  );
};

export default DeviceConfigView;

