import {App, Button, Modal, Space, Spin, Upload, UploadFile} from 'antd';
import React, {useState} from 'react';
import {PlusOutlined, ReloadOutlined, UploadOutlined} from '@ant-design/icons';
import PendingActions from './PendingActions';
import {API} from 'aws-amplify';
import * as queries from '../../utils/graphql/queries';
import {Floormap, GetFloorPreSignedUrlQuery} from '../../utils/graphql/queries';
import {GraphQLQuery} from '@aws-amplify/api';


type PendingTableHeaderProps = {
    selectedFloormaps: string[],
    setApprovalModalOpen: any,
    setRejectionModalOpen: any,
    addNewPendingFloormaps: (newFloormaps: Floormap[]) => void,
    fetchPendingFloormaps: any
}

const PendingTableHeader = ({
    selectedFloormaps,
    setApprovalModalOpen,
    setRejectionModalOpen,
    addNewPendingFloormaps,
    fetchPendingFloormaps
}: PendingTableHeaderProps) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploadFiles, setUploadFiles] = useState<{ name: string, content: string }[]>([]);
    const [isLoading, setLoading] = useState(false);
    const { message } = App.useApp();
    const { notification } = App.useApp();
    const canSubmitFiles = uploadFiles.length > 0;


    async function uploadSingle(fileForUpload: { name: string, content: string }) {
        const response = await API.graphql<GraphQLQuery<GetFloorPreSignedUrlQuery>>({
            query: queries.getFloorPreSignedUrl,
            variables: {
                fileName: fileForUpload.name
            }
        }).catch(error => {
            notification.error({
                message: 'Upload failed',
                description: error.errors[0].message,
            });
        });

        const url = response?.data?.getFloorPreSignedUrl.url;
        if (!url) {
            throw new Error('Failed to get pre-signed URL');
        }

        const uploadResponse = await fetch(url, {
            method: 'PUT',
            body: fileForUpload.content
        }).catch(
            error => {
                notification.error({
                    message: 'Upload failed',
                    description: error.errors[0].message,
                });
            }
        )
        // then stop on error
        if (!uploadResponse?.ok) {
            throw new Error('Failed to upload file');
        }
        console.log(uploadResponse);
        return uploadResponse;
    }

    async function bulkUploadSequential() {
        setLoading(true);
        const successfullyUploaded: Floormap[] = [];
        await Promise.all(uploadFiles.map(uploadFile => {
            return uploadSingle(uploadFile);
        }))
            .then((responses: any) => {
                responses.forEach((resp: any) => {
                    if (resp?.data?.uploadFloormap) {
                        successfullyUploaded.push(resp.data.uploadFloormap as Floormap);
                    }
                })
            })
            .finally(() => {
                if (successfullyUploaded.length > 0) {
                    addNewPendingFloormaps(successfullyUploaded);
                    message.success(`Success! Files uploaded to Pending: ${successfullyUploaded.length} / ${uploadFiles.length}.`);
                }
                setLoading(false);
                onModalCancel();
            });

        fetchPendingFloormaps().then();
    }

    function onModalCancel() {
        if (isLoading) return;
        setFileList([]);
        setUploadFiles([]);
        setModalOpen(false);
    }

    function addUploadFile(fileToAdd: any) {
        const fileAlreadyInList = fileList.find(file => file.name === fileToAdd.name) !== undefined;
        if (fileAlreadyInList) {
            message.warning(`File with name ${fileToAdd.name} is already prepared for upload.`);
            return false;
        }

        setFileList(prev => [...prev, fileToAdd]);
        const reader = new FileReader();
        reader.onload = e => {
            const fileContent = e?.target?.result;
            setUploadFiles(prev => [...prev, { name: fileToAdd.name, content: fileContent as string }]);
        };
        reader.readAsText(fileToAdd);
        return false;
    }

    function removeUploadFile(fileToRemove: UploadFile) {
        setFileList(prev => [...prev.filter(file => file.name !== fileToRemove.name)]);
        setUploadFiles(prev => [...prev.filter(file => file.name !== fileToRemove.name)]);
    }

    return <Space style={{ minHeight: 30, display: 'flex', justifyContent: 'space-between' }}>
        <div>
            <PendingActions
                buttonsDisabled={selectedFloormaps.length === 0}
                onApproveClick={() => setApprovalModalOpen(true)}
                onRejectClick={() => setRejectionModalOpen(true)}
            />
        </div>
        <Space>
            <Button onClick={fetchPendingFloormaps}>
                <ReloadOutlined /> Reload
            </Button>
            <Button onClick={() => setModalOpen(true)}>
                <PlusOutlined /> Upload new
            </Button>
        </Space>

        <Modal
            open={isModalOpen}
            title='Upload floors'
            onCancel={onModalCancel}
            onOk={bulkUploadSequential}
            okText={isLoading ? <Spin /> : 'Confirm'}
            okButtonProps={{ disabled: !canSubmitFiles || isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            centered
            width={500}
        >
            <Upload
                onRemove={removeUploadFile}
                beforeUpload={addUploadFile}
                fileList={fileList}
                multiple={true}
            >
                <Button icon={<UploadOutlined />}>
                    Upload
                </Button>
            </Upload>
        </Modal>
    </Space>
}

export default PendingTableHeader;