import {App, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../utils/graphql/queries";
import {FetchBatteryStatusQuery, GetBootHistoryForHeelableQuery} from "../../utils/graphql/queries";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import moment from "moment/moment";

interface BatteryChartProps {
    thing: string;
    startTimestamp: number;
    endTimestamp: number;
    showDeSync: boolean;
    showBootHistory: boolean;
    generatedTimestamp: number | null;
}

type BatteryData = {
    percentage: number;
    voltage: number;
    isCharging: boolean;
    usb: boolean;
    timestamp: number;
    receivedAt: number;
}

interface BootEvent {
    timestamp: number;
    bootTimeMs: number;
    bootReasonCode: number;
}


export const BatteryChart = ({
                                 thing,
                                 startTimestamp,
                                 endTimestamp,
                                 showDeSync,
                                 showBootHistory,
                                 generatedTimestamp
                             }: BatteryChartProps) => {
    const {message} = App.useApp();
    const [batteryData, setBatteryData] = useState<BatteryData[]>([]);
    const [bootHistory, setBootHistory] = useState<BootEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchBootHistory = async () => {
        const response: any = await API.graphql<GraphQLQuery<GetBootHistoryForHeelableQuery>>({
            query: queries.getBootHistoryForHeelable,
            variables: {
                heelableId: thing,
                startTime: Math.round(startTimestamp / 1000),
                endTime: Math.round(endTimestamp / 1000),
            }
        }).catch(
            (error) => {
                message.error(error.errors[0].message);
            }
        )

        let localBootHistory: BootEvent[] = [];

        if (response.data?.getBootHistoryForHeelable) {
            localBootHistory = response.data.getBootHistoryForHeelable;
        }
        return localBootHistory;
    }

    useEffect(() => {
        (async () => {
            if (generatedTimestamp === null) {
                return;
            }
            setLoading(true);
            const tmpBatteryData = await fetchBatteryData();
            setBatteryData(tmpBatteryData);

            setLoading(false);

            const tmpBootHistory = await fetchBootHistory();
            setBootHistory(tmpBootHistory);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thing, startTimestamp, endTimestamp, showBootHistory, generatedTimestamp]);

    const fetchBatteryData = async () => {
        const tmpBatteryData: BatteryData[] = [];
        let nextToken = null;
        do {
            const response: any = await API.graphql<GraphQLQuery<FetchBatteryStatusQuery>>({
                query: queries.fetchBatteryStatus,
                variables: {
                    page: {
                        nextToken: nextToken,
                    },
                    wearableId: thing,
                    start: Math.round(startTimestamp / 1000),
                    end: Math.round(endTimestamp / 1000),
                }
            });


            if (response.data?.fetchBatteryStatus?.items) {
                const localBatteryData: BatteryData[] = response.data.fetchBatteryStatus.items;
                tmpBatteryData.push(...localBatteryData);

                if (response.data?.fetchBatteryStatus?.nextToken) {
                    nextToken = response.data.fetchBatteryStatus.nextToken;
                } else {
                    nextToken = null;
                }
            }
        } while (nextToken !== null);

        return tmpBatteryData;
    }

    const timeRangeExtract = (evalMethod: (dataPoint: BatteryData) => boolean) => {
        const periods: { start: number, end: number }[] = [];
        let currentPeriod: { start: number, end: number } | null = null;
        for (let i = 0; i < batteryData.length; i++) {
            const dataPoint = batteryData[i];
            if (evalMethod(dataPoint)) {
                if (currentPeriod === null) {
                    currentPeriod = {start: dataPoint.timestamp, end: dataPoint.timestamp};
                }
                currentPeriod.end = dataPoint.timestamp;
            } else {
                if (currentPeriod !== null) {
                    periods.push(currentPeriod);
                    currentPeriod = null;
                }
            }
        }
        if (currentPeriod !== null) {
            periods.push(currentPeriod);
        }
        return periods;
    }

    const getChargingPeriods = () => {
        const chargingEval = (dataPoint: BatteryData) => {
            return dataPoint.usb;
        }
        return timeRangeExtract(chargingEval);
    }

    const getOutOfSyncPeriods = () => {
        const syncEval = (dataPoint: BatteryData) => {
            return dataPoint.receivedAt - dataPoint.timestamp > (5 * 60);
        }
        return timeRangeExtract(syncEval);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <h2>{thing}</h2>
                    {loading && <p>Loading...</p>}
                    {!loading && batteryData.length === 0 && <p>No data available</p>}
                    {!loading && batteryData.length > 0 &&
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                                width={1000}
                                height={300}
                                data={batteryData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                {showDeSync && getOutOfSyncPeriods().map((period) => {
                                    return (
                                        <ReferenceArea
                                            x1={period.start}
                                            x2={period.end}
                                            stroke="lightblue"
                                            fill="lightblue"
                                            fillOpacity={0.2}
                                        />
                                    )
                                })}
                                {getChargingPeriods().map((period) => {
                                    return (
                                        <ReferenceArea
                                            x1={period.start}
                                            x2={period.end}
                                            stroke="green"
                                            fill="green"
                                            fillOpacity={0.2}
                                        />
                                    )
                                })}
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis
                                    dataKey="timestamp"
                                    domain={['dataMin', 'dataMax']}
                                    type="number"
                                    tickFormatter={(unixTime) => moment(unixTime * 1000).format('DD/MM/YYYY HH:mm:ss')}
                                />
                                <YAxis
                                    domain={[2.7, 4.5]}
                                    dataKey="voltage"
                                    type={'number'}
                                />
                                <Tooltip
                                    labelFormatter={(unixTime) => moment(unixTime * 1000).format('DD/MM/YYYY HH:mm:ss')}
                                />
                                <Legend
                                    payload={[
                                        {
                                            value: 'Voltage',
                                            type: 'line',
                                            id: 'voltage',
                                            color: '#8884d8',
                                        },
                                        {
                                            value: 'Charging',
                                            type: 'rect',
                                            id: 'charging',
                                            color: 'green',
                                        },
                                        {
                                            value: 'DeSynced',
                                            type: 'rect',
                                            id: 'desynced',
                                            color: 'lightblue',
                                        },
                                    ]}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="voltage"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                                {showBootHistory && bootHistory.map((bootEvent) => {
                                    return (
                                        <ReferenceLine
                                            x={bootEvent.timestamp}
                                            stroke="black"
                                            label={{
                                                position: 'insideBottomRight',
                                                value: bootEvent.bootReasonCode,
                                            }}
                                            strokeDasharray={'3 3'}

                                        />
                                    )
                                })}
                            </LineChart>
                        </ResponsiveContainer>
                    }
                </Col>
            </Row>

        </>
    );


}