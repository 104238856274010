import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {GraphQLQuery} from "@aws-amplify/api";
import * as queries from "../../utils/graphql/queries";
import {ListThingsByThingTypeQuery} from "../../utils/graphql/queries";
import {Select} from "antd";

interface ThingSelectorProps {
    onSelect?: (deviceId: string) => void;
    defaultDevice?: string;
}

export const ThingSelector = ({onSelect, defaultDevice}: ThingSelectorProps) => {
    const [devices, setDevices] = useState<string[]>([]);

    const loadHeelables = async () => {
        const tmpDevices: string[] = [];
        let nextToken = null;

        do {
            const response: any = await API.graphql<GraphQLQuery<ListThingsByThingTypeQuery>>({
                query: queries.listThingsByThingType,
                variables: {
                    page: {
                        nextToken: nextToken,
                    },
                    thingType: "heelable",
                },
            });

            response.data?.listThingsByThingType.items.forEach((item: any) => {
                tmpDevices.push(item.name);
            });

            if (response.data?.listThingsByThingType.nextToken) {
                nextToken = response.data?.listThingsByThingType.nextToken;
            } else {
                nextToken = null;
            }
        } while (nextToken);

        // sort sensors
        tmpDevices.sort();

        setDevices(tmpDevices);
    }

    useEffect(() => {
        loadHeelables().then();
    }, []);

    const options = devices.map((device) => {
        return {
            label: device,
            value: device,
        }
    });

    const onSelectDevice = (value: string) => {
        if (onSelect) {
            onSelect(value);
        }
    }

    return (
        <div>
            <Select
                options={options}
                onSelect={(value) => {
                    onSelectDevice(value);
                }}
                style={{width: '100%'}}
                showSearch
                defaultValue={defaultDevice}
            />
        </div>
    );
}