import {NetworkEntry} from "./NetworkEntry";
import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Drawer, Form, Input, InputNumber, Radio, Row} from "antd";
import {EncryptionStatus} from "./EncryptionStatus";
import {passwordCrypto} from "./PasswordUtil";

interface EditNetworkEntryProps {
  network?: NetworkEntry | null;
  onClose?: () => void;
  onComplete: (network: NetworkEntry) => void;
  title?: string;
  showOpenButton?: boolean;
}

const EditNetworkEntry = ({network, onClose, onComplete, title, showOpenButton}: EditNetworkEntryProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [encryption, setEncryption] = useState<number | undefined>(EncryptionStatus.NOT_ENCRYPTED);
  const [encryptedPassword, setEncryptedPassword] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const close = () => {
    setOpen(false);
    setEncryption(EncryptionStatus.NOT_ENCRYPTED);
    setEncryptedPassword(undefined);
    // reset the form
    form.resetFields();
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (encryptedPassword) {
      form.setFieldsValue({
        encrypted_password: encryptedPassword,
      });
    }
  }, [encryptedPassword]);

  useEffect(() => {
    (async () => {
      if (showOpenButton) {
        return;
      }
      console.log('Network changed: ' + network);
      setEncryption(network?.encrypt_status)
      if (network) {
        setOpen(true);

        if (network.encrypt_status === EncryptionStatus.ENCRYPTED) {
          setEncryptedPassword(network.wifi_password);
          const decryptedPassword = await passwordCrypto(network.wifi_ssid, network.wifi_password, false);
          form.setFieldsValue({
            ssid: network.wifi_ssid,
            password: decryptedPassword,
            encrypted_password: network.wifi_password,
            encryption: network.encrypt_status,
            timeout: network.wifi_timeout,
            priority: network.wifi_priority,
          });
        } else {
          setEncryptedPassword(undefined);
          form.setFieldsValue({
            ssid: network.wifi_ssid,
            password: network.wifi_password,
            encryption: network.encrypt_status,
            timeout: network.wifi_timeout,
            priority: network.wifi_priority,
          });
        }
      } else {
        close();
      }
    })();
  }, [network]);

  const onFinish = async (values: any) => {
    setLoading(true);

    let password = values.password;
    if (values.encryption === EncryptionStatus.ENCRYPTED) {
      // Encrypt the password again. This should not be necessary
      // But doing it here ensures that the credentials always match.
      password = await passwordCrypto(values.ssid, password, true);
    }

    const newNetwork: NetworkEntry = {
      wifi_ssid: values.ssid,
      wifi_password: password,
      wifi_timeout: values.timeout,
      wifi_priority: values.priority,
      _: network?._,
      _internal_id: network?._internal_id,
    }
    if (values.encryption !== undefined) {
      newNetwork.encrypt_status = values.encryption;
    }

    console.log('New network: ', newNetwork);
    close();
    onComplete(newNetwork);
    setLoading(false);
  }

  const updateEncryptedPassword = async (ssid: string, password: string) => {
    if (EncryptionStatus.ENCRYPTED !== encryption) {
      console.log('Not encrypted');
      return;
    }
    const encryptedPassword = await passwordCrypto(ssid, password, true);
    if (encryptedPassword) {
      setEncryptedPassword(encryptedPassword);
    } else {
      console.log('Failed to encrypt password');
      setEncryptedPassword('Failed to encrypt password');
    }
  }

  useEffect(() => {
    updateEncryptedPassword(form.getFieldValue('ssid'), form.getFieldValue('password'));
  }, [encryption]);

  return (
    <>
      <Drawer
        title={title || 'Edit Network'}
        placement="right"
        closable={true}
        onClose={() => close()}
        visible={open}
        width={700}
      >
        <Row>
          <Col span={24}>
            {
              network?._ &&
                <Alert
                    message="This is an auto generated network. Please be careful when editing it."
                    type="warning"
                    showIcon
                />
            }

            <Form
              form={form}
              layout="vertical"
              name="network"
              onFinish={onFinish}
            >
              <Form.Item
                name="ssid"
                label="SSID"
                rules={[{required: true, message: 'Please enter the SSID'}]}
              >
                <Input
                  onBlur={async (e) => {
                    await updateEncryptedPassword(e.target.value, form.getFieldValue('password'));
                  }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{required: true, message: 'Please enter the password'}]}
              >
                <Input
                  onBlur={async (e) => {
                    await updateEncryptedPassword(form.getFieldValue('ssid'), e.target.value);
                  }}
                />
              </Form.Item>
              {/* Item to show encrypted password read only */}
              {/* Make it a bit grayed out*/}
              {encryption === EncryptionStatus.ENCRYPTED &&
                  <Form.Item
                      name="encrypted_password"
                      label="Encrypted password"
                  >
                      <Input
                          readOnly={true}
                          disabled={true}
                      />
                  </Form.Item>
              }
              <Form.Item
                name="encryption"
                label="Encryption"
              >
                {/*  Radio group*/}
                <Radio.Group onChange={(e) => setEncryption(e.target.value)}>
                  <Radio.Button value={undefined}>Default</Radio.Button>
                  <Radio.Button value={EncryptionStatus.NOT_ENCRYPTED}>Not encrypted</Radio.Button>
                  <Radio.Button value={EncryptionStatus.ENCRYPTED}>Encrypted</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {/* All optional fields now */}
              <Form.Item
                name="timeout"
                label="Timeout"
              >
                <InputNumber
                  min={0} max={600} step={1}
                />
              </Form.Item>

              <Form.Item
                name="priority"
                label="Priority"
              >
                <InputNumber
                  min={0} max={100} step={1}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
      { showOpenButton &&
          <Button
              type="primary"
              onClick={() => setOpen(true)}
          >
              Add Network
          </Button>
      }
    </>
  );
}

export default EditNetworkEntry;
